<template>  
  
  <div class="d-flex">
    
    <v-avatar v-if="showImage" tile class="align-self-start mx-2" size="30">
        <v-img :src="logo"></v-img>
    </v-avatar>

    <div class="align-self-start mx-2">
          <div class="mb-1">
            <v-icon size="12" class="mr-1">flaticon-event</v-icon>
            <span class="mr-1">{{new Date(appointment.timestamp) | moment('DD/MM/YYYY HH:mm')}}</span>

            <template v-if="showStatus">
              <v-chip
                  v-if="appointment.is_booked"
                  
                  color="green"
                  text-color="white"     
                  x-small         
                >
                  <v-avatar left>
                    <v-icon size="12">flaticon-check-mark</v-icon>
                  </v-avatar>
                  Réservé
              </v-chip>
              
              <v-chip
                  v-else-if="appointment.is_confirmed"
                  
                  color="green"
                  text-color="white"     
                  x-small         
                >
                  <v-avatar left>
                    <v-icon size="12">flaticon-check-mark</v-icon>
                  </v-avatar>
                  Confirmé
              </v-chip>
              
              <v-chip
                  v-else-if="appointment.is_refused"
                  
                  color="grey"
                  text-color="white"     
                  x-small         
                >
                  <v-avatar left>
                    <v-icon size="12">flaticon-cancel</v-icon>
                  </v-avatar>
                  Refusé
              </v-chip>

              <v-chip
                  v-else-if="appointment.is_canceled"
                  
                  color="red"
                  text-color="white"     
                  x-small         
                >
                  <v-avatar left>
                    <v-icon size="12">flaticon-cancel</v-icon>
                  </v-avatar>
                  Annulation demandé
              </v-chip>

              <v-chip
                  v-else-if="appointment.is_cancel_confirmed"
                  
                  color="red"
                  text-color="white"     
                  x-small         
                >
                  <v-avatar left>
                    <v-icon size="12">flaticon-cancel</v-icon>
                  </v-avatar>
                  Annulé
              </v-chip>
            </template>
          </div>

          <div>                  
            <div>{{appointment.place.name}}</div>            

            <div v-if="showPhoneNumber">
              <v-chip
                  v-if="appointment.place.phone !== ''"
                  class="ml-0 mr-1 my-1"
                  color="blue"
                  text-color="white"     
                  small         
                >
                  <v-avatar left>
                    <v-icon size="16">flaticon-telephone</v-icon>
                  </v-avatar>
                  {{appointment.place.phone}}
              </v-chip> 
            </div>  
            
            <div v-if="showAddress" class="caption" v-html="formatAddress(appointment)" />   
            
            <div v-if="showServices">
              <v-icon
                v-if="appointment.services.find(s => s.type == $constants.vehicleServices.SERVICE_OVERHAUL)"
                class="ml-0 mr-1 my-1"
                color="teal accent-3"
                text-color="white"     
                size="16"
              >
                flaticon-oil
              </v-icon>
                
              <v-icon
                v-if="appointment.services.find(s => s.type == $constants.vehicleServices.SERVICE_TIRES)"
                class="ml-0 mr-1 my-1"
                color="deep-purple"
                text-color="white"
                size="16"
                >
                  flaticon-tire
              </v-icon>
                              
              <v-icon
                v-if="appointment.services.find(s => s.type == $constants.vehicleServices.SERVICE_BRAKEPADS)"
                class="ml-0 mr-1 my-1"
                color="orange darken-3"
                text-color="white"
                size="16"
                >
                  flaticon-brake                  
                </v-icon>

              <v-icon
                v-if="appointment.services.find(s => s.type == $constants.vehicleServices.SERVICE_MISC)"
                class="ml-0 mr-1 my-1"
                color="light-blue darken-2"
                text-color="white"
                size="16"
              >
                flaticon-tools-and-utensils
              </v-icon>                           
            </div>      
          </div>         
          
    </div>
  </div>
    
</template>

<script>
 
export default {
    name: "AAppointmentLabel",
    props: {
        appointment: Object,   
        showStatus: {
          type: Boolean,
          default: false
        },
        showPhoneNumber: {
          type: Boolean,
          default: false
        },
        showImage: {
          type: Boolean,
          default: false
        },
        showAddress: {
          type: Boolean,
          default: false
        },
        showServices: {
          type: Boolean,
          default: false
        }                 
    },
    data() {
      return {        
      }
    },
    computed: {
      logo: function() {   
        try {               
          return `${this.$constants.application.resourcesURL}/images/${this.appointment.place.provider.image}`;
        }
        catch {
          return '';
        }
      }
    },
    methods: {      
      formatAddress: function(appointment) {
        let address = (appointment.place.address1 ? `${appointment.place.address1}<br>` : '') + 
          (appointment.place.address2 ? `${appointment.place.address2}<br>` : '') + 
          (appointment.place.address3 ? `${appointment.place.address3}<br>` : '') + 
          (appointment.place.zip ? `${appointment.place.zip} `: '') + (appointment.place.city ? appointment.place.city : '')    
        return address      
      }
    }
}
</script>

<style lang="scss" scoped>
</style>