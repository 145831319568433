<template>
  
  <v-container fluid>
    <a-page-header :title="title" 
      icon="flaticon-support"
      :image="imgCustomerLogo"
    />    

    <v-divider/>

    <v-speed-dial
      v-model="fab"    
      top
      right        
      direction="bottom"
      open-on-hover
      transition="slide-y-reverse-transition"
    >
        <template v-slot:activator>
            <v-btn
                v-model="fab"
                color="blue darken-2"
                dark
                fab
            >
                <v-icon v-if="fab">mdi-close</v-icon>
                <v-icon v-else >mdi-dots-vertical</v-icon>
            </v-btn>
        </template>

        <v-btn
            v-show="showAddFabBtn"
            ref="fabAdd"
            fab
            dark
            small
            color="primary"
            @click="onClickFabAdd()"
            :title="fabBtnAddTitle"
        >
        <v-icon size="20">mdi-plus</v-icon>
        </v-btn>

        <v-btn
            v-show="!this.isNew && showMessageFabBtn"   
            ref="fabMessage"
            fab
            dark
            small
            color="light-green"
            @click="onClickFabMessage()"
            title="Envoyer un message au collaborateurs sélectionnés"
          >
          <v-icon size="20">flaticon-chat</v-icon>
          </v-btn>
        
        <v-btn
            v-show="!this.isNew && showAuditFabBtn"   
            ref="fabAudit"
            fab
            dark
            small
            color="purple"
            @click="onClickFabAudit()"
            title="Demander un audit vehicule"
        >
        <v-icon size="20">flaticon-front-car</v-icon>
        </v-btn>
        <v-btn
            v-show="!this.isNew && showMileageFabBtn"   
            ref="fabMileage"
            fab
            dark
            small
            color="indigo"
            @click="onClickFabMileage()"
            title="Demander un relevé kilométrique"
        >
        <v-icon size="20">flaticon-speed</v-icon>
        </v-btn>
        <v-btn
        fab
        dark
        small
        color="red"
        @click="onClickFabDelete()"
        :title="fabBtnDeleteTitle"
        >
        <v-icon>mdi-delete</v-icon>
        </v-btn>
    </v-speed-dial>   

    <v-row>
      <v-col sm="12">
        <v-tabs v-model="tab">
          <v-tab href="#tab-1">FICHE CLIENT</v-tab>          
          <v-tab v-if="!this.isNew" href="#tab-2">COLLABORATEURS</v-tab>          
          <v-tab v-if="!this.isNew" href="#tab-3">VEHICULES</v-tab> 
          <v-tab v-if="!this.isNew" href="#tab-4">AGENCES</v-tab>   
          <v-tab v-if="!this.isNew" href="#tab-5">RECOMMANDATIONS</v-tab>          
        </v-tabs>    

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1" class="pa-10">   
          <v-row>
            <v-col :md="12">            
              <v-form
                ref="form"
                v-model="isValid"
                lazy-validation
              >
                <v-row>
                  <v-col md="12" class="subtitle-1 font-weight-medium">IDENTITE</v-col>
                            
                  <v-col md="12">
                    <v-text-field
                      v-model="customer.name"                  
                      :rules="[v => !!v || 'Raison sociale obligatoire']"     
                      label="Raison sociale"
                      required
                    ></v-text-field>
                  </v-col>                                           
                </v-row>  

                <v-divider/>

                <v-row>
                  <v-col md="12" class="subtitle-1 font-weight-medium">ADRESSE</v-col>
                
                  <v-col md="6">
                    <v-text-field
                      v-model="customer.address1"                                    
                      label="Adresse"
                      required
                    ></v-text-field>
                  </v-col>  
                  <v-col md="6">
                    <v-text-field
                      v-model="customer.address2"                                     
                      label="Complément d'adresse"
                      required
                    ></v-text-field>
                  </v-col>                          

                  <v-col md="8">
                    <v-text-field
                      v-model="customer.city"                                      
                      label="Ville"
                      required
                    ></v-text-field>
                  </v-col>   
                  <v-col md="4">
                    <v-text-field
                      v-model="customer.zip"                                      
                      label="Code postal"
                      required
                    ></v-text-field>
                  </v-col>   
                
                  <v-col md="4" class="text-right">
                    <v-icon size="32" class="mt-3">flaticon-pin</v-icon>
                  </v-col>  
                  <v-col md="4">
                    <v-text-field
                      v-model="customer.latitude"                                     
                      label="Latitude"
                      required
                    ></v-text-field>
                  </v-col>         
                  <v-col md="4">
                    <v-text-field
                      v-model="customer.longitude"                                      
                      label="Longitude"
                      required
                    ></v-text-field>
                  </v-col>                         
                </v-row>   

                <v-row>
                  <v-col md="12" class="subtitle-1 font-weight-medium">PRISE EN CHARGE</v-col>
                
                  <v-col md="4">
                    <v-select
                      v-model="customer.assistance_provider_id"
                      :items="assistanceProviders"
                      item-text="name"
                      item-value="id"                    
                      label="Compagnie d'assistance"  
                      @change="onChangeAssistanceProvider"                                          
                    ></v-select>
                  </v-col>   

                  <v-col md="4">
                    <v-text-field
                      v-model="customer.assistance_number"                                      
                      label="N° de contrat"                      
                    ></v-text-field>
                  </v-col> 

                  <v-col md="4">
                    <v-text-field
                      v-model="customer.phone_assistance"                                      
                      label="Téléphone assistance"                      
                    ></v-text-field>
                  </v-col> 

                  <v-col md="4">
                    <v-select
                      v-model="customer.insurance_provider_id"
                      :items="insuranceProviders"
                      item-text="name"
                      item-value="id"                       
                      label="Compagnie d'assurance"
                      @change="onChangeInsuranceProvider"
                    ></v-select>
                  </v-col> 

                  <v-col md="4">
                    <v-text-field
                      v-model="customer.insurance_number"                                      
                      label="N° de contrat"                      
                    ></v-text-field>
                  </v-col>   

                  <v-col md="4">
                    <v-text-field
                      v-model="customer.phone_insurance"                                      
                      label="Téléphone assurance"                      
                    ></v-text-field>
                  </v-col> 
              </v-row>    

              <v-row>
                <v-col md="4">
                  <v-checkbox
                    v-model="customer.is_custom_busy_params"
                    label="Personnalisation de la plage de prise en charge"
                    color='primary'
                  ></v-checkbox>
                </v-col>
                                
                <v-col md="4" v-show="customer.is_custom_busy_params" class="pt-5">
                  <span class="subtitle-2">Jours d'ouverture</span>
                  <v-checkbox                    
                    v-model="days[0]"
                    label="Lundi"
                    color='success'
                  ></v-checkbox>

                  <v-checkbox
                    v-model="days[1]"
                    label="Mardi"
                    color='success'
                  ></v-checkbox>

                  <v-checkbox
                    v-model="days[2]"
                    label="Mercredi"
                    color='success'
                  ></v-checkbox>

                  <v-checkbox
                    v-model="days[3]"
                    label="Jeudi"
                    color='success'
                  ></v-checkbox>

                  <v-checkbox
                    v-model="days[4]"
                    label="Vendredi"
                    color='success'
                  ></v-checkbox>

                  <v-checkbox
                    v-model="days[5]"
                    label="Samedi"
                    color='success'
                  ></v-checkbox>

                  <v-checkbox
                    v-model="days[6]"
                    label="Dimanche"
                    color='success'
                  ></v-checkbox>
                </v-col>

                <v-col md="4" v-show="customer.is_custom_busy_params" class="pt-5">
                  <span class="subtitle-2">Plage d'ouverture</span>
                  <v-text-field
                      v-model="customer.time_slot_start"                                      
                      label="Heure de début"
                      :required="customer.is_custom_busy_params"
                    ></v-text-field>

                    <v-text-field
                      v-model="customer.time_slot_end"                                      
                      label="Heure de fin"
                      :required="customer.is_custom_busy_params"                    
                    ></v-text-field>
                </v-col>

              </v-row>         

                <v-row>
                  <v-col md="12" class="text-right">
                    <v-btn
                      :disabled="!isValid"
                      color="success"
                      class="mr-4"
                      @click="onClickBtnValidate"
                    >
                      Valider
                    </v-btn>     
                  </v-col> 
                </v-row>    
              </v-form>    
            </v-col>    
          </v-row>           
          </v-tab-item>

          <!-- COLLABORATEURS
            ----------------->
          <v-tab-item value="tab-2" v-if="!this.isNew"> 
            <a-user-list  
              download-btn   
              :customer="this.customer.id"
              @change="onChangeUserSelection"     
              @clickRow="onClickUserRow"     
              ref="aUserList"     
            />             
          </v-tab-item>                

          <!-- VEHICULES
          -------------->
          <v-tab-item value="tab-3" v-if="!this.isNew"> 
            <a-vehicle-list   
              download-btn
              :customer="this.customer.id"
              :status="1"
              @change="onChangeVehicleSelection"         
              @clickRow="onClickVehicleRow"
              ref="aVehicleList"
            />       
          </v-tab-item>  

          <!-- AGENCES
          ----------->
          <v-tab-item value="tab-4" v-if="!this.isNew"> 
            <a-place-list   
              add-btn
              :customer="this.customer.id"
              @clickBtnAdd="onClickBtnAddPlace"
              @clickRow="onClickRowPlace"
              ref="aPlaceList"
            />       
          </v-tab-item>  

          <!-- RECOMMANDATIONS
          ------------------->
          <v-tab-item value="tab-5" v-if="!this.isNew" class="pa-10"> 
            <a-services-recommendations-panel v-if="customer.recommendations" 
              maintenance
              tires
              misc
              :recommendations="customer.recommendations"      
              @change="customer.recommendations=$event"        
            />
        

            <v-row>
              <v-col md="12" class="text-right">
                <v-btn
                  :disabled="!isValid"
                  color="success"
                  class="mr-4"
                  @click="onClickBtnValidate"
                >
                  Valider
                </v-btn>     
              </v-col> 
            </v-row>    
          </v-tab-item>  
        </v-tabs-items>                  
      </v-col>
    </v-row>

    <v-row>
      <a-modal-message-maker                 
        :title="modalMessageTitle"
        auto-close
        @sendMessage="onClickBtnSendMessage($event)"
        ref="modalMessageMaker"
        />     
    </v-row>

    <v-dialog        
      v-model="dialogPlace"
      max-width="60%"          
    >        
      <v-card min-height="400px">
        <v-card-title>AGENCE</v-card-title>
        <v-card-text>
          <a-place-form
            :place="place"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
      
</template>

<script>
import { mapGetters } from 'vuex'

import AVehicleList from "@/components/AVehicleList"
import AUserList from "@/components/AUserList"
import APlaceList from "@/components/APlaceList"
import APlaceForm from "@/components/APlaceForm"
import AServicesRecommendationsPanel from "@/components/AServicesRecommendationsPanel"

import APageHeader from "@/components/APageHeader"
import AModalMessageMaker from "@/components/AModalMessageMaker"


export default {
  name: 'Customer',  
  components: { APageHeader, AModalMessageMaker, AVehicleList, AUserList, APlaceList, APlaceForm, AServicesRecommendationsPanel},
  data: () => ({
    imgCustomerLogo: "",    
    dialogPlace: false,
    dataObject: {},    
    dateSelected: new Date().toISOString().substr(0, 10),     
    updateArgs: [true, true, {duration: 1000}],
    selectedVehicle: {},
    vehicles: [],
    days: [],

    modalMessageTitle: '',

    fab: false,
    tab: 0,
    model: '',    
    isValid: true,        
    customer: {id: 0},
    customers: [],  
    place: {},  

    vehicleItems: [],
    selectedVehiclesItems: [],
    selectedUsersItems: [],
    assistanceProviders: [],
    insuranceProviders: [],
    
    // Zones de recherche
    searchTab1: '',
    searchTab2: '',
    searchTab3: '',
    searchTab4: '',
    searchTab5: '',
    searchTab6: '',
    searchTab7: '',

    fabBtnAddTitle: '',
    fabBtnDeleteTitle: '',    
    isLoading: true,
    isNew: false,
    isManagingUser: false,
    showAddFabBtn: false,
    showMessageFabBtn: false,
    showAuditFabBtn: false,
    showMileageFabBtn: false,
  }),
  methods: {
    // Méthodes évènements UI
    // ----------------------
    onClickFabAdd: function() {
      this.dataObject = undefined
      switch (this.tab) {
        case "tab-1":
          break

        case "tab-2":          
          this.$router.push({
            name: 'Profile',
            params: { 
              id: 0,
              customer: this.customer.id
            }
          })
          break

        case "tab-3":
          this.$router.push({
            name: 'Vehicle',
            params: { 
              id: 0,
              customer: this.customer.id
            }
          })
          break

        default:
      }
    },  
    onClickFabMessage: function() {
      this.modalMessageTitle = 'Envoyer un message' + (this.selectedUsersItems.length > 1 ? ` aux ${this.selectedUsersItems.length} collaborateurs sélectionnés` : ` à ${this.selectedUsersItems[0].firstname} ${this.selectedUsersItems[0].lastname}`)
      this.$refs.modalMessageMaker.show()
    },
    onClickFabAudit: function() {      
      let url = 'audit?'
      let ids

      if (this.isManagingUser) {
        url += 'user&'
        ids = this.selectedUsersItems.map(el => el.id)        
      }
      else ids = this.selectedVehiclesItems.map(el => el.id)
      
      url += `ids=${ids.join(',')}`
      
      this.$http
            .post(url)   
            .then(response => {
              if (response.status == 200) this.getMileages()
            })
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
    },
    onClickFabMileage: function() {            
      let url = 'mileage?'
      let ids

      if (this.isManagingUser) {
        url += 'user&'
        ids = this.selectedUsersItems.map(el => el.id)        
      }
      else ids = this.selectedVehiclesItems.map(el => el.id)
      
      url += `ids=${ids.join(',')}`
      
      this.$http
            .post(url)   
            .then(response => {
              if (response.status == 200) this.getMileages()
            })
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
    },
    onClickFabDelete: function() {      
      switch (this.tab) {
        case "tab-1":
          this.onDeleteCustomer()
          break

        case "tab-2":
          this.onDeleteUsers()
          break

        case "tab-3":
          this.onDeleteVehicles()
          break

        default:
      }
    },    
    onClickTabLeases: function() {
      this.getLeases()
    },
    onClickBtnSendMessage: function(message) {
        let url = "messages"
        const ids = this.selectedUsersItems.map(el => el.id)      
        const data = {
            to: ids.join(','),
            text: message
        } 
        this.$http
          .post(url, data)            
          .then(response => {                        
              if (response.status == 200) {
                this.get(false)    
                this.dialog = false            
              }                                          
          })
          .catch(error => {             
              console.log(error)                 
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
    },
    onClickBtnValidate: function() {      
      
      this.customer.busy_days = this.busyDays           

      let url = `customers`
      if (this.customer.id == 0) {
        this.$http.post(url, this.customer)   
          .then(response => {
            if (response.status == 200) {
              this.initData(response.data)
              this.$root.$emit('notify', 'Compte client crée', 'success', 'mdi-checkbox-marked-circle')
              this.isNew = false
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
      else {
        this.$http.put(url, this.customer)   
          .then(response => {
            if (response.status == 200) {
              this.initData(response.data)
              this.$root.$emit('notify', 'Compte client mis à jour', 'success', 'mdi-checkbox-marked-circle')
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
    },    
    onChangeUserSelection: function(event) {
      this.selectedUsersItems = event       
      this.showMessageFabBtn = this.selectedUsersItems.length > 0
    },        
    onChangeVehicleSelection: function(event) {
      this.selectedVehiclesItems = event 
      this.showAuditFabBtn = this.selectedVehiclesItems.length > 0
      this.showMileageFabBtn = this.selectedVehiclesItems.length > 0      
    },
    onClickVehicleRow: function(event) { 
      this.$router.push({
        name: 'Vehicle', 
        params: { 
          id: event.id
        }
      })
    },
     onClickUserRow: function(event) { 
      this.$router.push({
        name: 'Profile', 
        params: { 
          id: event.id
        }
      })
    },
    onClickBtnAddPlace: function() {
      this.place = {}
      this.dialogPlace = true      
    },
    onClickRowPlace: function(event) {
      this.place = {...event}
      this.dialogPlace = true      
    },
    onChangeInsuranceProvider: function(event) {
      if (event == 0) {
        this.customer.phone_insurance = ''
        this.customer.insurance_number = ''
      }
      else {
        this.customer.phone_insurance = this.insuranceProviders.find(el => el.id == event).support_phone_number
      }
    },
    onChangeAssistanceProvider: function(event) {
      if (event == 0) {
        this.customer.phone_assistance = ''
        this.customer.assistance_number = ''
      }
      else {
        this.customer.phone_assistance = this.assistanceProviders.find(el => el.id == event).support_phone_number
      }
    },
    // Méthodes d'accès aux données
    // ----------------------------
    // Données client
    get: function() {    
        if (this.$route.params.id !== 0) { 
          this.isLoading = true
          let url = `customers/${this.$route.params.id}`
          this.$http
              .get(url)            
              .then(response => {                        
                  if (response.status == 200) {
                    this.initData(response.data)
                    this.imgCustomerLogo = `${this.$constants.application.resourcesURL}/images/${this.customer.image}`                      
                  }                                          
                  this.isLoading = false 
              })
              .catch(error => {                
                  this.isLoading = false
                  if (error.response.status === 401) this.$root.$emit("logout"); 
                  else this.$root.$emit("serverError", error);     
              });                                  
        }        
    },
    getCustomer: function() {
      let url = `customers`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.customers = response.data
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })                     
    },
    // Liste des Assiteurs
    getAssistanceProviders: function() {
      let url = `providers/types/6`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.assistanceProviders = [{id: 0, name: ''}, ...response.data]
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
    // Liste des Assureurs
    getInsuranceProviders: function() {
      let url = `providers/types/5`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.insuranceProviders = [{id: 0, name: ''}, ...response.data]
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
    onDeleteCustomer: function() {
      let url = `customers/${this.customer.id}`
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {                
                this.$router.go(-1)
              }              
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })    
    },
    onDeleteUsers: function() {
      const ids = this.selectedUsersItems.map(el => el.id)    
      let url = `users?ids=${ids.join(',')}` 
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {                
                this.$refs.aUserList.deleteItems(ids)
              }              
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })    
    },
    onDeleteVehicles: function() {
      const ids = this.selectedVehiclesItems.map(el => el.id)    
      let url = `vehicles?ids=${ids.join(',')}` 
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {                
                this.$refs.aVehicleList.deleteItems(ids)
              }              
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })        
    },
    initData: function(data) {
      this.customer = data           
      this.days = [
        this.customer.busy_days & 64,
        this.customer.busy_days & 32,
        this.customer.busy_days & 16,
        this.customer.busy_days & 8,
        this.customer.busy_days & 4,
        this.customer.busy_days & 2,
        this.customer.busy_days & 1
      ]      
    }
  },  
  watch: {    
    tab: function() {           

      switch (this.tab) {
        case "tab-2":
          this.isManagingUser = true
          this.fabBtnAddTitle = "Ajouter un collaborateur"
          this.fabBtnDeleteTitle = "Supprimer les collaborateurs sélectionnés"
          this.showAddFabBtn = true
          break
          
        case "tab-3":
          this.isManagingUsers = false 
          this.fabBtnAddTitle = "Ajouter un vehicule"
          this.fabBtnDeleteTitle = "Supprimer les vehicules sélectionnées"
          this.showAddFabBtn = true
          break  
          
        default:
          this.isManagingUsers = false 
          this.showAddFabBtn = false
          this.showMessageFabBtn = false
          this.showAuditFabBtn = false
          this.showMileageFabBtn = false
      }
    },
  },
  computed: {
    ...mapGetters([
      'userEnvironment'
    ]),
    title: function() {
      return this.customer.id == 0 
        ? "Nouveau compte client" 
        : this.customer.name
    },
    busyDays: function() {
      return  64 * (this.days[0] ? 1 : 0) +
              32 * (this.days[1] ? 1 : 0) +
              16 * (this.days[2] ? 1 : 0) +
              8 * (this.days[3] ? 1 : 0) +
              4 * (this.days[4] ? 1 : 0) +
              2 * (this.days[5] ? 1 : 0) +
              1 * (this.days[6] ? 1 : 0)
    }  
  },
  mounted() {
    this.getCustomer();    
    this.getAssistanceProviders()
    this.getInsuranceProviders();
    this.isNew = this.$route.params.id == 0    
    if (!this.isNew) this.get()        
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;  
}

.v-btn--floating {
  position: relative;
}
</style>