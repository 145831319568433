<template>  
        <!--- RENDEZ-VOUS --->
        <v-card
          class="elevation-2"
          :color="getAppointmentColor(activity.type)"
        >
          <v-card-title class="subtitle-2 pb-0">
            <v-icon x-small class="mr-1">flaticon-consulting-message</v-icon>
            Proposition de rendez-vous

            <v-chip
                v-if="activity.type == $constants.activity.TYPE_APPOINTMENT_CONFIRMED"
                class="ml-5 mr-1 my-1"
                color="green"
                text-color="white"     
                x-small         
              >
                <v-avatar left>
                  <v-icon size="12">flaticon-check-mark</v-icon>
                </v-avatar>
                Confirmé
            </v-chip>

            <v-chip
                v-if="activity.type == $constants.activity.TYPE_APPOINTMENT_BOOKED"
                class="ml-5 mr-1 my-1"
                color="green"
                text-color="white"     
                x-small         
              >
                <v-avatar left>
                  <v-icon size="12">flaticon-check-mark</v-icon>
                </v-avatar>
                Réservé
            </v-chip>

            <v-chip
                v-if="activity.type == $constants.activity.TYPE_APPOINTMENT_REFUSED"
                class="ml-5 mr-1 my-1"
                color="grey"
                text-color="white"     
                x-small         
              >
                <v-avatar left>
                  <v-icon size="12">flaticon-cancel</v-icon>
                </v-avatar>
                Refusé
            </v-chip>

            <v-chip
                v-if="activity.type == $constants.activity.TYPE_APPOINTMENT_CANCELED"
                class="ml-5 mr-1 my-1"
                color="red"
                text-color="white"     
                x-small         
              >
                <v-avatar left>
                  <v-icon size="12">flaticon-cancel</v-icon>
                </v-avatar>
                Annulation demandé
            </v-chip>

            <v-chip
                v-if="activity.type == $constants.activity.TYPE_APPOINTMENT_CANCEL_CONFIRMED"
                class="ml-5 mr-1 my-1"
                color="red"
                text-color="white"     
                x-small         
              >
                <v-avatar left>
                  <v-icon size="12">flaticon-cancel</v-icon>
                </v-avatar>
                Annulé
            </v-chip>

          </v-card-title>
          <v-card-text class="body-2 text-left">
            <div class="font-weight-medium">Le {{new Date(activity.appointment.timestamp) | moment('DD/MM/YYYY HH:mm')}}</div>
            <div class="font-weight-medium">{{activity.appointment.place.name}}</div>            
            
            <div>
              <v-chip
                  v-if="activity.appointment.place.phone !== ''"
                  class="ml-0 mr-1 my-1"
                  color="blue"
                  text-color="white"     
                  small         
                >
                  <v-avatar left>
                    <v-icon size="16">flaticon-telephone</v-icon>
                  </v-avatar>
                  {{activity.appointment.place.phone}}
              </v-chip> 
            </div>  
            
            <div class="caption" v-html="formatAddress(activity.appointment)" />   
            
            <div>
              <v-chip
                v-if="activity.appointment.services.find(s => s.type == $constants.vehicleServices.SERVICE_OVERHAUL)"
                class="ml-0 mr-1 my-1"
                color="teal accent-3"
                text-color="white"     
                small         
              >
                <v-avatar left>
                  <v-icon size="16">flaticon-oil</v-icon>
                </v-avatar>
                Vidange/Revision
              </v-chip>

              <v-chip
                v-if="activity.appointment.services.find(s => s.type == $constants.vehicleServices.SERVICE_TIRES)"
                class="ml-0 mr-1 my-1"
                color="deep-purple"
                text-color="white"
                small
              >
                <v-avatar left>
                  <v-icon size="16">flaticon-tire</v-icon>
                </v-avatar>
                Pneumatiques
              </v-chip>

              <v-chip
                v-if="activity.appointment.services.find(s => s.type == $constants.vehicleServices.SERVICE_BRAKEPADS)"
                class="ml-0 mr-1 my-1"
                color="orange darken-3"
                text-color="white"
                small
              >
                <v-avatar left>
                  <v-icon size="16">flaticon-brake</v-icon>
                </v-avatar>
                Plaquettes de freins
              </v-chip>

              <v-chip
                v-if="activity.appointment.services.find(s => s.type == $constants.vehicleServices.SERVICE_MISC)"
                class="ml-0 mr-1 my-1"
                color="light-blue darken-2"
                text-color="white"
                small
              >
                <v-avatar left>
                  <v-icon size="16">flaticon-tools-and-utensils</v-icon>
                </v-avatar>
                Divers/Réparation
              </v-chip>             
            </div>                 
          </v-card-text>

          <v-card-actions>
             <v-btn
              v-if="validateBtn"
              color="green"
              text
              @click="changeAppointmentStatus(activity.reference_id, 'booked')"
            >
              VALIDER LA RESERVATION
            </v-btn>    

            <v-btn
              v-if="cancelBtn"
              color="blue"
              text
              @click="changeAppointmentStatus(activity.reference_id, 'removed')"
            >
              ANNULER
            </v-btn>    

             <v-btn
                v-if="modifyBtn"
                color="blue"
                text
                @click="$emit('update-activity', activity)"
              >
              MODIFIER
            </v-btn>  

            <v-btn
              v-if="renewBtn"
              color="blue"
              text
              @click="changeAppointmentStatus(activity.reference_id, 'renewed')"
            >
              RENOUVELLER
            </v-btn>   

            <v-btn
              v-if="recallBtn"
              color="blue"
              text
              @click="changeAppointmentStatus(activity.reference_id, 'notified')"
            >
              RELANCER
            </v-btn> 

            <v-btn
              v-if="confirmBtn"
              color="red"
              text
              @click="changeAppointmentStatus(activity.reference_id, 'cancelconfirmed')"
            >
              CONFIRMER L'ANNULATION
            </v-btn>                        
          </v-card-actions>
        </v-card>
</template>

<script>
 
export default {
    name: "AActivityAppointmentItem",
    props: {
        activity: Object,        
        validateBtn: {
          type: Boolean,
          default: false
        },
        cancelBtn: {
          type: Boolean,
          default: false
        },
        modifyBtn: {
          type: Boolean,
          default: false
        },
        renewBtn: {
          type: Boolean,
          default: false
        },
        recallBtn: {
          type: Boolean,
          default: false
        },
        confirmBtn: {
          type: Boolean,
          default: false
        }
    },
    data() {
      return {
        appointmentTypes: [
          this.$constants.activity.TYPE_NEW_APPOINTMENT,
          this.$constants.activity.TYPE_APPOINTMENT_CONFIRMED,
          this.$constants.activity.TYPE_APPOINTMENT_REFUSED,
          this.$constants.activity.TYPE_APPOINTMENT_CANCELED,
          this.$constants.activity.TYPE_APPOINTMENT_CANCEL_CONFIRMED,
          this.$constants.activity.TYPE_APPOINTMENT_BOOKED
        ]
      }
    },
    methods: {
      getAppointmentColor: function(type) {
        let shade = this.$vuetify.theme.dark ? 'darken-3' : 'lighten-3'
        if (type == this.$constants.activity.TYPE_NEW_APPOINTMENT) return `blue ${shade}`
        if (type == this.$constants.activity.TYPE_APPOINTMENT_CONFIRMED) return `green ${shade}`
        if (type == this.$constants.activity.TYPE_APPOINTMENT_REFUSED) return `grey ${shade}`
        if (type == this.$constants.activity.TYPE_APPOINTMENT_CANCELED) return `red ${shade}`
        if (type == this.$constants.activity.TYPE_APPOINTMENT_CANCEL_CONFIRMED) return `red ${shade}`
        if (type == this.$constants.activity.TYPE_APPOINTMENT_BOOKED) return `green ${shade}`
      },
      formatAddress: function(appointment) {
        let address = (appointment.place.address1 ? `${appointment.place.address1}<br>` : '') + 
          (appointment.place.address2 ? `${appointment.place.address2}<br>` : '') + 
          (appointment.place.address3 ? `${appointment.place.address3}<br>` : '') + 
          (appointment.place.zip ? `${appointment.place.zip} `: '') + (appointment.place.city ? appointment.place.city : '')    
        return address      
      },
      // Evenements sur actions utilisateur
      // ----------------------------------
      changeAppointmentStatus: function(id, status) {          
          let url = `appointments/${id}/${status}`;                             
          this.$http
              .put(url)            
              .then(function(response) { 
                if (response.status == 200) {
                  this.$emit('change')
                }  
              }.bind(this))
      },
      onClickBtnSaveInfos: function() {    
          let data = {
            id: this.activity.reference_id,
            billing_data: {
              agreement_number: this.activity.appointment.billing_data.agreement_number,
              amount: this.activity.appointment.billing_data.amount,
              quote_id: this.activity.appointment.billing_data.quote_id,
              invoice_id: this.activity.appointment.billing_data.invoice_id              
            }
          }     
          let url = `appointments/${this.activity.reference_id}}`;                             
          this.$http
              .put(url, data)            
              .then(function(response) { 
                if (response.status == 200) {
                  this.$emit('change')
                }  
              }.bind(this))
      }    
    }
}
</script>

<style lang="scss" scoped>
</style>