<template>
    <v-toolbar flat>      
      <div v-if="image !== ''" class="d-flex flex-no-wrap align-center mr-5">
          <v-img max-height="64" max-width="64" contain :src="image"/>                                       
      </div>
      <v-icon v-else size=32 class="mr-5">{{icon}}</v-icon>
      <v-toolbar-title class="title">{{title}}</v-toolbar-title>
      <v-chip v-if="chipText !== undefined && chipText !== ''" :color="chipColor" small class="white--text ml-2">{{chipText}}</v-chip>
      <v-btn v-if="iconPencil" icon color="blue" class="ml-2" @click="$emit('update')"><v-icon size=16>mdi-pencil</v-icon></v-btn>      
      <v-btn v-if="iconFilter" icon color="blue" class="ml-2" @click="$emit('filter')"><v-icon size=16>{{filterApplied ? 'mdi-filter-check' : 'mdi-filter'}}</v-icon></v-btn>
    </v-toolbar>  
</template>

<script>
export default {
  name: 'APageHeader',
  props: {
    icon: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    chipColor: {
      type: String
    },
    chipText: {
      type: String
    },
    iconPencil: {
      type: Boolean,
      default: false
    },
    iconFilter: {
      type: Boolean,
      default: false
    },
    filterApplied: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
   
  })
}
</script>
