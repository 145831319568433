<template>
    <v-list-item class="grow">
      <v-list-item-avatar>
         <v-icon class="mr-1">mdi-share-variant</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="title">{{barTitle}}</v-list-item-title>
      </v-list-item-content>

      <v-row
        align="center"
        justify="end"
      >
      
     
        <v-icon class="mr-1">mdi-share-variant</v-icon>
        <span class="subheading">{{counter}}</span>
      </v-row>
    </v-list-item>
</template>

<script>
 
export default {
    name: "AActivityStreamActionBar",
    props: {
      barTitle: {
        type: String,
        default: "Flux d'activités"
      },
      barStyle: {
        type: String,
        default: "request"
      },
      counter: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        
      }    
    },
    updated() {      

    }
}
</script>

<style lang="scss" scoped>
 
</style>