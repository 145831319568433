<template>
  <v-row v-if="vehicle.lease"> 
    <v-col :md="colSize" v-if="!noConsumption" class="ma-0 pa-0">
      <highcharts :options="chartOptionsConsumption" :updateArgs="updateArgs"/>
    </v-col>

    <v-col :md="colSize" class="ma-0 pa-0">
      <highcharts :options="{...chartOptionsGauge, ...chartOptionsDuration}" :updateArgs="updateArgs"/>
    </v-col>

    <v-col :md="colSize" class="ma-0 pa-0">
      <highcharts :options="{...chartOptionsGauge, ...chartOptionsMileage}" :updateArgs="updateArgs"/>
    </v-col>

    <v-col :md="colSize" v-if="!noRollinglaw" class="ma-0 pa-0">
      <highcharts :options="{...chartOptionsGauge, ...chartOptionsLaw}" :updateArgs="updateArgs"/>
    </v-col>
  </v-row>    
  
</template>

<script>
import Highcharts from 'highcharts'

export default {
    name: "AVehicleCharts",
    props: {
      vehicle: Object,
      orientation: {
        type: String,
        default: "horizontal"
      },
      noConsumption: {
        type: Boolean,
        default: false
      },
      noRollinglaw: {
        type: Boolean,
        default: false
      },
      height: {
        type: Number,
        default: 250
      }
    },
    data() {
      return {   
        maxConsumption: 12,
        gaugeFontValue: Math.round(this.height * 0.075),
        gaugeFontScale: Math.round(this.height * 0.05),   
        updateArgs: [true, true, {duration: 1000}],
        chartOptionsConsumption: {
          chart: {
            type: 'solidgauge',
            height: this.height + 'px',
            backgroundColor: 'transparent',            
            events: {
                
            }
          },

          title: {
              text: 'Consommation',
              style: {
                  fontSize: '12px'
              }
          },

          tooltip: {
              borderWidth: 0,
              backgroundColor: 'none',
              shadow: false,
              style: {
                  fontSize: '10px'
              },
              valueSuffix: 'l',
              pointFormat: '{series.name}<br><span style="font-size:1.5em; color: {point.color}; font-weight: bold;">{point.value}</span><span style="color: {point.color};">l/km</span>',
              positioner: function (labelWidth) {
                  return {
                      x: (this.chart.chartWidth - labelWidth) / 2,
                      y: (this.chart.plotHeight / 2) + 15
                  };
              }
          },

          credits: {
              enabled: false
          },

          pane: {
              startAngle: 90,
              endAngle: 450,
              background: [{ // Track for Move
                  outerRadius: '112%',
                  innerRadius: '88%',
                  backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                      .setOpacity(0.3)
                      .get(),
                  borderWidth: 0
              }, { // Track for Exercise
                  outerRadius: '87%',
                  innerRadius: '63%',
                  backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1])
                      .setOpacity(0.3)
                      .get(),
                  borderWidth: 0
              }, { // Track for Stand
                  outerRadius: '62%',
                  innerRadius: '38%',
                  backgroundColor: Highcharts.color(Highcharts.getOptions().colors[2])
                      .setOpacity(0.3)
                      .get(),
                  borderWidth: 0
              }]
          },

          yAxis: {
              min: 0,
              max: 100,
              lineWidth: 0,
              tickPositions: []
          },

          plotOptions: {
              solidgauge: {
                  dataLabels: {
                      enabled: false
                  },
                  linecap: 'round',
                  stickyTracking: false,
                  rounded: true
              }
          },

          series: [{
              name: 'Théorique',
              data: [{
                  color: Highcharts.getOptions().colors[0],
                  radius: '112%',
                  innerRadius: '88%',
                  y: 0,
                  value: 0
              }]
          }, {
              name: 'Moyenne',
              data: [{
                  color: Highcharts.getOptions().colors[1],
                  radius: '87%',
                  innerRadius: '63%',
                  y: 0,
                  value: 0
              }]
          }, {
              name: 'Mensuel',
              data: [{
                  color: Highcharts.getOptions().colors[2],
                  radius: '62%',
                  innerRadius: '38%',
                  y: 0,
                  value: 0
              }]
          }]
        },

        chartOptionsGauge: {
          chart: {
            type: 'solidgauge',
            height: this.height + 'px',   
            backgroundColor: 'transparent'         
        },            

        credits: {
              enabled: false
          },

        exporting: {
            enabled: false
        },

        tooltip: {
            enabled: false
        },

        plotOptions: {
            solidgauge: {            
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                } 
            }
        }
        },

        chartOptionsDuration: {
          title: {
              text: 'Durée',
              style: {
                  fontSize: '12px'
              },              
          },
          yAxis: {
            min: 0,
            max: 36,                             
            minorTickInterval: null,
            tickInterval: 36,      
            tickLength: 0,  
            labels: {
              y: 20
            }
          },          
        
          pane: {
              center: ['50%', '75%'],
              size: "140%",
              startAngle: -90,
              endAngle: 90,
              
              background: {
                  backgroundColor: "rgba(212, 180, 0, 0.3)",
                  innerRadius: '70%',
                  outerRadius: '100%',
                  shape: 'arc'
              },
              
          },

          series: [{
              radius: '70%',
              innerRadius: '100%',
              name: 'Durée',
              data: [
                {
                  y: 31,
                  color: "rgba(212, 180, 0, 1)" 
                }],               
              dataLabels: {
                  y: -25,
                  format: '',
                  color: this.$vuetify.theme.dark ? "#FFF" : "#000"    
              },          
          }]
        },

        chartOptionsMileage: {
          title: {
              text: 'Kilométrage',
              style: {
                  fontSize: '12px'
              }
          },
          yAxis: {
            min: 0,
            max: 0,                             
            minorTickInterval: null,
            tickInterval: 0,      
            tickLength: 0,  
            labels: {
              y: 20
            }
          },          
        
          pane: {
              center: ['50%', '75%'],
              size: "140%",
              startAngle: -90,
              endAngle: 90,
              
              background: {
                  backgroundColor: 'rgba(169, 3, 252, 0.3)',
                  innerRadius: '70%',
                  outerRadius: '100%',
                  shape: 'arc'
              },
              
          },

          series: [{
              radius: '70%',
              innerRadius: '100%',
              name: 'Durée',
              data: [
                {
                  y: 60000,
                  color: 'rgba(169, 3, 252, 1)'
                }],               
              dataLabels: {
                  y: -25,
                  format: '',
                  color: this.$vuetify.theme.dark ? "#FFF" : "#000"    
              },          
          }]
        },

        chartOptionsLaw: {
          title: {
              text: 'Loi de roulage',
              style: {
                  fontSize: '12px',                  
              },              
          },
          yAxis: {
            min: 0,
            max: 200,                             
            minorTickInterval: null,
            tickInterval: 200,      
            tickLength: 0,  
            labels: {
              y: 20
            }
          },                  
          pane: {
              center: ['50%', '75%'],
              size: "140%",
              startAngle: -90,
              endAngle: 90,
              
              background: {
                  backgroundColor: '#FFF',
                  innerRadius: '70%',
                  outerRadius: '100%',
                  shape: 'arc'
              },
              
          },
          series: [{
              radius: '70%',
              innerRadius: '100%',
              name: 'Durée',
              data: [
                {
                  y: 0,
                  color: '#FFF'
                }],               
              dataLabels: {
                  y: -25,
                  format: '',
                  color: this.$vuetify.theme.dark ? "#FFF" : "#000"                     
              },          
          }]
        }
      }    
    },  
    methods: {      
      setRollingLawFillColor: function() {
          if (this.vehicle.rollinglaw < 90) return "rgba(242, 156, 56, 1)"                                   
          else if (this.vehicle.rollinglaw > 110) return "rgba(255, 82, 65, 1)"   
          else return "rgba(36, 181, 44, 1)"   
      }, 
      setRollingLawFillBackColor: function() {
          if (this.vehicle.rollinglaw < 90) return "rgba(242, 156, 56, 0.3)"                 
          else if (this.vehicle.rollinglaw > 110) return "rgba(255, 82, 65, 0.3)"  
          else return "rgba(36, 181, 44, 0.3)"        
      }
    }, 
    computed: {
      colSize: function() {
        let size
        if (this.orientation == "vertical") size = "12"
        else {
          let chartCount = 4
          if (this.noConsumption) chartCount--
          if (this.noRollinglaw) chartCount--

          size = 12 / chartCount
        }
        return size
      }
    },
    mounted() {      

      this.maxConsumption = 1.5 * this.vehicle.model.consumptions.mixte               
      this.chartOptionsConsumption.series[0].data[0].y = 100 * this.vehicle.model.consumptions.mixte / this.maxConsumption
      this.chartOptionsConsumption.series[0].data[0].value = this.vehicle.model.consumptions.mixte 
      this.chartOptionsConsumption.series[1].data[0].y = 100 * this.vehicle.consumption_avg / this.maxConsumption
      this.chartOptionsConsumption.series[1].data[0].value = this.vehicle.consumption_avg
      this.chartOptionsConsumption.series[2].data[0].y = 100 * this.vehicle.consumption / this.maxConsumption
      this.chartOptionsConsumption.series[2].data[0].value = this.vehicle.consumption


      this.chartOptionsLaw.pane.background.backgroundColor = this.setRollingLawFillBackColor()
      this.chartOptionsLaw.series[0].data[0].y = this.vehicle.rollinglaw
      this.chartOptionsLaw.series[0].data[0].color = this.setRollingLawFillColor()
      this.chartOptionsLaw.series[0].dataLabels.format = '<div style="text-align:center">' +
                      '<span style="font-size:' + this.gaugeFontValue + 'px">{y}</span><br/>' +
                      '<span style="font-size:' + this.gaugeFontScale + 'px;opacity:0.4">%</span>' +
                      '</div>'

      this.chartOptionsDuration.yAxis.max = this.vehicle.lease.duration
      this.chartOptionsDuration.yAxis.tickInterval = this.vehicle.lease.duration
      this.chartOptionsDuration.series[0].data[0].y = this.vehicle.lease.since
      this.chartOptionsDuration.series[0].dataLabels.format = '<div style="text-align:center">' +
                      '<span style="font-size:' + this.gaugeFontValue + 'px">{y}</span><br/>' +
                      '<span style="font-size:' + this.gaugeFontScale + 'px;opacity:0.4">Mois</span>' +
                      '</div>'

      this.chartOptionsMileage.yAxis.max = this.vehicle.lease.distance
      this.chartOptionsMileage.yAxis.tickInterval = this.vehicle.lease.distance
      this.chartOptionsMileage.series[0].data[0].y = this.vehicle.distance
      this.chartOptionsMileage.series[0].dataLabels.format = '<div style="text-align:center">' +
                      '<span style="font-size:' + this.gaugeFontValue + 'px">{y}</span><br/>' +
                      '<span style="font-size:' + this.gaugeFontScale + 'px;opacity:0.4">Km</span>' +
                      '</div>'
      
    }
}
</script>

<style lang="scss" scoped>
 
</style>