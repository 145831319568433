<template>

    <v-container fluid>
      <a-page-header title="Imports" icon="flaticon-cloud-computing"/>    

      <v-divider/>

      <v-row>
        <v-col sm="12">
          <v-tabs v-model="tab">
            <v-tab href="#tab-1">CARBURANT</v-tab>
            <v-tab href="#tab-2" @click="onClickTabLeases">ENTRETIENS</v-tab>
            <v-tab href="#tab-3" @click="onClickTabDrivers">PARC VEHICULES</v-tab>            
            <v-tab href="#tab-4" @click="onClickTabFuelIntakes">SITES FOURNISSEURS</v-tab>          
          </v-tabs> 

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">   
              <!--- IMPORT CARBURANT 
              --------------------->              
              <v-row class="pa-5" justify="space-around">
                <v-col
                  v-for="fuelImport in fuelImportsSelection"
                  :key="fuelImport.id"
                  cols="12"
                  md="3"
                  class="mb-2"
                  @click="onClickBtnImport(1, fuelImport.id)"
                >
                  <div class="subtitle-2">Import carburant {{fuelImport.name}}</div>
                  <v-btn height="100%" block class="pa-3">  
                      <v-img 
                        max-height="100"
                        max-width="100"
                        :src="`${$constants.application.resourcesURL}/images/${fuelImport.image}`" 
                      />                                         
                  </v-btn>
                </v-col>
              </v-row>       
            </v-tab-item>

            <v-tab-item value="tab-2"> 
              <!--- IMPORT ENTRETIENS 
              ---------------------->
              <v-row class="pa-5" justify="space-around">
                <v-col
                  v-for="serviceImport in serviceImportsSelection"
                  :key="serviceImport.id"
                  cols="12"
                  md="3"
                  class="mb-2"
                  @click="onClickBtnImport(2, serviceImport.id)"
                >
                  <div class="subtitle-2">Import entretiens {{serviceImport.name}}</div>
                  <v-btn height="100%" block class="pa-3">  
                      <v-img 
                        max-height="100"
                        max-width="100"
                        :src="`${$constants.application.resourcesURL}/images/${serviceImport.image}`" 
                      />                                         
                  </v-btn>
                </v-col>
              </v-row>                       
            </v-tab-item>

            <v-tab-item value="tab-3">          
              <!--- IMPORT FLOTTE 
              ------------------>
              <v-row class="pa-3" v-if="importType === 0 || (importType === 3 && importId !==0)">               
                <v-row class="pa-5" justify="space-around" v-if="customer !== undefined">
                  <v-col
                    cols="12"
                    md="3"
                    class="mb-2"
                  >
                    <div class="subtitle-2">Import parc automobile {{customer.name}}</div>
                    <v-btn height="100%" block class="pa-3">  
                        <v-img 
                          max-height="100"
                          max-width="100"
                          :src="customer.image" 
                        />                                         
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col sm="12" class="text-right">                    
                    <v-btn
                      color="primary"
                      text
                      x-small
                      tile
                      :href="$constants.application.defaultURL + '/download/template/fleet'"
                    >
                      Récuperer le fichier d'import                      
                      <v-icon
                        right
                        dark
                      >
                        mdi-cloud-download
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col sm="12">
                    <a-customer-list     
                      single-select             
                      @clickRow="onClickRow"          
                    />    
                  </v-col>    
                </v-row> 
              </v-row>            
            </v-tab-item>

            <v-tab-item value="tab-4">   
              <!--- IMPORT SITES 
              ------------------>
              <v-row class="pa-3" v-if="importType === 0 || (importType === 4 && importId !==0)">
                <v-row class="pa-5" justify="space-around" v-if="provider !== undefined">
                  <v-col
                    cols="12"
                    md="3"
                    class="mb-2"
                  >
                    <div class="subtitle-2">Import garage {{provider.name}}</div>
                    <v-btn height="100%" block class="pa-3">  
                        <v-img 
                          max-height="100"
                          max-width="100"
                          :src="provider.image" 
                        />                                         
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col sm="12">
                    <a-provider-list     
                      provider-type="2,4"
                      only-import
                      single-select             
                      @clickRow="onClickRowProvider"          
                    />    
                  </v-col>    
                </v-row> 
              </v-row>       
            </v-tab-item>

          </v-tabs-items>
        </v-col>
      </v-row>                                  

      <v-row class="pa-3" v-if="importType !== 0 && importId !==0">
        <v-col md="12">
          <v-file-input
            label="Fichier à importer"
            filled
            show-size
            @change="onChangeFile"
          ></v-file-input>
        </v-col>

        <v-col md="12" class="text-right">
          <v-btn                  
                  color="error"
                  class="mr-4"
                  @click="onClickBtnCancel"
                >
                  Annuler
                </v-btn>  
          
          <v-btn                  
                  color="primary"
                  class="mr-4"
                  @click="onClickBtnImportFile"
                  :disabled="file === undefined"
                >
                  Importer
                </v-btn>  
        </v-col>
      </v-row>
    </v-container>
  
</template>

<script>
import { mapGetters } from 'vuex'
import APageHeader from "@/components/APageHeader"

import ACustomerList from "@/components/ACustomerList"
import AProviderList from "@/components/AProviderList"


export default {
  name: 'Imports',
  components: { APageHeader, ACustomerList, AProviderList },
  data: () => ({   
    tab: 0, 
    fab: false,    
    fuelImports: [],
    serviceImports: [],    
    importType: 0,
    importId: 0,
    customer: undefined,
    provider: undefined,
    file: undefined
  }),
  methods: {
    // Méthodes évènements UI
    // ----------------------
    onClickBtnImportFile: function() {
      let url = `upload`
      const formData = new FormData()      
      formData.append('id', this.importId)
      formData.append('type', this.importType)
      formData.append('file', this.file, this.file.name)
      
      this.$http    
          .post(url, formData)            
          .then(response => {       
            if (response.status == 200) { 
              this.importType = 0
              this.importId = 0
              this.customer = undefined     
              this.provider = undefined     
              this.file = undefined   
            }                                            
          })
          .catch(error => {    
              console.log(error)                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
    onClickBtnImport: function(type, id) {
      this.importType = type
      this.importId = id
    },
    onClickBtnCancel: function() {
      this.importType = 0
      this.importId = 0
      this.customer = undefined
      this.provider = undefined
    },
    onClickBtnDownloadTemplate: function(template) {
      if (template !== '') {
        let url = `download/template/${template}`
        window.open(url, '_blank')    
      }   
    },
    onChangeSelection: function(event) {
      this.selectedItems = event      
    },
    onClickRow: function(row) {
      this.customer = row
      this.onClickBtnImport(3, row.id)
    },
    onClickRowProvider: function(row) {
      this.provider = row
      this.onClickBtnImport(4, row.id)
    },
    onChangeFile: function(file) {
      this.file = file;      
    },
    getImports: function() {
      let url = `providers/types/0?import`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                response.data.forEach(el => {
                  switch (el.type) {
                    case 1:
                      this.fuelImports.push(el)
                      break
                    
                    case 2:
                      this.serviceImports.push(el)
                      break

                    default:
                  }
                })
                
              }              
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })
    },
  },
  watch: {
    tab: function() {
      this.onClickBtnCancel()
    }
  },
  computed: {
    ...mapGetters([
      'userEnvironment'
    ]),
    fuelImportsSelection: function() {
      if (this.importType === 0) return this.fuelImports
      if (this.importType === 1) return this.fuelImports.filter(el => this.importId === 0 || el.id === this.importId)
      else return []
    },
    serviceImportsSelection: function() {
      if (this.importType === 0) return this.serviceImports
      if (this.importType === 2) return this.serviceImports.filter(el => this.importId === 0 || el.id === this.importId)
      else return []
    }
  },
  mounted() {
    this.getImports()
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;  
}

.v-btn--floating {
  position: relative;
}
</style>