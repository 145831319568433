<template>
  <v-app id="askme">
    <v-navigation-drawer
      v-if="isViewableHeader"
      v-model="drawer"
      app
      clipped
      :mini-variant="drawer"
      permanent      
    >
      <v-list dense>
        <v-list-item link @click="go('/dashboard')">
          <v-list-item-action>
            <v-icon>flaticon-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Tableau de bord</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="go('/requests')">
          <v-list-item-action>
            <v-icon>flaticon-question</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Demandes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="go('/dossiers')">
          <v-list-item-action>
            <v-icon>flaticon-portfolio</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Dossiers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showCustomerItem" link @click="go('/customers')">
          <v-list-item-action>
            <v-icon>flaticon-support</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Clients</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="go('/profiles')">
          <v-list-item-action>
            <v-icon>flaticon-user</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Collaborateurs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="go('/vehicles')">
          <v-list-item-action>
            <v-icon>flaticon-car-1</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Vehicules</v-list-item-title>
          </v-list-item-content>          
        </v-list-item>
        <v-list-item link @click="go('/imports')">
          <v-list-item-action>
            <v-icon>flaticon-cloud-computing</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Imports</v-list-item-title>
          </v-list-item-content>          
        </v-list-item>
        <v-list-item v-if="true" link @click="go('/settings')">
          <v-list-item-action>
            <v-icon>mdi-tune</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Paramêtres</v-list-item-title>
          </v-list-item-content>                 
        </v-list-item>
        <v-list-item link @click="go('/')">
          <v-list-item-action>
            <v-icon color="error">mdi-power</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title color="error">Déconnexion</v-list-item-title>
          </v-list-item-content>                 
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="isViewableHeader"
      app
      clipped-left      
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"><v-icon>flaticon-menu</v-icon></v-app-bar-nav-icon>
      <v-toolbar-title>{{appBarCaption}}</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$router.go(-1)">
        <v-icon>flaticon-left-arrow</v-icon>
      </v-btn>
      
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>

      <v-chip
        color="primary"  
        class="ml-2"   
        @click="$router.push({
            name: 'Profile',
            params: { 
              id: profile.id,
              me: true
            }
          })"           
      >      
        <v-icon left>mdi-account-circle</v-icon>
        {{profile.name}}
      </v-chip>
    </v-app-bar>

    <v-content >
      <v-container
        fluid             
      >
        <router-view />        
      </v-container>         
    </v-content>     

    <v-footer 
      v-if="isViewableFooter"
      app      
    >
      <span>v{{$constants.application.version}} &copy; 2024</span> 
    </v-footer> 

    <v-snackbar   
        app
        text   
        v-model="snackbar"         
        :color="snackbarColor"
        absolute
        right
        bottom 
        :timeout="3000"  
        multi-line=true  
      >
        <v-icon
            v-show="snakbarIcon !== ''"          
            dark
          >
            {{snakbarIcon}}
          </v-icon>
          {{snackbarText}}
      </v-snackbar>
   
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import constants from "./js/constants";

export default {
  data: () => ({
    login: '',
    pasword: '',
    show: true,
    animationClassEnter: 'fadeIn',
    animationClassLeave: 'fadeOut',
    drawer: null,
    mini: true,
    snackbar: false,
    snackbarText: '',
    snackbarColor: 'success',
    snackbarBtnText: '',
    snackbarBtnColor: 'white',
    snakbarIcon: ''
  }),
  computed: {
    ...mapGetters([
      'isViewableHeader',
      'isViewableFooter',
      'appBarCaption',
      'profile'
    ]),
    showCustomerItem: function() {  return this.profile.is_su || (this.profile.role == constants.users.USER_ROLE_MANAGER && this.profile.account > 1) }
  },
  methods: {
    go: function(destination) {
      this.$router.push(destination)
    }
  },
  updated() {
    this.animationClassEnter = this.$route.name == 'login' ? 'fadeIn' : 'slideInRight'
    this.animationClassLeave = 'fadeOut'
  },
  created () {
      //this.$vuetify.theme.dark = true
  },
  mounted () {
    this.$root.$on('notify', function(text, color = 'success', icon = '', buttonText = 'close', buttonColor = 'white') {    
        this.snackbarText = text
        this.snackbarColor = color
        this.snackbarBtnText = buttonText
        this.snackbarBtnColor = buttonColor
        this.snakbarIcon = icon
        this.snackbar = true
      }.bind(this)
    )
  },
  destroyed() {
    
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;  
}

.white-page {
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.transparent-page {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.snackbar {
  top: 50px;
  position: absolute;  
  right: 0;
}
</style>
