<template>  
    <v-container fluid>      
      <v-form 
        ref="form" 
        v-model="valid"
      >
            <v-row>
              <v-col md="6">
                <v-select
                  v-model="data.type"
                  :items="serviceList"
                  item-text="text"
                  item-value="value"
                  :rules="[rc => (error = !!rc) || 'Entretien obligatoire']"     
                  label="Entretien"
                  required     
                  @change="$refs.form.validate()"                            
                ></v-select>
              </v-col>

              <v-col md="6">
                <v-select
                  v-model="data.status"
                  :items="statusList"
                  item-text="label"
                  item-value="value"
                  :rules="[rc => (error = rc !== undefined) || 'Status obligatoire']"     
                  label="Status"                  
                  required    
                  @change="$refs.form.validate()"                             
                ></v-select>
              </v-col>                        
            </v-row>    
            
            <v-row>
              <v-col md="6">
                <v-text-field
                  v-model="data.distance"                                    
                  label="Kilométrage théorique"
                  :rules="[v => (error = !isNaN(v)) || 'Kilométrage incorect']"         
                  required
                  @change="$refs.form.validate()"
                ></v-text-field>
              </v-col>     

              <v-col md="6" v-if="showExtendedData">
                <v-text-field
                  v-model="data.realDistance"                                    
                  label="Kilométrage relevé" 
                  :rules="[v => (error = !isNaN(v)) || 'Kilométrage incorect']"      
                  @change="$refs.form.validate()"          
                ></v-text-field>
              </v-col>                        
            </v-row>    
            
            <template v-if="showExtendedData">
              <v-row>
                <v-col sm="6">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="data.date.toLocaleDateString()"
                        label="Date de l'entretien"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker     
                      v-show="menu"             
                      v-model="date"
                      locale="FR-fr"
                      first-day-of-week="1"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col md="6">
                  <v-menu
                    ref="menuTimePicker"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"                  
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="time"
                        label="Heure de l'entretien"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-show="menu2"
                      v-model="time"                    
                      type="month"
                      format="24hr"
                      full-width                    
                      :allowed-minutes ="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"                
                    ></v-time-picker>
                  </v-menu>
                </v-col>                        
              </v-row>                
                        
              <v-row v-if="data.place">
                <v-col>
                  <div class="caption">
                    Lieu de réalisation
                  </div>
                  <div class="body">
                    <v-avatar tile size="36">
                      <v-img :src="image"/>         
                    </v-avatar>                  
                    {{ data.place.name }}
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" style="height: 30vh">
                  <a-provider-selector
                      ref="providerSelector" 
                      :services="{
                        overhaul: data.isOverhaulService,
                        tires: data.isTiresService,
                        brakepads: data.isBrakepadsService,
                        misc: data.isMiscService,
                      }"                                        
                      :vehicle="vehicle"   
                      :place-id="data.place ? data.place.id : 0"                                                                   
                      only-select
                      :map-center="mapCenter"
                      @select="onSelectPlace"                         
                  />
                </v-col>
              </v-row>            
            </template>

            <v-row>
              <v-col md="12" class="text-right mt-2">
                <v-btn                  
                  color="error"
                  class="mr-4"
                  @click="onClickBtnCancel"
                >
                  Annuler
                </v-btn>                  
                <v-btn                  
                  color="success"
                  class="mr-4"
                  :disabled="!valid"
                  @click="onClickBtnValidate"
                >
                  Valider
                </v-btn>     
              </v-col> 
            </v-row>                                    
      </v-form>
    </v-container>  
</template>

<script>
//import moment from 'moment'
import { ServiceLabelCollection } from "@/domain/Label.js";
import { ServiceStatus, ServiceStatusCollection } from "@/domain/Status.js";
import AProviderSelector from "@/components/AProviderSelector"

export default {
  name: 'AMaintenanceForm',
  props: {
    service: Object,
    vehicle: Object,    
    driver: Object    
  },
  components: { AProviderSelector },
  data: () => ({
    serviceDate: undefined,
    menu: false,
    menu2: false,
    serviceList: ServiceLabelCollection,
    statusList: ServiceStatusCollection.filter(el => ['todo', 'completed', 'canceled'].includes(el.code)),
    data: {},    
    date: undefined,
    time: undefined, 
    showPlaceSelecion: false,
    valid: true
  }),
  methods: {
    onClickBtnValidate: function (emitEvent = true) {
      let id = this.data.id === undefined ? 0 : this.data.id
      const url = `services`      
      let params = {
          id: id,  
          type: this.data.type,             
          vehicle: this.data.vehicleId,
          status: this.data.status,
          distance: Number(this.data.distance),                                        
        }
      
      if (this.data.status !== ServiceStatus.todo) {
        params = {
          ...params,
          real_distance: Number(this.data.realDistance),
          place: this.data.placeId,
          timestamp: this.data.date.getTime(),
          date: this.data.date.toISOString()
        }
      }
      
      if (id == 0) {
        this.$http
          .post(url, params)            
          .then(response => {                        
              if (response.status == 200) {
                if (emitEvent) this.$emit('validate', this.data)              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });  
      }
      else {
        this.$http
          .put(url, params)            
          .then(response => {                        
              if (response.status == 200) {
                this.$emit('validate', this.data)              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });  
      }             
    },
    onClickBtnCancel: function () {
      this.$emit('cancel')
    },
    onSelectPlace: function(event) {
      this.data.place = event;
      this.data.placeId = event.id;
    },
    init: function() {
      this.data = {
          ...this.service, 
          status: this.service.status.value, 
          placeId: this.service.place ? this.service.place.id: 0, 
          vehicleId: this.vehicle ? this.vehicle.id : 0,
          date: this.service.timestamp ? new Date(this.service.timestamp) : new Date()
        };    
                
        this.date = this.data.date.toISOString().substring(0, 10);          
        this.time = this.data.date.getHours().toString().padStart(2, '0') + ":" + this.data.date.getMinutes().toString().padStart(2, '0');                
        this.showPlaceSelecion = this.service.status.value !== ServiceStatus.todo;
        
      },
      setFullDate: function() {
        if (this.date) this.data.date = new Date(this.date); 
        if (this.time) {  
          const [hours, minutes] = this.time.split(":");
          this.data.date.setHours(hours);
          this.data.date.setMinutes(minutes);
        }        
      }
  },
  computed: {
    image: function() {
      return this.data.place ? `${this.$constants.application.resourcesURL}/images/${this.data.place.provider.image}` : '';
    },
    mapCenter: function() {
      if (this.data.place) return [this.data.place.longitude, this.data.place.latitude];
      else return [this.driver.longitude, this.driver.latitude];
    },
    showExtendedData: function() {
      return this.data.status !== ServiceStatus.todo;
    },    
  },
  watch: {
    date: function() {         
      this.setFullDate();      
    },
    time: function() {
      this.setFullDate();
    }
  },
  beforeMount() {
    this.init();    
  }
}
</script>
