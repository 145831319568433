<template>

  <v-container fluid>
    <a-page-header :title="title" icon="flaticon-question" :chip-text="status" :chip-color="color" icon-pencil @update="isUpdatingStatus = !isUpdatingStatus"/>       
    <v-divider/>

    <v-expand-transition>
        <a-status-changer v-show="isUpdatingStatus" 
            @change="onChangeStatus($event)" />    
    </v-expand-transition>
    
    <v-row>
        <!-- DEMANDEUR -->
        <v-col md="6">
            <a-user-header :user="request.requester" @click="onClickUserHeader()"></a-user-header>
        </v-col>

        <!-- VEHICULE -->    
        <v-col md="6">
            <a-vehicle-header :vehicle="request.vehicle" @click="onClickVehicleHeader()" show-tires show-lease></a-vehicle-header>            
        </v-col>        
    </v-row>
    
    <v-row>
        <!-- DEMANDE TYPE : RENDEZ-VOUS ENTRETIEN VEHICULE -->
        <v-col md="12" id="request">
            <a-vehicle-appointment 
                v-if="request.type == $constants.dossiers.TYPE_APPOINTMENT"
                title="Details de la demande"
                :appointment="request.params"    
                :requester="request.requester"     
                icon-pencil
                small  
                validate-button
                @validate="onValidateRequestWishes"                   
            ></a-vehicle-appointment>                      
            <v-card v-else>
                <v-card-title>
                    Informations complémentaires
                </v-card-title>
                <v-card-text>        
                    {{request.params ? request.params.infos : '' }}                                             
                </v-card-text>                                                      
            </v-card>

            <v-speed-dial
                v-model="fab"    
                top
                right        
                direction="bottom"
                open-on-hover
                transition="slide-y-reverse-transition"
            >
                <template v-slot:activator>
                    <v-btn
                        v-model="fab"
                        color="blue darken-2"
                        dark
                        fab
                    >
                        <v-icon v-if="fab">mdi-close</v-icon>
                        <v-icon v-else >mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-btn
                    v-if="request.type == $constants.dossiers.TYPE_APPOINTMENT"
                    ref="fabEvent"
                    fab
                    dark
                    small
                    color="green"
                    @click="onClickFabEvent()"
                >
                <v-icon size="20">flaticon-add-event</v-icon>
                </v-btn>
                <v-btn
                    v-if="request.type == $constants.dossiers.TYPE_ASSISTANCE"
                    ref="fabEvent"
                    fab
                    dark
                    small
                    color="green"
                    @click="onClickFabNewRequest()"
                >
                <v-icon size="20">flaticon-question</v-icon>
                </v-btn>
                <v-btn
                    ref="fabMessage"
                    fab
                    dark
                    small
                    color="indigo"
                    @click="onClickFabMessage()"
                >
                <v-icon size="20">flaticon-chat</v-icon>
                </v-btn>
                <v-btn
                fab
                dark
                small
                color="red"
                @click="onClickFabDelete()"
                >
                <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-speed-dial>
        </v-col>        
    </v-row>          

    <!-- DEVIS -->
    <v-row v-if="request.type == $constants.dossiers.TYPE_APPOINTMENT">  
        <v-col md="12" >
            <a-quotation
                :quotation="quotation"
                :request="request.id"
                :vehicle="request.vehicle.id"
                :user="request.requester.id"
                :customer="request.requester.customer_id"
                title="Devis"
                icon-pencil
                @save="onSaveQuotation"
                @deletefile="onDeleteQuotationFile"
                @delete="onDeleteQuotation"
            />
        </v-col>
    </v-row>

    <!-- DEMANDE AVEC PHOTOS -->
    <v-row v-if="request.picture_capability">
        <v-col md="12">  
            <a-photo-gallery bar-title="Photos" :image-list="imageList"/>  
        </v-col>        
    </v-row>

    <!-- DEMANDE LOCALISEE -->  
    <v-row v-if="request.params.location !== undefined">  
        <v-col md="12" >
            <v-card height="300px">
                <v-card-title>
                    Localisation
                </v-card-title>
                <v-card-text>                                      
                        <div id="map" />                                        
                </v-card-text>
            </v-card>      
        </v-col>
    </v-row>

    <v-row>
        <!-- FLUX D'ACTIVITE SUR LA DEMANDE -->
        <v-col md="12">
            <v-card>
                <v-card-actions>
                    <a-activity-stream-action-bar bar-style="request" bar-title="Flux d'activitées" :counter="activities.length"/>
                </v-card-actions>

                <v-card-text>
                    <v-row>
                        <v-col md="4"></v-col>
                        <v-col md="4" class="text-center caption">
                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                                              
                                color="yellow"
                                
                                :small="!showComments"
                                @click="showComments = !showComments"
                                >
                                <v-icon dark>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-textarea                                   
                                v-show="showComments"
                                v-model="request.comments"
                                class="body-2 font-weight-ligth mt-2" 
                                light                               
                                solo                
                                shaped
                                clearable
                                clear-icon="mdi-close-circle"                                
                                filled     
                                rows="1"                                
                                auto-grow                                
                                background-color="yellow lighten-5"                                                    
                                label="Commentaires"
                                @click:clear="showComments = false"
                                @blur="onBlurComments"
                                @focus="stopTimer()"
                            >
                            </v-textarea>    
                        </v-col>
                        <v-col md="4"></v-col>
                    </v-row>
                    <a-activity-stream 
                        v-if="activity" :activities="activities"
                        @change="get(false)"
                        @update-activity="onUpdateActivity($event)"
                    />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>


    <v-row justify="center">        
        <a-modal-message-maker            
            color="indigo"
            :title="`Envoyer un message à ${this.request.requester.firstname} ${this.request.requester.lastname}`"
            auto-close
            @sendMessage="onClickBtnSendMessage($event)"
            ref="modalMessageMaker"
        />   
               
        <a-modal-provider-selector       
            color="green"            
            auto-close                        
            ref="modalProviderSelector"
            :services="request.params.reasons"
            :localization="localization"
            :preferences="request.params.preferences" 
            :vehicle="request.vehicle"
            @validate="onClickBtnSuggestAppointment($event)"
        />
    </v-row>
  </v-container>

</template>

<script>
import { mapGetters } from 'vuex'
import APageHeader from "@/components/APageHeader"
import AVehicleHeader from "@/components/AVehicleHeader"
import AUserHeader from "@/components/AUserHeader"
import AStatusChanger from '@/components/AStatusChanger.vue'
import AQuotation from '@/components/AQuotation.vue'

import AVehicleAppointment from "@/components/AVehicleAppointment"
import AActivityStream from "@/components/AActivityStream"
import AActivityStreamActionBar from "@/components/AActivityStreamActionBar"
import AModalMessageMaker from "@/components/AModalMessageMaker"

import AModalProviderSelector from "@/components/AModalProviderSelector"
import APhotoGallery from "@/components/APhotoGallery"

import mapboxgl from 'mapbox-gl';

import Constants from "@/js/constants"


export default {
  name: 'Request',
  components: { APageHeader, AVehicleHeader, AUserHeader, AVehicleAppointment, AActivityStream, AActivityStreamActionBar, AModalMessageMaker, AModalProviderSelector, AStatusChanger, APhotoGallery, AQuotation },
  data: () => ({
    activities: undefined,
    activity: undefined,
    title: '',
    status: '',
    color: '',
    timerId: 0,
    timer: 5000,
    request: {},
    localization: {markers: {}},
    fab: false,
    dialogBarColor: "",
    dialogBarTitle: "",
    map: undefined,
    isUpdatingStatus: false,
    quotation: undefined,
    quotationfile: undefined,
    isLoaded: false,
    showComments: false
  }),
  methods: {
    // Méthodes d'accès aux données
    // ----------------------------
    get: function(initTimer = true) {      
      let url = `requests/${this.$route.params.id}`      
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {   
                this.request = response.data                              
                this.showComments = this.showComments && this.request.comments;
                this.activities = response.data.activities
                this.activity = response.data.activity    
                if (this.quotation === undefined) {            
                    if (response.data.quotation !== undefined) { 
                        this.quotation = this.request.quotation
                    }
                    else {
                    this.quotation = {
                                id: 0,
                                number: '',
                                agreement_number: '',
                                date: ''
                            }
                    }
                }
                this.defineContent()
                this.defineStatusCSS()   
                this.defineRequestDisplay()     
                
                
                if (this.request.vehicle.fullpath_image_file !== null) this.request.vehicle.fullpath_image_file = `${this.$constants.application.resourcesURL}/${this.request.vehicle.fullpath_image_file}`
                if (this.request.params.location !== undefined && this.map === undefined) this.initMap()    
                
                if (initTimer) {
                    this.timerId = setTimeout(() => {
                            this.get()
                        }, 
                        this.timer
                    )
                } 
              }                                          
          })
          .catch(error => {                
              console.log(error)              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
    },
    // Méthodes d'évènements
    // ---------------------
    onClickFabDelete: function() {
        let url = `requests?ids=${this.request.id}`      
        this.$http
            .delete(url)            
            .then(() => { 
                this.$router.go(-1)                                                                             
            })
            .catch(error => {                              
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });   
    },
    onClickFabEvent: function() {
        this.$refs.modalProviderSelector.show()
    },
    onClickFabMessage: function() {
        this.$refs.modalMessageMaker.show()
    },
    onClickFabNewRequest: function() {
        let url = `requests`;
        let data = {
            user: this.request.requester.id,
            type: 1,
            object: {class: 'vehicle', id: this.request.vehicle.id},
            params: {
                reasons:{ overhaul: false, tires: false, brakepads: false, misc: true},
                preferences: {day1: false, day2: false, day3: false, day4: false, day5: false, day6: false},
                localization: {home: false, work: false, other: false},    
                from: {
                    type: Constants.objects.REQUEST,
                    id: this.request.id
                }            
            }
        }                   
        this.$http
            .post(url, data)            
            .then(response => {                        
                if (response.status == 200) {  
                    this.$router.push({ 
                        name: 'Request', 
                        params: { 
                        id: response.data.id
                        }
                    })             
                }                            
            })
             .catch(error => {                
              console.log(error)              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
    },
    onClickBtnSendMessage: function(message) {
        let url = "messages"
        const data = {
            request: this.$route.params.id,
            to_requester: true,
            text: message
        } 
        this.$http
          .post(url, data)            
          .then(response => {                        
              if (response.status == 200) {
                this.get(false)    
                this.dialog = false            
              }                                          
          })
          .catch(error => {             
              console.log(error)                 
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
    },
    onClickBtnSuggestAppointment: function(appointment) {
        if (this.isValidAppointment(appointment)) {
            // Formatage des données pour le passage de paramêtres à l'API
            let date = new Date(appointment.date + "T" + appointment.time).getTime()
            let services = []
            if (appointment.services.overhaul) services.push(Constants.vehicleServices.SERVICE_OVERHAUL)
            if (appointment.services.tires) services.push(Constants.vehicleServices.SERVICE_TIRES)
            if (appointment.services.brakepads) services.push(Constants.vehicleServices.SERVICE_BRAKEPADS)
            if (appointment.services.misc) services.push(Constants.vehicleServices.SERVICE_MISC)
            
            // Appel de l'API pour génération du rendez-vous
            let url = "appointments"
            let data = {
                place: appointment.provider.id,
                user: this.request.requester.id,
                vehicle: this.request.vehicle.id,
                timestamp: date,
                request: this.request.id,
                services: services
            }        
            
            if (appointment.id === undefined) {                
                this.$http
                    .post(url, data)            
                    .then(response => {                        
                        if (response.status == 200) {
                            this.get(false)    
                            this.dialog = false            
                        }                                          
                    })
                    .catch(error => {             
                        console.log(error)                 
                        if (error.response.status === 401) this.$root.$emit("logout"); 
                        else this.$root.$emit("serverError", error);     
                    });    
            }
            else {
                data.id = appointment.id
                this.$http
                    .put(url, data)            
                    .then(response => {                        
                        if (response.status == 200) {
                            this.get(false)    
                            this.dialog = false            
                        }                                          
                    })
                    .catch(error => {             
                        console.log(error)                 
                        if (error.response.status === 401) this.$root.$emit("logout"); 
                        else this.$root.$emit("serverError", error);     
                    });    
            }
        }     
    },
    onClickUserHeader: function() {      
      this.$router.push({ 
        name: 'Profile', 
        params: { 
          id: this.request.requester.id
        }
      })
    },
    onClickVehicleHeader: function() {      
      this.$router.push({ 
        name: 'Vehicle', 
        params: { 
          id: this.request.vehicle.id
        }
      })
    },
    onChangeStatus: function(status) {
        this.request.status = status
        this.defineStatusCSS()
        this.isUpdatingStatus = false

        let url = `requests/${this.request.id}/${this.statusApiParams}`
        this.$http
            .put(url)            
            .then(() => {                                                                                 
            })
            .catch(error => {                                     
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });    
    },
    onBlurComments: function() {                
        let url = `requests/${this.request.id}`
        this.$http
            .put(url, { comments: this.request.comments ?? '' })            
            .then(() => {   
                this.get();                                                   
            })
            .catch(error => {                                     
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });            
    },
    onUpdateActivity: function(activity) {        
        this.$refs.modalProviderSelector.show(activity.appointment)
    },
    onValidateRequestWishes: function(event) {
        let url = `requests`;
        let data = {
            id: this.request.id,
            params: event        
        }                   
        this.$http
            .put(url, data)            
            .then(response => {                        
                if (response.status == 200) {  
                    this.$root.$emit('notify', 'Demande mise à jour', 'success', 'mdi-checkbox-marked-circle')       
                    this.request.params = {...event}
                }                            
            })
             .catch(error => {                
              console.log(error)              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
    },
    onDeleteQuotation: function(event) {
        this.request.quotation = undefined
        this.$root.$emit('notify', `Devis ${event.number} supprimé`, 'success', 'mdi-checkbox-marked-circle') 
    },
    onDeleteQuotationFile: function(event) {
        this.request.quotation = event
        this.$root.$emit('notify', `Fichier du devis ${event.number} supprimé`, 'success', 'mdi-checkbox-marked-circle') 
    },
    onSaveQuotation: function(event) {
        this.$root.$emit('notify', `Devis ${event.number} mise à jour`, 'success', 'mdi-checkbox-marked-circle') 
    },
    // Méthodes du composant
    // ---------------------
    stopTimer: function() {
        clearTimeout(this.timerId);
    },
    // Validation des données du rendez-vous
    isValidAppointment: function(appointment) {
        let isValid = true
        if (isNaN(new Date(appointment.date + "T" + appointment.time).getTime())) isValid = false
        else {
            let services = Object.values(appointment.services)
            isValid = services.reduce((result, current) => result || current)
        }
        return isValid
    },
    // Determination des composants a afficher en fonction du type de la demande
    defineRequestDisplay: function() {            
        switch (this.request.type) {
            case this.$constants.dossiers.TYPE_APPOINTMENT:
                this.localization = this.request.params.localization
                this.localization.markers = {
                    home: {
                        id: this.request.requester.id,
                        firstname: this.request.requester.firstname,
                        lastname: this.request.requester.lastname,
                        address1: this.request.requester.address1,
                        address2: this.request.requester.address2,
                        address3: this.request.requester.address3,
                        zip: this.request.requester.zip,
                        city: this.request.requester.city,
                        latitude: this.request.requester.latitude,
                        longitude: this.request.requester.longitude
                    },
                    work: this.request.requester.work.agency == undefined ? this.request.requester.work : this.request.requester.work.agency
                }  

                switch (this.request.object) {
                    case 'vehicle':                                                        
                        this.title = "RENDEZ-VOUS VEHICULE"
                        this.object = {image: '', registration: '', model: ''}                                              
                        break

                    default:

                } 
                break

            case this.$constants.dossiers.TYPE_ASSISTANCE:
                switch (parseInt(this.request.params.type)) {
                    case this.$constants.assistances.TYPE_ACCIDENT:
                        this.title = "DECLARATION D'ACCIDENT"
                        break

                    case this.$constants.assistances.TYPE_BREAKDOWN:
                        this.title = "ASSISTANCE PANNE"
                        break

                    case this.$constants.assistances.TYPE_BROKEN_GLASS:
                        this.title = "DECLARATION BRIS DE GLACE"
                        break

                    case this.$constants.assistances.TYPE_TIRES:
                        this.title = "ASSISTANCE PNEUMATIQUE"
                        break

                    case this.$constants.assistances.TYPE_THEFT:
                        this.title = "DECLARATION DE VOL"
                        break

                    default:
                        this.title = "ASSISTANCE"
                        break
                }
                this.object = {image: '', registration: '', model: ''}

                if (this.request.params.location.geocoding.relevance > 0.75) this.address = this.request.params.location.geocoding.place_name.split(',').map(el => el.trim())
                else this.address = this.request.params.location.address.split(',').map(el => el.trim)                

                break
            
            default:
        }

    },
    defineContent: function() {
            switch (this.request.type) {
                case this.$constants.dossiers.TYPE_APPOINTMENT:                    
                    this.title = "Rendez-vous véhicule"
                    this.icon = '&#xf121;'
                    this.content = `${this.request.vehicle.model.brand} ${this.request.vehicle.model.model} : ${this.request.vehicle.registration}`
                    break

                case this.$constants.dossiers.TYPE_ASSISTANCE:
                    
                    switch (parseInt(this.request.params.type)) {
                        case this.$constants.assistances.TYPE_ACCIDENT:
                            this.title = "Déclaration d'accident"
                            break

                        case this.$constants.assistances.TYPE_BREAKDOWN:
                            this.title = "Assistance dépannage panne"
                            break

                        case this.$constants.assistances.TYPE_BROKEN_GLASS:
                            this.title = "Déclaration de bris de glace"
                            break

                        case this.$constants.assistances.TYPE_TIRES:
                            this.title = "Assistance dépannage pneumatique"
                            break

                        case this.$constants.assistances.TYPE_THEFT:
                            this.title = "Déclaration de vol"
                            break

                        default:
                            this.title = "Assistance diverse"
                            break
                    }
                    this.icon = '&#xf105;'
                    this.content = `${this.request.vehicle.model.brand} ${this.request.vehicle.model.model} : ${this.request.vehicle.registration}`
                    break

                default:
                    break
            }
    },        
    defineStatusCSS: function() {
        switch (this.request.status) {
            case this.$constants.status.STATUS_CREATE:
                this.status = "Nouveau"  
                this.statusApiParams = "new"                  
                this.color = this.$constants.colors.STATUS_NEW                    
                break
            case this.$constants.status.STATUS_RECEIVED:
                this.status = "Reçue"
                this.statusApiParams = "received"
                this.color = this.$constants.colors.STATUS_RECEIVED                    
                break
            case this.$constants.status.STATUS_PROCESSING:
                this.status = "En cours"
                this.statusApiParams = "processing"
                this.color = this.$constants.colors.STATUS_PROCESSING                    
                break
            case this.$constants.status.STATUS_STANDBY:
                this.status = "En attente"
                this.statusApiParams = "waiting"
                this.color = this.$constants.colors.STATUS_WAITING                   
                break
            case this.$constants.status.STATUS_WAITING_USER_INFOS:
                this.status = "En attente d'information"
                this.statusApiParams = "waiting_user"
                this.color = this.$constants.colors.STATUS_WAITING_INFOS                    
                break
            case this.$constants.status.STATUS_WAITING_PROVIDER_INFOS:
                this.status = "En attente fournisseur"
                this.statusApiParams = "waiting_provider"
                this.color = this.$constants.colors.STATUS_WAITING_PROVIDER_INFOS                    
                break
            case this.$constants.status.STATUS_COMPLETED:
                this.status = "Réalisée"
                this.statusApiParams = "complete"
                this.color = this.$constants.colors.STATUS_COMPLETED                    
                break
            case this.$constants.status.STATUS_CANCELED:
                this.status = "Annulée"
                this.statusApiParams = "cancel"
                this.color = this.$constants.colors.STATUS_CANCELED                    
                break
            case this.$constants.status.STATUS_REFUSED:
                this.status = "Refusée"
                this.statusApiParams = "new"
                this.color = this.$constants.colors.STATUS_REFUSED                    
                break
            case this.$constants.status.STATUS_ARCHIVED:
                this.status = "Archivée"
                this.statusApiParams = "archived"
                this.color = this.$constants.colors.STATUS_ARCHIVED                    
                break
            default:
                break

        }
    },
    initMap: function() {
        this.$nextTick(() => {
        let center = []
        let lat = this.request.params.location.geocoding.latitude
        let lng = this.request.params.location.geocoding.longitude            
        
        center = [lng, lat]

        mapboxgl.accessToken = 'pk.eyJ1IjoiZnNnYXJkIiwiYSI6ImNrODVtanhmcTA3Y3YzZ3BqbXBpaXcwY2EifQ.Q9J9kR1nowA7GThkE7SCWw'
        this.map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
            center: center, // starting position [lng, lat]
            zoom: 17, // starting zoom
            attributionControl: false
        });                  

        this.map.on('load',
            function() {
                let popupHTMLContent = this.request.params.location.geocoding.place_name.replace(/,/g, "</br>")
                // create the popup
                var popup = new mapboxgl.Popup({ offset: 25, closeButton: false }).setHTML(popupHTMLContent)    

                let marker = new mapboxgl.Marker()
                    .setLngLat([lng, lat])
                    .setPopup(popup)  
                        .addTo(this.map)
                
                marker.togglePopup()   
                }.bind(this))                
        })
    }      
  },
  computed: {
    ...mapGetters([
      'userEnvironment'
    ]),
    imageList: function() {
        return this.request.pictures.map(el => `data:image/jpg;base64,${el.data}`)
    }
  },
  mounted() {             
    this.get()
  },
  destroyed() {
      clearTimeout(this.timerId)
  }
}
</script>

<style scoped>
#map { position: absolute; top: 0.5%; bottom: 0.5%; left: 0.5%; height: 99%; width: 99%; overflow: hidden;}

#request .v-speed-dial {
  position: absolute;  
}

#request .v-btn--floating {
  position: relative;
}

</style>

<style>
.v-textarea textarea {
      line-height: 1.1 !important;
      color:gray;
 }
</style>
