<template>
    <v-dialog v-model="dialog" width="70%">     
        <v-card>                
                <v-card-title>{{title.toUpperCase()}}</v-card-title>       
                <v-card-text>    
                    <v-row>
                        <!-- DEMANDEUR -->
                        <v-col md="6">
                            <a-user-header :user="requester"></a-user-header>
                        </v-col>

                        <!-- VEHICULE -->    
                        <v-col md="6">
                            <a-vehicle-header :vehicle="vehicle"></a-vehicle-header>            
                        </v-col>        
                    </v-row>
                    <v-row>
                        <!-- DEMANDE TYPE : RENDEZ-VOUS ENTRETIEN VEHICULE -->
                        <v-col md="12" id="request">
                            <a-vehicle-appointment                 
                                title="Details de la demande"
                                :appointment="params"    
                                :requester="requester"
                                form         
                                ref="aVehiculeAppointment"                                                                                                                             
                            ></a-vehicle-appointment>                                  
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dialog = false">Fermer</v-btn>
                    <v-btn color="green" @click="onClickBtnValidate()">Valider</v-btn>
                </v-card-actions>  
        </v-card>            
    </v-dialog>
</template>

<script>
import AVehicleHeader from "@/components/AVehicleHeader"
import AUserHeader from "@/components/AUserHeader"
import AVehicleAppointment from "@/components/AVehicleAppointment"

export default {
  name: 'AModalRequest',
  components: { AVehicleHeader, AUserHeader, AVehicleAppointment },
  props: {
    requester: Object,
    vehicle: Object,
    request: Object,
    title: {
        type: String,
        default: "Envoyer un message"
    },    
    color: {
        type: String,
        default: "blue"
    },
    autoClose: {
        type: Boolean,
        default: false
    }
  },
  data: () => ({
      dialog: false,
      message: "",
      params: {
        reasons:{ overhaul: false, tires: false, brakepads: false, misc: true, control: false},
        preferences: {day1: true, day2: true, day3: true, day4: true, day5: true, day6: true},
        localization: {home: false, work: false, other: false}
      }
  }),
  methods: {    
    show: function(message = "") {        
        if (this.request !== undefined) this.params = this.request.params
        this.message = message
        this.dialog = true
    },
    hide: function() {
        this.dialog = false
    },
    onClickBtnValidate: function() {
        let data = {
            user: this.requester.id,
            type: 1,
            object: {class: 'vehicle', id: this.vehicle.id},
            params: this.$refs.aVehiculeAppointment.$data.proposed
        }
        this.$emit('validate', data)        
        if (this.autoClose) this.dialog = false
    }
  }
}
</script>
