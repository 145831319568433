<template>
  <v-card elevation="0">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :height="height"
        v-model="selectedItems"
        :headers="headersDrivers"
        :items="items"
        :search="search"
        :single-select="singleSelect"
        :show-select="showSelect"        
        item-key="id"      
        :loading="isLoading"
        loading-text="Chargement en cours..."
        locale="fr-FR"      
        disable-pagination
        hide-default-footer 
        @item-selected="onItemSelected"
        @click:row="onClickRow"  
      >      

        <template v-slot:group.header="{group, items, isOpen, toggle}">
          <th colspan="5" class="subtitle-2">
            <v-icon @click="toggle" class="mr-3"
              >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
            </v-icon>
            {{ group }}
          </th>
        </template>        

        <template v-slot:item.name="{ item }">
            <div class="d-flex flex-no-wrap align-center">
                <v-img max-width="36" :src="item.image"/>                       
                <div class="ml-3">{{ item.name }}</div>
            </div>            
        </template>                  
                         
      </v-data-table>
    </v-card>                      
</template>

<script>
 
export default {
    name: "AProviderList",  
    props: {
      showSelect: {
        type: Boolean,
        default: false
      },
      singleSelect: {
        type: Boolean,
        default: false
      },
      defaultSearch: {
        type: String,
        default: ''
      },
      small: {
        type: Boolean,
        default: false
      },
      providerType: {
        type: String,
        default: '0'
      },
      onlyImport: {
        type: Boolean,
        default: false
      },
      selectedIds: {
        type: String,
        default: ""
      },
      height: {
        type: String,
        default: '100%'
      }
    }, 
    data() {
      return {
        isLoading: true,        
        // Table des conducteurs
        headersDrivers: [                  
          {text: "Nom/Raison sociale", value: "name"},      
          {text: "Type", value: "type", groupable: false},                                   
        ],
        selectedItems: [],
        items: [],
        search: ''
      }    
    },
    methods: {
      get: function() {
      this.isLoading = true
      let url = `providers/types/${this.providerType}` + (this.onlyImport ? '?import' : '')
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.items = response.data.map(el => {  
                  let type = ''
                  switch (el.type) {                                        
                    case 2:
                      type = 'Garage'
                      break

                    case 3:
                      type = 'Loueur'
                      break

                    case 4:
                      type = 'Constructeur/Concessionnaire'
                      break

                    case 5:
                      type = 'Assureur'
                      break

                    default:
                      type = 'Pétrolier'


                  }                
                  return {
                    id: el.id,
                    name: el.name,
                    type: type,                    
                    image: el.image != "" ? `${this.$constants.application.resourcesURL}/images/${el.image}` : "",                    
                  }
                })
                this.search = this.defaultSearch

                if (this.selectedIds !== '') {
                  console.log(this.selectedIds)
                  let selected = this.selectedIds.split(',').map(element => parseInt(element));                
                  this.items.forEach(element => {                  
                    if (selected.indexOf(element.id) != -1) this.selectedItems.push(element)                  
                  });
                  this.$emit('init', this.selectedItems)
                }
              } 
              this.isLoading = false             
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
      },
      getSelectedItems: function() {
        return this.selectedItems
      },
      resetSelectedItems: function() {
        this.selectedItems = []
      },
      onItemSelected: function(event) {
        this.$emit('itemSelected', event)
        this.$nextTick(() => {this.$emit('change', this.selectedItems)})
      },      
      onClickRow: function(event) {
        this.$emit('clickRow', event)
      }
    },
    mounted() {      
      this.get()
    }
}
</script>

<style lang="scss" scoped>
 
</style>