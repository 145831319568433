import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import VueMoment from 'vue-moment'
//import moment from 'moment-timezone'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more';
import solidgauge from 'highcharts/modules/solid-gauge'

highchartsMore(Highcharts);
solidgauge(Highcharts)

// Application constants
// ---------------------
import constants from "./js/constants";
import functions from "./js/functions";

Vue.config.productionTip = false

// Definition du header pour les appels d'API                
axios.defaults.headers.common['Content-Type'] = 'application/json'

//axios.defaults.baseURL = 'http://172.20.10.2:8888/api/app/'
//axios.defaults.baseURL = 'http://172.20.10.3:8888/api/app/'
axios.defaults.baseURL = constants.application.defaultURL;

// Uses
// ----
Vue.use(functions);
Vue.use(VueMoment);
Vue.use(HighchartsVue)

// Prototypage
// -----------
Vue.prototype.$http = axios
Vue.prototype.$constants = constants
Vue.prototype.$functions = functions


// Gestion des actions sur changement de route
// -------------------------------------------
router.afterEach((to) => {
  console.log(to.name)
  store.commit('setViewableHeader', to.name != 'Login')
  store.commit('setViewableFooter',     
    to.name != 'Login' 
  )
})

// Instanciation de Vue
// --------------------
var vm = new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

// Evenements applicatif
// ---------------------
vm.$root.$on('login', function() {      
  let url = "auth";  
  this.$http
      .get(url)            
      .then(function(response) {              
          if (response.status === 200) {
            this.$vuetify.theme.dark = response.data.is_dark_theme  
            //vm.$store.commit('functionalities', response.data.functions);                        
            this.$store.commit('setProfile', response.data)

            this.$store.dispatch('initFiltersVehicle');
              
            this.$router.push('dashboard');  
          }                             
      }.bind(this))
      .catch(error => {
          console.log(error)
  });                 
});

vm.$root.$on('logout', function() {
  this.$store.dispatch('logout');
  this.$router.push('/');
});

vm.$root.$on('serverError', function(error) {
  console.log(error);
})

