import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/assets/fonts/flaticon.css' 

Vue.use(Vuetify);

export default new Vuetify({   
    theme: { dark: false},
    icons: {
        //iconfont: "flaticon"
    }   
})