<template>
  
    <v-container fluid>
      <a-page-header :title="title" icon="flaticon-user"/>    

      <v-divider/>
        <v-speed-dial
          v-model="fab"    
          top
          right        
          direction="bottom"
          open-on-hover
          transition="slide-y-reverse-transition"
        >
            <template v-slot:activator>
                <v-btn
                    v-model="fab"
                    color="blue darken-2"
                    dark
                    fab
                >
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else >mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-btn
                v-show="showAddFabBtn"
                ref="fabAdd"
                fab
                dark
                small
                color="primary"
                @click="onClickFabAdd()"
                :title="fabBtnAddTitle"
            >
            <v-icon size="20">mdi-plus</v-icon>
            </v-btn>

            <v-btn
                v-show="!isNew"   
                ref="fabMessage"
                fab
                dark
                small
                color="light-green"
                @click="onClickFabMessage()"
                title="Envoyer un message au conducteur actuel"
              >
              <v-icon size="20">flaticon-chat</v-icon>
              </v-btn>
            
            <v-btn
                v-show="!isNew && showAuditFabBtn"   
                ref="fabAudit"
                fab
                dark
                small
                color="purple"
                @click="onClickFabAudit()"
                title="Demander un audit vehicule"
            >
            <v-icon size="20">flaticon-front-car</v-icon>
            </v-btn>
            <v-btn
                v-show="!isNew && showMileageFabBtn"   
                ref="fabMileage"
                fab
                dark
                small
                color="indigo"
                @click="onClickFabMileage()"
                title="Demander un relevé kilométrique"
            >
            <v-icon size="20">flaticon-speed</v-icon>
            </v-btn>
            <v-btn
            fab
            dark
            small
            color="red"
            @click="onClickFabDelete()"
            :title="fabBtnDeleteTitle"
            >
            <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-speed-dial>   
      <v-row>
      <v-col sm="12">
        <v-tabs v-model="tab">
          <v-tab href="#tab-1">FICHE COLLABORATEUR</v-tab>          
          <v-tab v-if="!isNew && !isMe" href="#tab-3" @click="onClickTabVehicles">VEHICULES</v-tab>          
          <v-tab v-if="!isNew && !isMe" href="#tab-4" @click="onClickTabFuelIntakes">CARBURANTS</v-tab>
          <v-tab v-if="!isNew && !isMe" href="#tab-5" @click="onClickTabServices">ENTRETIENS</v-tab>
          <v-tab v-if="!isNew && !isMe" href="#tab-6" @click="onClickTabAudits">AUDITS</v-tab>
          <v-tab v-if="!isNew && !isMe" href="#tab-7" @click="onClickTabMileages">RELEVES KILOMETRIQUES</v-tab>
          <v-tab href="#tab-8" @click="onClickTabProfile">PROFIL</v-tab>
        </v-tabs>    

        <!-- FICHE COLLABORATEUR 
        ----------------------->
        <v-tabs-items v-model="tab" class="pa-10">
          <v-tab-item value="tab-1">   
          <v-row>
            <v-col :md="isNew || isMe? 12 : 8">            
              <v-form
                ref="form"
                v-model="isValid"
                lazy-validation                
              >
                <v-row>
                  <v-col md="12" class="subtitle-1 font-weight-medium">IDENTITE</v-col>
                            
                  <v-col md="6">
                    <v-text-field
                      v-model="collaborator.lastname"                  
                      :rules="[v => !!v || 'Nom obligatoire']"     
                      label="Nom"
                      required
                    ></v-text-field>
                  </v-col>         
                  <v-col md="6">
                    <v-text-field
                      v-model="collaborator.firstname"                  
                      :rules="[v => !!v || 'Prénom obligatoire']"     
                      label="Prénom"
                      required
                    ></v-text-field>
                  </v-col>   

                  <v-col md="12">
                    <v-text-field
                      v-model="collaborator.mail"                  
                      :rules="[v => !!v || 'Email obligatoire']"     
                      label="E-mail / Login"
                      required
                    ></v-text-field>
                  </v-col>  

                  <v-col md="6">
                    <v-text-field
                      v-model="collaborator.mobile"                                      
                      label="Mobile"
                      required
                    ></v-text-field>
                  </v-col>         
                  <v-col md="6">
                    <v-text-field
                      v-model="collaborator.phone"                                      
                      label="Téléphone"
                      required
                    ></v-text-field>
                  </v-col>                     
                </v-row>  

                <v-divider/>

                <v-row>
                  <v-col md="12" class="subtitle-1 font-weight-medium">ADRESSE</v-col>
                
                  <v-col md="6">
                    <v-text-field
                      v-model="collaborator.address1"                                    
                      label="Adresse"
                      required
                    ></v-text-field>
                  </v-col>  
                  <v-col md="6">
                    <v-text-field
                      v-model="collaborator.address2"                                     
                      label="Complément d'adresse"
                      required
                    ></v-text-field>
                  </v-col>                          

                  <v-col md="8">
                    <v-text-field
                      v-model="collaborator.city"                                      
                      label="Ville"
                      required
                    ></v-text-field>
                  </v-col>   
                  <v-col md="4">
                    <v-text-field
                      v-model="collaborator.zip"                                      
                      label="Code postal"
                      required
                    ></v-text-field>
                  </v-col>   
                
                  <v-col md="4" class="text-right">
                    <v-icon size="32" class="mt-3">flaticon-pin</v-icon>
                  </v-col>  
                  <v-col md="4">
                    <v-text-field
                      v-model="collaborator.latitude"                                     
                      label="Latitude"                      
                    ></v-text-field>
                  </v-col>         
                  <v-col md="4">
                    <v-text-field
                      v-model="collaborator.longitude"                                      
                      label="Longitude"                      
                    ></v-text-field>
                  </v-col>                         
                </v-row>     

                <v-divider/>

                <v-row>
                  <v-col md="12" class="subtitle-1 font-weight-medium">ENTREPRISE</v-col>
                
                  <v-col md="4">
                    <div class="d-flex flex-no-wrap">
                      <div v-if="imgCustomerLogo">
                        <v-avatar tile size="52">
                          <v-img 
                            :src="imgCustomerLogo" 
                            max-width="80"
                          />
                        </v-avatar>
                      </div>
                      <div style="width: 100%"> 
                        <v-select       
                          class="pl-2"               
                          v-model="collaborator.customer_id"
                          :items="customers"
                          item-text="name"
                          item-value="id"
                          :rules="[v => !!v || 'Entreprise obligatoire']"     
                          label="Entreprise / Employeur"
                          @change="onChangeCustomer()"
                          required                                   
                        ></v-select>
                      </div>
                    </div>
                  </v-col>

                  <v-col md="4">                   
                    <v-select       
                      class="pl-2"               
                      v-model="collaborator.place_id"
                      :items="places"
                      item-text="name"
                      item-value="id"                         
                      label="Agence"
                      required                                   
                    ></v-select>                      
                  </v-col>

                  <v-col md="4">
                    <v-checkbox
                        v-model="collaborator.is_out"
                        label="A quitter l'entreprise"
                        color='red'
                      ></v-checkbox>
                  </v-col>

                  <v-col md="4">
                    <v-text-field
                      v-model="collaborator.user_number"                                      
                      label="Numero/Matricule"                      
                    ></v-text-field>
                  </v-col>   
                    
                  <v-col md="4">
                    <v-text-field
                      v-model="collaborator.department"                                     
                      label="Département"
                      required
                    ></v-text-field>
                  </v-col>         

                  <v-col md="4">
                    <v-text-field
                      v-model="collaborator.function"                                      
                      label="Fonction"
                      required
                    ></v-text-field>
                  </v-col>  

                  <v-col md="4">
                    <v-text-field
                      v-model="collaborator.cost_id"                                      
                      label="Centre de cout"                      
                    ></v-text-field>
                  </v-col>    

                  <v-col md="4">
                    <v-text-field
                      v-model="collaborator.car_policy"                                      
                      label="Car policy"                      
                    ></v-text-field>
                  </v-col>                                                
                </v-row>                  

                <v-row>              
                  <v-col md="12" class="text-right">
                    <v-btn
                      v-if="isMe"
                      color="primary"
                      class="mr-4"
                      @click="onCLickBtnChangePassword"
                    >
                      Modifier mon mot de passe
                    </v-btn>  

                    <v-btn      
                      v-if="!isMe"            
                      :disabled="!isValid"
                      color="primary"
                      class="mr-4"
                      @click="onCLickBtnNewPassword"
                    >
                      Envoyer un nouveau mot de passe
                    </v-btn>                    

                    <v-btn
                      :disabled="!isValid"
                      color="success"
                      class="mr-4"
                      @click="onClickBtnValidate"
                    >
                      Valider
                    </v-btn>     
                  </v-col> 
                </v-row>  
              </v-form>    
            </v-col>  

            <v-col v-if="!isNew && !isMe" md="4">
              <v-col md="12" class="subtitle-1 font-weight-medium mb-2">VEHICULE ACTUEL</v-col>
              <v-row v-for="vehicle in currentVehicles" :key="vehicle.id"> 
                           
                <v-col md="12" class="text-center ma-0 pa-0">
                  <a @click="onClickVehicleRow({id: vehicle.id})" title="Cliquez pour accéder à la fiche véhicule">    
                    <div class="d-flex flex-column justify-space-between align-center">
                      <v-img 
                        :src="`${$constants.application.resourcesURL}/images/vehicles/${vehicle.model.brand.toLowerCase().replace(' ', '_')}/logo.png`" 
                        max-width="80"
                      />
                    </div>
                  </a>
                </v-col>
                <v-col md="12" class="text-center ma-0 pa-0"> 
                  <a @click="onClickVehicleRow({id: vehicle.id})" title="Cliquez pour accéder à la fiche véhicule">    
                  <div class="d-flex flex-column justify-space-between align-center">
                    <v-img 
                      :src="vehicle.image" 
                      max-width="55%"
                    />            
                  </div>
                  </a>
                </v-col>   
                <v-col md="12" class="text-center ma-0 pa-0">  
                  {{vehicle.model.brand}} {{vehicle.model.model}}<br/>{{vehicle.registration}}
                </v-col>  
                <v-col v-if="vehicle.lease" md="12" class="text-center ma-0 pa-0">  
                  <v-chip
                    v-if="vehicle.lease.status != 0"
                    :color="getLeaseStatusColor(vehicle.lease.status)"
                    dark
                  >
                    {{ getLeaseStatusLabel(vehicle.lease.status) }}
                  </v-chip>  
                </v-col>       
                <v-col v-else md="12" class="text-center ma-0 pa-0">  
                  <v-chip
                    v-if="vehicle.status !== 1"
                    :color="getVehicleStatusColor(vehicle.status)"
                    dark
                  >
                    {{ getVehicleStatusLabel(vehicle.status) }}
                  </v-chip>  
                </v-col>  

                
                <v-col md="12" class="ma-0 pa-0">
                  <a-vehicle-charts :vehicle="vehicle" no-consumption orientation="vertical" :height="200"/>
                </v-col>   
                
              </v-row>
            </v-col>      
          </v-row>
        </v-tab-item>

          <!-- VEHICULES
          -------------->
          <v-tab-item value="tab-3" v-if="!isNew && !isMe"> 
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                  v-model="searchTab3"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedVehiclesItems"
                :headers="headerVehicles"
                :items="vehiclesItems"
                :search="searchTab3"
                show-select                
                item-key="id"      
                :loading="isLoading"
                loading-text="Chargement en cours..."
                locale="fr-FR"     
                @click:row="onClickVehicleRow"           
              >
                <template v-slot:group.header="{group, items, isOpen, toggle}">
                  <th colspan="5" class="subtitle-2">
                    <v-icon @click="toggle" class="mr-3"
                      >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ group }}
                  </th>
                </template>

                <template v-slot:item.designation="{ item }">
                   <div class="d-flex flex-no-wrap align-center">
                    <v-img max-width="48" :src="item.image"/>                       
                    <div>{{ item.designation }}</div>
                  </div>                                   
                </template>                  

                <template v-slot:item.status="{ item }">
                  <v-chip 
                    v-if="item.status != ''"                                       
                    class="ml-0 mr-1 my-1"
                    :color="item.statusColor"
                    text-color="white"        
                  >                   
                    {{ item.status }}
                  </v-chip>                 
                </template>     

                <template v-slot:item.actions="{ item }">
                  <v-btn
                    v-if="item.isCurrent"
                    x-small
                    class="mr-2"
                    @click.stop="onClickBtnVehicleGiveBack(item)"
                  >
                    Restituer
                  </v-btn>                 
                </template>                  
              </v-data-table>
            </v-card>                      
          </v-tab-item>
         

          <!-- CARBURANTS
          -------------->
          <v-tab-item value="tab-4" v-if="!isNew"> 
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                  v-model="searchTab4"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedFuelIntakesItems"
                :headers="headersFuelIntakes"
                :items="fuelIntakesItems"
                :search="searchTab4"
                show-select                
                item-key="id"      
                :loading="isLoading"
                loading-text="Chargement en cours..."                
              >
                <template v-slot:group.header="{group, items, isOpen, toggle}">
                  <th colspan="5" class="subtitle-2">
                    <v-icon @click="toggle" class="mr-3"
                      >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ group }}
                  </th>
                </template>

                <template v-slot:item.distributor="{ item }">
                    <v-avatar tile size="36">
                      <v-img :src="item.image"/>         
                    </v-avatar>
                    {{ item.distributor }}                  
                </template>        

                <template v-slot:item.model="{ item }">
                    <v-avatar tile size="36">
                      <v-img :src="item.maker_image"/>         
                    </v-avatar>
                    {{ item.model }}                  
                </template>          
              </v-data-table>
            </v-card>                      
          </v-tab-item>

          <!-- ENTRETIENS
          -------------->
          <v-tab-item value="tab-5" v-if="!isNew"> 
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                  v-model="searchTab5"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedServicesItems"
                :headers="headersServices"
                :items="servicesItems"
                :search="searchTab5"
                show-select                
                item-key="id"      
                :loading="isLoading"
                loading-text="Chargement en cours..."                
              >
                <template v-slot:group.header="{group, items, isOpen, toggle}">
                  <th colspan="6" class="subtitle-2">
                    <v-icon @click="toggle" class="mr-3"
                      >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ group }}
                  </th>
                </template>

                <template v-slot:item.service="{ item }">
                  <v-chip
                    v-if="item.type == $constants.vehicleServices.SERVICE_OVERHAUL"
                    small
                    class="ml-0 mr-1 my-1"
                    color="teal accent-3"
                    text-color="white"        
                  >
                    <v-avatar left>
                      <v-icon size="18">flaticon-oil</v-icon>
                    </v-avatar>
                    {{ item.service }}
                  </v-chip>

                  <v-chip
                    v-if="item.type == $constants.vehicleServices.SERVICE_TIRES"
                    small
                    class="ml-0 mr-1 my-1"
                    color="deep-purple"
                    text-color="white" 
                  >
                    <v-avatar left>
                      <v-icon size="18">flaticon-tire</v-icon>
                    </v-avatar>
                    {{ item.service }}
                  </v-chip>

                  <v-chip
                    v-if="item.type == $constants.vehicleServices.SERVICE_BRAKEPADS"
                    small
                    class="ml-0 mr-1 my-1"
                    color="orange darken-3"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon size="18">flaticon-brake</v-icon>
                    </v-avatar>
                    {{ item.service }}
                  </v-chip>

                  <v-chip
                    v-if="item.type == $constants.vehicleServices.SERVICE_MISC"
                    small
                    class="ml-0 mr-1 my-1"
                    color="light-blue darken-2"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon size="18">flaticon-tools-and-utensils</v-icon>
                    </v-avatar>
                    {{ item.service }}
                  </v-chip>
                </template>

                <template v-slot:item.status="{ item }">
                  <v-chip                    
                    small
                    class="ml-0 mr-1 my-1"
                    :color="item.statusColor"
                    text-color="white"        
                  >                   
                    {{ item.status }}
                  </v-chip>                 
                </template>

                <template v-slot:item.place="{ item }">
                    <v-avatar tile size="36">
                      <v-img :src="item.image"/>         
                    </v-avatar>
                    {{ item.place }}                  
                </template>

                <template v-slot:item.model="{ item }">
                    <v-avatar tile size="36">
                      <v-img :src="item.maker_image"/>         
                    </v-avatar>
                    {{ item.model }}                  
                </template>  
              </v-data-table>
            </v-card>               
          </v-tab-item>

          <!-- AUDITS
          ---------->
          <v-tab-item value="tab-6" v-if="!isNew"> 
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                  v-model="searchTab6"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedAuditsItems"
                :headers="headersAudits"
                :items="auditsItems"
                :search="searchTab6"
                show-select                
                item-key="id"      
                :loading="isLoading"
                loading-text="Chargement en cours..."                
              >
                <template v-slot:group.header="{group, items, isOpen, toggle}">
                  <th colspan="5" class="subtitle-2">
                    <v-icon @click="toggle" class="mr-3"
                      >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ group }}
                  </th>
                </template>

                <template v-slot:item.status="{ item }">
                  <v-chip                    
                    small
                    class="ml-0 mr-1 my-1"
                    :color="item.statusColor"
                    text-color="white"        
                  >                   
                    {{ item.status }}
                  </v-chip>                                
                </template>      

                <template v-slot:item.model="{ item }">
                    <v-avatar tile size="36">
                      <v-img :src="item.maker_image"/>         
                    </v-avatar>
                    {{ item.model }}                  
                </template>                
              </v-data-table>
            </v-card>                     
          </v-tab-item>

          <!-- RELEVES KILOMETRIQUES
          -------------------------->
          <v-tab-item value="tab-7" v-if="!isNew"> 
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                  v-model="searchTab7"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedMileagesItems"
                :headers="headersMileages"
                :items="mileagesItems"
                :search="searchTab7"
                show-select                
                item-key="id"      
                :loading="isLoading"
                loading-text="Chargement en cours..."                
              >
                <template v-slot:item.source="{ item }">                  
                    <v-icon dense :color="item.sourceColor">
                      {{item.sourceIcon}}
                    </v-icon>                  
                </template>     

                <template v-slot:item.status="{ item }">
                  <v-chip                    
                    small
                    class="ml-0 mr-1 my-1"
                    :color="item.statusColor"
                    text-color="white"        
                  >                   
                    {{ item.status }}
                  </v-chip>                 
                </template>    

                <template v-slot:item.model="{ item }">
                    <v-avatar tile size="36">
                      <v-img :src="item.maker_image"/>         
                    </v-avatar>
                    {{ item.model }}                  
                </template>                 
              </v-data-table>
            </v-card>                     
          </v-tab-item>

          <!-- PROFIL
          ---------->
          <v-tab-item value="tab-8"> 
            <v-row>
              <v-col md="12" class="subtitle-1 font-weight-medium">PROPRIETES</v-col>              
              <v-col md="12">
                <v-checkbox
                  v-if="!isMe"
                  v-model="collaborator.is_locked"
                  label="Profil verrouillé"
                  color='red'
                ></v-checkbox> 

                <v-checkbox
                  v-if="isMe"
                  v-model="collaborator.is_dark_theme"
                  label="Affichage sombre"
                  color='primary'
                  @change="$vuetify.theme.dark = collaborator.is_dark_theme"
                ></v-checkbox> 
                
                <v-checkbox
                  v-model="collaborator.is_oncall_duty"
                  label="Reçoit les notifications SMS d'assistance"
                  color='primary'
                ></v-checkbox>               
              </v-col> 

              <v-col md="4">
                <v-text-field
                  v-model="collaborator.name"                                      
                  label="identifiant de connexion"                      
                  disabled
                ></v-text-field>
              </v-col>    

              <v-col md="4">
                <v-select                      
                  v-model="collaborator.role"
                  :items="roles"
                  item-text="name"
                  item-value="id"                    
                  label="Role"
                  required       
                  :disabled="isMe"                            
                ></v-select>     
              </v-col>
              <v-col md="4">
                <v-checkbox
                  v-model="collaborator.using_app"
                  label="Utilise l'application mobile"
                ></v-checkbox>
              </v-col>              
            </v-row> 

            <v-row v-if="profile.is_su && collaborator.role == 1">
              <v-col md="12" class="subtitle-1 font-weight-medium">COMPTE CLIENT EN GESTION</v-col>
              <v-col sm="12">
                <a-customer-list   
                  show-select            
                  @change="onChangeSelection"   
                  :selected-ids="collaborator.managed_customers_ids"                          
                />    
              </v-col>
            </v-row>       

             <v-row>              
              <v-col md="12" class="text-right">
                <v-btn
                  v-if="isMe"
                  color="primary"
                  class="mr-4"
                  @click="onCLickBtnChangePassword"
                >
                  Modifier mon mot de passe
                </v-btn>  

                <v-btn      
                  v-if="!isMe"            
                  :disabled="!isValid"
                  color="primary"
                  class="mr-4"
                  @click="onCLickBtnNewPassword"
                >
                  Envoyer un nouveau mot de passe
                </v-btn>                    

                <v-btn
                  :disabled="!isValid"
                  color="success"
                  class="mr-4"
                  @click="onClickBtnValidate"
                >
                  Valider
                </v-btn>     
              </v-col> 
            </v-row>  
     
          </v-tab-item>
        </v-tabs-items>                     
      </v-col>
    </v-row>

    <v-row v-if="!isNew">
      <v-dialog        
        v-model="dialog"
        max-width="700px"        
      >        
        <v-card max-width="700px">
          <v-card-title>CONTRAT</v-card-title>
          <v-card-text>
            <a-lease-form 
              :lease="dataObject"               
              :initialize="dialog" 
              @cancel="dialog = false" 
              @validate="onValidateLease($event)"
            />
          </v-card-text>
          </v-card>
      </v-dialog>

      <v-dialog        
        v-model="dialogMaintenance"
        max-width="700px"        
      >        
        <v-card max-width="700px">
          <v-card-title>ENTRETIEN A PREVOIR</v-card-title>
          <v-card-text>
            <a-maintenance-form 
              :service="dataObject"               
              :initialize="dialogMaintenance" 
              @cancel="dialogMaintenance = false" 
              @validate="onValidateMaintenance($event)"
            />
          </v-card-text>
          </v-card>
      </v-dialog>

      <v-dialog        
        v-model="dialogFuelIntake"
        max-width="60%"        
      >        
        <v-card>
          <v-card-title>PRISE DE CARBURANT</v-card-title>
          <v-card-text>
            <a-fuel-intake-form                             
              :initialize="dialogFuelIntake" 
              @cancel="dialogFuelIntake = false" 
              @validate="onValidateFuelIntake($event)"
            />
          </v-card-text>
          </v-card>
      </v-dialog>

      <v-dialog        
        v-model="dialogGetDateRestitution"
        max-width="600px"        
      >        
        <v-card>
          <v-card-title>RESTITUTION DU VEHICULE</v-card-title>
          <v-card-text>
            <a-vehicle-header class="mb-1" :vehicle="selectedVehicle"></a-vehicle-header>
            <v-date-picker 
                class="mb-1"     
                first-day-of-week="1"
                flat                        
                v-model="dateSelected"    
                full-width                         
                locale="fr-fr"                
              ></v-date-picker>   
            <v-text-field                
                v-model="distance"                                    
                label="Kilométrage"
                required
              ></v-text-field>
          </v-card-text>
          <v-card-actions class="text-right">
            <v-spacer></v-spacer>
            <div class="mb-2">
              <v-btn
                    :disabled="!isValid"
                    color="error"
                    class="mr-4"
                    @click="dialogGetDateRestitution = false"
                  >
                    Annuler
              </v-btn>   
              <v-btn
                    :disabled="!isValid"
                    color="primary"
                    class="mr-4"
                    @click="onClickBtnGiveBack()"
                  >
                    Restituer
              </v-btn>          
            </div>       
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog        
        v-model="dialogGetDateAssignation"
        max-width="500px"        
      >        
        <v-card>
          <v-card-title>AFFECTATION DU VEHICULE AU</v-card-title>
          <v-card-text>
            <v-date-picker      
                first-day-of-week="1"
                flat                        
                v-model="dateSelected"    
                full-width                         
                locale="fr-fr"                
              ></v-date-picker>   
              <v-text-field
                v-model="distance"                                    
                label="Kilométrage"
                required
              ></v-text-field>
          </v-card-text>
          <v-card-actions class="text-right">
            <v-spacer></v-spacer>
            <v-btn
                  :disabled="!isValid"
                  color="primary"
                  class="mr-4"
                  @click="onClickBtnAssign()"
                >
                  Affecter
            </v-btn>     
          </v-card-actions>
        </v-card>
      </v-dialog>

      <a-modal-vehicle-selector        
        title="AFFECTATION DE VEHICULE"
        single-select
        :customer="collaborator.customer_id"
        ref="modalVehicleSelector"
        @validate="onValidateUser"
      />

      <a-modal-message-maker                 
        :title="`Envoyer un message à ${this.collaborator.firstname} ${this.collaborator.lastname}`"
        auto-close
        @sendMessage="onClickBtnSendMessage($event)"
        ref="modalMessageMaker"
        />     
    </v-row>

    <a-modal-change-password ref="aModalChangePassword"/>
    </v-container>
    
  
</template>

<script>
import { mapGetters } from 'vuex'

import Functions from "@/js/functions.js"

import APageHeader from "@/components/APageHeader"
import AMaintenanceForm from "@/components/AMaintenanceForm"
import ALeaseForm from "@/components/ALeaseForm"
import AFuelIntakeForm from "@/components/AFuelIntakeForm"
import AModalMessageMaker from "@/components/AModalMessageMaker"
import AVehicleCharts from "@/components/AVehicleCharts"
import AVehicleHeader from "@/components/AVehicleHeader"

import AModalVehicleSelector from '../components/AModalVehicleSelector.vue'
import ACustomerList from "@/components/ACustomerList"
import AModalChangePassword from "@/components/AModalChangePassword"

export default {
  name: 'Profile',  
  components: { APageHeader, ALeaseForm, AMaintenanceForm, AFuelIntakeForm, AModalMessageMaker, AModalVehicleSelector, AVehicleCharts, AVehicleHeader, ACustomerList, AModalChangePassword },
  data: () => ({
    assignatedDriver: 0,
    dialog: false,
    dialogGetDateRestitution: false,
    dialogGetDateAssignation: false,
    dialogMaintenance: false,
    dialogFuelIntake: false,
    dataObject: {},
    distance: undefined,
    dateSelected: new Date().toISOString().substr(0, 10),
    currentDriver: undefined,    
    updateArgs: [true, true, {duration: 1000}],
    selectedVehicle: {},
    vehicles: [],
    roles: [],
    imgCustomerLogo: undefined,

    // Series
    series: [
      {id: 1, label: "Berline"},
      {id: 2, label: "Break"},
      {id: 3, label: "Coupé"},
      {id: 4, label: "SUV"},
      {id: 5, label: "Utilitaire"},
      {id: 6, label: "Cabriolet"}
    ],

    // Table des vehicules
    headerVehicles: [                  
      {text: "Immatriculation", value: "registration"},      
      {text: "Désignation", value: "designation", groupable: false},      
      {text: "Affecté le", value: "dateAssignment"},
      {text: "Restitué le", value: "dateRestitution"},                           
      {text: "Status", value: "status", align: "center"},      
      {text: "Echéance", value: "closure", groupable: false},  
      {text: "", value: 'actions', sortable: false },
    ],
    selectedVehiclesItems: undefined,
    vehiclesItems: [],    

    // Table des prises de carburant
    headersFuelIntakes: [                  
      {text: "Date", value: "date"},
      {text: "Distributeur", value: "distributor"},
      {text: "Carburant", value: "fuel", groupable: false},
      {text: "Quantité", value: "quantity", align: "end"},
      {text: "Prix unitaire", value: "unitPrice", align: "end"},
      {text: "Prix", value: "totalPrice", align: "end"},                
      {text: "Kilométrage", value: "distance", align: "end"},  
      {text: "Vehicule", value: "model"},
      {text: "Immatriculation", value: "registration"},
    ],
    selectedFuelIntakesItems: undefined,
    fuelIntakesItems: [],
    
    // Table des Entretiens
    headersServices: [            
      {text: "Entretien", value: "service", groupable: false},
      {text: "Vehicle", value: "model"},
      {text: "Immatriculation", value: "registration"},
      {text: "Date", value: "date"},
      {text: "Lieu", value: "place"},
      {text: "Kilométrage relevé", value: "realDistance"},
      {text: "Kilométrage théorique", value: "distance"},
      {text: "Statut", value: "status", groupable: false}
    ],
    selectedServicesItems: undefined,
    servicesItems: [],

    // Table des audit
    headersAudits: [              
      {text: "Date de demande", value: "dateCreated"},
      {text: "Date de réalisation", value: "dateRealised"},
      {text: "Vehicule", value: "model"},
      {text: "Immatriculation", value: "registration"},
      {text: "Kilométrage", value: "distance"},      
      {text: "Statut", value: "status", groupable: false}
    ],
    selectedAuditsItems: undefined,
    auditsItems: [],

    // Table des relevés kilométriques
    headersMileages: [               
      {text: "Date de demande", value: "dateCreated"},
      {text: "Date de réalisation", value: "dateRealised"},
      {text: "Vehicule", value: "model"},
      {text: "Immatriculation", value: "registration"},
      {text: "Kilométrage", value: "distance"},      
      {text: "Source", value: "source", groupable: false, align: "center"},
      {text: "Statut", value: "status", groupable: false, align: "center"}
    ],
    selectedMileagesItems: undefined,
    mileagesItems: [],    

    fab: false,
    tab: 0,
    registration: '',
    registrationRules: [
        v => !!v || 'Immatriculation obligatoire'
      ],
    brand: '',
    model: '',
    distanceInitialDate: '',
    distanceInitial: '',
    rollingLaw: 94,
    isValid: true,        
    collaborator: {id: 0},
    customers: [],
    places:[],
    currentVehicles: [],
    models: [],
    modifications: [],
    lines: [],
    vehicleItems: [],
    selectedItems: [],
    selectedCustomers: [],
    
    // Zones de recherche
    searchTab1: '',
    searchTab2: '',
    searchTab3: '',
    searchTab4: '',
    searchTab5: '',
    searchTab6: '',
    searchTab7: '',

    fabBtnAddTitle: '',
    fabBtnDeleteTitle: '',    
    isLoading: true,
    isNew: false,
    isMe: false,
    showAddFabBtn: false,
    showAuditFabBtn: false,
    showMileageFabBtn: false
  }),
  methods: {
    getLeaseStatusColor: function(status) {
      return Functions.Leases.getStatusColor(status)
    },
    getLeaseStatusLabel: function(status) {
      return Functions.Leases.getStatusLabel(status)
    },
    getVehicleStatusColor: function(status) {
      return Functions.Vehicles.getStatusColor(status)
    },
    getVehicleStatusLabel: function(status) {
      return Functions.Vehicles.getStatusLabel(status)
    },
    // Méthodes évènements UI
    // ----------------------
    onClickFabAdd: function() {
      this.dataObject = undefined
      switch (this.tab) {
        case "tab-1":
          break

        case "tab-2":          
          this.dialog = true
          break

        case "tab-3":
          this.$refs.modalVehicleSelector.show()
          break

        case "tab-4":
          this.dialogFuelIntake = true
          break

        case "tab-5":
          this.dialogMaintenance = true
          break

        case "tab-6":
          break

        case "tab-7":
          break

        default:
      }
    },  
    onClickFabMessage: function() {
        this.$refs.modalMessageMaker.show()
    },
    onClickFabAudit: function() { 
      if (this.selectedVehiclesItems.length > 0) {     
        let ids = this.selectedVehiclesItems.map(el => el.id)      
  
        let url = `audit?ids=${ids.join(',')}`   
        this.$http
              .post(url)   
              .then(response => {
                if (response.status == 200) this.getAudits()
              })
              .catch(error => {                                
                  if (error.response.status === 401) this.$root.$emit("logout"); 
                  else this.$root.$emit("serverError", error);     
              });
      }
    },
    onClickFabMileage: function() {  
      if (this.selectedVehiclesItems.length > 0) {
        let ids = this.selectedVehiclesItems.map(el => el.id)    
        let url = `mileage?ids=${ids.join(',')}`   
        this.$http
              .post(url)   
              .then(response => {
                if (response.status == 200) this.getMileages()
              })
              .catch(error => {                                
                  if (error.response.status === 401) this.$root.$emit("logout"); 
                  else this.$root.$emit("serverError", error);     
              });
      }
    },
    onClickFabDelete: function() {      
      switch (this.tab) {
        case "tab-1":
          break

        case "tab-3":
          this.onDeleteVehicles()
          break

        case "tab-4":
          this.onDeleteFuelIntakes()
          break

        case "tab-5":      
          this.onDeleteServices();    
          break

        case "tab-6":
          this.onDeleteAudits()
          break
        
        case "tab-7":
          this.onDeleteMileages()
          break

        default:
      }
    },
    onClickBtnSendMessage: function(message) {
        let url = "messages"
        const data = {
            to: this.collaborator.id,
            text: message
        } 
        this.$http
          .post(url, data)            
          .then(response => {                        
              if (response.status == 200) {
                this.get(false)    
                this.dialog = false            
              }                                          
          })
          .catch(error => {             
              console.log(error)                 
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
    },
    onCLickBtnNewPassword: function() {
      if (this.collaborator.name !== '') {
        let url = "forgetpassword"
        const data = {
            user: this.collaborator.name
        }
        this.$http
            .post(url, data)            
            .then(response => {                                   
                if (response.status === 200) {
                  this.$root.$emit('notify', 'Nouveau mot de passe envoyé', 'success', 'mdi-checkbox-marked-circle')
                } else {
                  console.log();
                }                        
            })
            .catch(error => {
                console.log(error)
            });           
      }
    },
    onCLickBtnChangePassword: function() {
      this.$refs.aModalChangePassword.show()
    },
    onClickBtnValidate: function() {
      let params = {
        id: this.collaborator.id,
        firstname: this.collaborator.firstname,
        lastname: this.collaborator.lastname,
        username: this.collaborator.name,
        user_number: this.collaborator.user_number,
        mail: this.collaborator.mail,
        phone: this.collaborator.phone,
        mobile: this.collaborator.mobile,
        address1: this.collaborator.address1,
        address2: this.collaborator.address2,
        zip: this.collaborator.zip,
        city: this.collaborator.city,
        latitude: this.collaborator.latitude,
        longitude: this.collaborator.longitude,
        customer_id: this.collaborator.customer_id,
        place_id: this.collaborator.place_id,
        function: this.collaborator.function,
        department: this.collaborator.department,
        cost_id: this.collaborator.cost_id,
        role: this.collaborator.role,
        using_app: this.collaborator.using_app,
        managed_customers_ids: this.selectedCustomers.map(element => element.id).join(','),
        is_locked: this.collaborator.is_locked,
        is_out: this.collaborator.is_out,        
        car_policy: this.collaborator.car_policy,
        is_oncall_duty: this.collaborator.is_oncall_duty,
        is_dark_theme: this.collaborator.is_dark_theme        
      }
      let url = `user`
      if (this.collaborator.id == 0) {
        this.$http.post(url, params)   
          .then(response => {
            if (response.status == 200) {
              this.collaborator = response.data
              this.isNew = false 
              this.$root.$emit('notify', 'Nouveau collaborateur crée', 'success', 'mdi-checkbox-marked-circle')             
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
      else {
        this.$http.put(url, params)   
          .then(response => {
            if (response.status == 200) {
              this.collaborator = response.data
              this.$root.$emit('notify', 'Le collaborateur a été mis à jour', 'success', 'mdi-checkbox-marked-circle')
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
    },
    onClickTabLeases: function() {
      this.getLeases()
    },
    onClickTabVehicles: function() {
      this.getVehicles()
    },
    onClickTabFuelIntakes: function() {
      this.getFuelIntakes()
    },
    onClickTabServices: function() {
      this.getServices()
    },
    onClickTabAudits: function() {
      this.getAudits()
    },
    onClickTabMileages: function() {
      this.getMileages()
    },
        onClickTabProfile: function() {

    },
    onClickLeaseRow: function(event) {
      this.dataObject = event      
      this.dialog = true      
    },
    onClickVehicleRow: function(event) { 
      this.$router.push({
        name: 'Vehicle', 
        params: { 
          id: event.vehicle_id
        }
      })      
    },
    onClickBtnVehicleGiveBack: function(event) { 
      if (event.isCurrent) {
        let vehicle = this.vehicles.find(el => el.vehicle_id == event.vehicle_id)
        this.selectedVehicle = {
          id: vehicle.id,
          registration: vehicle.registration,
          model: {
            brand: vehicle.provider.name,
            model: vehicle.model.name,
            image: `${this.$constants.application.resourcesURL}/images/vehicles/${vehicle.provider.name.toLowerCase().replace(' ', '_')}/${vehicle.image_file}`,    
          }
        }
        this.assignatedDriver = event.id   
        this.distance = undefined    
        this.dateSelected = new Date().toISOString().substr(0, 10)   
        this.dialogGetDateRestitution = true
      }       
    },
    onClickBtnGiveBack: function() {
      let params = {        
        date_restitution: this.dateSelected
      }
      if (this.assignatedDriver !== undefined) params.driver = this.assignatedDriver
      if (this.distance !== undefined) params.distance = this.distance
      let url = `vehicle/${this.$route.params.id}`
      this.$http
            .put(url, params)   
            .then(response => {
              if (response.status == 200) {
                this.getVehicles();
                this.get();  
                this.dialogGetDateRestitution = false
              }  
            })
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
    },
    onClickBtnAssign: function() {
      let params = {
        user: this.assignatedDriver,
        date_assignation: this.dateSelected        
      }
      if (this.distance !== undefined) params.distance = this.distance
      let url = `vehicle/${this.$route.params.id}`
      this.$http
            .put(url, params)   
            .then(response => {
              if (response.status == 200) {
                this.getVehicles();
                this.get();  
                this.dialogGetDateAssignation = false
              }  
            })
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
    },
    onChangeMaker: function() {
      this.getModels()
      this.getModifications()
      this.getLines()
    },
    onChangeCustomer: async function() {
      this.getCustomerLogo();
      await this.getPlaces();
    },
    onValidateUser: function(user) {
      this.assignatedDriver = user[0].id
      this.dateSelected = new Date().toISOString().substr(0, 10)
      this.dialogGetDateAssignation = true
    },
    onValidateLease: function(lease) {
      this.dataObject = lease
      this.getLeases()
      this.get();
      this.dialog = false
    },

    onValidateMaintenance: function(service) {
      this.dataObject = service
      this.getServices()
      this.get();
      this.dialogMaintenance = false
    },
    onValidateFuelIntake: function() {
      this.getFuelIntakes()
      this.get();
      this.dialogFuelIntake = false
    },
    onDeleteVehicles: function() {
      console.log('onDeleteVehicles')
    },
    onDeleteAudits: function() {     
      let ids = this.selectedAuditsItems.map(el => el.id)      

      let url = `audit?ids=${ids.join(',')}`      
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.auditsItems = this.auditsItems.filter(el => ids.indexOf(el.id) == -1)                                        
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });            
    },
    onDeleteMileages: function() {     
      let ids = this.selectedMileagesItems.map(el => el.id)      

      let url = `mileage?ids=${ids.join(',')}`      
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.mileagesItems = this.mileagesItems.filter(el => ids.indexOf(el.id) == -1)                
                this.get();              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });            
    },    
    onDeleteFuelIntakes: function() {     
      let ids = this.selectedFuelIntakesItems.map(el => el.id)      

      let url = `fuelintakes?ids=${ids.join(',')}`      
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.fuelIntakesItems = this.fuelIntakesItems.filter(el => ids.indexOf(el.id) == -1)                
                this.get();              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });            
    },
    onDeleteServices: function() {     
      let ids = this.selectedServicesItems.map(el => el.id)      

      let url = `services?ids=${ids.join(',')}`      
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {                
                let message = 'Les entretiens sélectionnés ont été supprimés.'
                if (response.data.undeleted) {
                  if (response.data.undeleted.length == 1) message += " 1 entretiens déjà réalisés n'a pas été supprimé."; 
                  else if (response.data.undeleted.length > 1) message += ` ${response.data.undeleted.length} entretiens déjà réalisés n'ont pas été supprimés.`; 
                }
                this.$root.$emit('notify', message , 'success', 'mdi-checkbox-marked-circle') 
                this.servicesItems = this.servicesItems.filter(el => ids.indexOf(el.id) == -1 || (response.data.undeleted === undefined ? false : (response.data.undeleted.indexOf(el.id) != -1)));                
                this.get();              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });            
    },
    onChangeSelection: function(event) {
      this.selectedCustomers = event      
    },
    // Méthodes d'accès aux données
    // ----------------------------
    // Données vehicule
    get: function() {    
        if (this.$route.params.id !== 0) { 
          this.isLoading = true
          let url = `user/${this.$route.params.id}`
          this.$http
              .get(url)            
              .then(response => {                        
                  if (response.status == 200) {
                    this.collaborator = response.data                               
                    this.getCurrentVehicles();     
                    if (this.collaborator.customer_id) this.getPlaces()                                        
                  }                                  
                  this.isLoading = false 
              })
              .catch(error => {                
                  this.isLoading = false
                  if (error.response.status === 401) this.$root.$emit("logout"); 
                  else this.$root.$emit("serverError", error);     
              });                                  
        }        
    },
    getCustomers: async function() {
      try {
        let url = `customers`
        let response = await this.$http.get(url)            
                                  
        if (response.status == 200) {
          this.customers = response.data
          if (this.$route.params.customer !== undefined) {
            this.collaborator.customer_id = this.$route.params.customer         
            this.getCustomerLogo()                   
          }                      
        }              
      }
     catch (error) {                
        this.isLoading = false
        if (error.response.status === 401) this.$root.$emit("logout")
        else this.$root.$emit("serverError", error)
      }               
    },
    getPlaces: async function() {  
      try {      
        let url = `places?cstm=${this.collaborator.customer_id}`                     
        let response = await this.$http.get(url)                                                       
          if (response.status == 200) {
            this.places = response.data                                       
          }                              
        }
        catch (error) {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          }
      },
    getCurrentVehicles: function() {
      let url = `vehicles/user/${this.collaborator.id}`;  
        
      this.$http
          .get(url)                      
          .then(response => {                        
              if (response.status == 200) {
                this.currentVehicles = response.data
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })                     
    },
    getVehicles: function() {
      let url = `users/${this.collaborator.id}/vehicles`;  
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.vehicles = response.data
                this.vehiclesItems = response.data.map(el => {   
                  let status
                  let statusColor
                  let isCurrent
                  let closure
                  
                  if (el.status == 1) {                 
                    status = 'Actuel'
                    statusColor = "green"
                    isCurrent = true

                    if (new Date(el.date_restitution) < new Date()) {
                      status = 'Rendu'
                      statusColor = "red"  
                      isCurrent = false                  
                    }
                    if (new Date(el.date_assignment) > new Date()) {
                      status = "A venir"
                      statusColor = "grey" 
                      isCurrent = false                   
                    }                                                                
                  }
                  else {
                    status = Functions.Vehicles.getStatusLabel(el.status)
                    statusColor = Functions.Vehicles.getStatusColor(el.status)
                  }

                  if (el.lease) {
                    closure = el.lease.date_closure
                    if (el.lease.status !== 0) {
                      status = Functions.Leases.getStatusLabel(el.lease.status)
                      statusColor = Functions.Leases.getStatusColor(el.lease.status)
                    }
                  }

                  return {
                    id: el.id,
                    user_id: el.user_id,
                    vehicle_id: el.vehicle_id,
                    registration: el.registration,
                    designation: el.modification === undefined ? `${el.provider.name} ${el.model.name}` : `${el.provider.name} ${el.model.name} ${el.modification.name}` ,                    
                    dateAssignment: el.date_assignment, 
                    dateRestitution: el.date_restitution,
                    image: `${this.$constants.application.resourcesURL}/images/vehicles/${el.provider.name.toLowerCase().replace(' ', '_')}/logo.png`,                                        
                    status: status,
                    statusColor: statusColor,
                    isCurrent: isCurrent,
                    closure: closure,                    
                    leaseStatus: el.lease ? el.lease.status : 0
                  }
                })
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
    getAudits: function() {      
      let url = `audits/user/${this.$route.params.id}`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.auditsItems = response.data.map(el => {
                  return {
                    id: el.id,
                    dateRealised: el.timestamp_realised == 0 ? '' : this.$moment(new Date(el.timestamp_realised)).format("DD/MM/YYYY HH:mm"),
                    dateCreated: this.$(new Date(el.timestamp_created)).format("DD/MM/YYYY HH:mm"),
                    vehicle: el.vehicle.id,                    
                    model: `${el.vehicle.model.brand} ${el.vehicle.model.model}`,
                    registration: el.vehicle.registration,
                    maker_image: `${this.$constants.application.resourcesURL}/images/vehicles/${el.vehicle.model.brand.toLowerCase().replace(' ', '_')}/logo.png`,
                    distance: el.distance,
                    status: el.is_closed ? 'Terminé' : 'En cours',
                    statusColor: el.is_closed ? this.$constants.colors.STATUS_COMPLETED : this.$constants.colors.STATUS_PROCESSING
                  }
                })
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })          
    },    
    getMileages: function() {
      let url = `mileages/user/${this.$route.params.id}`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.mileagesItems = response.data.map(el => {
                  let sourceColor = "indigo"
                  let sourceIcon = "flaticon-speed"
                  switch (el.source) {
                    case 1:
                      sourceColor = "green"
                      sourceIcon = "flaticon-keys"
                      break

                    case 2:
                      sourceColor = "red"
                      sourceIcon = "flaticon-keys"
                      break
                    
                    case 3:
                      sourceColor = "green"
                      sourceIcon = "flaticon--front-car"
                      break

                    case 4:
                      sourceColor = "orange"
                      sourceIcon = "flaticon--front-car"
                      break

                  }
                  return {
                    id: el.id,
                    dateRealised: el.timestamp_realised == 0 ? '' : this.$moment(new Date(el.timestamp_realised)).format("DD/MM/YYYY HH:mm"),
                    dateCreated: this.$moment(new Date(el.timestamp_created)).format("DD/MM/YYYY HH:mm"),
                    vehicle: el.vehicle.id,                    
                    model: `${el.vehicle.model.brand} ${el.vehicle.model.model}`,
                    registration: el.vehicle.registration,
                    maker_image: `${this.$constants.application.resourcesURL}/images/vehicle/${el.vehicle.model.brand.toLowerCase().replace(' ', '_')}/logo.png`,
                    distance: el.distance,
                    source: el.source,
                    sourceColor: sourceColor,
                    sourceIcon: sourceIcon,
                    status: el.is_closed ? 'Terminé' : 'En cours',
                    statusColor: el.is_closed ? this.$constants.colors.STATUS_COMPLETED : this.$constants.colors.STATUS_PROCESSING
                  }
                })
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
    getFuelIntakes: function() {      
      let url = `fuelintakes/user/${this.$route.params.id}`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.fuelIntakesItems = response.data.map(el => {
                  return {
                    id: el.id,
                    date: this.$moment(new Date(el.intake_timestamp)).format("DD/MM/YYYY HH:mm"),
                    distributor: el.distributor.name,
                    image: `${this.$constants.application.resourcesURL}/images/${el.image}`,
                    fuel: `${el.name} - ${el.type}`,
                    vehicle: el.vehicle.id,                    
                    model: `${el.vehicle.model.brand} ${el.vehicle.model.model}`,                  
                    registration: el.vehicle.registration,
                    maker_image: `${this.$constants.application.resourcesURL}/images/vehicle/${el.vehicle.model.brand.toLowerCase().replace(' ', '_')}/logo.png`,
                    distance: el.distance,
                    unitPrice: el.unit_price,
                    totalPrice: el.total_price,
                    quantity: el.quantity
                  }
                })
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })          
    },
    getServices: function() {      
      let url = `services/user/${this.$route.params.id}`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.servicesItems = response.data.map(el => {
                  let service = ''
                  switch (el.type) {
                    case this.$constants.vehicleServices.SERVICE_OVERHAUL:
                      service = 'Vidange/Revision'
                      break;
                    case this.$constants.vehicleServices.SERVICE_TIRES:
                      service = 'Pneumatiques'
                      break;
                    case this.$constants.vehicleServices.SERVICE_BRAKEPADS:
                      service = 'Plaquettes de freins'
                      break;
                    case this.$constants.vehicleServices.SERVICE_MISC:
                      service = 'Divers/Réparations'
                      break;
                    default:
                      break;
                  }

                  let status = ''
                  let statusColor = ''
                  switch (el.status_code) {
                    case this.$constants.vehicleServicesStatus.STATUS_TODO:
                      status = 'A prévoir'
                      statusColor = this.$constants.colors.STATUS_TODO
                      break;
                    case this.$constants.vehicleServicesStatus.STATUS_SCHEDULED:
                      status = 'Planifié'
                      statusColor = this.$constants.colors.STATUS_SCHEDULED
                      break;
                    case this.$constants.vehicleServicesStatus.STATUS_PROCESSING:
                      status = 'En cours'
                      statusColor = this.$constants.colors.STATUS_PROCESSING
                      break;
                    case this.$constants.vehicleServicesStatus.STATUS_COMPLETED:
                      status = 'Réalisé'
                      statusColor = this.$constants.colors.STATUS_COMPLETED
                      break;
                    case this.$constants.vehicleServicesStatus.STATUS_CANCELED:
                      status = 'Annulé'
                      statusColor = this.$constants.colors.STATUS_CANCELED
                      break;
                    default:
                      break;
                  }

                  return {
                    id: el.id,
                    type: el.type,
                    service: service,
                    date: el.timestamp === 0 ? '' : this.$moment(new Date(el.timestamp)).format("DD/MM/YYYY HH:mm"),
                    place: el.place_id === 0 ? '' : el.place.name,
                    image: el.place_id === 0 ? '' : `${this.$constants.application.resourcesURL}/images/${el.place.provider.image}`,
                    vehicle: el.vehicle.id,                    
                    model: `${el.vehicle.model.brand} ${el.vehicle.model.model}`,                  
                    registration: el.vehicle.registration,
                    maker_image: `${this.$constants.application.resourcesURL}/images/vehicle/${el.vehicle.model.brand}/logo.png`,
                    realDistance: el.real_distance != 0 ? el.real_distance : '',
                    distance: el.distance != 0 ? el.distance : '',
                    status: status,                     
                    statusColor: statusColor
                  }
                })

                
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })         
    },
    getUserRolesList: function() {
      let url = `listitems/roles`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.roles = response.data                
              }              
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })
    },
    getCustomerLogo: function() {
      this.imgCustomerLogo = undefined
      let c = this.customers.find(el => el.id == this.collaborator.customer_id)
      if (c !== undefined) {
        let img = c.image
        if (img) this.imgCustomerLogo = `${this.$constants.application.resourcesURL}/images/${img}`        
      }          
    }
  },  
  watch: {    
    tab: function() {
      this.showAddFabBtn = false
      this.showAuditFabBtn = false
      this.showMileageFabBtn = false
      switch (this.tab) {
        case "tab-2":
          this.fabBtnAddTitle = "Ajouter un contrat"
          this.fabBtnDeleteTitle = "Supprimer les contrats sélectionnés"
          this.showAddFabBtn = true
          break

        case "tab-3":
          this.fabBtnAddTitle = "Affecter un véhicule"
          this.fabBtnDeleteTitle = "Supprimer les conducteurs sélectionnés"
          this.showAddFabBtn = true
          this.showAuditFabBtn = true
          this.showMileageFabBtn = true
          break
          
        case "tab-4":
          this.fabBtnAddTitle = "Ajouter une prise de carburant"
          this.fabBtnDeleteTitle = "Supprimer les prises de carburant sélectionnées"
          this.showAddFabBtn = true
          break
          
        case "tab-5":
          this.fabBtnAddTitle = "Ajouter un entretien"
          this.fabBtnDeleteTitle = "Supprimer les entretiens sélectionnés"
          this.showAddFabBtn = true
          break
          
        case "tab-6":          
          this.fabBtnDeleteTitle = "Supprimer les audits sélectionnés"          
          break
          
        case "tab-7":
          this.fabBtnAddTitle = "Ajouter un relevé kilométrique"
          this.fabBtnDeleteTitle = "Supprimer les relevés kilométriques sélectionnés"          
          break                
      }
    }
  },
  computed: {
    ...mapGetters([
      'userEnvironment',
      'profile'
    ]),  
    
    title: function() {
      return this.collaborator.id == 0 
        ? "Nouveau collaborateur" 
        : `${this.collaborator.firstname} ${this.collaborator.lastname}`
    }  
  },
  mounted() {    
    this.getCustomers()    
    this.getUserRolesList()  
    this.isNew = this.$route.params.id == 0  
    this.isMe = this.$route.params.me == true    
    if (!this.isNew) this.get()       
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;  
}

.v-btn--floating {
  position: relative;
}
</style>