<template>  
    <v-container fluid>
     
      <v-form>
            <v-row>
              <v-col md="12">
                <v-select
                  v-model="leaseType"
                  :items="listItems.leases"
                  item-text="name"
                  item-value="id" 
                  label="Type de contrat"
                  required                                 
                ></v-select>
              </v-col>
                                           
              <v-col md="6">    
                <div class="d-flex flex-no-wrap">
                  <div v-if="imgProvider">
                    <v-avatar tile size="52">
                      <v-img 
                        :src="imgProvider" 
                        max-width="80"
                        contain
                      />
                    </v-avatar>
                  </div>
                
                  <div style="width: 100%">     
                    <v-select
                      class="pl-2"   
                      v-model="data.providerId"
                      :items="providersList"
                      item-text="name"
                      item-value="id"
                      :rules="[rc => !!rc || providerTypeName + ' obligatoire']"     
                      :label="providerTypeName"
                      required 
                      @change="setProviderImage()"                 
                    ></v-select>
                  </div>
                </div>
              </v-col>

              <v-col md="6">
                <v-text-field
                  v-model="data.reference"                                    
                  label="Référence"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-tabs v-model="tab">
              <v-tab href="#tab-1">OPTIONS</v-tab>
              <v-tab href="#tab-2">RECOMMANDATIONS</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">  
                <v-row>                          
                  <v-col md="4">
                    <v-text-field
                      type="date"
                      v-model="data.dateStart"                                    
                      label="Date de début"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col md="4">
                    <v-text-field
                      type="number"
                      v-model="data.duration"                                    
                      label="Durée"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col md="4">
                    <v-text-field
                      type="date"
                      v-model="data.dateEffective"                                    
                      label="Date d'effet"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>           

                <v-row v-show="leaseType < $constants.leases.TYPE_INSURANCE">                         
                  <v-col md="6">
                      <v-text-field
                        type="number"
                        v-model="data.distance"                                    
                        label="Kilométrage"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col md="6">
                      <v-text-field
                        type="number"
                        v-model="data.distance_initial"                                    
                        label="Kilométrage initial"
                        required
                      ></v-text-field>
                    </v-col>
                </v-row>

                <v-row v-show="leaseType < $constants.leases.TYPE_INSURANCE">
                  <v-col md="4">
                    <v-checkbox
                      v-model="data.hasRent"
                      label="Loyer"
                      :disabled="leaseType == 0"
                    ></v-checkbox>
                    <v-text-field
                      type="number"
                      v-show="data.hasRent"
                      v-model="data.rent"                                    
                      label="Montant"
                    ></v-text-field>
                  </v-col>

                  <v-col md="4">
                    <v-checkbox                  
                      v-model="data.hasMaintenanceRent"
                      label="Entretien"                  
                    ></v-checkbox>
                    <v-text-field
                      type="number"
                      v-show="data.hasMaintenanceRent"
                      v-model="data.maintenanceRent"                                    
                      label="Montant"                    
                    ></v-text-field>
                  </v-col>

                  <v-col md="4">
                    <v-checkbox
                      v-model="data.hasTiresRent"
                      label="Pneumatiques"
                    ></v-checkbox>
                    <v-text-field
                      type="number"
                      v-show="data.hasTiresRent"
                      v-model="data.tiresRent"                                    
                      label="Montant"
                    ></v-text-field>
                  </v-col>

                  <v-col md="4">
                    <v-checkbox                  
                      v-model="data.hasAssistanceRent"
                      label="Assistance"
                    ></v-checkbox>
                    <v-text-field
                      v-show="data.hasAssistanceRent"
                      type="number"                  
                      v-model="data.assistanceRent"                                    
                      label="Montant"
                    ></v-text-field>
                  </v-col>

                  <v-col md="4">
                    <v-checkbox                  
                      v-model="data.hasInsuranceRent"
                      label="Assurance"
                    ></v-checkbox>
                    <v-text-field
                        v-show="data.hasInsuranceRent"
                        type="number"                    
                        v-model="data.insuranceRent"                                    
                        label="Montant"
                      ></v-text-field>
                  </v-col>              

                  <v-col md="4">
                    <v-checkbox
                      v-model="data.hasOtherRent"
                      label="Autres"
                    ></v-checkbox>
                    <v-text-field
                        type="number"
                        v-show="data.hasOtherRent"
                        v-model="data.otherRent"                                    
                        label="Montant"
                      ></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-show="leaseType == $constants.leases.TYPE_ASSISTANCE">
                  <v-col md="6">
                    <v-text-field
                      type="number"                  
                      v-model="data.assistanceRent"                                    
                      label="Mensualité"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-show="leaseType == $constants.leases.TYPE_INSURANCE">
                  <v-col md="6">
                    <v-text-field
                        type="number"                    
                        v-model="data.insuranceRent"                                    
                        label="Mensualité"
                      ></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-show="leaseType < $constants.leases.TYPE_INSURANCE">
                  <v-col md="6">
                      <v-checkbox
                        v-model="data.isRenewal"
                        label="Procédure de renouvellement engagée auprès du collaborateur"
                      ></v-checkbox>
                                  
                      <v-select
                        v-show="data.isRenewal"
                        class="pl-2"   
                        v-model="data.renewalStatus"
                        :items="listItems.lease_renewal_status"
                        item-text="name"
                        item-value="id"                        
                        label="Statut du renouvellement"           
                      ></v-select>
                    </v-col>                    
                </v-row>                                
              </v-tab-item>

              <v-tab-item value="tab-2">              
                <a-services-recommendations-panel
                  height="285px"
                  :maintenance="data.hasMaintenanceRent"
                  :tires="data.hasTiresRent"
                  misc
                  :recommendations="data.recommendations"      
                  @change="data.recommendations=$event"     
                />
              </v-tab-item>
            </v-tabs-items>  

            <v-row>
              <v-col md="12" class="text-right">
                <v-btn                  
                  color="error"
                  class="mr-4"
                  @click="onClickBtnCancel"
                >
                  Annuler
                </v-btn>   
               
                <v-btn 
                  v-if="this.data.id && this.data.dateEnd == ''"                 
                  color="primary"
                  class="mr-4"
                  @click="onClickBtnClose"
                >
                  Cloturer
                </v-btn>  
                <v-btn     
                  v-if="this.data.id && this.data.dateEnd !== ''"                
                  color="primary"
                  class="mr-4"
                  @click="onClickBtnOpen"
                >
                  Reouvrir
                </v-btn>  

                 <v-btn 
                  v-if="this.data.id && this.data.dateEnd == ''"                 
                  color="success"
                  class="mr-4"
                @click="onClickBtnAddendum"
                >
                  Creér un avenant
                </v-btn>  

                <v-btn                  
                  color="success"
                  class="mr-4"
                  @click="onClickBtnValidate"
                >
                  Valider
                </v-btn>     
              </v-col> 
            </v-row> 
      </v-form>
    </v-container>  
</template>

<script>
import AServicesRecommendationsPanel from "@/components/AServicesRecommendationsPanel"
import { mapGetters } from 'vuex'

export default {
  name: 'ALeaseForm',
  components: { AServicesRecommendationsPanel },
  props: {
    lease: Object,
    vehicle: {
      type: Number,
      default: 0
    },
    initialize: Boolean
  },
  data: () => ({
    tab: "tab-1",
    rentalCompanies: [],
    insuranceCompanies: [],
    assistanceCompanies: [],
    makers: [],
    providersList: [],
    providerTypeName: "Loueur",
    data: {},
    providerId: 0,
    imgProvider: '',
    leaseTypes: [],
    leaseRenewalStatus: [],
    leaseType: 0    
  }),
  methods: {
    onClickBtnValidate: function (emitEvent = true) {
      this.data.rent = parseFloat(this.data.rent)
      this.data.maintenanceRent = parseFloat(this.data.maintenanceRent)
      this.data.tiresRent = parseFloat(this.data.tiresRent)
      this.data.assistanceRent = parseFloat(this.data.assistanceRent)
      this.data.insuranceRent = parseFloat(this.data.insuranceRent)
      this.data.otherRent = parseFloat(this.data.otherRent)

      if (this.leaseType === this.$constants.leases.TYPE_INSURANCE) this.data.hasInsuranceRent = true
      if (this.leaseType === this.$constants.leases.TYPE_ASSISTANCE) this.data.hasAssistanceRent = true
       
      let id = this.data.id === undefined ? 0 : this.data.id
      let url = `leases/${id}`
      let params = {
        id: this.data.id,
        type: this.leaseType,
        provider_id: this.data.providerId,
        vehicle_id: this.vehicle,
        driver_id: this.data.driverId,
        distance: this.data.distance,
        distance_initial: this.data.distance_initial,
        duration: this.data.duration,
        date_start: this.data.dateStart,
        date_end: this.data.dateEnd,
        date_effect: this.data.dateEffective,
        reference: this.data.reference,
        rent: this.data.hasRent ? this.data.rent : false,
        maintenance_rent: this.data.hasMaintenanceRent ? this.data.maintenanceRent : false,
        tires_rent: this.data.hasTiresRent ? this.data.tiresRent : false,
        assistance_rent: this.data.hasAssistanceRent ? this.data.assistanceRent : false,
        insurance_rent: this.data.hasInsuranceRent ? this.data.insuranceRent : false,
        other_rent: this.data.hasOtherRent ? this.data.otherRent : false,
        is_renewal: this.data.isRenewal ? this.data.isRenewal : false,
        renewal_status: this.data.renewalStatus,
        recommendations: this.data.recommendations,
        is_addendum: this.data.isAddendum ? true : false  
      }
      if (this.data.id == 0) {
        this.$http
          .post(url, params)            
          .then(response => {                                    
              if (response.status == 200) {                       
                if (emitEvent) this.$emit('validate', this.data)              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });  
      }
      else {
        this.$http
          .put(url, params)            
          .then(response => {                        
              if (response.status == 200) {                                  
                this.$emit('validate', this.data)   
                this.data.isAddendum = false    
                this.data.id = response.data.id
                this.data.reference = response.data.reference          
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });  
      }          
      
    },
    onClickBtnCancel: function () {
      this.$emit('cancel')
    },
    onClickBtnAddendum: function () {      
      this.data.isAddendum = true
      this.onClickBtnValidate()      
    },
    onClickBtnClose: function () {
      this.data.dateEnd = this.$moment(Date.now()).format("YYYY-MM-DD")
      this.onClickBtnValidate()      
    },
    onClickBtnOpen: function() {
      this.data.dateEnd = ""
      this.onClickBtnValidate(false)      
    },
    getRentalCompanies: function() {
      let url = `providers/types/3`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.rentalCompanies = response.data
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })
                 
    },
    getMakers: function() {
      let url = `providers/types/4`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.makers = response.data
                this.setProvidersList()
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })
                 
    },
    getInsuranceCompanies: function() {
      let url = `providers/types/5`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.insuranceCompanies = response.data
                this.setProvidersList()
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })
                 
    },
    getAssistanceCompanies: function() {
      let url = `providers/types/6`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.assistanceCompanies = response.data
                this.setProvidersList()
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })
                 
    },
    getLeaseTypesList: function() {
        this.$store.dispatch('getListItems', 'leases').then(() =>  {         
            this.setProvidersList() 
          });
    },
    getLeaseRenewalStatusList: function() {     
        this.$store.dispatch('getListItems', 'lease_renewal_status');
    },
    setProvidersList: function() {
      switch (this.leaseType) {
        case this.$constants.leases.TYPE_PURCHASE:
          this.providersList = this.makers
          this.providerTypeName = "Constructeur"
          break
        
        case this.$constants.leases.TYPE_INSURANCE:
          this.providersList = this.insuranceCompanies
          this.providerTypeName = "Companie"
          break

        case this.$constants.leases.TYPE_ASSISTANCE:
          this.providersList = this.assistanceCompanies
          this.providerTypeName = "Companie"
          break

        default:
          this.providersList = this.rentalCompanies
          this.providerTypeName = "Loueur"
      }
      this.setProviderImage()
    },
    setProviderImage: function() {  
      this.imgProvider = ''
      if (this.data !== undefined) {  
        let provider = this.providersList.find(el => el.id == this.data.providerId)
        if (provider !== undefined) this.imgProvider = provider.image !== '' ? `${this.$constants.application.resourcesURL}/images/${provider.image}` : ''  
      }
    }
  },
  watch: {
    initialize: function() {
      if (this.initialize) {
        this.data = Object.assign({}, this.lease)
        this.leaseType = this.lease.type
        this.setProvidersList()
      }
    },
    leaseType: function() {
      this.setProvidersList()
    }
  },
  computed: {
    ...mapGetters([
      'listItems'
    ])
  },
  created() {
    this.getLeaseTypesList()
    this.getLeaseRenewalStatusList()
    this.getRentalCompanies()
    this.getInsuranceCompanies()
    this.getAssistanceCompanies()
    this.getMakers()
    
    this.data = Object.assign({}, this.lease)  
    this.leaseType = this.lease.type 
    this.setProvidersList()
  }
}
</script>

<style scoped>
img {
  max-width: 150px;
}

.login {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}
</style>
