export const Colors = {
     status: { 
        new: "#e014fb",
        received: "#147efb",
        recalled: "#fd9426",
        processing: "#5fc9f8",
        waiting: "#fd9426",
        waiting_infos: "#fd9426",
        waiting_provider_info: "#fd9426",
        complete: "#53d769",
        canceled: "#8e8e93",
        archived: "#8e8e93",
        refused: "#fc3158",
        todo: "#e014fb",
        scheduled: "#147efb"
    }
}