<template>
  
    <v-container fluid>
      <a-page-header :title="title" 
        icon="flaticon-support"
        :image="providerImage"
      />    

      <v-divider/>

      <v-row class="pt-5">        
        <v-col sm="12">          
              <v-row>        
                <v-col md="12" class="subtitle-1 font-weight-medium">INFORMATIONS</v-col>
                <v-col sm="12">
                  <v-form
                    ref="form"
                    v-model="isValid"
                    lazy-validation
                  >
                    <v-row>                                              
                      <v-col md="6">
                        <v-text-field
                          v-model="provider.name"                  
                          :rules="[v => !!v || 'Nom obligatoire']"     
                          label="Nom"
                          required
                        ></v-text-field>
                      </v-col>               

                      <v-col md="2">
                      <v-checkbox
                            v-model="provider.import_enabled"
                            label="Import de données"
                            color='blue'
                        ></v-checkbox>
                      </v-col>

                      <v-col md="2">
                        <v-select                      
                          v-model="provider.customer_id"
                          :items="customers"
                          item-text="name"
                          item-value="id"                       
                          label="Spécifique au client"                                 
                        ></v-select>
                      </v-col>  

                      <v-col md="2">
                        <v-select
                          v-if="!isNew"
                          v-model="provider.merge"                  
                          label="Fusionner avec"
                          :items="providers"
                          item-text="name"
                          item-value="id"
                          required
                          @change="showConfirmButton = false"
                        ></v-select>
                      </v-col>                             
                    </v-row>  
                  </v-form>        
                </v-col>
              </v-row>

              <v-row>
                <v-col md="12" class="text-right">
                  <v-btn v-if="deletable"               
                    class="mr-4"    
                    color="error" 
                    @click="onClickBtnDeleteModel()"
                  >
                    SUPPRIMER
                  </v-btn>

                  <v-btn
                    v-show="!provider.merge"
                    :disabled="!isValid"                    
                    color="success"
                    class="mr-4"                
                    @click="onClickBtnValidate()"
                  >
                    VALIDER
                  </v-btn>      

                  <v-btn 
                    v-show="provider.merge && !showConfirmButton" 
                    :disabled="!isValid"                    
                    color="success"
                    class="mr-4"                
                    @click="showConfirmButton = true"
                  >
                    VALIDER
                  </v-btn>

                  <v-btn 
                    v-show="provider.merge && showConfirmButton"                     
                    :disabled="!isValid"                    
                    color="default"
                    class="mr-4"                
                    @click="onClickCancel"
                  >
                    ANNULER
                  </v-btn>

                  <v-btn 
                    v-show="provider.merge && showConfirmButton"                     
                    :disabled="!isValid"                    
                    color="warning"
                    class="mr-4"                
                    @click="onClickBtnValidate()"
                  >
                    CONFIRMER
                  </v-btn>
                </v-col>     
              </v-row>    

                <!-- COMPLEMENT D'AFFICHAGE POUR LES LOUEURS -->   
              <template v-if="provider.type == $constants.providers.TYPE_RENTER">                  
                <v-row>
                  <v-col md="12" class="subtitle-1 font-weight-medium">ASSISTANCE</v-col> 
                </v-row>
                <v-row>
                  <v-col md="3">
                    <v-text-field
                      v-model="provider.support_phone_number"                                            
                      label="N° d'assistance"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col md="3"></v-col>

                  <v-col md="6">
                    <span class="subtitle-2">Pris en charge par l'assistance</span>
                    <v-checkbox        
                      v-model="provider.features.crash"                                
                      label="Accident"
                      color='success'
                    ></v-checkbox>

                    <v-checkbox                        
                    v-model="provider.features.breakdown"                
                      label="Panne"
                      color='success'
                    ></v-checkbox>

                    <v-checkbox            
                      v-model="provider.features.glass_breakage"                            
                      label="Bris de glace"
                      color='success'
                    ></v-checkbox>

                    <v-checkbox           
                      v-model="provider.features.tires"                             
                      label="Crevaison"
                      color='success'
                    ></v-checkbox>

                    <v-checkbox            
                      v-model="provider.features.theft"                            
                      label="Vol"
                      color='success'
                    ></v-checkbox>
                  </v-col>
                </v-row>       
              <v-divider/>
                <v-row>
                  <v-col md="12" class="subtitle-1 font-weight-medium">RECOMMANDATIONS</v-col>
                  <v-col sm="12">
                    <a-services-recommendations-panel 
                      maintenance
                      tires
                      misc
                      :recommendations="provider.recommendations"      
                      @change="provider.recommendations=$event"        
                    />
                  </v-col>
                
                  <v-col md="12" class="text-right">
                    <v-btn
                      :disabled="!isValid"
                      color="success"
                      class="mr-4"
                      @click="onClickBtnValidate"
                    >
                      Valider
                    </v-btn>     
                  </v-col> 
                </v-row>   
              </template>


              <!-- COMPLEMENT D'AFFICHAGE POUR LES CONSTRUCTEURS -->
              <v-row v-if="provider.type == $constants.providers.TYPE_MAKER">
                <v-col md="12" class="subtitle-1 font-weight-medium">CATALOGUE</v-col>
                <v-col sm="12">
                  <v-tabs v-model="tab">               
                    <v-tab v-if="!this.isNew" href="#tab-1">MODELES</v-tab>          
                    <v-tab v-if="!this.isNew" href="#tab-2">MOTORISATIONS</v-tab>
                    <v-tab v-if="!this.isNew" href="#tab-3">FINITIONS</v-tab> 
                    <v-tab v-if="!this.isNew" href="#tab-4">VEHICULES</v-tab>       

                    <v-tab-item value="tab-1">
                      <v-card flat>
                        <v-card-text>
                          <a-vehicle-model-list     
                            :maker-id="provider.id"              
                            single-select         
                            show-image
                            add-btn    
                            @clickRow="onClickRowModel"  
                            @clickBtnAdd="onClickAddModelBtn"       
                            ref="aVehicleModelList"
                          />
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                      <v-card flat>
                        <v-card-text>
                          <a-modification-list     
                            :maker-id="provider.id"              
                            single-select    
                            show-image
                            add-btn         
                            @clickRow="onClickRowModification"  
                            @clickBtnAdd="openModificationDialog()"   
                            ref="aModificationList"                                                   
                          />
                        </v-card-text>
                      </v-card>
                    </v-tab-item>

                    <v-tab-item value="tab-3">
                      <v-card flat>
                        <v-card-text>
                          <a-line-list     
                            :maker-id="provider.id"              
                            single-select     
                            show-image        
                            add-btn
                            @clickRow="onClickRowLine"      
                            @clickBtnAdd="openLineDialog()"  
                            @loaded="lines = $event"   
                            ref="aLineList"                              
                          />
                        </v-card-text>
                      </v-card>
                    </v-tab-item>  
                    
                    <v-tab-item value="tab-4">
                      <v-card flat>
                        <v-card-text>
                          <a-vehicle-list   
                            download-btn     
                            small
                            :makers="provider.id"                                                                                                                                             
                            @clickRow="onClickRowVehicle"
                            ref="aVehicleList"                            
                          />    
                        </v-card-text>
                      </v-card>
                    </v-tab-item>  
                  </v-tabs>  
                </v-col>
              </v-row>                         
        </v-col>
      </v-row>

      <!-- MODAL POUR CONSTRUCTEUR -->
      <template v-if="provider.type == $constants.providers.TYPE_MAKER">
        <v-dialog      
          v-model="dialogModification"
          max-width="40%"        
        >        
          <v-card>
            <v-card-title>MOTORISATION</v-card-title>
            <v-card-text>
              <v-row>
              <v-col md="6">
                  <v-text-field
                    v-model="modification.name"                  
                    :rules="[v => !!v || 'Nom obligatoire']"     
                    label="Nom"
                    required
                  ></v-text-field>
                </v-col>                

                <v-col md="6">
                  <v-select
                    v-model="modification.family"                  
                    label="Catégorie"
                    :items="$constants.vehicleModifications.ENERGIES"
                    item-text="label"
                    item-value="id"
                    required
                  ></v-select>
                </v-col>     
              </v-row>
              <v-row>
                <v-col md-12>
                  <v-select
                    v-show="showMergeField"
                    v-model="mergeModificationWith"
                    label="Fusionner avec"
                    :items="getMergeModificationList(modification.id)"
                    item-text="name"
                    item-value="id"
                    @change="modification.merge = mergeModificationWith"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="danger" @click="dialogModification = false">ANNULER</v-btn>
              <v-btn v-show="modification.count === 0" text color="error" @click="onClickBtnDeleteCatalogItem(modification)">SUPPRIMER</v-btn>
              <v-btn v-show="mergeModificationWith === 0" text color="primary" @click="onClickBtnValidateCatalogItem(modification)">VALIDER</v-btn>
              <v-btn v-show="mergeModificationWith !== 0 && !showConfirmButton" text color="primary" @click="showConfirmButton = true">VALIDER</v-btn>
              <v-btn v-show="showConfirmButton" text color="success" @click="onClickBtnValidateCatalogItem(modification)">CONFIRMER</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog   
          v-model="dialogLine"
          max-width="40%"        
        >        
          <v-card>
            <v-card-title>FINITIONS</v-card-title>
            <v-card-text>
              <v-row>
              <v-col md="12">
                  <v-text-field
                    v-model="line.name"                  
                    :rules="[v => !!v || 'Nom obligatoire']"     
                    label="Nom"
                    required
                  ></v-text-field>
                  <v-select
                    v-show="showMergeField"
                    v-model="mergeLineWith"
                    label="Fusionner avec"
                    :items="getMergeLineList(line.id)"
                    item-text="name"
                    item-value="id"
                    @change="line.merge = mergeLineWith"
                  ></v-select>
                </v-col>                              
              </v-row>              
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="danger" @click="dialogLine = false">ANNULER</v-btn>
              <v-btn v-show="line.count === 0" text color="error" @click="onClickBtnDeleteCatalogItem(line)">SUPPRIMER</v-btn>
              <v-btn v-show="mergeLineWith === 0" text color="primary" @click="onClickBtnValidateCatalogItem(line)">VALIDER</v-btn>
              <v-btn v-show="mergeLineWith !== 0 && !showConfirmButton" text color="primary" @click="showConfirmButton = true">VALIDER</v-btn>
              <v-btn v-show="showConfirmButton" text color="success" @click="onClickBtnValidateCatalogItem(line)">CONFIRMER</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>    
      </template>      

    </v-container>
    
  
</template>

<script>
import { mapGetters } from 'vuex'

import APageHeader from "@/components/APageHeader"
import AVehicleModelList from "@/components/AVehicleModelList"
import AModificationList from "@/components/AModificationList"
import ALineList from "@/components/ALineList"
import AServicesRecommendationsPanel from "@/components/AServicesRecommendationsPanel"
import AVehicleList from '@/components/AVehicleList.vue'

export default {
  name: 'Provider',  
  components: { APageHeader, AVehicleModelList, AModificationList, ALineList, AServicesRecommendationsPanel, AVehicleList },
  data: () => ({   
    isNew: true,
    isValid: true,  
    tab: '',    
    provider: {recommendations: {}},
    providerImage: "",
    customers: [{id:0, name: ''}],
    providers: [{id:0, name: ''}],
    models: [],
    modifications: [],
    modification: {},
    lines: [],
    line: {},
    mergeLineWith: 0,
    mergeModificationWith: 0,
    showConfirmButton: false,
    showMergeField: false,
    dialogModel: false,
    dialogModification: false,
    dialogLine: false,    
    deletable: false
  }),
  methods: {
    openModificationDialog: function(modification = {id: 0, provider_id: this.provider.id, class: 'vehicle_modification'}, showMergeField = false) {
      this.modification = modification;
      this.mergeModificationWith = 0;
      this.showConfirmButton = false;
      this.showMergeField = showMergeField;
      this.dialogModification = true;
    },
    openLineDialog: function(line = {id: 0, provider_id: this.provider.id, class: 'vehicle_line'}, showMergeField = false) {
      this.line = line;
      this.mergeLineWith = 0;
      this.showConfirmButton = false;
      this.showMergeField = showMergeField;
      this.dialogLine = true;
    },
    onClickBtnValidate: function() {
      let url = `providers`
      if (this.provider.id == 0) {
        this.$http.post(url, this.provider)   
          .then(response => {
            if (response.status == 200) {
              this.provider = response.data
              this.isNew = false
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
      else {        
        this.$http.put(url, this.provider)   
          .then(response => {
            if (response.status == 200) {
              if (this.provider.merge !== 0) {
                this.providers = this.providers.filter(el => el.id !== this.provider.merge);
                if (this.$refs.aVehicleModelList) this.$refs.aVehicleModelList.get();
                if (this.$refs.aModificationList) this.$refs.aModificationList.get();
                if (this.$refs.aLineList) this.$refs.aLineList.get();
                if (this.$refs.aVehicleList) this.$refs.aVehicleList.get();
              }
              this.provider = response.data
              this.showConfirmButton = false;      
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
    },
    onClickCancel: function() {
      this.provider.merge = 0;
      this.showConfirmButton = false;   
    },
    onClickBtnValidateCatalogItem: function(object) {       
      let url = `catalogs`
      if (object.id === 0) {
        this.$http.post(url, object)   
            .then(response => {
              if (response.status == 200) {    
                if (object.class == 'vehicle_modification') {
                  this.$refs.aModificationList.get()
                  this.dialogModification = false                
                }
                else if (object.class == 'vehicle_line') {                  
                  this.$refs.aLineList.get();
                  this.dialogLine = false                
                }
              }  
            })
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
      }
      else {
        this.$http.put(url, object)   
            .then(response => {
              if (response.status == 200) {    
                if (object.class == 'vehicle_modification') {
                  this.$refs.aModificationList.get()
                  this.dialogModification = false                
                }
                else if (object.class == 'vehicle_line') {
                  //this.$refs.aLineList.updateRow(object)
                  this.$refs.aLineList.get();
                  this.dialogLine = false                                  
                }
              }  
            })
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
          }
    },
    onClickBtnDeleteCatalogItem: function(object) {       
      let url = `catalogs?id=${object.id}`
      this.$http.delete(url)   
          .then(response => {
            if (response.status == 200) {    
              if (object.class == 'vehicle_modification') {
                this.$refs.aModificationList.get()
                this.dialogModification = false                
              }
              else if (object.class == 'vehicle_line') {                
                this.$refs.aLineList.get();
                this.dialogLine = false                
              }
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
    },
    onClickRowModel: function(row) {
      this.$router.push({ 
        name: 'Model', 
        params: { 
          id: row.id,
          maker: this.provider.id,
          deletable: row.count === 0
        }
      })
    },
    onClickAddModelBtn: function() {
      this.$router.push({ 
        name: 'Model', 
        params: { 
          id: 0,
          provider: this.provider,
          image: this.provider.image
        }
      })
    },
    onClickRowModification: function(row) {      
      this.openModificationDialog({...row}, true);
    },
    onClickRowLine: function(row) {            
      this.openLineDialog({...row}, true);
    },
    onClickRowVehicle: function(row) {      
      this.$router.push({ 
        name: 'Vehicle', 
        params: { 
          id: row.id
        }
      })
    },
    // Méthodes d'accès aux données
    // ----------------------------
    // Données vehicule
    get: function() {    
        if (this.$route.params.id !== 0) { 
          this.isLoading = true
          let url = `providers/${this.$route.params.id}`
          this.$http
              .get(url)            
              .then(response => {                        
                  if (response.status == 200) {                                   
                    this.provider = response.data                                                                         
                    this.providerImage = this.provider.image != "" ? `${this.$constants.application.resourcesURL}/images/${this.provider.image}` : "";
                    this.getProviders();         
                    
                    if (this.provider.type == this.$constants.providers.TYPE_RENTER) {
                      if (this.provider.features === null) {
                        this.provider.features = {
                          crash: false,
                          breakdown: false,
                          glass_breakage: false,
                          tires: false,
                          theft: false
                        }
                      }
                    }
                  }                                          
                  this.isLoading = false 
              })
              .catch(error => {                
                  this.isLoading = false
                  if (error.response.status === 401) this.$root.$emit("logout"); 
                  else this.$root.$emit("serverError", error);     
              });                                  
        }        
    },
    getProviders: function() {
      this.isLoading = true
      let url = `providers/types/${this.provider.type}`
      this.$http
          .get(url)            
          .then(response => {                        
              this.providers = [...this.providers, ...response.data.filter(el => el.id !== this.provider.id)].sort((a,b) => a.name < b.name)          
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
      },
    getCustomer: function() {
      let url = `customers`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {                           
                this.customers = [...this.customers, ...response.data].sort((a,b) => a.name < b.name)                    
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })                     
    },
    getMergeLineList: function(id) {
      let list = [{id:0, name: ''}];
      if (this.$refs.aLineList) list = [...list, ...this.$refs.aLineList.listItems.filter(el => el.id !== id)];
      return list;
    },
    getMergeModificationList: function(id) {
      let list = [{id:0, name: ''}];
      if (this.$refs.aModificationList) list = [...list, ...this.$refs.aModificationList.listItems.filter(el => el.id !== id)];
      return list;
    }
  },
  computed: {
    ...mapGetters([
      'userEnvironment'
    ]),
    title: function() {
      if (this.provider.id == 0) {        
        switch (this.provider.type) {
          case this.$constants.providers.TYPE_FUEL_COMPANY: return 'Nouveau pétrolier'
          case this.$constants.providers.TYPE_GARAGE: return 'Nouveau garage'
          case this.$constants.providers.TYPE_RENTER: return 'Nouveau loueur'
          case this.$constants.providers.TYPE_MAKER: return 'Nouveau constructeur'
          case this.$constants.providers.TYPE_INSURANCE: return "Nouvelle compagnie d'assistance"
          case this.$constants.providers.TYPE_ASSISTANCE: return "Nouvelle compagnie d'assurance"
          case this.$constants.providers.TYPE_TIRES_MAKER: return 'Nouveau fabricant'
          default: return ''
        }
      }
      else return this.provider.name     
    }
  },
  mounted() {   
    this.provider.id = parseInt(this.$route.params.id)
    this.getCustomer()    
    this.isNew = this.provider.id == 0    
    if (!this.isNew) {
      this.get()      
    }
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;  
}

.v-btn--floating {
  position: relative;
}
</style>