import constants from "./constants"

export default {
    Resources: {
        getUnsplashImage(w, h) {
            const themes = ['cars', 'architecture','sport','nature','travel', 'work']
            const idx = Math.floor(Math.random() * Math.floor(themes.length))
            return `https://source.unsplash.com/${w}x${h}/?${themes[idx]}&monochrome=90000000&client_id=9b06b47954536190011a77c9d3a4a591339e48ec769f00f5faa2f1256d286b61`
        },
        exportData(arrayHeader, arrayData, delimiter, fileName) {
            let header = arrayHeader.join(delimiter) + '\n';
            let csv = header;
            arrayData.forEach( array => {
                csv += array.join(delimiter)+"\n";
            });
 
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
 
            let hiddenElement = document.createElement('a');
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = fileName + '.csv';
            hiddenElement.click();
        }
    },
    Date: {
        toUnixTimestamp(date) {
            let d = date.getTime()
            let now = new Date()
            let offset = now.getTimezoneOffset() * 60 * 1000 // now in milliseconds
            return d + offset
        }
    },
    Vehicles: {
        getStatusColor(status) {
            let color = status === undefined ? '' : 'transparent'
            if (status == 0) color = 'blue'
            else if (status == 2) color = 'red'            
            return color
        },
        getStatusLabel(status) {
            let label = ''
            if (status == 0) label = 'En commande'
            else if (status == 2) label = 'Hors parc'            
            return label
        },
        getEnergyLabel: function(id) {
            let label = ''
            let energy = constants.vehicleModifications.ENERGIES.find(el => el.id === id)
            if (energy !== undefined) label = energy.label

            return label
        }
    },
    Leases: {
        getClosureStatusColor(rest) {
            let color = rest === undefined ? '' : 'green'
            if (rest <= 0) color = 'black'
            else if (rest <= 3) color = 'red'
            else if (rest <= 6) color = 'orange'
            else if (rest <= 12) color = 'yellow'
            
            return color
        },
        getStatusColor(status) {
            let color = status === undefined ? '' : 'green'
            if (status == 1) color = 'orange'
            else if (status == 2) color = 'red'
            else if (status == 3) color = 'blue'
            return color
        },
        getStatusLabel(status) {
            let label = 'En cours'
            if (status == 1) label = 'A renouveller'
            else if (status == 2) label = 'Echu'
            else if (status == 3) label = 'Renouvellement en cours'                
            return label
        }
    },
    getStatusColor(status) {
        let color = ''
        switch (status) {
            case 0:                
                color = constants.colors.STATUS_NEW                    
                break
            case 10:
                color = constants.colors.STATUS_RECEIVED                    
                break
            case 15:
                color = constants.colors.STATUS_RECALLED     
                break
            case 20:
                color = constants.colors.STATUS_PROCESSING                    
                break
            case 30:
                color = constants.colors.STATUS_WAITING                   
                break
            case 40:
                color = constants.colors.STATUS_WAITING_INFOS                    
                break
            case 50:
                color = constants.colors.STATUS_WAITING_PROVIDER_INFOS                    
                break
            case 60:
                color = constants.colors.STATUS_COMPLETED                    
                break
            case 70:
                color = constants.colors.STATUS_CANCELED                    
                break
            case 80:
                color = constants.colors.STATUS_REFUSED                    
                break
            case 90:
                color = constants.colors.STATUS_ARCHIVED              
                break
            default:
                color = "#000000"
                break

        }   
        
        return color
    },
    getStatusLabel(status) {
        let label = ''
        switch (status) {
            case 0:                
                label = "Nouveau"                    
                break
            case 10:
                label = "Reçu"                   
                break
            case 15:
                label = "Relancé"                   
                break
            case 20:
                label = "En cours"                    
                break
            case 30:
                label = "En attente"                   
                break
            case 40:
                label = "En attente d'information"                    
                break
            case 50:
                label = "En attente d'information fournisseur"                   
                break
            case 60:
                label = "Terminé"                    
                break
            case 70:
                label = "Annulé"                  
                break
            case 80:
                label = "Refusé"                
                break
            case 90:
                label = "Archivé"                
                break
            default:
                label = "Aucun"
                break

        }   
        
        return label
    }
}