var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.downloadBtn)?_c('v-btn',{staticClass:"mt-5 mx-2",attrs:{"icon":"","elevation":"2","title":"Exporter la sélection"},on:{"click":function($event){return _vm.onClickBtnExport()}}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersDrivers,"items":_vm.driversItems,"search":_vm.search,"single-select":_vm.singleSelect,"show-select":"","item-key":"id","loading":_vm.isLoading,"loading-text":"Chargement en cours...","locale":"fr-FR","disable-pagination":"","hide-default-footer":""},on:{"item-selected":_vm.onItemSelected,"click:row":_vm.onClickRow,"toggle-select-all":_vm.onToggleSelectAll},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{staticClass:"subtitle-2",attrs:{"colspan":"5"}},[_c('v-icon',{staticClass:"mr-3",on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" "+_vm._s(group)+" ")],1)]}},{key:"item.account",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":"","size":"36"}},[_c('v-img',{attrs:{"src":item.image}})],1),_vm._v(" "+_vm._s(item.account)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.isSU)?_c('v-icon',{attrs:{"color":"yellow","title":"Super utilisateur"}},[_vm._v("mdi-shield-account")]):_vm._e(),(item.isLocked)?_c('v-icon',{attrs:{"color":"red","title":"Utilisateur verrouillé"}},[_vm._v(_vm._s(item.role == 2 ? 'mdi-account' : (item.role == 1 ? 'mdi-account-tie' : 'mdi-account-tie-hat')))]):_c('v-icon',{attrs:{"color":"green","title":"Utilisateur autorisé"}},[_vm._v(_vm._s(item.role == 2 ? 'mdi-account' : (item.role == 1 ? 'mdi-account-tie' : 'mdi-account-tie-hat')))]),(item.usingApp)?_c('v-icon',{attrs:{"color":"green","title":"Application AskMe autorisée"}},[_vm._v("mdi-cellphone-check")]):_c('v-icon',{attrs:{"color":"red","title":"Application AskMe non autorisée"}},[_vm._v("mdi-cellphone-remove")]),(item.managedCustomersCount != 0)?_c('v-chip',{staticClass:"ml-2 my-1",attrs:{"small":"","color":"blue","text-color":"white"}},[_vm._v(" "+_vm._s(item.managedCustomersCount)+" ")]):_vm._e(),(item.status != '')?_c('v-chip',{staticClass:"ml-2 my-1",attrs:{"small":"","color":item.statusColor,"text-color":"white"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }