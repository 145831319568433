import Vue from 'vue'
import VueRouter from 'vue-router'

// Login
import Login from '@/views/Login.vue'

// Navigation drawer
import Dashboard from '@/views/Dashboard.vue'
import Requests from '@/views/Requests.vue'
import Dossiers from '@/views/Dossiers.vue'
import Customers from '@/views/Customers.vue'
import Profiles from '@/views/Profiles.vue'
import Vehicles from '@/views/Vehicles.vue'

// Navigation details
import Request from '@/views/Request.vue'
import Dossier from '@/views/Dossier.vue'
import Vehicle from '@/views/Vehicle.vue'
import Profile from '@/views/Profile.vue'
import Customer from '@/views/Customer.vue'
import Imports from '@/views/Imports.vue'
import Settings from '@/views/Settings.vue'
import Provider from '@/views/Provider.vue'
import Model from '@/views/Model.vue'
import Modification from '@/views/Modification.vue'
import Line from '@/views/Line.vue'

Vue.use(VueRouter)

const routes = [
  // NAVIGATION DRAWER
  // -----------------
  {
    path: '/',
    name: 'Login',
    component: Login
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  
  {
    path: '/requests',
    name: 'Requests',
    component: Requests
  },

  {
    path: '/dossiers',
    name: 'Dossiers',
    component: Dossiers
  },

  {
    path: '/customers',
    name: 'Customers',
    component: Customers
  },

  {
    path: '/profiles',
    name: 'Profiles',
    component: Profiles
  },

  {
    path: '/vehicles/:makers?/:models?/:years?/:distance_real?/:distance_contract?/:consumption?/:rolling_law?',
    name: 'Vehicles',
    component: Vehicles
  },

  // NAVIGATION DETAILS
  // ------------------
  {
    path: '/request/:id',
    name: 'Request',
    component: Request
  },

  {
    path: '/dossier/:id',
    name: 'Dossier',
    component: Dossier
  },

  {
    path: '/vehicle/:id/:customer?',
    name: 'Vehicle',
    component: Vehicle
  },

  {
    path: '/profile/:id/:customer?/:me?',
    name: 'Profile',
    component: Profile
  },

  {
    path: '/customer/:id',
    name: 'Customer',
    component: Customer
  },
  
  {
    path: '/imports',
    name: 'Imports',
    component: Imports
  },
  
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  
  {
    path: '/maker/:id',
    name: 'Provider',
    component: Provider
  },
  
  {
    path: '/model/:id/:maker?',
    name: 'Model',
    component: Model
  },
  
  {
    path: '/modification/:id/:maker?/:model?',
    name: 'Modification',
    component: Modification
  },

  {
    path: '/line/:id/:maker?/:model?/:modification?',
    name: 'Line',
    component: Line
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
