<template>
  
    <v-container fluid>
      <a-page-header :title="title" icon="flaticon-car-1" :chip-text="headerChipText" :chip-color="headerChipColor"/>    

      <v-divider/>

      <v-speed-dial
        v-model="fab"    
        top
        right        
        direction="bottom"
        open-on-hover
        transition="slide-y-reverse-transition"
      >
          <template v-slot:activator>
              <v-btn
                  v-model="fab"
                  color="blue darken-2"
                  dark
                  fab
              >
                  <v-icon v-if="fab">mdi-close</v-icon>
                  <v-icon v-else >mdi-dots-vertical</v-icon>
              </v-btn>
          </template>

          <v-btn
              v-show="showAddFabBtn"
              ref="fabAdd"
              fab
              dark
              small
              color="primary"
              @click="onClickFabAdd()"
              :title="fabBtnAddTitle"
          >
          <v-icon size="20">mdi-plus</v-icon>
          </v-btn>

          <v-btn
              v-show="!this.isNew && this.hasDriver"   
              ref="fabMessage"
              fab
              dark
              small
              color="light-green"
              @click="onClickFabMessage()"
              title="Envoyer un message au conducteur actuel"
            >
            <v-icon size="20">flaticon-chat</v-icon>
          </v-btn>
          
          <v-btn                    
                  ref="fabEvent"
                  fab
                  dark
                  small
                  color="green"
                  @click="onClickFabNewRequest()"
                   title="Créer un nouvelle demande"
              >
          <v-icon size="20">flaticon-question</v-icon>
          </v-btn>

          <v-btn
              v-show="!this.isNew && this.hasDriver"   
              ref="fabAudit"
              fab
              dark
              small
              color="purple"
              @click="onClickFabAudit()"
              title="Demander un audit vehicule"
          >
            <v-icon size="20">flaticon-front-car</v-icon>
          </v-btn>

          <v-btn
              v-show="!this.isNew && this.hasDriver"   
              ref="fabMileage"
              fab
              dark
              small
              color="indigo"
              @click="onClickFabMileage()"
              title="Demander un relevé kilométrique"
          >
            <v-icon size="20">flaticon-speed</v-icon>
          </v-btn>

          <v-btn
          fab
          dark
          small
          color="red"
          @click="onClickFabDelete()"
          :title="fabBtnDeleteTitle"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
      </v-speed-dial>   

    <v-row>
      <v-col sm="12">
        <v-tabs v-model="tab">
          <v-tab href="#tab-1">FICHE VEHICULE</v-tab>
          <v-tab v-if="!isNew" href="#tab-2" @click="onClickTabLeases">CONTRATS</v-tab>
          <v-tab v-if="!isNew" href="#tab-3" @click="onClickTabDrivers">CONDUCTEURS</v-tab>          
          <v-tab v-if="!isNew && vehicle.status == 1" href="#tab-4" @click="onClickTabFuelIntakes">CARBURANTS</v-tab>
          <v-tab v-if="!isNew && vehicle.status == 1" href="#tab-5" @click="onClickTabServices">ENTRETIENS</v-tab>
          <v-tab v-if="!isNew && vehicle.status == 1" href="#tab-6" @click="onClickTabAudits">AUDITS</v-tab>
          <v-tab v-if="!isNew && vehicle.status == 1" href="#tab-7" @click="onClickTabMileages">RELEVES KILOMETRIQUES</v-tab>
          <v-tab v-if="!isNew && vehicle.status == 1" href="#tab-8" @click="onClickTabDocuments">DOCUMENTS</v-tab>
        </v-tabs>    

        <v-tabs-items v-model="tab">
           <!-- FICHE VEHICULE
          ------------------->
          <v-tab-item value="tab-1">   
            <v-row v-if="!isNew && vehicle.status == 1">  
              <a-vehicle-charts :vehicle="vehicle" :key="updateCharts"/>
            </v-row>         
              <v-form
              ref="form"
              v-model="isValid"
              lazy-validation
              class="pa-10"
            >
            <v-row >
              <v-col md="12" class="subtitle-1 font-weight-medium">IDENTIFICATION</v-col>
              <v-col md="3">
                <v-text-field
                  v-model="vehicle.registration"
                  :counter="10"
                  :rules="registrationRules"
                  label="Immatriculation"
                  required
                ></v-text-field>
              </v-col>

              <v-col md="3">
                <v-text-field
                  v-model="vehicle.serial_number"                                 
                  label="N° de série"
                  required
                ></v-text-field>
              </v-col>              

              <v-col md="3">
                <v-text-field
                  type="date"
                  v-model="vehicle.circulation_date"                                 
                  label="Mise en circulation"                  
                ></v-text-field>
              </v-col>

              <v-col md="3"></v-col>              
              <v-col md="3">
                <v-select
                  v-model="vehicle.maker_id"
                  :items="makers"
                  item-text="name"
                  item-value="id"
                  :rules="[v => !!v || 'Constructeur obligatoire']"     
                  label="Constructeur"
                  required
                  @change="onChangeMaker()"
                ></v-select>
              </v-col>                          

              <v-col md="3">
                <v-select
                  v-model="vehicle.model_id"
                  :items="models"
                  item-text="text"
                  item-value="id"
                  :rules="[v => !!v || 'Modèle obligatoire']"     
                  label="Modèle"                  
                  required       
                  @change="getModifications()"                         
                ></v-select>
              </v-col>

              <v-col md="3">
                <v-select
                  v-model="vehicle.modification_id"
                  :items="modifications"
                  item-text="name"
                  item-value="id"                  
                  label="Motorisation"                                                      
                ></v-select>
              </v-col>     

              <v-col md="3">
                <v-select
                  v-model="vehicle.line_id"
                  :items="lines"
                  item-text="name"
                  item-value="id"                  
                  label="Finition"                                    
                ></v-select>
              </v-col> 
            </v-row>

            <v-row>
              <v-col md="12" class="subtitle-1 font-weight-medium">ECOLOGIE</v-col>
              <v-col md="3">
                <v-text-field
                  v-model="vehicle.city_consumption"
                  label="Consomation urbaine"                 
                ></v-text-field>
              </v-col>

              <v-col md="3">
                <v-text-field
                  v-model="vehicle.mixte_consumption"                                  
                  label="Consomation mixte"                  
                ></v-text-field>
              </v-col>

              <v-col md="3">
                <v-text-field
                  v-model="vehicle.highway_consumption"                                    
                  label="Consomation autoroute"                  
                ></v-text-field>
              </v-col>

              <v-col md="3">
                <v-text-field
                  v-model="vehicle.co2"                 
                  label="CO2"                  
                ></v-text-field>
              </v-col>
            </v-row>

             <v-row>
              <v-col md="12" class="subtitle-1 font-weight-medium">PNEUMATIQUES</v-col>
              <v-col md="4">    
                <span class="subtitle-2">Chaussage</span>                           
                <v-select
                  v-model="vehicle.tires_type"
                  :items="tiresTypes"
                  item-text="name"
                  item-value="id"                  
                  label="Type de pneumatique"                                    
                ></v-select>
              </v-col>

              <v-col md="4">
                <span class="subtitle-2">Train avant</span>
                <v-text-field
                  v-model="vehicle.front_tires_size"                                   
                  label="Dimensions"                  
                ></v-text-field>

                <v-select
                  v-model="vehicle.front_tires_provider_id"
                  :items="tiresMakers"
                  item-text="name"
                  item-value="id"                   
                  label="Marque"                                    
                ></v-select>
              </v-col>

              <v-col md="4">
                <span class="subtitle-2">Train arrière</span>
                <v-text-field
                  v-model="vehicle.rear_tires_size"                                    
                  label="Dimensions"                  
                ></v-text-field>

                <v-select
                  v-model="vehicle.rear_tires_provider_id"
                  :items="tiresMakers"
                  item-text="name"
                  item-value="id"                  
                  label="Marque"                                    
                ></v-select>                             
              </v-col>              
            </v-row>

            <v-row>
              <v-col md="12" class="subtitle-1 font-weight-medium">INFORMATIONS</v-col>
              <v-col md="3">
                <v-select
                  v-model="vehicle.status"
                  :items="status"
                  item-text="label"
                  item-value="id"                  
                  label="Statut"                                    
                ></v-select>
              </v-col>

              <v-col md="9">                
              </v-col>    
              
              <v-col v-if="isNew" md="3">
                <v-text-field
                  type="number"
                  v-model="distanceInitial"                                 
                  label="Kilométrage constaté"
                  :rules="[v => !!v || 'Kilométrage constaté obligatoire']"    
                  required
                ></v-text-field>
              </v-col>

              <v-col v-else md="3">
                <v-text-field
                  type="number"
                  v-model="vehicle.distance"                                 
                  :label="`Kilométrage au ${$moment(vehicle.last_mileage_timestamp * 1000).format('DD/MM/YYYY')}`"                                    
                ></v-text-field>
              </v-col>

              <v-col v-if="isNew" md="3">
                <v-text-field
                  type="date"
                  v-model="distanceInitialDate" 
                  :rules="[v => !!v || 'Date obligatoire']"                                    
                  label="A date du"
                  required
                ></v-text-field>
              </v-col>        
              
              <v-col :md="isNew ? '6' : '9'">                
              </v-col>                                                                  

              <v-col md="4">
                <v-text-field
                  type="text"
                  v-model="vehicle.order_number"                                                   
                  label="N° de commande"
                  required
                ></v-text-field>
              </v-col>  

              <v-col md="4">
                <v-text-field
                  type="date"
                  v-model="vehicle.order_date"                                             
                  label="Date de commande"
                  required
                ></v-text-field>
              </v-col> 

              <v-col md="4">
                <v-text-field
                  type="date"
                  v-model="vehicle.delivery_date"                                                    
                  label="Date de livraison"
                  required
                ></v-text-field>                                
              </v-col>                   
            </v-row>             

            <v-row>
              <v-col md="12" class="subtitle-1 font-weight-medium">AMORTISSEMENT</v-col>
              <v-col md="4">
                <v-text-field
                  type="number"
                  v-model="vehicle.price"                                    
                  label="Prix"
                  suffix="€"                  
                ></v-text-field>
              </v-col>

              <v-col md="4">
                <v-text-field
                  v-model="vehicle.discounted_price"                                    
                  label="Prix remisé"
                  suffix="€"
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col md="4">
                <v-text-field
                  v-model="vehicle.options_price"                                    
                  label="Prix des options"
                  suffix="€"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12" class="subtitle-1 font-weight-medium">FLOTTE</v-col>
                  
              <v-col md="8">
                <div class="d-flex flex-no-wrap">
                  <div>
                    <v-avatar tile size="52">
                      <v-img                         
                        :src="imgCustomerLogo" 
                        max-width="80"
                      />
                    </v-avatar>
                  </div>
                  <div class="ml-2" style="width: 100%"> 
                    <v-select                      
                      v-model="vehicle.customer_id"
                      :items="customers"
                      item-text="name"
                      item-value="id"                      
                      label="Entreprise / Employeur"                                                         
                    ></v-select>
                  </div>
                </div>
              </v-col> 

               <v-col md="4">
                  <v-text-field
                    v-model="vehicle.fleet_code"                                      
                    label="Numero/Code"                      
                  ></v-text-field>
                </v-col>    
            </v-row>            

            <v-row>
              <v-col md="12" class="text-right">
                <v-btn
                  :disabled="!isValid"
                  color="success"
                  class="mr-4"
                  @click="onClickBtnValidate"
                >
                  Valider
                </v-btn>     
              </v-col> 
            </v-row>                 
          </v-form>             
          </v-tab-item>

          <!-- CONTRATS
          ------------->
          <v-tab-item value="tab-2" v-if="!isNew"> 
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                  v-model="searchTab2"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedLeasesItems"
                :headers="headersLeases"
                :items="leasesItems"
                :search="searchTab2"
                show-select                
                item-key="id"      
                :loading="isLoading"
                loading-text="Chargement en cours..."
                locale="fr-FR"  
                @click:row="onClickLeaseRow"            
              >
                <template v-slot:group.header="{group, items, isOpen, toggle}">
                  <th colspan="5" class="subtitle-2">
                    <v-icon @click="toggle" class="mr-3"
                      >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ group }}
                  </th>
                </template>

                <template v-slot:item.rentalCompany="{ item }">
                    <v-avatar tile size="36">
                      <v-img :src="item.image"/>         
                    </v-avatar>
                    {{ item.rentalCompany }}                  
                </template>                  

                <template v-slot:item.status="{ item }">
                  <v-chip    
                    v-if="item.status > 0"                                    
                    class="ml-0 mr-1 my-1"
                    :color="item.statusColor"
                    text-color="white"        
                  >                   
                    {{ item.status }}
                  </v-chip>                 
                </template>    

                <template v-slot:item.dateClosure="{ item }">
                  <v-chip  
                    v-if="item.dateClosure != ''"                                      
                    class="ml-0 mr-1 my-1"
                    :color="item.closureColor"
                    text-color="white"        
                  >                   
                    {{ item.dateClosure }}
                  </v-chip>                 
                </template>             
              </v-data-table>
            </v-card>                       
          </v-tab-item>

          <!-- CONDUCTEURS
          --------------->
          <v-tab-item value="tab-3" v-if="!isNew"> 
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                  v-model="searchTab3"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedDriversItems"
                :headers="headersDrivers"
                :items="driversItems"
                :search="searchTab3"
                show-select                          
                item-key="id"      
                :loading="isLoading"
                loading-text="Chargement en cours..."
                locale="fr-FR"    
                @click:row="onClickDriverRow"                           
              >
                <template v-slot:group.header="{group, items, isOpen, toggle}">
                  <th colspan="5" class="subtitle-2">
                    <v-icon @click="toggle" class="mr-3"
                      >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ group }}
                  </th>
                </template>

                <template v-slot:item.account="{ item }">
                    <v-avatar tile size="36">
                      <v-img :src="item.image"/>         
                    </v-avatar>
                    {{ item.account }}                  
                </template>                  

                <template v-slot:item.status="{ item }">
                  <v-chip 
                    v-if="item.status != ''"                   
                    small
                    class="ml-0 mr-1 my-1"
                    :color="item.statusColor"
                    text-color="white"        
                  >                   
                    {{ item.status }}
                  </v-chip>                 
                </template>  

                <template v-slot:item.actions="{ item }">
                  <v-btn
                    v-if="item.isCurrent"
                    x-small
                    class="mr-2"
                    @click.stop="onClickBtnDriverGiveBack(item)"
                  >
                    Restituer
                  </v-btn>                 
                </template>
                                
              </v-data-table>
            </v-card>                      
          </v-tab-item>

          <!-- CARBURANTS
          -------------->
          <v-tab-item value="tab-4" v-if="!isNew && vehicle.status == 1">
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                  v-model="searchTab4"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedFuelIntakesItems"
                :headers="headersFuelIntakes"
                :items="fuelIntakesItems"
                :search="searchTab4"
                show-select                
                item-key="id"      
                :loading="isLoading"
                loading-text="Chargement en cours..."                
              >
                <template v-slot:group.header="{group, items, isOpen, toggle}">
                  <th colspan="5" class="subtitle-2">
                    <v-icon @click="toggle" class="mr-3"
                      >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ group }}
                  </th>
                </template>

                <template v-slot:item.distributor="{ item }">
                    <v-avatar tile size="36">
                      <v-img :src="item.image"/>         
                    </v-avatar>
                    {{ item.distributor }}                  
                </template>           
              </v-data-table>
            </v-card>                      
          </v-tab-item>

          <!-- ENTRETIENS
          -------------->
          <v-tab-item value="tab-5" v-if="!isNew && vehicle.status == 1">
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                  v-model="searchTab5"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedServicesItems"
                :headers="headersServices"
                :items="servicesItems"
                :search="searchTab5"
                show-select                
                item-key="id"      
                :loading="isLoading"
                loading-text="Chargement en cours..."    
                @click:row="onClickMaintenanceRow"           
              >
                <template v-slot:group.header="{group, items, isOpen, toggle}">
                  <th colspan="6" class="subtitle-2">
                    <v-icon @click="toggle" class="mr-3"
                      >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ group }}
                  </th>
                </template>

                <template v-slot:item.label="{ item }">
                  <v-chip
                    v-if="item.object.isOverhaulService"
                    small
                    class="ml-0 mr-1 my-1"
                    color="teal accent-3"
                    text-color="white"        
                  >
                    <v-avatar left>
                      <v-icon size="18">flaticon-oil</v-icon>
                    </v-avatar>
                    {{ item.object.label }}
                  </v-chip>

                  <v-chip
                    v-if="item.object.isTiresService"
                    small
                    class="ml-0 mr-1 my-1"
                    color="deep-purple"
                    text-color="white" 
                  >
                    <v-avatar left>
                      <v-icon size="18">flaticon-tire</v-icon>
                    </v-avatar>
                    {{ item.object.label }}
                  </v-chip>

                  <v-chip
                    v-if="item.object.isBrakepadsService"
                    small
                    class="ml-0 mr-1 my-1"
                    color="orange darken-3"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon size="18">flaticon-brake</v-icon>
                    </v-avatar>
                    {{ item.object.label }}
                  </v-chip>

                  <v-chip
                    v-if="item.object.isMiscService"
                    small
                    class="ml-0 mr-1 my-1"
                    color="light-blue darken-2"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon size="18">flaticon-tools-and-utensils</v-icon>
                    </v-avatar>
                    {{ item.object.label }}
                  </v-chip>

                  <v-chip
                    v-if="item.object.isTechnicalControlService"
                    small
                    class="ml-0 mr-1 my-1"
                    color="yellow darken-2"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon size="18">flaticon-car</v-icon>
                    </v-avatar>
                    {{ item.object.label }}
                  </v-chip>
                </template>

                <template v-slot:item.status="{ item }">
                  <v-chip                    
                    small
                    class="ml-0 mr-1 my-1"
                    :color="item.status.color"
                    text-color="white"        
                  >                   
                    {{ item.status.label }}
                  </v-chip>                 
                </template>

                <template v-slot:item.place="{ item }">
                    <v-avatar tile size="36">
                      <v-img :src="item.image"/>         
                    </v-avatar>
                    {{ item.place }}                  
                </template>

              </v-data-table>
            </v-card>               
          </v-tab-item>

          <!-- AUDITS
          ---------->
          <v-tab-item value="tab-6" v-if="!isNew && vehicle.status == 1">
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                  v-model="searchTab6"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedAuditsItems"
                :headers="headersAudits"
                :items="auditsItems"
                :search="searchTab6"
                show-select                
                item-key="id"      
                :loading="isLoading"
                loading-text="Chargement en cours..."                
              >
                <template v-slot:group.header="{group, items, isOpen, toggle}">
                  <th colspan="5" class="subtitle-2">
                    <v-icon @click="toggle" class="mr-3"
                      >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ group }}
                  </th>
                </template>

                <template v-slot:item.status="{ item }">
                  <v-chip                    
                    small
                    class="ml-0 mr-1 my-1"
                    :color="item.statusColor"
                    text-color="white"        
                  >                   
                    {{ item.status }}
                  </v-chip>                 
                </template>              
              </v-data-table>
            </v-card>                     
          </v-tab-item>

          <!-- RELEVES KILOMETRIQUES
          -------------------------->
          <v-tab-item value="tab-7" v-if="!isNew && vehicle.status == 1"> 
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                  v-model="searchTab7"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedMileagesItems"
                :headers="headersMileages"
                :items="mileagesItems"
                :search="searchTab7"
                show-select                
                item-key="id"      
                :loading="isLoading"
                loading-text="Chargement en cours..."                
              >
                <template v-slot:item.source="{ item }">                  
                    <v-icon dense :color="item.sourceColor">
                      {{item.sourceIcon}}
                    </v-icon>                  
                </template>     

                <template v-slot:item.status="{ item }">
                  <v-chip                    
                    small
                    class="ml-0 mr-1 my-1"
                    :color="item.statusColor"
                    text-color="white"        
                  >                   
                    {{ item.status }}
                  </v-chip>                 
                </template>              
              </v-data-table>
            </v-card>                     
          </v-tab-item>

          <!-- DOCUMENTS
          ------------->
          <v-tab-item value="tab-8" v-if="!isNew && vehicle.status == 1"> 
            <v-card elevation="0">
              <v-card-title>
                <v-text-field
                  v-model="searchTab8"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedDocumentsItems"
                :headers="headersDocuments"
                :items="documentsItems"
                :search="searchTab8"
                show-select                
                item-key="id"      
                :loading="isLoading"
                loading-text="Chargement en cours..."                
              >     

              <template v-slot:item.actions="{ item }">
                <v-btn 
                  v-if="item.actions.requestId != 0"                  
                  icon
                  @click="onClickDocumentsRow('Request', item.actions.requestId)"
                  >
                  <v-icon>flaticon-question</v-icon>   
                </v-btn>

                <v-btn 
                  v-if="item.actions.dossierId != 0"                                    
                  icon   
                  @click="onClickDocumentsRow('Dossier', item.actions.dossierId)"
                >
                  <v-icon>flaticon-portfolio</v-icon>  
                </v-btn>   

                <v-btn 
                  v-if="item.actions.userId != 0"                                    
                  icon   
                  @click="onClickDocumentsRow('Profile', item.actions.userId)"
                >          
                  <v-icon>flaticon-user</v-icon>   
                </v-btn>
                
                <v-btn
                  v-if="item.actions.file != ''"                  
                  icon
                  :href="`${$constants.application.defaultURL}api/app/download/quotation/${item.actions.file}?inline`"        
                  target="_blank"
                >
                  <v-icon>mdi-file-sign</v-icon>   
                </v-btn>
                
              </template>                          
              </v-data-table>
            </v-card>                     
          </v-tab-item>
        </v-tabs-items>                     
      </v-col>
    </v-row>

    <v-row v-if="!this.isNew">
      <v-dialog        
        v-model="dialog"        
        max-width="80%"        
      >        
        <v-card>
          <v-card-title>CONTRAT</v-card-title>
          <v-card-text>
            <a-lease-form 
              :lease="dataObject" 
              :vehicle="vehicle.id"
              :initialize="dialog" 
              @cancel="dialog = false" 
              @validate="onValidateLease($event)"
            />
          </v-card-text>
          </v-card>
      </v-dialog>
      
      <v-dialog        
        v-model="dialogFuelIntake"
        max-width="60%"                
      >        
        <v-card>
          <v-card-title>PRISE DE CARBURANT</v-card-title>
          <v-card-text>
            <a-fuel-intake-form               
              :vehicle="vehicle"
              :initialize="dialogFuelIntake" 
              @cancel="dialogFuelIntake = false" 
              @validate="onValidateFuelIntake($event)"
            />
          </v-card-text>
          </v-card>
      </v-dialog>

      <v-dialog        
        v-model="dialogGetDateRestitution"
        max-width="600px"        
      >        
        <v-card>
          <v-card-title>RESTITUTION DU VEHICULE</v-card-title>
          <v-card-text>
            <a-user-header class="mb-2" :user="selectedDriver"></a-user-header>
            <v-date-picker      
                class="mb-2"
                first-day-of-week="1"
                flat                        
                v-model="dateSelected"    
                full-width                         
                locale="fr-fr"                
              ></v-date-picker>   
            <v-text-field
                v-model="distance"                                    
                label="Kilométrage"
                required
              ></v-text-field>
          </v-card-text>
          <v-card-actions class="text-right">
            <v-spacer></v-spacer>
            <div class="mb-2">
              <v-btn
                    :disabled="!isValid"
                    color="error"
                    class="mr-4"
                    @click="dialogGetDateRestitution = false"
                  >
                    Annuler
              </v-btn>   
              <v-btn
                    :disabled="!isValid"
                    color="primary"
                    class="mr-4"
                    @click="onClickBtnGiveBack()"
                  >
                    Restituer
              </v-btn>          
            </div>       
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog        
        v-model="dialogGetDateAssignation"
        max-width="500px"        
      >        
        <v-card>
          <v-card-title>AFFECTATION DU VEHICULE AU</v-card-title>
          <v-card-text>
            <v-date-picker      
                first-day-of-week="1"
                flat                        
                v-model="dateSelected"    
                full-width                         
                locale="fr-fr"                
              ></v-date-picker>   
              <v-text-field
                v-model="distance"                                    
                label="Kilométrage"
                required
              ></v-text-field>
          </v-card-text>
          <v-card-actions class="text-right">
            <v-spacer></v-spacer>
            <v-btn
                  :disabled="!isValid"
                  color="primary"
                  class="mr-4"
                  @click="onClickBtnAssign()"
                >
                  Affecter
            </v-btn>     
          </v-card-actions>
        </v-card>
      </v-dialog>

      <a-modal-user-selector        
        title="AFFECTATION DE COLLABORATEURS"
        single-select
        :customer="vehicle.customer_id"
        ref="modalUserSelector"
        @validate="onValidateUser"
      />

      <a-modal-message-maker         
        v-if="this.hasDriver"   
        :title="`Envoyer un message à ${vehicle.driver.firstname} ${vehicle.driver.lastname}`"
        auto-close
        @sendMessage="onClickBtnSendMessage($event)"
        ref="modalMessageMaker"
        />     

      <a-modal-request      
         v-if="this.hasDriver"       
          color="indigo"
          :title="`Nouvelle demande pour ${vehicle.driver.firstname} ${vehicle.driver.lastname}`"
          :requester="vehicle.driver"
          :vehicle="vehicle"
          auto-close            
          @validate="onValidateRequest"
          ref="modalRequest"
        /> 
        
        <a-modal-maintenance 
          :vehicle="vehicle"
          :driver="vehicle.driver"
          ref="modalMaintenance"
        />
    </v-row>
    </v-container>
    
  
</template>

<script>
import { mapGetters } from 'vuex'

import Functions from "@/js/functions.js"

import Service from "@/domain/Service.js"

import APageHeader from "@/components/APageHeader"
import ALeaseForm from "@/components/ALeaseForm"
import AFuelIntakeForm from "@/components/AFuelIntakeForm"
import AModalMessageMaker from "@/components/AModalMessageMaker"
import AModalRequest from "@/components/AModalRequest"
import AVehicleCharts from "@/components/AVehicleCharts"
import AUserHeader from "@/components/AUserHeader"

import AModalUserSelector from '../components/AModalUserSelector.vue'
import AModalMaintenance from '../components/AModalMaintenance.vue'

import { isNumber } from 'highcharts'

export default {
  name: 'Vehicle',  
  components: { APageHeader, ALeaseForm, AModalMaintenance, AFuelIntakeForm, AModalMessageMaker, AModalUserSelector, AVehicleCharts, AUserHeader, AModalRequest },
  data: () => ({
    assignatedDriver: 0,
    dialog: false,
    dialogGetDateRestitution: false,
    dialogGetDateAssignation: false,    
    dialogFuelIntake: false,
    dataObject: {},
    distance: undefined,
    dateSelected: new Date().toISOString().substr(0, 10),
    selectedDriver: {},  
    customers: [],  
    updateCharts: Math.random(),

    // Series
    series: [
      {id: 1, label: "Berline"},
      {id: 2, label: "Break"},
      {id: 3, label: "Coupé"},
      {id: 4, label: "SUV"},
      {id: 5, label: "Utilitaire"},
      {id: 6, label: "Cabriolet"}
    ],

    status: [
      {id: 0, label: "En commande"},
      {id: 1, label: "Actif"},
      {id: 2, label: "Hors parc"}
    ],

    // Table des conducteurs
    headersDrivers: [                  
      {text: "Nom", value: "lastname"},      
      {text: "Prénom", value: "firstname", groupable: false},
      {text: "Compte", value: "account"},
      {text: "Affecté le", value: "dateAssignment"},
      {text: "Restitué le", value: "dateRestitution"},                           
      {text: "Status", value: "status"},      
      {text: "", value: 'actions', sortable: false },
    ],
    selectedDriversItems: undefined,
    driversItems: [],

    // Table des contrats
    headersLeases: [                  
      {text: "Fournisseur", value: "provider"},      
      {text: "Référence", value: "reference", groupable: false},
      {text: "type", value: "type_label", groupable: false},
      {text: "Durée", value: "duration", groupable: false},
      {text: "Debut de contrat", value: "dateStart"},
      {text: "Echéance", value: "dateClosure"},
      {text: "Cloture du contrat", value: "dateEnd"},  
      {text: "Date d'effet", value: "dateEffective"},                     
      {text: "Kilométrage", value: "distance", align: "end"},
      {text: "Projection en Km", value: "projectionDistance", groupable: false, align:'center'},
      {text: "Projection en durée", value: "projectionDuration", groupable: false, align:'center'},              
      {text: "Status", value: "status", align: "center"} 
    ],
    selectedLeasesItems: undefined,
    leasesItems: [],

    // Table des prises de carburant
    headersFuelIntakes: [                  
      {text: "Date", value: "date"},
      {text: "Distributeur", value: "distributor"},
      {text: "Carburant", value: "fuel", groupable: false},
      {text: "Quantité", value: "quantity", align: "end"},
      {text: "Prix unitaire", value: "unitPrice", align: "end"},
      {text: "Prix", value: "totalPrice", align: "end"},                
      {text: "Kilométrage", value: "distance", align: "end"},  
      {text: "Collaborateur", value: "userName"}    
    ],
    selectedFuelIntakesItems: undefined,
    fuelIntakesItems: [],
    
    // Table des Entretiens
    headersServices: [            
      {text: "Entretien", value: "label", groupable: false},
      {text: "Date", value: "date"},
      {text: "Lieu", value: "place"},
      {text: "Kilométrage relevé", value: "realDistance"},
      {text: "Kilométrage théorique", value: "distance"},
      {text: "Statut", value: "status", groupable: false}
    ],
    selectedServicesItems: [],
    servicesItems: [],

    // Table des audit
    headersAudits: [              
      {text: "Date de demande", value: "dateCreated"},
      {text: "Date de réalisation", value: "dateRealised"},
      {text: "Collaborateur", value: "userName"},
      {text: "Kilométrage", value: "distance"},      
      {text: "Statut", value: "status", groupable: false}
    ],
    selectedAuditsItems: undefined,
    auditsItems: [],

    // Table des relevés kilométriques
    headersMileages: [               
      {text: "Date de demande", value: "dateCreated"},
      {text: "Date de réalisation", value: "dateRealised"},
      {text: "Collaborateur", value: "userName"},
      {text: "Kilométrage", value: "distance"},      
      {text: "Source", value: "source", groupable: false, align: "center"},
      {text: "Statut", value: "status", groupable: false, align: "center"}
    ],
    selectedMileagesItems: undefined,
    mileagesItems: [],    

    // Table des documents
    headersDocuments: [                        
      {text: "Type", value: "doctype"},            
      {text: "Référence", value: "reference"},            
      {text: "Date", value: "date", align: "center"},    
      {text: "Ajouté par", value: "userAddName"},      
      {text: "Ajouté le", value: "dateAdd"},
      {text: "Fichier joint par", value: "dateFileAdd"},
      {text: "Joint le", value: "dateFileAdd"},
      {text: "Actions", value: "actions"},
    ],
    selectedDocumentsItems: undefined,
    documentsItems: [],

    fab: false,
    tab: 0,
    registration: '',
    registrationRules: [
        v => !!v || 'Immatriculation obligatoire'
      ],
    brand: '',
    model: '',
    distanceInitialDate: '',
    distanceInitial: '',
    rollingLaw: 94,
    isValid: true,        
    vehicle: {
      id: 0,
      registration: '',
      serial_number: '',
      maker_id: 0,
      model_id: 0,
      modification_id: 0,
      line_id: 0,
      serie_id: 0,
      model_year: '',
      model: { 
          model: '', 
          brand: ''
        }    
    },
    makers: [],
    tiresMakers: [],
    tiresTypes: [],
    documents: [],
    models: [],
    modifications: [],
    lines: [],
    vehicleItems: [],
    selectedItems: [],
    
    
    // Zones de recherche
    searchTab1: '',
    searchTab2: '',
    searchTab3: '',
    searchTab4: '',
    searchTab5: '',
    searchTab6: '',
    searchTab7: '',
    searchTab8: '',

    fabBtnAddTitle: '',
    fabBtnDeleteTitle: '',    
    isLoading: true,
    isNew: false,
    showAddFabBtn: false,
    headerChipText: '',
    headerChipColor: '' 
  }),
  methods: {
    // Méthodes évènements UI
    // ----------------------
    onClickFabAdd: function() {
      this.dataObject = undefined
      switch (this.tab) {
        case "tab-1":
          break

        case "tab-2":          
          this.dialog = true
          break

        case "tab-3":
          this.$refs.modalUserSelector.show()
          break

        case "tab-4":
          this.dialogFuelIntake = true
          break

        case "tab-5":          
          this.$refs.modalMaintenance.show()
          break

        case "tab-6":
          break

        case "tab-7":
          break

        default:
      }
    },  
    onClickFabMessage: function() {
        this.$refs.modalMessageMaker.show()
    },
    onClickFabAudit: function() {      
      let url = `audit?ids=${this.$route.params.id}`
      this.$http
            .post(url)   
            .then(response => {
              if (response.status == 200) this.getAudits()
            })
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
    },
    onClickFabMileage: function() {      
      let url = `mileage?ids=${this.$route.params.id}`
      this.$http
            .post(url)   
            .then(response => {
              if (response.status == 200) this.getMileages()
            })
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
    },
    onClickFabDelete: function() {      
      switch (this.tab) {
        case "tab-1":
          break

        case "tab-2":
          this.onDeleteLeases()
          break

        case "tab-3":
          this.onDeleteDrivers()
          break

        case "tab-4":
          this.onDeleteFuelIntakes()
          break

        case "tab-5":   
          this.onDeleteServices();                 
          break

        case "tab-6":
          this.onDeleteAudits()
          break
        
        case "tab-7":
          this.onDeleteMileages()
          break

        default:
      }
    },
    onClickBtnSendMessage: function(message) {
        let url = "messages"
        const data = {
            to: this.vehicle.driver.id,
            text: message
        } 
        this.$http
          .post(url, data)            
          .then(response => {                        
              if (response.status == 200) {
                this.get(false)    
                this.dialog = false            
              }                                          
          })
          .catch(error => {             
              console.log(error)                 
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
    },
    onClickBtnValidate: function() {
      let params = {
        id: this.vehicle.id,
        registration: this.vehicle.registration,
        serial_number: this.vehicle.serial_number,
        maker_id: this.vehicle.maker_id,
        model_id: this.vehicle.model_id,
        modification_id: this.vehicle.modification_id,
        line_id: this.vehicle.line_id,        
        circulation_date: this.vehicle.circulation_date,
        distance: this.vehicle.distance,
        distance_initial_date: this.distanceInitialDate,
        distance_initial: this.distanceInitial,
        price: this.vehicle.price,
        discounted_price: this.vehicle.discounted_price,
        options_price: this.vehicle.options_price,
        customer_id: this.vehicle.customer_id,
        fleet_code: this.vehicle.fleet_code,        
        order_number: this.vehicle.order_number,
        order_date: this.vehicle.order_date,
        order_delivery: this.vehicle.delivery_date,
        status: this.vehicle.status,
        city_consumption: this.vehicle.city_consumption,
        mixte_consumption: this.vehicle.mixte_consumption,
        highway_consumption: this.vehicle.highway_consumption,
        co2: this.vehicle.co2,
        front_tires_size: this.vehicle.front_tires_size,
        front_tires_provider_id: this.vehicle.front_tires_provider_id,
        rear_tires_size: this.vehicle.rear_tires_size,
        rear_tires_provider_id: this.vehicle.rear_tires_provider_id,
        tires_type: this.vehicle.tires_type
      }
      let url = `vehicle`
      if (this.vehicle.id == 0) {
        this.$http.post(url, params)   
          .then(response => {
            if (response.status == 200) {
              this.isNew = false
              this.getDrivers();
              this.get();  
              this.dialogGetDateRestitution = false              
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
      else {
        this.$http.put(url, params)   
          .then(response => {
            if (response.status == 200) {
              this.getDrivers();
              this.get();  
              this.dialogGetDateRestitution = false              
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
    },
    onClickBtnDriverGiveBack: function(event) {         
      this.selectedDriver = event   
      if (event.isCurrent) {      
        this.assignatedDriver = event.id   
        this.distance = undefined    
        this.dateSelected = new Date().toISOString().substr(0, 10)   
        this.dialogGetDateRestitution = true      
      }     
    },
    onClickBtnGiveBack: function() {
      let params = {        
        date_restitution: this.dateSelected
      }
      if (this.assignatedDriver !== undefined) params.driver = this.assignatedDriver
      if (this.distance !== undefined) params.distance = this.distance
      let url = `vehicle/${this.$route.params.id}`
      this.$http
            .put(url, params)   
            .then(response => {
              if (response.status == 200) {
                this.getDrivers();
                this.get();  
                this.dialogGetDateRestitution = false
              }  
            })
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
    },
    onClickBtnAssign: function() {
      let params = {
        user: this.assignatedDriver,
        date_assignation: this.dateSelected        
      }
      if (this.distance !== undefined) params.distance = this.distance
      let url = `vehicle/${this.$route.params.id}`
      this.$http
            .put(url, params)   
            .then(response => {
              if (response.status == 200) {
                this.getDrivers();
                this.get();  
                this.dialogGetDateAssignation = false
              }  
            })
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
    },
    onClickTabLeases: function() {
      this.getLeases()
    },
    onClickTabDrivers: function() {
      this.getDrivers()
    },
    onClickTabFuelIntakes: function() {
      this.getFuelIntakes()
    },
    onClickTabServices: function() {
      this.getServices()
    },
    onClickTabAudits: function() {
      this.getAudits()
    },
    onClickTabMileages: function() {
      this.getMileages()
    },
    onClickTabDocuments: function() {
      this.getDocuments()
    },
    onClickMaintenanceRow(event) {      
      this.$refs.modalMaintenance.show(event.object);
    },
    onClickLeaseRow: function(event) {
      this.dataObject = event      
      this.dialog = true      
    },
    onClickDriverRow: function(event) {
      this.$router.push({
          name: 'Profile', 
          params: { 
            id: event.userId
          }
        })
    },
    onChangeMaker: function() {
      this.getModels()
      this.getModifications()
      this.getLines()
    },
    onValidateUser: function(user) {
      this.assignatedDriver = user[0].id
      this.dateSelected = new Date().toISOString().substr(0, 10)
      this.dialogGetDateAssignation = true
    },
    onValidateLease: function(lease) {
      this.dataObject = lease
      this.getLeases()
      this.get();
      this.dialog = false
    },    
    onValidateFuelIntake: function() {
      this.getFuelIntakes()
      this.get();
      this.dialogFuelIntake = false
    },
    onDeleteLeases: function() {     
      let ids = this.selectedLeasesItems.map(el => el.id)      

      let url = `leases?ids=${ids.join(',')}`      
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.leasesItems = this.leasesItems.filter(el => ids.indexOf(el.id) == -1)                
                this.get();              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });            
    },
    onDeleteDrivers: function() {     
      let ids = this.selectedDriversItems.map(el => el.id)      

      let url = `drivers?ids=${ids.join(',')}`      
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.driversItems = this.driversItems.filter(el => ids.indexOf(el.id) == -1)                
                this.get();              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });            
    },
    onDeleteMileages: function() {     
      let ids = this.selectedMileagesItems.map(el => el.id)      

      let url = `mileage?ids=${ids.join(',')}`      
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.mileagesItems = this.mileagesItems.filter(el => ids.indexOf(el.id) == -1)                
                this.get();              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });            
    },
    onDeleteAudits: function() {     
      let ids = this.selectedAuditsItems.map(el => el.id)      

      let url = `audit?ids=${ids.join(',')}`      
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.auditsItems = this.auditsItems.filter(el => ids.indexOf(el.id) == -1)                                        
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });            
    },    
    onDeleteFuelIntakes: function() {     
      let ids = this.selectedFuelIntakesItems.map(el => el.id)      

      let url = `fuelintakes?ids=${ids.join(',')}`      
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.fuelIntakesItems = this.fuelIntakesItems.filter(el => ids.indexOf(el.id) == -1)                
                this.get();              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });            
    },
    onDeleteServices: function() {     
      let ids = this.selectedServicesItems.map(el => el.id)      

      let url = `services?ids=${ids.join(',')}`      
      this.$http
          .delete(url)            
          .then(response => {                        
              if (response.status == 200) {                
                let message = 'Les entretiens sélectionnés ont été supprimés.'
                if (response.data.undeleted) {
                  if (response.data.undeleted.length == 1) message += " 1 entretiens déjà réalisés n'a pas été supprimé."; 
                  else if (response.data.undeleted.length > 1) message += ` ${response.data.undeleted.length} entretiens déjà réalisés n'ont pas été supprimés.`; 
                }
                this.$root.$emit('notify', message , 'success', 'mdi-checkbox-marked-circle') 
                this.servicesItems = this.servicesItems.filter(el => ids.indexOf(el.id) == -1 || (response.data.undeleted === undefined ? false : (response.data.undeleted.indexOf(el.id) != -1)));                
                this.get();              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });            
    },
    onClickDocumentsRow(pageName, id) {
      this.$router.push({ 
        name: pageName, 
        params: { 
          id
        }
      })
    },
    onClickFabNewRequest: function() {
      this.$refs.modalRequest.show()
    },
    onValidateRequest: function(event) {      
      
      let url = `requests`;
      let data = event
      data.params.from = { type: 0}
                   
      this.$http
          .post(url, data)            
          .then(response => {                        
              if (response.status == 200) {  
                  this.$router.push({ 
                      name: 'Request', 
                      params: { 
                      id: response.data.id
                      }
                  })             
              }                            
          })
            .catch(error => {                
            console.log(error)              
            if (error.response.status === 401) this.$root.$emit("logout"); 
            else this.$root.$emit("serverError", error);     
        });        
    },
    // Méthodes d'accès aux données
    // ----------------------------
    // Données vehicule
    get: function() {    
        if (this.$route.params.id !== 0) { 
          this.isLoading = true
          let url = `vehicle/${this.$route.params.id}`
          this.$http
              .get(url)            
              .then(response => {                        
                  if (response.status == 200) {
                    this.vehicle = response.data   
                    if (this.vehicle.image_file !== null) this.vehicle.image_file = `${this.$constants.application.resourcesURL}/${this.vehicle.image_file}`
                    this.updateCharts = Math.random()
                    this.isNew = false  
                    
                    if (this.vehicle.status == 0) {
                      this.headerChipText = "En commande"
                      this.headerChipColor = "blue"
                    }
                    else if (this.vehicle.status == 2) {
                      this.headerChipText = "Hors parc"
                      this.headerChipColor = "red"
                    }
                  }                                          

                  this.getModels()
                  if (this.vehicle.model_id != 0) this.getModifications()
                  this.getLines()                  
                  this.isLoading = false 
              })
              .catch(error => {                
                  this.isLoading = false
                  if (error.response.status === 401) this.$root.$emit("logout"); 
                  else this.$root.$emit("serverError", error);     
              });                                  
        }        
    },
    getCustomers: function() {
      let url = `customers`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.customers = response.data

                if (this.$route.params.customer !== undefined) this.vehicle.customer_id = this.$route.params.customer
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })                     
    },
    // Liste des constructeurs
    getMakers: function() {
      let url = `providers/types/4`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.makers = response.data
              }              
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
     // Liste des fabricants de pneumatiques
    getTiresMakers: function() {
      let url = `providers/types/7`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.tiresMakers = [{id: 0, name: ''}, ...response.data]
              }              
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
    // Liste des type de pnaumatiques
    getTiresTypes: function() {
      let url = `listitems/tires_types`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.tiresTypes = response.data.map(el => {return {...el, "label": `${el.name}`}})
              }              
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })
    },
    // Liste des constructeurs
    getModels: function() {
      if (this.vehicle.maker_id === undefined) this.models = []
      else {        
        let url = `catalogs/provider/${this.vehicle.maker_id}/vehicle_model`                     
        this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.models = response.data.map(el => { return {...el, text: `${el.name} (${el.version})`} })
              }               
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
      }
    },
    // Liste des constructeurs
    getModifications: function() {
      if (this.vehicle.maker_id === undefined) this.modifications = []
      else {
        let url = `catalogs/provider/${this.vehicle.maker_id}/vehicle_modification`                     
        this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.modifications = response.data
              }               
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
      }
    },
    // Liste des constructeurs
    getLines: function() {
      if (this.vehicle.maker_id === undefined) this.lines = []
      else {        
        let url = `catalogs/provider/${this.vehicle.maker_id}/vehicle_line`                     
        this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.lines = response.data
              }               
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
      }
    },
    getDrivers: function() {
      let url = `vehicle/${this.$route.params.id}/drivers`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.driversItems = response.data.map(el => {                     
                  let status = 'Actuel'
                  let statusColor = "green"
                  let isCurrent = true
                  if (new Date(el.date_restitution) < new Date()) {
                    status = 'Rendu'
                    statusColor = "red"    
                    isCurrent = false                
                  }
                  if (new Date(el.date_assignment) > new Date()) {
                    status = "A venir"
                    statusColor = "grey"
                    isCurrent = false                    
                  }                  
                  if (isCurrent) this.assignatedDriver = el.id
                  return {
                    id: el.id,
                    userId: el.user_id,
                    firstname: el.user.firstname,
                    lastname: el.user.lastname,
                    phone: el.user.phone,
                    mobile: el.user.mobile,
                    mail: el.user.mail,
                    account: el.user.work.name,
                    dateAssignment: el.date_assignment, 
                    dateRestitution: el.date_restitution,
                    image: `${this.$constants.application.resourcesURL}/images/${el.user.work.image}`,                                        
                    status: status,
                    statusColor: statusColor,
                    isCurrent: isCurrent
                  }
                })
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
    getLeases: function() {
      let url = `vehicle/${this.$route.params.id}/leases`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.leasesItems = response.data.map(el => { 
                  let status = ''
                  let statusColor = ''
                  if (el.date_closure) {     
                    status = new Date(el.date_closure) > new Date() ? 'En cours' : 'Echu'
                    statusColor = new Date(el.date_closure) > new Date() ? 'green' : 'red'
                    if (new Date(el.date_start) > new Date()) {
                      status = "En attente"
                      statusColor = "grey"
                    }
                    else if (el.date_end !== '') {
                      status = "Cloturé"
                      statusColor = 'red'
                    }
                    else {
                      statusColor = Functions.Leases.getStatusColor(el.status)
                      status = Functions.Leases.getStatusLabel(el.status)
                    }
                  }

                  let rest = el.duration - el.since
                  let closureColor = Functions.Leases.getClosureStatusColor(rest)
                  let typeLabel
                  switch (el.type) {
                    case this.$constants.leases.TYPE_LOA:
                      typeLabel = "LOA"
                      break
                    
                    case this.$constants.leases.TYPE_INSURANCE:
                      typeLabel = "Assurance"
                      break

                    case this.$constants.leases.TYPE_ASSISTANCE:
                      typeLabel = "Assistance"
                      break

                    case this.$constants.leases.TYPE_LLD:
                      typeLabel = "LLD"
                      break

                    case this.$constants.leases.TYPE_LCD:
                      typeLabel = "LCD"
                      break

                    case this.$constants.leases.TYPE_LMD:
                      typeLabel = "LMD"
                      break

                    default:
                      typeLabel = "Achat"
                  }

                  let projectionDistance = Math.ceil(el.distance * el.rollinglaw / 100)
                  let projectionDuration = Math.ceil(el.duration * el.rollinglaw / 100)
                  
                  return {
                    id: el.id,
                    type: el.type,
                    type_label: typeLabel,
                    dateStart: el.date_start,
                    dateEnd: el.date_end,
                    dateEffective: el.date_effect,
                    dateClosure: el.date_closure,
                    distance_initial: el.distance_initial,
                    driver: el.driver.id == undefined ? '' : el.driver.firstname + ' ' + el.driver.lastname,    
                    driverId: el.driverId,
                    duration: el.duration > 0 ? el.duration : '',               
                    provider: el.provider.name,
                    providerId: el.provider.id,
                    reference: el.reference,
                    image: `${this.$constants.application.resourcesURL}/images/${el.provider.image}`,
                    distance: el.distance,
                    hasRent: el.has_rent,
                    hasMaintenanceRent: el.has_maintenance_rent,
                    hasTiresRent: el.has_tires_rent,
                    hasAssistanceRent: el.has_assistance_rent,
                    hasInsuranceRent: el.has_insurance_rent,
                    hasOtherRent: el.has_other_rent,
                    rent: el.rent,
                    maintenanceRent: el.maintenance_rent,
                    tiresRent: el.tires_rent,
                    assistanceRent: el.assistance_rent,
                    insuranceRent: el.insurance_rent,
                    otherRent: el.other_rent,                    
                    isRenewal: el.is_renewal,
                    renewalStatus: el.renewal_status,
                    dateRenewalStatus: el.date_renewal_status,
                    status: status,
                    statusColor: statusColor,
                    closureColor: closureColor,
                    projectionDistance: projectionDistance > 0 ? projectionDistance : '',
                    projectionDuration: projectionDuration > 0 ? projectionDuration : '',
                    recommendations: el.recommendations
                  }
                })
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
    getDocuments: function() {
      let url = `vehicle/${this.$route.params.id}/documents`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.documents = response.data
                this.documentsItems = response.data.map(el => { 
                  let docType
                  if (el.doctype == 'quotation') docType = "Devis"

                  let actions = {
                    requestId: el.request_id === undefined ? 0 : el.request_id,
                    dossierId: el.dossier_id === undefined ? 0 : el.dossier_id,
                    userId: el.user_id === undefined ? 0 : el.user_id,
                    file: el.file === undefined ? '' : el.file                 
                  }

                  return {
                    doctype: docType,
                    id: el.id,
                    reference: el.number,
                    date: el.date,
                    dateAdd: isNumber(el.tmestamp_add) ? new Date(el.tmestamp_add * 1000).toLocaleTimeString() : '',
                    dateAddFile: isNumber(el.tmestamp_add) ? new Date(el.tmestamp_add_file * 1000).toLocaleTimeString() : '',
                    userAdd: el.user_add.id === undefined ? '' : `${el.user_add.firstname} ${el.user_add.lastname}`,
                    userAddFile: el.user_add_file.id === undefined ? '' : `${el.user_add_file.firstname} ${el.user_add_file.lastname}`,
                    actions: actions
                  }
                })
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
    getAudits: function() {
      let url = `vehicle/${this.$route.params.id}/audits`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.auditsItems = response.data.map(el => {
                  return {
                    id: el.id,
                    dateRealised: el.timestamp_realised == 0 ? '' : this.$moment(new Date(el.timestamp_realised)).format("DD/MM/YYYY HH:mm"),
                    dateCreated: this.$moment(new Date(el.timestamp_created)).format("DD/MM/YYYY HH:mm"),
                    user: el.user.id,                    
                    userName: el.user.firstname + ' ' + el.user.lastname,
                    distance: el.distance,
                    status: el.is_closed ? 'Terminé' : 'En cours',
                    statusColor: el.is_closed ? this.$constants.colors.STATUS_COMPLETED : this.$constants.colors.STATUS_PROCESSING
                  }
                })
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },    
    getMileages: function() {
      let url = `vehicle/${this.$route.params.id}/mileages`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.mileagesItems = response.data.map(el => {
                  let sourceColor = "indigo"
                  let sourceIcon = "flaticon-speed"
                  switch (el.source) {
                    case 1:
                      sourceColor = "green"
                      sourceIcon = "flaticon-keys"
                      break

                    case 2:
                      sourceColor = "red"
                      sourceIcon = "flaticon-keys"
                      break
                    
                    case 3:
                      sourceColor = "green"
                      sourceIcon = "flaticon--front-car"
                      break

                    case 4:
                      sourceColor = "orange"
                      sourceIcon = "flaticon--front-car"
                      break

                    case 5:
                      sourceColor = "indigo"
                      sourceIcon = "flaticon-user"
                      break

                  }
                  return {
                    id: el.id,
                    dateRealised: el.timestamp_realised == 0 ? '' : this.$moment(new Date(el.timestamp_realised)).format("DD/MM/YYYY HH:mm"),
                    dateCreated: this.$moment(new Date(el.timestamp_created)).format("DD/MM/YYYY HH:mm"),
                    user: el.user.id,                    
                    userName: el.user.firstname + ' ' + el.user.lastname,
                    distance: el.distance,
                    source: el.source,
                    sourceColor: sourceColor,
                    sourceIcon: sourceIcon,
                    status: el.is_closed ? 'Terminé' : 'En cours',
                    statusColor: el.is_closed ? this.$constants.colors.STATUS_COMPLETED : this.$constants.colors.STATUS_PROCESSING
                  }
                })
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
    getFuelIntakes: function() {
      let url = `vehicle/${this.$route.params.id}/fuelintakes`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.fuelIntakesItems = response.data.map(el => {
                  return {
                    id: el.id,
                    date: this.$moment(new Date(el.intake_timestamp)).format("DD/MM/YYYY HH:mm"),
                    distributor: el.distributor.name,
                    image: `${this.$constants.application.resourcesURL}/images/${el.image}`,
                    fuel: `${el.name} - ${el.type}`,
                    userName: `${el.driver.firstname} ${el.driver.lastname}`,
                    distance: el.distance,
                    unitPrice: el.unit_price,
                    totalPrice: el.total_price,
                    quantity: el.quantity
                  }
                })
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
    getServices: function() {
      let url = `vehicle/${this.$route.params.id}/services`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.servicesItems = response.data.map(el => {      
                  const service = Service.fromObject(el);                 
                  return {
                    object: service,
                    id: service.id,
                    label: service.label,
                    date: service.timestamp ? (new Date(service.timestamp)).toLocaleString() : '',
                    distance: service.distance,
                    realDistance: service.realDistance,
                    place: service.place ? service.place.name : '',
                    status: service.status,
                    image: service.place ? `${this.$constants.application.resourcesURL}/images/${service.place.provider.image}` : ''
                  }           
                })
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
    },
    setRollingLawFillColor: function() {
        if (this.vehicle.rollinglaw < 90) return "rgba(242, 156, 56, 1)"                                   
        else if (this.vehicle.rollinglaw > 110) return "rgba(255, 82, 65, 1)"   
        else return "rgba(36, 181, 44, 1)"   
    }, 
    setRollingLawFillBackColor: function() {
        if (this.vehicle.rollinglaw < 90) return "rgba(242, 156, 56, 0.3)"                 
        else if (this.vehicle.rollinglaw > 110) return "rgba(255, 82, 65, 0.3)"  
        else return "rgba(36, 181, 44, 0.3)"        
    }
  },  
  watch: {    
    tab: function() {
      this.showAddFabBtn = false
      switch (this.tab) {
        case "tab-2":
          this.fabBtnAddTitle = "Ajouter un contrat"
          this.fabBtnDeleteTitle = "Supprimer les contrats séléctionnés"
          this.showAddFabBtn = true
          break

        case "tab-3":
          this.fabBtnAddTitle = "Ajouter un conducteur"
          this.fabBtnDeleteTitle = "Supprimer les conducteurs séléctionnés"
          this.showAddFabBtn = true
          break
          
        case "tab-4":
          this.fabBtnAddTitle = "Ajouter une prise de carburant"
          this.fabBtnDeleteTitle = "Supprimer les prises de carburant séléctionnées"
          this.showAddFabBtn = true
          break
          
        case "tab-5":
          this.fabBtnAddTitle = "Ajouter un entretien"
          this.fabBtnDeleteTitle = "Supprimer les entretiens séléctionnés"
          this.showAddFabBtn = true
          break
          
        case "tab-6":          
          this.fabBtnDeleteTitle = "Supprimer les audits séléctionnés"          
          break
          
        case "tab-7":
          this.fabBtnAddTitle = "Ajouter un relevé kilométrique"
          this.fabBtnDeleteTitle = "Supprimer les relevés kilométriques séléectionnés"          
          break                
      }
    }
  },
  computed: {
    ...mapGetters([
      'userEnvironment'
    ]),  
    imgCustomerLogo: function() {
      let c = this.customers.find(el => el.id == this.vehicle.customer_id)
      if (c !== undefined) {
        let img = c.image
        return `${this.$constants.application.resourcesURL}/images/${img}`
      }
      else return ''
    },
    title: function() {
      return this.vehicle.id == 0 && (this.vehicle.registration == '' || this.vehicle.model.brand == '' || this.vehicle.model.model == '')
        ? "Nouveau vehicule" 
        : `${this.vehicle.model.brand} ${this.vehicle.model.model} : ${this.vehicle.registration}`
    },
    hasDriver: function() {
      return this.vehicle.driver && this.vehicle.driver.id
    }
  },
  mounted() {
    this.isNew = this.$route.params.id == 0
    this.getMakers()
    this.getCustomers()
    this.getTiresMakers()
    this.getTiresTypes()
    if (!this.isNew) this.get()        
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;  
}

.v-btn--floating {
  position: relative;
}
</style>