<template>        
              
      <div id="map" @click="showDateTimePickers = false">

      <div v-show="isLoading" class="loader-bloc">
        <v-progress-linear
          indeterminate        
          color="blue"
        /> 
      </div>  
        

      <div id="searchBloc" class="search-bloc ma-1">    
       
            <v-text-field                                  
              label="Rechercher"            
              filled
              rounded
              dense
              :background-color="backgroundColor"
              v-model="searchTags"
            ></v-text-field>    
            
            <v-btn   
              v-if="false"           
              ref="fabAdd"
              fab
              dark
              small
              color="primary"
              @click.stop="onClickFabAddProviderPlace()"
              title="Ajouter un prestataire"
              class="ma-2"
          >
          <v-icon size="20">mdi-plus</v-icon>
          </v-btn>                        

      </div> 

      <div v-if="showCreationBloc" id="creationBloc" class="creation-bloc ma-1">
        <v-card                                
          class="mx-auto my-1"          
          :color="backgroundColor"                                                          
        >     
          <v-card-title>Ajouter un lieu</v-card-title>
          <v-card-text>     
            <v-form>

              <v-select
                v-model="provider"                
                :items="providers"                
                label="Prestataire" 
                return-object
                single-line               
              >      
                <template v-slot:item="{ item }">     
                  <v-list-item
                    ripple   
                    @click="provider = item"                                     
                  >
                    <v-list-item-avatar tile>
                      <v-img :src="item.image"/>                                              
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>                   
                  </v-list-item>
                </template>
              </v-select>
              <v-row>
                <v-col sm="12">
                    <v-text-field                                  
                    label="Nom"                                        
                    dense    
                    ref="providerName"          
                  ></v-text-field>  
                </v-col>  
              </v-row>
              <v-row>
                <v-col sm="12">
                    <v-text-field                                  
                    label="Adresse"                                        
                    dense              
                  ></v-text-field>  
                </v-col>  
              </v-row>
              <v-row>
                <v-col sm="12">
                    <v-text-field                                  
                    label="Complément d'adresse"                                        
                    dense              
                  ></v-text-field>  
                </v-col>  
              </v-row>
              <v-row>
                <v-col sm="4">
                    <v-text-field                                  
                    label="Code postal"                                        
                    dense              
                  ></v-text-field>  
                </v-col>  

                <v-col sm="8">
                    <v-text-field                                  
                    label="Ville"                                        
                    dense              
                  ></v-text-field>  
                </v-col>  
              </v-row>

              <v-row>
                <v-col sm="6">
                    <v-text-field                                  
                    label="Latitude"                                        
                    dense              
                  ></v-text-field>  
                </v-col>  
                
                <v-col sm="6">
                  <v-text-field                                  
                    label="Longitude"                          
                    dense                            
                  ></v-text-field>  
                </v-col>  
              </v-row>
            </v-form>            
             
          </v-card-text>
        </v-card>       
      </div>

      <div v-if="resultPlaceSearch.length > 1" id="selectionBloc" class="search-result ma-1"> 
        <v-card                      
          v-for="place in resultPlaceSearch" :key="place.id" 
          class="mx-auto my-1"          
          :color="backgroundColor"        
          hover   
          @click="onClickSearchPlaceCard(place)"                                  
        >     
          
          <v-card-text>                 
            <div class="d-flex flex-no-wrap">                          
                <v-avatar                    
                  size="90"
                  tile
                >
                    <v-img :src="`${baseURL}${place.provider.image.toLowerCase()}`"></v-img>
              </v-avatar>      
                
              <div class="ml-5">      
                <div class="title">{{place.name}}</div>              
                <p v-html="formatPlaceAddress(place)"></p>           
              </div>
            </div>  
          </v-card-text>
        </v-card>        
      </div>      

      <div v-if="!onlySelect && selectedPlace !== undefined && resultPlaceSearch.length == 0" id="selectionBloc" class="selection-bloc ma-6" @click.stop> 
        <v-row>
          <v-card            
            class="mx-auto"          
            :color="backgroundColor"
            width="500px"
          >
            <v-card-subtitle class="title">Prestataire séléctionné</v-card-subtitle>
            <v-card-text>
              <div class="d-flex flex-no-wrap">                          
                  <v-avatar                    
                    size="125"
                    tile
                  >
                      <v-img :src="`${baseURL}${selectedPlace.provider.image.toLowerCase()}`"></v-img>
                </v-avatar>      
                  
                <div class="ml-5"> 
                <p class="headline text--primary">
                  {{selectedPlace.name}}
                </p>
                <p v-html="formatPlaceAddress(selectedPlace)"></p>           
                </div>
              </div>  
                          
              <v-chip
                v-if="proposed.overhaul"
                class="ml-0 mr-1 my-1"
                color="teal accent-3"
                text-color="white"   
                close   
                @click:close="proposed.overhaul = false"        
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-oil</v-icon>
                </v-avatar>
                Vidange/Revision
              </v-chip>

              <v-chip
                v-if="proposed.tires"
                class="ml-0 mr-1 my-1"
                color="deep-purple"
                text-color="white"
                close
                @click:close="proposed.tires = false"
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-tire</v-icon>
                </v-avatar>
                Pneumatiques
              </v-chip>

              <v-chip
                v-if="proposed.brakepads"
                class="ml-0 mr-1 my-1"
                color="orange darken-3"
                text-color="white"
                close
                @click:close="proposed.brakepads = false"
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-brake</v-icon>
                </v-avatar>
                Plaquettes de freins
              </v-chip>

              <v-chip
                v-if="proposed.misc"
                class="ml-0 mr-1 my-1"
                color="light-blue darken-2"
                text-color="white"
                close
                @click:close="proposed.misc = false"
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-tools-and-utensils</v-icon>
                </v-avatar>
                Divers/Réparation
              </v-chip>          
              
              <div class="subtitle-2 my-2" v-show="needAcceptance">N° d'accord attribué par {{vehicle.lease.provider.name}}</div> 

              <div class="title" v-if="date !== '' && time !== ''">Rendez-vous prévu le {{new Date(`${date} ${time}`).toLocaleString()}}</div>     
            </v-card-text>      
            <v-card-actions>
              <v-btn block color="primary" @click.stop="showDateTimePickers = true">Horaire du rendez-vous</v-btn>
            </v-card-actions>                                             
          </v-card>   
      
          <v-card         
            v-show="showDateTimePickers"    
            height="470px"      
            width="400px" 
            :color="backgroundColor"            
          >
            <v-card-subtitle class="title">Date</v-card-subtitle>   
            <v-card-text>                  
                  <v-date-picker      
                    first-day-of-week="1"
                    flat                        
                    v-model="date"    
                    full-width                         
                    locale="fr-fr"
                    :events="functionEvents"
                    event-color="green lighten-1"
                    @change="selectEvent()"                    
                  ></v-date-picker>                                                 
            </v-card-text>                                                    
          </v-card>   
      
          <v-card            
            v-show="showDateTimePickers"
            height="470px" 
            width="330px"       
            :color="backgroundColor"
          >
            <v-card-subtitle class="title">Heure</v-card-subtitle>   
            <v-card-text>                                                    
                  <v-time-picker                    
                    v-model="time"                    
                    type="month"
                    format="24hr"
                    full-width                    
                    :allowed-minutes ="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
                    @change="selectEvent()"
                    @click.stop
                  ></v-time-picker>
                
            </v-card-text>                                                    
          </v-card>   
        </v-row>              
      </div>

      <div class="info-bloc ma-0 text-right">                                   
        <v-card-text v-if="!onlySelect" text-align="right" class="pa-0">
          <v-chip  
            v-if="vehicle !== undefined && vehicle.lease !== undefined"              
            class="ml-0 mr-1 my-1"
            color="black"
            text-color="white"                                                           
          >                 
            <span class="mr-2">Informations contrat : {{vehicle.lease.provider.name}}</span>
            <v-icon v-if="vehicle.lease.has_maintenance_rent" size="16" color="teal accent-3" class="mx-1">flaticon-oil</v-icon>
            <v-icon v-if="vehicle.lease.has_maintenance_rent" size="16" color="orange darken-3" class="mx-1">flaticon-brake</v-icon>
            <v-icon v-if="vehicle.lease.has_tires_rent" size="16" color="deep-purple" class="mx-1">flaticon-tire</v-icon>
            <v-icon v-if="vehicle.lease.has_other_rent" size="16" color="light-blue darken-2" class="mx-1">flaticon-tools-and-utensils</v-icon>    
          </v-chip>
        </v-card-text>

        <v-card-text v-if="!onlySelect && services" text-align="right" class="pa-0">
          <v-chip                
            class="ml-0 mr-1 my-1"
            :color="services.overhaul ? 'teal accent-3' : 'grey accent-3'"
            text-color="white"    
            :close="!proposed.overhaul"
            close-icon="mdi-plus-circle" 
            @click:close="proposed.overhaul = true"       
          >
            <v-avatar left>
              <v-icon size="18">flaticon-oil</v-icon>
            </v-avatar>
            Vidange/Revision
          </v-chip>

          <v-chip                
            class="ml-0 mr-1 my-1"
            :color="services.tires ? 'deep-purple' : 'grey accent-3'"                
            text-color="white"
            :close="!proposed.tires"
            close-icon="mdi-plus-circle" 
            @click:close="proposed.tires = true"   
          >
            <v-avatar left>
              <v-icon size="18">flaticon-tire</v-icon>
            </v-avatar>
            Pneumatiques
          </v-chip>

          <v-chip                
            class="ml-0 mr-1 my-1"
            :color="services.brakepads ? 'orange darken-3' : 'grey accent-3'"                
            text-color="white"
            :close="!proposed.brakepads"
            close-icon="mdi-plus-circle" 
            @click:close="proposed.brakepads = true"  
          >
            <v-avatar left>
              <v-icon size="18">flaticon-brake</v-icon>
            </v-avatar>
            Plaquettes de freins
          </v-chip>

          <v-chip                
            class="ml-0 mr-1 my-1"
            :color="services.misc ? 'light-blue darken-2' : 'grey accent-3'"                
            text-color="white"
            :close="!proposed.misc"
            close-icon="mdi-plus-circle" 
            @click:close="proposed.misc = true"
          >
            <v-avatar left>
              <v-icon size="18">flaticon-tools-and-utensils</v-icon>
            </v-avatar>
            Divers/Réparation
          </v-chip>           
          
          <v-chip                
            class="ml-0 mr-1 my-1"
            :color="services.control ? 'yellow darken-2' : 'grey accent-3'"                
            text-color="white"
            :close="!proposed.control"
            close-icon="mdi-plus-circle" 
            @click:close="proposed.control = true"
          >
            <v-avatar left>
              <v-icon size="18">flaticon-car</v-icon>
            </v-avatar>
            Contrôt Technique
          </v-chip>      
        </v-card-text>

        <v-card-text v-if="!onlySelect && preferences" text-align="right" class="pa-0">
          <v-chip
            v-if="preferences.day1"
            class="ml-0 mr-1 my-1"
            color="green"
            text-color="white"
          >
            Lundi
          </v-chip>
          <v-chip
            v-if="preferences.day2"
            class="ml-0 mr-1 my-1"
            color="green"
            text-color="white"
          >
            Mardi
          </v-chip>
          <v-chip
            v-if="preferences.day3"
            class="ml-0 mr-1 my-1"
            color="green"
            text-color="white"
          >
            Mercredi
          </v-chip>
          <v-chip
            v-if="preferences.day4"
            class="ml-0 mr-1 my-1"
            color="green"
            text-color="white"
          >
            Jeudi
          </v-chip>
          <v-chip
            v-if="preferences.day5"
            class="ml-0 mr-1 my-1"
            color="green"
            text-color="white"
          >
            Vendredi
          </v-chip>
          <v-chip
            v-if="preferences.day6"
            class="ml-0 mr-1 my-1"
            color="green"
            text-color="white"
          >
            Samedi
          </v-chip>
        </v-card-text>    

        <v-card-text v-if="!onlySelect && localization" text-align="right" class="pa-0">
          <v-chip
            v-if="localization.home"
            class="ml-0 mr-1 my-1"
            color="light-blue lighten-3"
            text-color="white"
            @click="map.setCenter(center)"
          >
            <v-avatar left>
              <v-icon size="18">flaticon-oil</v-icon>
            </v-avatar>
            Proche domicile
          </v-chip> 
        
          <v-chip
            v-if="localization.work"
            class="ml-0 mr-1 my-1"
            color="pink lighten-3"
            text-color="white"
            @click="map.setCenter(center)"
          >
            <v-avatar left>
              <v-icon size="18">flaticon-tire</v-icon>
            </v-avatar>
            Proche lieu de travail
          </v-chip>
        </v-card-text>    
      </div>     
    </div>       
  
</template>

<script>
import mapboxgl from 'mapbox-gl';
import constants from '@/js/constants' 

const ICON_SIZE = 52

export default {
    name: "AProviderSelector",
    props: {
      localization: {
        type: Object        
      },
      services: {
        type: Object        
      },
      preferences: {
        type: Object        
      },
      vehicle: {
        type: Object
      },
      onlySelect: {
        type: Boolean,
        default: false
      },
      mapCenter: {
        type: Array
      }
    },
    data() {
      return {
        id: undefined,
        places: undefined,
        date: '',
        time: '',
        map: undefined,   
        center: [0,0], 
        markers: [],
        providers:[],
        provider: 0,
        isLoading: false,
        showDateTimePickers: false,
        showCreationBloc: false,
        selectedPlace: undefined,
        selectedMarker: undefined,
        proposed: {...this.services},
        baseURL: constants.application.resourcesURL + '/images/',
        searchTags: '',
        resultPlaceSearch: [],
        backgroundColor: this.$vuetify.theme.dark ? '#000000CC' :'#FFFFFFDD'
      }
    },
    watch: {
      proposed: function() {
        this.selectEvent()
      },
      searchTags: function() {
        if (this.searchTags.length > 3 && this.places !== undefined) { 
          let tags = this.searchTags.replace(/[ -/,]/, ' ').split(' ')
          this.resultPlaceSearch = this.places.filter(el => {              
            for (let i = 0; i < tags.length; i++) {
              if (el.searchString.indexOf(tags[i]) == -1) {
                return false
              }
            }
            return true
          })
          if (this.resultPlaceSearch.length == 1) {
            let center = [this.resultPlaceSearch[0].longitude, this.resultPlaceSearch[0].latitude]
            this.map.setCenter(center).setZoom(16)
          }               
        }
        else this.resultPlaceSearch = []
      },
      mapCenter: function() {        
          this.center = [...this.mapCenter];
          if (this.map) this.map.setCenter(this.center);          
      }
    },
    computed: {
      needAcceptance: function() {        
        if (this.vehicle !== undefined && this.vehicle.lease !== undefined) {
          if (this.vehicle.lease.has_maintenance_rent && this.proposed.overhaul || this.proposed.brakepads) return true
          if (this.vehicle.lease.has_tires_rent && this.proposed.tires) return true
          if (this.vehicle.lease.has_other_rent && this.proposed.misc) return true
        }
        return false        
      }
    },
    methods: {
      // Méthodes sur évenements
      // -----------------------
      onClickSearchPlaceCard: function(place) {
        if (this.selectedMarker !== undefined) {
          let popup = this.selectedMarker.getPopup()
          if (popup.isOpen()) this.selectedMarker.togglePopup()
        }
        
        this.map.setCenter([place.longitude, place.latitude]).setZoom(16)
        if (place.marker !== undefined) {
          let popup = place.marker.getPopup()
          if (!popup.isOpen()) place.marker.togglePopup()
        }
        this.selectedMarker = place.marker
      },
      onClickMarkerPlace: function(marker, place) {
        this.selectedMarker = marker
        this.selectedPlace = place
        this.resultPlaceSearch = []
        this.searchTags = ''
        this.selectEvent()
      },
      onClickFabAddProviderPlace: function() {
        this.searchTags = '';
        this.resultPlaceSearch = [];
        this.showCreationBloc = true;
        this.getProviders();
      },
      onClickProvider: function(event) {
        this.$nextTick(() => {
          this.provider = event;
          this.$refs.providerName.$el.focus();
        })
      },
      // Méthodes d'accès aux données
      // ----------------------------
      get: function() {      
        this.isLoading = true;
        let url = `places?vecl=${this.vehicle.id}&${this.needAcceptance ? 'renter_priority' : 'customer_priority'}` + (this.services.overhaul ? "&overhaul" : '') + (this.services.brakepads ? "&brakepads" : '') + (this.services.tires ? "&tires" : '') + (this.services.misc ? "&misc" : '') + (this.services.control ? "&misc" : '')   
        this.$http
            .get(url)            
            .then(response => {                        
                if (response.status == 200) {                
                    this.places = response.data
                    this.places.forEach(element => {
                      const place = `place_${element.id}`
                      const image = `${element.provider.name.toLowerCase().replace(' ', '_')}_64x64.png`                      
                      let html = `<h3 class="black--text">${element.name}</h3><span class="black--text">${this.formatPlaceAddress(element)}</span>`
                      if (element.phone != '') html += `</br><span class="black--text"><i class="glyph-icon flaticon-telephone"></i>&nbsp;&nbsp;${element.phone}</span>`
                      if (element.email != '') html += `</br><span class="black--text"><i class="glyph-icon flaticon-email"></i> ${element.email}</span>`
                      element.marker = this.addMarkerOnMap(place, element.longitude, element.latitude, image, ICON_SIZE, html, element)
                      element.searchString = (`${element.provider.name} ${element.name} ${element.address1} ${element.address2} ${element.address3} ${element.city} ${element.zip} ${element.phone.replace(/[ -/]/, '')} ${element.email}`).toLowerCase().replace(/[ -/,]/, ' ')
                    });                    
                }             
                this.isLoading = false;                             
            })
            .catch(error => {    
              this.isLoading = false;            
              console.log(error)              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
            });
      },
      getProviders: function() {
        if (this.providers.length === 0) {
          let url = "providers/types/2,4"
          this.$http
              .get(url)            
              .then(response => {                        
                  if (response.status == 200) {
                    this.providers = response.data.map(el => {                   
                      return {
                        value: el.id,
                        text: el.name,                    
                        image: el.image != "" ? `${this.$constants.application.resourcesURL}/images/${el.image}` : "",                    
                      }
                    }) 
                  }                    
              })
              .catch(error => {                              
                  if (error.response.status === 401) this.$root.$emit("logout"); 
                  else this.$root.$emit("serverError", error);     
              })
        }
      },
      // Méthodes du composant
      // ---------------------
      functionEvents: function(date) {   
        if (this.preferences) {
          const d = new Date(date)    
          return this.preferences[`day${d.getDay()}`] && d >= new Date()
        }
        return false;
      },
      formatPlaceAddress: function(place) {
        let address = place.address1.trim()
        if (place.address2 != "" && place.address2 !== null) address += `<br>${place.address2.trim()}`
        if (place.address3 != "" && place.address3 !== null) address += `<br>${place.address3.trim()}`
        address += `<br>${place.zip} ${place.city}`
        return address
      },
      selectEvent: function() {   
        if (this.onlySelect) {
          this.$emit('select', this.selectedPlace);
        }  
        else {           
          let appointment = {
            provider: this.selectedPlace,
            services: this.proposed,
            date: this.date,
            time: this.time,          
            id: this.id
          };          
          this.$emit('select', appointment);  
        }      
      },
      init: function(appointment) {
        this.searchTags = '';
        if (appointment === undefined) {
          this.date = ''
          this.time = ''
          this.selectedPlace = undefined
          this.proposed = {...this.services}   
          this.id = undefined                 
        } else {
          const services = Object.values(appointment.services).map(el => el.type_label)
          
          this.proposed = {
            overhaul: services.indexOf('overhaul') != -1,
            tires: services.indexOf('tires') != -1,
            brakepads: services.indexOf('brakepads') != -1,
            misc: services.indexOf('misc') != -1
          }
          
          let date = new Date(appointment.timestamp)           
          this.selectedPlace = appointment.place
          this.date = date.toISOString().split('T')[0]
          this.time = date.toTimeString().split(' ')[0]
          this.id = appointment.id

          this.$emit('select', appointment)
        }

        if (this.selectedMarker !== undefined) {
          let popup = this.selectedMarker.getPopup()
          if (popup.isOpen()) this.selectedMarker.togglePopup()
        }
      },
      // Méthodes associé au fonctionnement de la map
      // --------------------------------------------
      addMarkerOnMap: function(name, lng, lat , img, size, popupHTMLContent, onClickElement) {
        const baseURL = constants.application.resourcesURL + '/images/'        
        const imageURL = `url(${baseURL}${img})`            
        // create a DOM element for the marker
        var el = document.createElement('div')
        el.id = name
        el.className = 'marker'
        el.style.backgroundImage = imageURL            
        el.style.width = size + 'px'
        el.style.height = size + 'px'
        el.style.backgroundSize = 'cover'        
        
        let marker = new mapboxgl.Marker(el)
            .setLngLat([lng, lat])

        if (popupHTMLContent !== undefined) {
          // create the popup
          var popup = new mapboxgl.Popup({ offset: 25, closeButton: false }).setHTML(popupHTMLContent)                   
          marker.setPopup(popup)          
        }
        
        // add marker to map
        marker.addTo(this.map)              

        if (onClickElement !== undefined) {                       
          el.addEventListener('click', function() {              
              this.onClickMarkerPlace(marker, onClickElement)                                     
          }.bind(this))
        }

        return marker
      },
      reloadPlaces: function() {
        this.map.setCenter(this.center)
        this.places.forEach(place => place.marker.remove())
        this.places = undefined
        this.get();      
      },
      initMap: function() {  
        if (document.querySelector('#map')) {
          this.$nextTick(() => {    
            let bounds = undefined      
                                  
            if (this.localization) {      
              let lat = this.localization.markers.home.latitude
              let lng = this.localization.markers.home.longitude            

              if (this.localization.home && this.localization.work) {          
                lat = (this.localization.markers.home.latitude + this.localization.markers.work.latitude) / 2
                lng = (this.localization.markers.home.longitude + this.localization.markers.work.longitude) / 2     
                
                bounds = [
                  [this.localization.markers.home.longitude, this.localization.markers.home.latitude],
                  [this.localization.markers.work.longitude, this.localization.markers.work.latitude]
                ]
              } else if (this.localization.work) {
                lat = this.localization.markers.work.latitude
                lng = this.localization.markers.work.longitude
              }
              this.center = [lng, lat]
            }

            mapboxgl.accessToken = 'pk.eyJ1IjoiZnNnYXJkIiwiYSI6ImNrODVtanhmcTA3Y3YzZ3BqbXBpaXcwY2EifQ.Q9J9kR1nowA7GThkE7SCWw'
            this.map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
                center: this.center, // starting position [lng, lat]
                zoom: 14, // starting zoom
                attributionControl: false
            });      
            if (bounds !== undefined) this.map.fitBounds(bounds, {padding: {top: 100, bottom:100, left: 200, right: 200}})
            if (this.mapCenter) {
              this.center = [...this.mapCenter];
              this.map.setCenter(this.center);   
            }  

            this.map.on('load',
              function() {
                  this.get()
                  if (this.localization) {
                    this.addMarkerOnMap('home', this.localization.markers.home.longitude, this.localization.markers.home.latitude, 'home_64x64.png', ICON_SIZE)
                    this.addMarkerOnMap('work', this.localization.markers.work.longitude, this.localization.markers.work.latitude, 'work_64x64.png', ICON_SIZE)   
                  }

                  console.log(document.querySelector("#map").style.height)    
              }.bind(this))                
          })
        }
      }      
    },
    mounted() {          
      this.initMap()                    
    }   
}
</script>

<style lang="scss" scoped>
#map { position: relative; top: 0; bottom: 0; height: inherit; width: 100%; margin: 0; padding: 0}

.marker {
  display: block;
  border: none;  
  cursor: pointer;
  padding: 0;
}

.loader-bloc {
  position: absolute;  
  top: 0;
  left: 0;  
  width: 100%;  
  z-index: 100;
}
.search-bloc {
  position: absolute;  
  display: flex;
  float: left;
  width: 500px;
  z-index: 100;
}

.selection-bloc {  
  position: absolute;
  float: left;  
  top: 100px;
  left: 0;
  z-index: 200;
}

.search-result {  
  position: absolute;
  float: left;
  top: 80px;
  width: 500px;
  overflow-y: scroll;    
  max-height: calc(100% - 130px);
  z-index: 100;
}

.creation-bloc {  
  position: absolute;
  float: left;
  top: 80px;
  width: 500px;
  overflow-y: scroll;      
  z-index: 100;
}

.info-bloc {
  position: relative;
  float: right;  
  z-index: 50;
}

</style>