<template>
  <v-row align="center" justify="center">
    <v-overlay
          :absolute="true"
          color="black"
          opacity=0.6
          value="true"
          z-index=-0
        >
    
      <a-login-form/>
    </v-overlay>
  </v-row>
</template>

<script>
// @ is an alias to /src
import ALoginForm from '@/components/ALoginForm.vue'

export default {
  name: 'Login',
  components: {
    ALoginForm
  },
  mounted() {
    // This hides the address bar:
    const h = window.screen.height
    const w = window.screen.width    
    document.querySelector("#askme").style.backgroundImage = `url('${this.$functions.Resources.getUnsplashImage(w, h)}')`  
  },
  destroyed() {
    document.querySelector("#askme").style.backgroundImage = "none"
  }
}
</script>

<style lang="scss">

</style>
