<template>
  <v-container fluid>
    <a-page-header title="Dossiers" icon="flaticon-portfolio"/>       

    <v-divider/>

     <v-row>
        <v-col sm="12">
    
          <v-tabs v-model="tab">
              <v-tab href="#tab-1" @click="onClickTab('stream')">Flux</v-tab>
              <v-tab href="#tab-2" @click="onClickTab('activated')">En cours</v-tab>
              <v-tab href="#tab-3" @click="onClickTab('archived')">Terminés</v-tab>        
          </v-tabs>
          
            <v-tabs-items v-model="tab">
              
            <v-tab-item value="tab-1">
              <v-card flat>
                <v-card-text>
                  <div>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Rechercher"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </div>                
                  
                  <v-list>                    
                    <a-dossier-item v-for="item in filteredDossiers.stream" :item="item" :key="item.id" @select="onClickDossierItem"/>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
              <v-card flat>
                <v-card-text>
                    <div>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                  </div>      
                  
                  <v-row>
                    <v-col sm="2">
                      <v-switch
                        v-model="showMileage"
                        flat
                        dense
                        label="Relevé kilométrique"
                      ></v-switch>
                    </v-col>

                    <v-col sm="2">
                      <v-switch
                        v-model="showAudit"
                        flat
                        dense
                        label="Audit Vehicule"
                      ></v-switch>
                    </v-col>
                  </v-row>

                  
                  <div class="text-center mt-10" v-if="isLoading">                                      
                    <v-progress-circular
                      :size="50"
                      :width="3"                      
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <v-list v-else>
                    <a-dossier-item v-for="item in filteredDossiers.activated" 
                      :item="item" 
                      :key="item.id" 
                      :btn-recall="showActionButton"
                      :btn-delete="showActionButton"
                      @delete="deleteItem"
                      @recall="updateItem($event, item)"
                      @select="onClickDossierItem"/>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-3">
              <v-card flat>
                <v-card-text>
                    <div>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                  </div>

                  <v-row>
                    <v-col sm="2">
                      <v-switch
                        v-model="showMileage"
                        flat
                        dense
                        label="Relevés kilométriques"
                      ></v-switch>
                    </v-col>

                    <v-col sm="2">
                      <v-switch
                        v-model="showAudit"
                        flat
                        dense
                        label="Audits Vehicule"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  
                  <div class="text-center mt-10" v-if="isLoading">                                      
                    <v-progress-circular
                      :size="50"
                      :width="3"                      
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <v-list v-else>
                    <a-dossier-item v-for="item in filteredDossiers.archived" 
                      :item="item" 
                      :key="item.id"  
                      :btn-delete="showActionButton"
                      @delete="deleteItem"                     
                      @select="onClickDossierItem" 
                    />
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

        </v-col>
     </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import APageHeader from "@/components/APageHeader"
import ADossierItem from "@/components/ADossierItem"

export default {
  name: 'Dossiers',
  components: { APageHeader, ADossierItem },
  data: () => ({
    intervalId: undefined,
    interval: 10000,
    tab: 'tab-1',
    isLoading: false,
    showAudit: true,
    showMileage: true,
    dossiers: {
      stream: [],
      activated: [],
      archived: []    
    },
    filteredDossiers: {
      stream: [],
      activated: [],
      archived: []    
    },
    status: 'stream',
    search: ''
  }),
  methods: {
    onClickDossierItem: function(id) {
        this.$router.push({ 
          name: 'Dossier', 
          params: { 
            id: id
          }
        })
      },

    onClickTab: function(status) {
      if (this.status !== status) { 
        if (status !== undefined) this.status = status     
        this.search = ''
        this.showAudit = true;
        this.showMileage = true;
        this.get()
      }
    },

    // Méthodes d'accès aux données
    // ----------------------------
    // Liste des demandes de l'utilisateur
    get: function() {         
        this.isLoading = true
        let url = `dossiers/${this.status}`      
        this.$http
            .get(url)            
            .then(response => {      
                this.dossiers[this.status] = []   
                this.filteredDossiers[this.status] = []          
                if (response.status == 200) {                                      
                  this.dossiers[this.status] = response.data    
                  this.filterRequests()                                                                          
                }                            
                this.isLoading = false 
            })
            .catch(error => {                
                this.isLoading = false
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
    },

    // Méthodes fonctionnelles
    // -----------------------
    updateItem: function(newItem, item) {                  
      Object.assign(item, newItem)
    },
    deleteItem: function(id) {
      this.dossiers[this.status] = this.dossiers[this.status].filter(el => el.id !== id) 
      this.filteredDossiers[this.status] = this.filteredDossiers[this.status].filter(el => el.id !== id) 
    },
    // Filtre les demandes du status en cour en fonction du contenu de la zone de recherche
    filterRequests: function() {    
        this.filteredDossiers[this.status] = this.dossiers[this.status].filter(el =>  {  
            let filterResult = true
            if (this.search && this.search != '') {
              filterResult = `${el.recipient.lastname} ${el.recipient.firstname} ${el.recipient.work.name} ${el.recipient.work.agency ? el.recipient.work.agency.name : ''} ${el.vehicle_audit ? el.vehicle_audit.vehicle.registration : ''}  ${el.vehicle_mileage ? el.vehicle_mileage.vehicle.registration : ''}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1  
            }
            if (filterResult) {              
              if (this.showAudit && el.vehicle_audit !== undefined) filterResult = true
              else if (this.showMileage && el.vehicle_mileage !== undefined) filterResult = true              
              else filterResult = false
            }
           
            return filterResult
        })      
    }

  },
  watch: {
    showAudit: function() {      
      this.filterRequests()
    },    
    showMileage: function() {
      this.filterRequests()
    },
    search: function() {      
       this.filterRequests()
    }
  },
  computed: {
    ...mapGetters([
      'userEnvironment',
      'profile'
    ]),
    showActionButton: function() {  return this.profile.is_su || this.profile.role == this.$constants.users.USER_ROLE_MANAGER || this.profile.role == this.$constants.users.USER_ROLE_ADMIN }
  },
  mounted() {
    this.get();
    this.intervalId = setInterval(() => { 
        if (this.status == 'stream') this.get()
      }, 
      this.interval)
  },
  destroyed() {
    clearInterval(this.intervalId)
  }
}
</script>

<style scoped>

</style>