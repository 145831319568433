<template>
    <v-dialog v-model="dialog">     
            <v-card>                                         
                <v-row style="height: 80vh">
                    <v-col cols="12" style="height: 100%">
                        <a-provider-selector
                            ref="providerSelector" 
                            :localization="localization"
                            :services="services"            
                            :preferences="preferences" 
                            :vehicle="vehicle"                                                                      
                            @select="appointment = $event"                         
                        />
                    </v-col>
                </v-row>                
                <v-divider></v-divider>     
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="hide()" class="px-12">Fermer</v-btn>      
                    <v-btn color="green" @click="onClickBtnValidate" class="px-12">Valider</v-btn>               
                </v-card-actions>  
            </v-card>
        </v-dialog>
</template>

<script>
import AProviderSelector from "@/components/AProviderSelector"

export default {
    components: { AProviderSelector },
    name: 'AModalProviderSelector',
    props: {
        title: {
            type: String,
            default: "Séléctionner un prestataire"
        },
        color: {
            type: String,
            default: "blue"
        },
        autoClose: {
            type: Boolean,
            default: false
        },
        localization: {
            type: Object        
        },
        preferences: {
            type: Object        
        },
        services: {
            type: Object        
        },
        vehicle: {
            type: Object        
        }
    },
    data: () => ({
        dialog: false,      
        appointment: undefined,
        appointementToUpdate: undefined,
        isResetingSelection: false  
    }),
    methods: {
        show: function(appointment) {            
            this.dialog = true
            this.$nextTick(() => { 
                this.$refs.providerSelector.init(appointment) 
                this.$refs.providerSelector.reloadPlaces() 
            })
        },
        hide: function() {
            this.dialog = false
        },
        onClickBtnValidate: function() {        
            this.$emit('validate', this.appointment)
            this.hide()
        }  
    }
}
</script>
