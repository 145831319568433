<template>
  <v-container fluid>
      <a-page-header :title="title" icon="flaticon-user"/>   

      <v-divider/> 

      <v-row>
        <v-col sm="12">
          <v-tabs>
          <v-tab>        
            CONSTRUCTEURS
          </v-tab>
          <v-tab>         
            GARAGES
          </v-tab>
           <v-tab>         
            LOUEURS
          </v-tab>
          <v-tab>      
            PETROLIERS
          </v-tab>
          
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <a-provider-list     
                  provider-type="4"              
                  single-select             
                  @clickRow="onClickRowProvider"          
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <a-provider-list     
                  provider-type="2"              
                  single-select             
                  @clickRow="onClickRowProvider"       
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <a-provider-list     
                  provider-type="3"              
                  single-select             
                  @clickRow="onClickRowProvider"       
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <a-provider-list     
                  provider-type="1"
                  only-import
                  single-select             
                  @clickRow="onClickRowProvider"       
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
        </v-col>
      </v-row>
  </v-container>  
</template>

<script>

import APageHeader from "@/components/APageHeader"
import AProviderList from "@/components/AProviderList"

import { mapGetters } from 'vuex'

export default {
  name: 'Settings',
  components: { APageHeader, AProviderList },
  data: () => ({
    title: "Paramètres"
  }),
  methods: {
    onClickRowProvider: function(row) {
      this.$router.push({ 
        name: 'Provider', 
        params: { 
          id: row.id
        }
      })
    }
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters([
      'userEnvironment'
    ]),
  }
}
</script>

<style scoped>

</style>
