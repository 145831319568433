<template>
  <div class="text-center">
    <v-form class="login">
          <v-flex class="text-center mt-12">
            <img
                src="@/assets/images/askme.png"
            />
            <div class="caption font-weight-thin">v{{$constants.application.version}}</div>
          </v-flex>
        <v-flex class="text-center mt-12">
            <v-text-field
              v-model="userName"
              label="E-mail"
              clearable
              rounded
              filled
              background-color="#FFFFFF88"
              color="black"
            ></v-text-field>

            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              label="Password"
              rounded
              filled
              background-color="#FFFFFF88"
              color="black"
              @click:append="show = !show"
            ></v-text-field>
            <v-btn class="mt-12" outlined rounded x-large color="white" @click="onClickBtnConnect()">CONNEXION</v-btn>
            
        </v-flex>
    </v-form>
  </div>
</template>

<script>
import sha1 from 'sha1';

export default {
  name: 'ALoginForm',
  data: () => ({
    userName: '',
    password: '',
    show: true
  }),
  methods: {
    onClickBtnConnect: function () {
      let loginData = {
            environment: this.environment,
            user: this.userName,
            password: sha1(this.password),
            ip: this.$store.state.ip,
            offset: -(new Date()).getTimezoneOffset()
        }
                    
        this.$store.dispatch('login', loginData)
            .then((token) => {                                                                                                                  
                this.$root.$emit('login', token);                    
            })      
            .catch(error => {            
                console.log(error)    
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);                     
            });                        
    }
  }
}
</script>

<style scoped>
img {
  max-width: 150px;
}

.login {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}
</style>
