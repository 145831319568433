<template>  
    <v-row>
      <!-- Vidange\Révisions\Plaquettes -->
      <v-col v-if="maintenance" :md="colSize">
        <v-card elevation="0">
          <v-card-title class="subtitle-1 font-weight-medium">
            ENTRETIENS
          </v-card-title>
          <v-card-text>                 
            <v-checkbox
              v-model="makerMaintenanceEnabled"
              label="Réseaux constructeurs"
              color='primary'
              class="ml-3"
              @change="changeRecommendations"
            ></v-checkbox>                      
            <a-provider-list   
              :height="height"
              ref="aMaintenanceProvidersList"  
              provider-type="2"  
              :selected-ids="recommendations.overhaul"
              showSelect                                       
              @change="onChangeProviderMaintenance"   
              @init="providersMaintenance=$event"   
            />
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Pneumatiques -->
      <v-col v-if="tires" :md="colSize">
        <v-card elevation="0">
          <v-card-title class="subtitle-1 font-weight-medium">
            PNEUMATIQUES
          </v-card-title>
          <v-card-text>
            <v-checkbox
              v-model="makerTiresEnabled"
              label="Réseaux constructeurs"
              color='primary'
              class="ml-3"
              @change="changeRecommendations"
            ></v-checkbox>
            <a-provider-list   
              :height="height"
              ref="aTiresProvidersList"  
              provider-type="2"   
              :selected-ids="recommendations.tires"
              showSelect                                       
              @change="onChangeProviderTires"    
              @init="providersTires=$event"      
            />
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Autres -->
      <v-col v-if="misc" :md="colSize">
        <v-card elevation="0">
          <v-card-title class="subtitle-1 font-weight-medium">
            AUTRES REPARATIONS
          </v-card-title>
          <v-card-text>
            <v-checkbox
              v-model="makerMiscEnabled"
              label="Réseaux constructeurs"
              color='primary'
              class="ml-3"
              @change="changeRecommendations"
            ></v-checkbox>
            <a-provider-list   
              :height="height"
              ref="aMiscProvidersList"  
              provider-type="2"    
              :selected-ids="recommendations.misc"
              showSelect                                      
              @change="onChangeProviderMisc"     
              @init="providersMisc=$event"   
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>    
</template>

<script>
import AProviderList from "@/components/AProviderList"

export default {
  name: 'AServicesRecommendationsPanel',
  components: { AProviderList },
  props: {
    recommendations: {
      type: Object,
      default: () => ({
        overhaul: '',
        brakepads: '',
        tires: '',
        misc: ''
      })
    },
    maintenance: {
      type: Boolean,
      default: false
    },
    tires: {
      type: Boolean,
      default: false
    },
    misc: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  data: () => ({
    providersMaintenance: [],
    providersTires: [],
    providersMisc: [],

    makerMaintenanceEnabled: false,
    makerTiresEnabled: false,
    makerMiscEnabled: false,

    colSize: 12
  }),
  methods: {
    onChangeProviderMaintenance: function(event) {
      this.providersMaintenance = event
      this.changeRecommendations()
    },
    onChangeProviderTires: function(event) {
      this.providersTires = event
      this.changeRecommendations()
    },
    onChangeProviderMisc: function(event) {
      this.providersMisc = event
      this.changeRecommendations()
    },
    changeRecommendations: function() {      
      // Traitement de recommandation sur les réseaux constructeur id = -1
      let providersMaintenance = [...this.providersMaintenance]
      let providersTires = [...this.providersTires]
      let providersMisc = [...this.providersMisc]

      if (this.makerMaintenanceEnabled) providersMaintenance.push({id: -1})
      if (this.makerTiresEnabled) providersTires.push({id: -1})
      if (this.makerMiscEnabled) providersMisc.push({id: -1})
      
      // Vidanges, révisions et plaquettes sont inclus dans la maintenance 
      // La sélection des prestataires de services pour maintenance est affectée aux types de service overhaul et brakepads
      let recommendations = {
        overhaul: providersMaintenance.map(el => el.id).join(','),
        brakepads: providersMaintenance.map(el => el.id).join(','),
        tires: providersTires.map(el => el.id).join(','),
        misc: providersMisc.map(el => el.id).join(',')
      }

      this.$emit('change', recommendations)
   } 
  },
  updated() {
    if (this.recommendations !== undefined) {
      if (this.recommendations.overhaul !== undefined) this.makerMaintenanceEnabled = this.recommendations.overhaul.indexOf("-1") !== -1
      if (this.recommendations.tires !== undefined) this.makerTiresEnabled = this.recommendations.tires.indexOf("-1") !== -1
      if (this.recommendations.misc !== undefined) this.makerMiscEnabled = this.recommendations.misc.indexOf("-1") !== -1    
    }
  },
  mounted() {
    let colCount = (this.maintenance ? 1 : 0) + (this.tires ? 1 : 0) + (this.misc ? 1 : 0)
    this.colSize = 12 / colCount
    if (this.recommendations !== undefined) {
      if (this.recommendations.overhaul !== undefined) this.makerMaintenanceEnabled = this.recommendations.overhaul.indexOf("-1") !== -1
      if (this.recommendations.tires !== undefined) this.makerTiresEnabled = this.recommendations.tires.indexOf("-1") !== -1
      if (this.recommendations.misc !== undefined) this.makerMiscEnabled = this.recommendations.misc.indexOf("-1") !== -1   
    }
  }
}
</script>

<style scoped>

</style>
