import Status, { ServiceStatus, ServiceStatusCollection } from "./Status";

export const ServiceType = Object.freeze({
    Overhaul: 1,
    Tires: 2,
    Brakepads: 3,
    Misc: 4,
    TechnicalControl: 5
})

export default class Service {

    get isOverhaulService() { return false }
    get isBrakepadsService() { return false }
    get isTiresService() { return false }
    get isMiscService() { return false }
    get isTechnicalControlService() { return false }
    get label() { return 'Service' }

    constructor() {
        this.id = 0;    
        this.type = 0;
        this.status = Status.find(ServiceStatus.todo, ServiceStatusCollection);
        this.distance = undefined;
        this.realDistance = undefined;
        this.timestamp = undefined;
        this.place = undefined;
        this.vehicle = undefined;
    }

    static fromObject(object) {
        try {
            let service;
            if (object.type === ServiceType.Overhaul) service = new OverhaulService();
            else if (object.type === ServiceType.Tires) service = new TiresService();       
            else if (object.type === ServiceType.Brakepads) service = new BrakepadsService();
            else if (object.type === ServiceType.TechnicalControl) service = new TechnicalControlService();
            else service = new MiscService(); 
            
            service.id = object.id;    
            service.type = object.type;
            service.status = Status.find(object.status_code, ServiceStatusCollection);
            service.distance = object.distance;
            service.realDistance = object.real_distance;
            service.timestamp = object.timestamp;
            service.place = object.place;
            service.vehicle = object.vehicle;

            return service;
        }
        catch (error) {
            console.error(error)
        }
    }
}

export class OverhaulService {
    get isOverhaulService() { return true }
    get label() { return 'Vidange/Revision' }
}

export class BrakepadsService {
    get isBrakepadsService() { return true }
    get label() { return 'Plaquettes de freins' }
}

export class TiresService {
    get isTiresService() { return true }
    get label() { return 'Pneumatiques' }
}

export class MiscService {
    get isMiscService() { return true }
    get label() { return 'Divers/Réparations' }
}

export class TechnicalControlService {
    get isTechnicalControlService() { return true }
    get label() { return 'Contrôle technique' }
}