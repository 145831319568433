<template>
  <v-row>
    <v-col :md="12">            
      <v-form
        ref="form"
        v-model="isValid"
        lazy-validation
      >
        <v-row>
          <v-col md="12" class="subtitle-1 font-weight-medium">IDENTITE</v-col>                    
          <v-col md="12">
            <v-text-field
              v-model="place.name"                  
              :rules="[v => !!v || 'Raison sociale obligatoire']"     
              label="Raison sociale"
              required
            ></v-text-field>
          </v-col>                                           
        </v-row>  
        

        <v-row>
          <v-col md="12" class="subtitle-1 font-weight-medium">ADRESSE</v-col>        
          <v-col md="6">
            <v-text-field
              v-model="place.address1"                                    
              label="Adresse"
              required
            ></v-text-field>
          </v-col>  
          <v-col md="6">
            <v-text-field
              v-model="place.address2"                                     
              label="Complément d'adresse"
              required
            ></v-text-field>
          </v-col>                          

          <v-col md="8">
            <v-text-field
              v-model="place.city"                                      
              label="Ville"
              required
            ></v-text-field>
          </v-col>   
          <v-col md="4">
            <v-text-field
              v-model="place.zip"                                      
              label="Code postal"
              required
            ></v-text-field>
          </v-col>   
        
          <v-col md="4" class="text-right">
            <v-btn>
              <v-icon size="32" class="mt-3">flaticon-pin</v-icon>
            </v-btn>
          </v-col>  
          <v-col md="4">
            <v-text-field
              v-model="place.latitude"                                     
              label="Latitude"
              required
            ></v-text-field>
          </v-col>         
          <v-col md="4">
            <v-text-field
              v-model="place.longitude"                                      
              label="Longitude"
              required
            ></v-text-field>
          </v-col> 
        </v-row>

        <v-row>
        </v-row>
      </v-form> 
    </v-col>       
  </v-row>                
</template>

<script>

export default {
  name: 'APlaceForm',
  props: {
    place: {
      type: Object
    }
  },
  data: () => ({
    
  }),
  methods: {
    
  }
}
</script>

<style scoped>

</style>
