<template>
  <div>
    <v-container fluid>
      <a-page-header title="Comptes Clients" icon="flaticon-support"/>       

      <v-divider/>    
      
      <v-speed-dial
          v-if="profile.is_su"
          v-model="fab"    
          top
          right        
          direction="bottom"
          open-on-hover
          transition="slide-y-reverse-transition"
        >
          <template v-slot:activator>
            <v-btn
                v-model="fab"
                color="blue darken-2"
                dark
                fab
            >
              <v-icon v-if="fab">mdi-close</v-icon>
              <v-icon v-else >mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-btn
              ref="fabEvent"
              fab
              dark
              small
              color="blue"
              @click="onClickFabAdd()"
              title="Ajouter un compte client"
          >
            <v-icon size="20">mdi-plus</v-icon>
          </v-btn>
          
          <v-btn
            fab
            dark
            small
            color="red"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
      </v-speed-dial>   

      <v-row>
        <v-col sm="12">
          <a-customer-list   
            :show-select="profile.is_su"          
            @change="onChangeSelection"     
            @clickRow="onClickRow"          
          />    
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import APageHeader from "@/components/APageHeader"
import ACustomerList from "@/components/ACustomerList"


export default {
  name: 'Customers',
  components: { APageHeader, ACustomerList },
  data: () => ({
    fab: false,    
    selectedItems: []
  }),
  methods: {
    onClickFabAdd: function() {
      this.$router.push({ 
        name: 'Customer', 
        params: { 
          id: 0
        }
      })
    },  
    onChangeSelection: function(event) {
      this.selectedItems = event      
    },
    onClickRow: function(row) {      
      this.$router.push({ 
        name: 'Customer', 
        params: { 
          id: row.id
        }
      })
    }
  },
  watch: {
    
  },
  computed: {
    ...mapGetters([      
      'profile'
    ])
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;  
}

.v-btn--floating {
  position: relative;
}
</style>