<template>
    <v-dialog v-model="dialog" width="50%">     
            <v-card>                
                <v-card-title>{{title.toUpperCase()}}</v-card-title>       
                <v-card-text>     
                
                    <v-row>
                        <v-col cols="12" class="pa-3">
                            <v-textarea
                                name="input-7-1"
                                label="Message"
                                v-model="message"
                                hint="Saisissez votre message et envoyer"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                
                </v-card-text>   
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dialog = false">Fermer</v-btn>
                    <v-btn color="green" @click="onClickBtnSend()">Envoyer</v-btn>
        </v-card-actions>  
            </v-card>
        </v-dialog>
</template>

<script>
export default {
  name: 'AModalMessageMaker',
  props: {
    title: {
        type: String,
        default: "Envoyer un message"
    },
    color: {
        type: String,
        default: "blue"
    },
    autoClose: {
        type: Boolean,
        default: false
    }
  },
  data: () => ({
      dialog: false,
      message: ""
  }),
  methods: {
    show: function(message = "") {
        this.message = message
        this.dialog = true
    },
    hide: function() {
        this.dialog = false
    },
    onClickBtnSend: function() {
        this.$emit('sendMessage', this.message)
        if (this.autoClose) this.dialog = false
    }
  }
}
</script>
