<template>
  <div>
    <v-container fluid>
      <a-page-header title="Collaborateurs" icon="flaticon-user"/>    

      <v-divider/>
      
      <v-speed-dial
          v-model="fab"    
          top
          right        
          direction="bottom"
          open-on-hover
          transition="slide-y-reverse-transition"
        >
          <template v-slot:activator>
            <v-btn
                v-model="fab"
                color="blue darken-2"
                dark
                fab
            >
                <v-icon v-if="fab">mdi-close</v-icon>
                <v-icon v-else >mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          
          <v-btn
              ref="fabEvent"
              fab
              dark
              small
              color="blue"
              @click="onClickFabAdd()"
              title="Ajouter un vehicule"
          >
            <v-icon size="20">mdi-plus</v-icon>
          </v-btn>

          <v-btn            
              v-show="selectedItems.length > 0"   
              ref="fabMessage"
              fab
              dark
              small
              color="light-green"
              @click="onClickFabMessage()"
              title="Envoyer un message au conducteur actuel"
            >
            <v-icon size="20">flaticon-chat</v-icon>
          </v-btn>

          <v-btn
            v-show="selectedItems.length > 0"
            fab
            dark
            small
            color="red"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
      </v-speed-dial>   
      
      <v-row>
        <v-col sm="12">
          <a-user-list     
            download-btn
            @change="onChangeSelection"     
            @clickRow="onClickRow"          
          />    
        </v-col>
      </v-row>

      <v-row>
        <a-modal-message-maker                 
          :title="modalMessageTitle"
          auto-close
          @sendMessage="onClickBtnSendMessage($event)"
          ref="modalMessageMaker"
          />     
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import APageHeader from "@/components/APageHeader"
import AUserList from "@/components/AUserList"
import AModalMessageMaker from "@/components/AModalMessageMaker"



export default {
  name: 'Profiles',
  components: { APageHeader, AUserList, AModalMessageMaker},
  data: () => ({    
    fab: false,    
    modalMessageTitle: '',
    selectedItems: []
  }),
  methods: {
    // Méthodes évènements UI
    // ----------------------
    onClickFabAdd: function() {
      this.$router.push({ 
        name: 'Profile', 
        params: { 
          id: 0
        }
      })
    },
    onClickFabMessage: function() {
      this.modalMessageTitle = 'Envoyer un message' + (this.selectedItems.length > 1 ? ` aux ${this.selectedUsersItems.length} collaborateurs sélectionnés` : ` à ${this.selectedItems[0].firstname} ${this.selectedItems[0].lastname}`)
      this.$refs.modalMessageMaker.show()
    },
    onChangeSelection: function(event) {
      this.selectedItems = event      
    },
    onClickRow: function(row) {      
      this.$router.push({ 
        name: 'Profile', 
        params: { 
          id: row.id
        }
      })
    },
    onClickBtnSendMessage: function(event) {
      let ids = this.selectedItems.map(el => el.id).join(',')      

      let url = "messages"
        const data = {
            to: ids,
            text: event
        } 
        this.$http
          .post(url, data)            
          .then()
          .catch(error => {             
              console.log(error)                 
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
    }
  },
  watch: {
    
  },
  computed: {
    ...mapGetters([
      'userEnvironment'
    ])
  },
  mounted() {
    
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;  
}

.v-btn--floating {
  position: relative;
}
</style>