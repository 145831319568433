import Status, { DossierStatusCollection } from "./Status";

export default class Dossier {
    static get Type() { return  2 }

    get isVehicleMileage() { return false }
    get isVehicleAudit() { return false }
    
    static fromObject(object) {
        if (object.id === undefined) throw("Bad object format");
        if (object.type === undefined) throw("Bad object format");
        if (object.status === undefined) throw("Bad object format");

        let dossier;
        if (object.type === 4) {
            dossier = new VehicleMileageDossier();
            dossier.vehicle = object.vehicle_mileage.vehicle
        }
        if (object.type === 5) {
            dossier = new VehicleAuditDossier()  
            dossier.vehicle = object.vehicle_audit.vehicle
        }
         
        dossier.id = object.id;    
        dossier.type = object.type;
        dossier.status = Status.find(object.status, DossierStatusCollection);
        dossier.params = object.params        

        return dossier;
    }
}

export class VehicleMileageDossier extends Dossier {
    get isVehicleMileage() { return true }        
    
}

export class VehicleAuditDossier extends Dossier {
    get isVehicleAudit() { return true }        
}