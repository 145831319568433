<template>
    <v-container fluid>
      <a-page-header :title="title" 
        icon="flaticon-support"
        :image="modificationImage"
      />    

      <v-divider/>

      <v-row>
        <v-col sm="12">
          <v-form
            ref="form"
            v-model="isValid"
            lazy-validation
          >
            <v-row>  
              <v-col md="3">  
                <v-select
                    v-model="modification.model_id"
                    :items="models"
                    :item-text="text"
                    item-value="id"
                    :rules="[v => !!v || 'Modèle obligatoire']"     
                    label="Modèle"                  
                    required       
                    @change="getModifications()"                         
                  ></v-select>       
              </v-col>

              <v-col md="6">
                <v-text-field
                  v-model="modification.name"                  
                  :rules="[v => !!v || 'Nom obligatoire']"     
                  label="Nom"
                  required
                ></v-text-field>
              </v-col> 

              <v-col md="3">
                <v-select
                  v-model="modification.energy"                  
                  label="Energie"
                  :items="$constants.vehicleModifications.ENERGIES"
                  item-text="label"
                  item-value="id"                  
                  required
                ></v-select>
              </v-col>     
            </v-row>  

            <v-row>                                                            
              <v-col md="3">
                <v-text-field
                  v-model="modification.city_consumption"                                       
                  label="Consommation urbaine"
                 
                ></v-text-field>
              </v-col>    

              <v-col md="3">
                <v-text-field
                  v-model="modification.mixte_consumption"                                     
                  label="Consommation mixte"
                 
                ></v-text-field>
              </v-col>    

              <v-col md="3">
                <v-text-field
                  v-model="modification.highway_consumption"                                      
                  label="Consomation autoroute"                  
                ></v-text-field>
              </v-col>    

              <v-col md="3">
                <v-text-field
                  v-model="modification.co2"                                      
                  label="CO2"                  
                ></v-text-field>
              </v-col>     
            </v-row>  
          </v-form>        
        </v-col>

        <v-col md="12" class="text-right">
          <v-btn
            :disabled="!isValid"
            color="success"
            class="mr-4"
            @click="onClickBtnValidate"
          >
            Valider
          </v-btn>     
        </v-col> 
      </v-row>    

      <v-row>
       <v-col sm="12">
        <v-tabs v-model="tab">                                                  
          <v-tab v-if="!this.isNew" href="#tab-1">FINITIONS</v-tab>                 
          <v-tab-item value="tab-1">
            <v-card flat>
              <v-card-text>
                <a-vehicle-line-list     
                  :maker-id="modification.maker_id"
                  :model-id="modification.model_id"
                  :modification-id="$route.params.id"              
                  single-select        
                  add-btn     
                  @clickRow="true"    
                  :key="update"       
                />
              </v-card-text>
            </v-card>
          </v-tab-item>  
        </v-tabs>  
       </v-col>
      </v-row>
        
    </v-container>
    
  
</template>

<script>
import { mapGetters } from 'vuex'

import APageHeader from "@/components/APageHeader"
import AVehicleLineList from "@/components/AVehicleLineList"

export default {
  name: 'Modification',  
  components: { APageHeader, AVehicleLineList },
  data: () => ({   
    isNew: true,
    isValid: true,  
    tab: '',
    models: [],
    modification: {},
    modelImage: "",
    customers: [],        
    lines: [],    
    update: (new Date()).getTime()
  }),
  methods: {
    onClickBtnValidate: function() {
      let makerId = this.$route.params.maker === undefined ? 0 : this.$route.params.maker
      let modelId = this.$route.params.model === undefined ? 0 : this.$route.params.model
      let url = `vehiclemodifications/${makerId}/${modelId}/${this.modification.id}`
      if (this.modification.id == 0) {
        this.$http.post(url, this.modification)   
          .then(response => {
            if (response.status == 200) {
              this.modification = response.data
              this.isNew = false
              this.update = (new Date()).getTime()
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
      else {
        this.$http.put(url, this.modification)   
          .then(response => {
            if (response.status == 200) {
              this.modification = response.data
              this.update = (new Date()).getTime()
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
    },
    // Méthodes d'accès aux données
    // ----------------------------
    // Données de la modification
    get: function() {    
        if (this.$route.params.id !== 0) { 
          this.isLoading = true
          let url = `vehiclemodifications/0/0/${this.$route.params.id}`
          this.$http
              .get(url)            
              .then(response => {                        
                  if (response.status == 200) {                    
                    this.modification = response.data                                                                         
                    this.modificationImage = this.modification.image != "" ? `${this.$constants.application.resourcesURL}/images/${this.modification.image}` : ""  
                    this.getModels(this.modification.maker_id)                                      
                  }                                          
                  this.isLoading = false 
              })
              .catch(error => {                
                  this.isLoading = false
                  if (error.response.status === 401) this.$root.$emit("logout"); 
                  else this.$root.$emit("serverError", error);     
              });                                  
        }        
    },
    // Liste des modèles
    getModels: function(makerId) {
      if (makerId === undefined) this.models = []
      else {
        let url = `vehiclemodels/${makerId}`
        this.$http
            .get(url)            
            .then(response => {                        
                if (response.status == 200) {
                  this.models = response.data.map(el => { return {...el, text: `${el.name} (${el.generation})`} })                  
                }              
            })
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            })
      }
    },
  },
  computed: {
    ...mapGetters([
      'userEnvironment'
    ]),
    title: function() {
      let prefix = (this.modification.maker === undefined ? '' : this.modification.maker.name + ' ') + (this.modification.model === undefined ? '' : this.modification.model.name + ' ')
      return prefix + (this.modification.id == 0 
        ? "Nouvelle motorisation" 
        : this.modification.name) + (this.modification.model === undefined ? '' : ' [' + this.modification.model.generation + ']')
    }  
  },
  mounted() {       
    this.isNew = this.$route.params.id == 0    
    if (!this.isNew) {
      this.get()            
    }
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;  
}

.v-btn--floating {
  position: relative;
}
</style>