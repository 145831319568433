<template>
    <v-row>
       <v-col>
           <v-chip
            :small="!xSmall"
            :x-small="xSmall"
            class="white--text mr-2"            
            :color="$constants.colors.STATUS_NEW"
            @click.stop="onClickStatus($constants.status.STATUS_CREATE)"
            >Nouveau</v-chip>

            <v-chip
            :small="!xSmall"
            :x-small="xSmall"
            class="white--text mr-2"
            :color="$constants.colors.STATUS_RECEIVED"
            @click.stop="onClickStatus($constants.status.STATUS_RECEIVED)"
            >Reçu</v-chip>

            <v-chip
            :small="!xSmall"
            :x-small="xSmall"
            class="white--text mr-2"
            :color="$constants.colors.STATUS_PROCESSING"
            @click.stop="onClickStatus($constants.status.STATUS_PROCESSING)"
            >En cours</v-chip>

            <v-chip
            :small="!xSmall"
            :x-small="xSmall"
            class="white--text mr-2"
            :color="$constants.colors.STATUS_WAITING"
            @click.stop="onClickStatus($constants.status.STATUS_STANDBY)"
            >En attente</v-chip>

            <v-chip
            :small="!xSmall"
            :x-small="xSmall"
            class="white--text mr-2"
            :color="$constants.colors.STATUS_WAITING_INFOS"
            @click.stop="onClickStatus($constants.status.STATUS_WAITING_USER_INFOS)"
            >En attente d'informations utilisateur</v-chip>

            <v-chip
            :small="!xSmall"
            :x-small="xSmall"
            class="white--text mr-2"
            :color="$constants.colors.STATUS_WAINTING_PROVIDER_INFOS"
            @click.stop="onClickStatus($constants.status.STATUS_WAITING_PROVIDER_INFOS)"
            >En attente d'informations fournisseur</v-chip>

            <v-chip
            :small="!xSmall"
            :x-small="xSmall"
            class="white--text mr-2"
            :color="$constants.colors.STATUS_COMPLETED"
            @click.stop="onClickStatus($constants.status.STATUS_COMPLETED)"
            >Terminé</v-chip>

            <v-chip
            :small="!xSmall"
            :x-small="xSmall"
            class="white--text mr-2"
            :color="$constants.colors.STATUS_REFUSED"
            @click.stop="onClickStatus($constants.status.STATUS_REFUSED)"
            >Refusée</v-chip>

            <v-chip
            :small="!xSmall"
            :x-small="xSmall"
            class="white--text mr-2"
            :color="$constants.colors.STATUS_CANCELED"
            @click.stop="onClickStatus($constants.status.STATUS_CANCELED)"
            >Annulée</v-chip>

            <v-chip
            :small="!xSmall"
            :x-small="xSmall"
            class="white--text mr-2"
            :color="$constants.colors.STATUS_ARCHIVED"
            @click.stop="onClickStatus($constants.status.STATUS_ARCHIVED)"
            >Archivée</v-chip>
       </v-col>
    </v-row>
</template>

<script>
export default {
  name: 'AStatusChanger',
  props: {        
    xSmall: {
      type: Boolean,
      value: true
    },
  },
  data: () => ({
   
  }),
  methods: {
    onClickStatus: function(status) {
      this.$emit("change", status)
    }
  }
}
</script>
