<template>
  <div>
    <v-card >
      <v-card-title class="pb-0">
        {{title}}
        <v-btn v-if="iconPencil" icon color="blue" class="ml-2" @click="formMode = !formMode"><v-icon size=16>mdi-pencil</v-icon></v-btn>
      </v-card-title>
      <v-card-text v-if="formMode" @drop.prevent="addDropFile" @dragover.prevent>
        <v-row>
            <v-col md="3" sm="6">
            <v-text-field
                v-model="quotationData.number"                                      
                label="N° Devis"
            ></v-text-field>
            </v-col>

            <v-col md="3" sm="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="quotationData.date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field                                                
                        :value="quotation.date | moment('DD/MM/YYYY')"
                        label="Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="quotation.date"
                    no-title
                    scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                        Annuler
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(quotationData.date)"
                    >
                        Ok
                    </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>

            <v-col md="3" sm="6">                    
            <v-text-field
                append-icon="mdi-currency-eur"
                v-model="quotationData.amount" 
                :rules="[v => v && !isNumber(v) || 'Montant doit être numérique']"
                label="Montant HT"
            ></v-text-field>
            </v-col>                  

            <v-col md="3" sm="6">
            <v-text-field
                v-model="quotationData.agreement_number"                                      
                label="N° d'accord"
            ></v-text-field>
            </v-col>                       
        </v-row>    
        <v-row>
            <v-col md="12">
                <v-textarea
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Commentaires"
                    v-model="quotationData.comments"
                    filled
                    auto-grow
                    rows="3"
                ></v-textarea>
            </v-col>                   
        </v-row>   
      </v-card-text>
      <v-card-text v-else>
        <v-row v-if="quotationData.id !== undefined && quotationData.id !== 0">          
           <v-col md="4">
            <div class="caption">N° de devis</div>
            <div class="body-1 black--text font-weight-bold mb-2">{{quotationData.number}}</div>            
            <div class="caption">N° d'accord</div>
            <div class="body-1 black--text font-weight-bold mb-2">{{quotationData.agreement_number}}</div>            
          </v-col>
          <v-col md="4">            
            <div class="caption">Date</div>
            <div class="body-1 black--text font-weight-bold mb-2">{{dateFormatted}}</div>            
            <div class="body-1 black--text font-italic">{{quotationData.comments}}</div>
          </v-col>
          <v-col md="4" class="display-2 text-right">
            <div>{{ quotationData.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} &euro;</div>
            <div>
              <v-btn
                v-show="quotationData.file !== undefined && quotationData.file !== ''"
                text                    
                color="primary"                    
                :href="`${$constants.application.defaultURL}api/app/download/quotation/${quotationData.file}?inline`"
                target="_blank"
            >
                VOIR LE DEVIS
            </v-btn>
            </div>
          </v-col>    
        </v-row>
      </v-card-text>
      <v-card-actions v-show="formMode" @drop.prevent="addDropFile" @dragover.prevent>                
          <v-btn
              v-show="quotationData.file !== undefined && quotationData.file !== ''"
              text                    
              color="secondary"                    
              @click="onClickBtnDeleteQuotationFile"
          >
              SUPPRIMER LE FICHIER
          </v-btn>          
          <v-btn
              v-show="quotationData.file !== undefined && quotationData.file !== ''"
              text                    
              color="primary"                    
              :href="`${$constants.application.defaultURL}api/app/download/quotation/${quotationData.file}?inline`"
              target="_blank"
          >
              VOIR LE DEVIS
          </v-btn>
          
          <v-file-input                
              v-show="quotationData.file === undefined || quotationData.file === ''"
              small-chips
              v-model="quotationfile"
              accept="image/*,.pdf"    
              truncate-length=100      
              @change="onChangeQuotationFile"                                             
          ></v-file-input>
          <v-spacer></v-spacer>
          <v-btn              
              text
              color="secondary"
              @click="onClickDeleteQuotation"
          >
              SUPPRIMER
          </v-btn>
          <v-btn              
              text
              color="primary"
              @click="onClickBtnSaveQuotation"
          >
              SAUVEGARDER
          </v-btn>                       
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {    
    name: "AQuotation",
    props: {              
        quotation: Object,
        request: Number,
        dossier: Number,
        vehicle: Number,
        user: Number,
        customer: Number,
        title: {
          type: String,
          default: ""
        },
        iconPencil: {
          type: Boolean,
          default: false
        },      
        small: {
          type: Boolean,
          default: false
        },
        validateButton: {
          type: Boolean,
          default: false
        },
        form: {
          type: Boolean,
          default: false
        }            
      },
    data() {
      return {
        date: undefined,
        time: undefined,
        showDateTimeSelector: false,             
        quotationData: {...this.quotation},    
        quotationfile: undefined,            
        formMode: this.form,
        menu: false
      }
    },
    watch: {
      showDateTimeSelector: function() {        
        if (this.showDateTimeSelector) this.showProviders = false
      },
      showProviders: function() {
        if (this.showProviders) this.showDateTimeSelector = false
      }
    },
    methods: {
      // Méthodes d'évènements
      // ---------------------
      addDropFile(e) { 
        this.quotationfile = e.dataTransfer.files[0]; 
      },
      onClickBtnSaveQuotation: function() {        
        const formData = new FormData()      
        formData.append('id', this.quotationData.id)                               
        formData.append('request_id', typeof this.request == 'number' ? this.request : this.request.id)
        formData.append('vehicle_id', typeof this.vehicle == 'number' ? this.vehicle : this.vehicle.id)
        formData.append('user_id', typeof this.user == 'number' ? this.user : this.user.id)
        formData.append('customer_id', typeof this.customer == 'number' ? this.customer : this.customer.id)
        formData.append('agreement_number', this.quotationData.agreement_number)
        formData.append('amount', this.quotationData.amount)
        formData.append('date', this.quotationData.date ? moment(this.quotationData.date).format('YYYY-MM-DD') : '')
        formData.append('number', this.quotationData.number)
        formData.append('comments', this.quotationData.comments ? this.quotationData.comments : '')
        if (this.quotationfile !== undefined) formData.append('file', this.quotationfile, this.quotationfile.name)
        
        let url = `quotations/${this.quotationData.id}`      
        this.$http
            .post(url, formData)            
            .then(response => {  
                if (response.status == 200) {
                    this.quotationData = response.data                                                                                
                    this.formMode = false
                    this.$emit('save', this.quotationData)
                }
            })
            .catch(error => {                              
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });           
      },
      onClickBtnDeleteQuotationFile: function() {  
        let url = `quotations/${this.quotationData.id}`      
        this.$http
          .post(url, {id: this.quotationData.id, remove_file: true})  
          .then(response => {  
                  if (response.status == 200) {
                      this.quotationData = response.data  
                      this.quotationfile = undefined
                      this.$emit('deletefile', this.quotationData)                                                                             
                  }
              })
              .catch(error => {                              
                  if (error.response.status === 401) this.$root.$emit("logout"); 
                  else this.$root.$emit("serverError", error);     
              });             
        
      },
      onClickDeleteQuotation: function() {
        let url = `quotations/${this.quotationData.id}`      
        this.$http
          .delete(url, {id: this.quotationData.id, remove_file: true})  
          .then(response => {  
              if (response.status == 200) {
                  this.formMode = false
                  this.$emit('delete', this.quotationData)         
                  this.quotationData = {}                                                                    
              }
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });    
      },
      onChangeQuotationFile: function(file) {        
        this.quotationfile = file     
      },
    },
    computed: {
      dateFormatted() {
        return this.quotationData.date !== undefined ? moment(this.quotationData.date).format('DD/MM/YYYY') : ''
      }
    }
}
</script>

<style lang="scss" scoped>
  
</style>