<template>
    <v-dialog
        v-model="dialog"
        max-width="60%"    
        max-height="60%"    
      >        
        <v-card>
          <v-card-title>{{title}}</v-card-title>
          <v-card-text>
            <a-user-list 
                :single-select="singleSelect" 
                :customer="customer"
                ref="aUserList"
                @itemSelected="onItemSelected"
            />
          </v-card-text>
          <v-card-actions class="text-right">
            <v-spacer></v-spacer>
            <v-btn
                  :disabled="!isValid"
                  color="primary"
                  class="mr-4"     
                  @click="onClickBtnValidate"             
                >
                  Valider
            </v-btn>     
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
import AUserList from "@/components/AUserList"

export default {
    components: { AUserList },
    name: 'AModalUserSelector',
    props: {
        title: {
            type: String,
            default: "COLLABORATEURS"
        },
        singleSelect: {
            type: Boolean,
            default: false
        },
        customer: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        dialog: false, 
        isValid: false     
    }),
    methods: {
        show: function() {            
            this.dialog = true            
        },
        hide: function() {
            this.dialog = false
        },
        onClickBtnValidate: function() {        
            this.$emit('validate', this.$refs.aUserList.getSelectedItems())
            this.hide()
        },
        onItemSelected: function() {
            this.$nextTick(() => {
                this.isValid = this.$refs.aUserList.getSelectedItems().length > 0
            })
        }
    }
}
</script>
