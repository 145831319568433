<template>   
    <v-lazy        
        :options="{
        threshold: 1
        }"        
        transition="fade-transition"
    >
    <div>
    <v-list-item @click="onListItemClick()">        
        <v-list-item-avatar :color="dossier.status.color">
          <v-icon v-if="dossier.isVehicleAudit" class="white--text">flaticon-front-car</v-icon>
          <v-icon v-if="dossier.isVehicleMileage" class="white--text">flaticon-speed</v-icon>          
        </v-list-item-avatar>

        <v-list-item-content>          
            <v-list-item-title class="font-weight-medium">
                {{title}}
                <v-chip :color="dossier.status.color" x-small class="white--text"
                    @click.stop="isUpdatingStatus = !isUpdatingStatus" 
                    @mouseenter="openStatusChanger()"
                >
                    {{ dossier.status.label }}
                    <v-icon size=10 class="ml-1">{{ isUpdatingStatus ? 'mdi-close' : 'mdi-pencil' }}</v-icon>
                </v-chip>                                                            
            </v-list-item-title>

            <v-row class="py-0 my-0">
                <v-col md="12" class="py-0 my-0">
                    <v-expand-transition>
                        <a-status-changer v-show="isUpdatingStatus" x-small @change="onChangeStatus($event)" />                                                
                    </v-expand-transition>    
                </v-col>
            </v-row>
        
            <v-row class="mt-1">   
                <v-col sm="4" class="ma-0 py-1">                                                                     
                    <div><v-icon size="12">flaticon-user</v-icon> {{item.recipient.firstname}} {{item.recipient.lastname}}</div>
                    <div class="caption light-blue--text text--darken-1 mt-1" >Crée le {{item.create_date}}</div>    
                    <div class="caption light-blue--text text--darken-1" >Status au {{item.status_date}}</div>                   
                </v-col>
                <v-col sm="4" class="ma-0 py-1">                    
                    <div class="d-flex">                            
                        <v-avatar size="30" tile class="align-self-start mx-2">
                            <v-img :src="logo"></v-img>
                        </v-avatar>
                        <div class="align-self-start mx-2">                 
                            <div>{{dossier.vehicle.model.brand}} {{dossier.vehicle.model.model}}</div>
                            <div><v-icon size="12" class="mr-2">flaticon-car-1</v-icon> {{dossier.vehicle.registration}}</div>    
                            <div><v-icon size="12" class="mr-2">flaticon-speed</v-icon> {{dossier.vehicle.distance}} {{dossier.vehicle.distance_unit}}</div>           
                        </div>                                                     
                    </div>          
                </v-col>
                <v-col sm="4" class="ma-0 py-1">
                    <v-icon size="12" class="mr-2">flaticon-support</v-icon> {{item.recipient.work.name}}</v-col>
            </v-row>             
            
            <v-progress-linear
                v-show="onDeleting"
                indeterminate
                color="red"
            ></v-progress-linear>      

        </v-list-item-content>

        <v-list-item-action>
            <v-card flat>
                <v-badge
                    v-show="item.activity.messages !== undefined"
                    color="blue darken-1"  
                    overlap  
                    class="ml-4"                      
                >
                    <span slot="badge">{{item.activity.messages}}</span>
                    <v-icon large>flaticon-consulting-message</v-icon>
                </v-badge>

                <v-badge
                    v-show="item.activity.appointments_refused !== undefined"
                    color="red darken-1"  
                    overlap   
                    class="ml-4"                    
                >
                    <span slot="badge">{{item.activity.appointments_refused}}</span>
                    <v-icon large>flaticon-event-1</v-icon>
                </v-badge>

                <v-badge
                    v-show="item.activity.appointments_confirmed !== undefined"
                    color="green darken-1"  
                    overlap     
                    class="ml-4"                   
                >
                    <span slot="badge">{{item.activity.appointments_confirmed}}</span>
                    <v-icon large>flaticon-event-2</v-icon>
                </v-badge>
            </v-card>

            <v-slide-x-reverse-transition hide-on-leave>  
                <div v-if="btnRecall && !confirmDelete">
                    <div class="mb-5">
                        <v-btn                            
                            elevation=0
                            fab
                            dark
                            x-small
                            color="indigo"    
                            @click.stop="onClickFabRecall"  
                        >
                            <v-icon size="20">flaticon-update-arrow</v-icon>
                        </v-btn>
                    </div>

                    <div>
                        <v-btn                            
                            elevation=0
                            fab
                            dark
                            x-small
                            color="red"
                            @click.stop="confirmDelete = true"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </div> 
            </v-slide-x-reverse-transition>

            <v-slide-x-reverse-transition hide-on-leave>   
                <div v-if="btnDelete && confirmDelete">
                    <div class="mb-5">
                        <v-btn                
                            elevation=0
                            fab
                            x-small
                            dark                
                            color="green"
                            @click.stop="onClickFabConfirmDelete"
                        >
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </div>

                    <div>
                        <v-btn                            
                            elevation=0
                            fab
                            x-small
                            dark                
                            color="red"
                            @click.stop="confirmDelete = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-slide-x-reverse-transition>
                                    
        </v-list-item-action>   
        
      </v-list-item>
      <v-divider inset></v-divider>
    </div>
</v-lazy>
</template>

<script>
import Status, { DossierStatusCollection } from '../domain/Status';
import Dossier from "@/domain/Dossier.js";
import AStatusChanger from './AStatusChanger.vue';

export default {    
    name: "ADossierItem",
    components: { AStatusChanger },
    props: {
        item: Object,
        btnRecall: {
            type: Boolean,
            default: false
        },
        btnDelete: {
            type: Boolean,
            default: false    
        }
    },
    data () {
        return {           
            status: '',  
            dossier: Dossier.fromObject(this.item),   
            confirmDelete: false,
            onDeleting: false,
            isUpdatingStatus: false,            
        }
    },
    computed: {
        logo: function() {
            try {
                return `${this.$constants.application.resourcesURL}/images/vehicles/${this.vehicle.model.brand.toLowerCase()}/logo.png`;
            }
            catch (error) {
                return '';
            }
        },
        title: function() {            
            if (this.dossier.isVehicleMileage) return "Relevé kilométrique";
            return "Audit véhicule";
        }
    },
    methods: {
        onListItemClick: function() {            
            this.$emit('select', this.dossier.id)
        },    
        onClickFabConfirmDelete: function() {     
            this.onDeleting = true       
            let url = `dossiers?id=${this.dossier.id}`      
            this.$http
                .delete(url)            
                .then(() => { 
                    this.onDeleting = false
                    this.$emit('delete', this.dossier.id)                                                                    
                })
                .catch(error => {                              
                    if (error.response.status === 401) this.$root.$emit("logout"); 
                    else this.$root.$emit("serverError", error);     
                });  
            
        },
        onClickFabRecall: function() {                               
            let url = `dossiers/${this.dossier.id}/recalled`
            this.$http
                .put(url)            
                .then(response => {                        
                    if (response.status == 200) {
                        this.$emit('recall', response.data)                       
                    }                                          
                })
                .catch(error => {                                     
                    if (error.response.status === 401) this.$root.$emit("logout"); 
                    else this.$root.$emit("serverError", error);     
                });                
        },
        onChangeStatus: function(status) {
            this.isUpdatingStatus = false;
            this.dossier.status = Status.find(status, DossierStatusCollection);            

            let url = `dossiers/${this.dossier.id}/${this.dossier.status.code}`            
            
            this.$http
                .put(url)            
                .then(() => {                                                                                 
                })
                .catch(error => {                                     
                    if (error.response.status === 401) this.$root.$emit("logout"); 
                    else this.$root.$emit("serverError", error);     
                });   
            
        },
        openStatusChanger: function() {
            this.isUpdatingStatus = true;
            setTimeout(() => { if (this.isUpdatingStatus) this.isUpdatingStatus = false }, 10000);
        } 
    }
}
</script>

<style lang="scss" scoped>

</style>