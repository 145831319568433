import Status, { RequestStatusCollection } from "./Status";

export default class Request {
    static get Type() { return  1 }

    get isAppointment() { return false}
    get isAssistance() { return false}
    
    static fromObject(object) {
        if (!object.id === undefined) throw("Bad object format");
        if (!object.type === undefined) throw("Bad object format");
        if (!object.status === undefined) throw("Bad object format");

        let request;
        if (object.type === 1) request = new AppointmentRequest();
        if (object.type === 2) request = new AssistanceRequest()        
         
        request.id = object.id;    
        request.type = object.type;
        request.status = Status.find(object.status, RequestStatusCollection);
        request.params = object.params

        return request;
    }
}

export class AppointmentRequest extends Request {
    get isAppointment() { return true }        
}

export class AssistanceRequest extends Request {
    get isAssistance() { return true } 
    get isCrashAssistance() { return this.params.type === 1 }
    get isBreakdownAssistance() {return this.params.type === 2 }
    get isGlassBreakageAssistance() { return this.params.type === 3 }   
    get isTiresAssistance() { return this.params.type === 4 }
    get isTheftAssistance() { return this.params.type === 5 }
    get isMiscAssistance() { return this.params.type === 99 }
}