<template>
    <v-list-item class="grow">      
      <v-list-item-content>
        <v-list-item-title class="title">{{barTitle}}</v-list-item-title>
      </v-list-item-content>

      <v-row
        align="center"
        justify="end"
      >
      
        <v-icon class="mr-1">flaticon-camera-1</v-icon>
        <span class="subheading mr-2">{{count}}</span>        
        <v-btn v-show="showCloseBtn" @click="$emit('close')">Fermer</v-btn>
      </v-row>
    </v-list-item>
</template>

<script>
 
export default {
    name: "APhotoGalleryActionBar",
    props: {
      barTitle: {
        type: String,
        default: "Photos"
      },
      count: {
        type: Number,
        default: 0
      },
      showCloseBtn: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {      
      }    
    }
}
</script>

<style lang="scss" scoped>
 
</style>