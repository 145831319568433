<template>
  
    <v-container v-if="showDashboard" fluid >
      <a-page-header :title="title" icon="flaticon-dashboard" icon-filter @filter="showFilter = !showFilter"/>    

      <v-divider />

      <v-row v-show="showFilter">
        <v-col sm="12">
          <a-customer-list                 
            single-select                 
            @clickRow="onClickRowCustomer"          
          />    
        </v-col>

        <v-col md="12" class="d-flex justify-end">
          <v-btn fab color="error" @click="onClickCancelFilter"><v-icon>mdi-filter-remove</v-icon></v-btn>
        </v-col>

        <v-divider />

      </v-row>

      <v-row>
        <v-col sm="12">
          <a-page-header v-if="currentCustomer !== undefined" :title="currentCustomer.name" 
            icon="flaticon-support"
            :image="currentCustomer.image"
          />  
        </v-col>
      </v-row>

       <v-row>
         <v-col md="3">
          <v-card elevation="2" >
            <v-card-title>Vehicules en activité</v-card-title>
            <v-card-subtitle>Nombre de véhicules en parc</v-card-subtitle>
            <v-card-text>
              <div class="text-right display-2">{{kpis.count_vehicles}}</div>                                           
            </v-card-text>
          </v-card>
        </v-col>

        <v-col md="3">
          <v-card elevation="2" >
            <v-card-title>Collaborateurs</v-card-title>
            <v-card-subtitle>Total | Avec véhicule | Utilisateur AskMe</v-card-subtitle>
            <v-card-text>
              <div class="text-right display-2">                
                {{kpis.count_users}} | {{kpis.count_users_with_vehicle}} | {{kpis.count_users_using_app}}
              </div>
            </v-card-text>
          </v-card>
        </v-col>

          <v-col md="3">
          <v-card elevation="2" >
            <v-card-title>Demandes en cours</v-card-title>
            <v-card-subtitle>Demandes en traitement</v-card-subtitle>
            <v-card-text>
              <div class="text-right display-2">{{kpis.count_processing_requests}}</div>
            </v-card-text>
          </v-card>
        </v-col>

          <v-col md="3">
          <v-card elevation="2" >
            <v-card-title>Dossiers en cours</v-card-title>
            <v-card-subtitle>Dossiers en traitement</v-card-subtitle>
            <v-card-text>
              <div class="text-right display-2">{{kpis.count_processing_dossiers}}</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="2" >
            <v-card-title>Demandes</v-card-title>
            <v-card-text>     
              <v-row>
                <v-col md="4">                        
                  <highcharts :options="chartProduction1Options" :updateArgs="updateArgs" />         
                </v-col>

                <v-col md="4">
                  <highcharts :options="chartProductionAssistanceOptions" :updateArgs="updateArgs" />
                </v-col>

                <v-col md="4">
                  <highcharts :options="chartProductionAppointmentOptions" :updateArgs="updateArgs" />
                </v-col>
              </v-row>                                  
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>     

      <v-row>
        <v-col md="12">
          <v-card elevation="2" >
            <v-card-title>Carburant</v-card-title>
            <v-card-text>     
              <v-row>
                <v-col md="6">                        
                  <highcharts :options="chartMonthlyFuelIntakesOptions" :updateArgs="updateArgs" />         
                </v-col>

                <v-col md="6">
                  <highcharts :options="chartConsumptionDistributionOptions" :updateArgs="updateArgs" />
                </v-col>
              </v-row>                                  
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>     

      <v-row>
        <v-col md="12">
          <v-card elevation="2" >
            <v-card-title>Utilisation des vehicules</v-card-title>
            <v-card-text>
              <div class="text-right display-2">{{kpis.count_vehicles}}</div>              
              <v-row>
                <v-col md="6">
                  <highcharts :options="chartAnnualDistancesDistributionOptions" :updateArgs="updateArgs" />
                </v-col>

                <v-col md="6">
                  <highcharts :options="chartRollingLawDistributionOptions" :updateArgs="updateArgs" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>          

      <v-row>
        <v-col md="3">          
          <v-card elevation="2" >
            <v-card-title>Renouvellement à 9 mois</v-card-title>
            <v-card-subtitle>Contrat non cloturé à échéance à 12 mois | dont engagés</v-card-subtitle>
            <v-card-text>
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="kpis.count_leases_renewed_in_9_months * 100 / kpis.count_leases_expired_in_9_months"
                  color="#85e8c3"
                >
                  <span class="title">{{ Math.round(kpis.count_leases_renewed_in_9_months * 100 / kpis.count_leases_expired_in_9_months) }}%</span>
                </v-progress-circular>
                <div class="text-right display-2">{{kpis.count_leases_expired_in_9_months}} | {{kpis.count_leases_renewed_in_9_months}}</div>
              </div>
            </v-card-text>
          </v-card>        
        </v-col>
        <v-col md="3">          
          <v-card elevation="2" >
            <v-card-title>Renouvellement à 6 mois</v-card-title>
            <v-card-subtitle>Contrat non cloturé à échéance à 6 mois | dont engagés</v-card-subtitle>
            <v-card-text>
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="kpis.count_leases_renewed_in_6_months * 100 / kpis.count_leases_expired_in_6_months"
                  color="#85e8c3"
                >
                  <span class="title">{{ Math.round(kpis.count_leases_renewed_in_6_months * 100 / kpis.count_leases_expired_in_6_months) }}%</span>
                </v-progress-circular>
                <div class="text-right display-2">{{kpis.count_leases_expired_in_6_months}} | {{kpis.count_leases_renewed_in_6_months}}</div>              
              </div>
            </v-card-text>
          </v-card>        
        </v-col>
        <v-col md="3">          
          <v-card elevation="2" >
            <v-card-title>Renouvellement à 3 mois</v-card-title>
            <v-card-subtitle>Contrat non cloturé à échéance à 3 mois | dont engagés</v-card-subtitle>
            <v-card-text>    
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="kpis.count_leases_renewed_in_3_months * 100 / kpis.count_leases_expired_in_3_months"
                  color="#85e8c3"
                >
                  <span class="title">{{ Math.round(kpis.count_leases_renewed_in_3_months * 100 / kpis.count_leases_expired_in_3_months) }}%</span>
                </v-progress-circular>          
                <div class="text-right display-2">{{kpis.count_leases_expired_in_3_months}} | {{kpis.count_leases_renewed_in_3_months}}</div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>      
        <v-col md="3">          
          <v-card elevation="2" >
            <v-card-title>Renouvellement à 1 mois</v-card-title>
            <v-card-subtitle>Contrat non cloturé à échéance à 1 mois | dont engagés</v-card-subtitle>
            <v-card-text>
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="kpis.count_leases_renewed_in_1_months * 100 / kpis.count_leases_expired_in_1_months"
                  color="#85e8c3"
                  
                >
                  <span class="title">{{ Math.round(kpis.count_leases_renewed_in_1_months * 100 / kpis.count_leases_expired_in_1_months) }}%</span>
                </v-progress-circular>
                <div class="text-right display-2">{{kpis.count_leases_expired_in_1_months}} | {{kpis.count_leases_renewed_in_1_months}}</div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>    
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="2" >
              <v-card-title>Rotation du parc</v-card-title>
              <v-card-text>  
                <v-row>              
                  <v-col md="6">
                    <highcharts :options="chartAgesDistributionOptions" :updateArgs="updateArgs" />
                  </v-col>
                  <v-col md="6">
                    <highcharts :options="chartRenewalsDistributionOptions" :updateArgs="updateArgs" />
                  </v-col>
                </v-row>
              </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="2" >
            <v-card-title>Vehicules en activité</v-card-title>
            <v-card-text>
              <div class="text-right display-2">{{kpis.count_vehicles}}</div>                             
              <highcharts :options="chartMakersDistributionOptions" :updateArgs="updateArgs" />
              <highcharts :options="chartModelsDistributionOptions" :updateArgs="updateArgs" />
              <highcharts :options="chartDistancesDistributionOptions" :updateArgs="updateArgs" />   

              
              <v-row>              
                <v-col md="6">
                  <highcharts :options="chartEnergiesDistributionOptions" :updateArgs="updateArgs" />
                </v-col>
                <v-col md="6">
                  <highcharts :options="chartCO2DistributionOptions" :updateArgs="updateArgs" />
                </v-col>
              </v-row>
                                        
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>                 
     
    </v-container>
  
</template>

<script>
import APageHeader from "@/components/APageHeader"
import ACustomerList from "@/components/ACustomerList"
import { mapGetters } from 'vuex'


export default {
  name: 'Dashboard',
  components: { APageHeader, ACustomerList },
  data: () => ({
    title: "Tableau de bord",
    showDashboard: false,
    timerId: undefined,
    timer: 60000,    
    kpis: {},
    showFilter: false,
    currentCustomer: undefined,
    updateArgs: [true, true, {duration: 1000}],
    chartColumnOptions: {
      colors: ['#85e8c3', '#081e2a', "#7cb5ec", "#f45b5b", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f"],
      chart: {        
          height: '500px',     
          backgroundColor: 'transparent'   
      },
      title: {
          text: ''
      },
      subtitle: {
          text: ''
      },
      xAxis: {
          categories: [            
          ],
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: ''
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y} {series.unit}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: []
    },
    chartMakersDistributionOptions: {},
    chartModelsDistributionOptions: {},
    chartDistancesDistributionOptions: {},
    chartAnnualDistancesDistributionOptions: {},
    chartRollingLawDistributionOptions: {},
    chartAgesDistributionOptions: {},
    chartConsumptionDistributionOptions: {},
    chartRenewalsDistributionOptions: {},
    chartMonthlyFuelIntakesOptions: {},
    chartEnergiesDistributionOptions: {},
    chartCO2DistributionOptions: {},
    chartProduction1Options: {},
    chartProductionAppointmentOptions: {},
    chartProductionAssistanceOptions: {}
  }),
  methods: {  
    onClickRowCustomer: function(customer) {
      localStorage.setItem('askme.dashboard.customer', JSON.stringify(customer))
      this.currentCustomer = customer
      this.showFilter = false      
    },
    onClickCancelFilter: function() {
      this.currentCustomer = undefined
      this.showFilter = false
    },
    get: function(initTimer = true) {      
      let url = `dashboard/${this.currentCustomer === undefined ? '0' : this.currentCustomer.id}`      
      this.$http
          .get(url)            
          .then(function(response) {                        
              if (response.status == 200) {  
                this.showDashboard = true
                this.kpis = response.data                              
                if (initTimer) {
                    if (this.timerId) clearTimeout(this.timerId)
                    this.timerId = setTimeout(() => {
                            this.get()
                        }, 
                        this.timer
                    )
                    let vm = this

                    this.chartProduction1Options = Object.assign({}, this.chartColumnOptions)
                    this.chartProduction1Options.xAxis = [{categories: Object.keys(this.kpis.production_data.type.assistance)}]
                    this.chartProduction1Options.yAxis = [{title: {text: 'Nombre de demandes'}}]
                    this.chartProduction1Options.title = {text: "Répartition par categorie"}
                    this.chartProduction1Options.plotOptions = {
                        series: {
                            stacking: 'normal',
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    this.chartProduction1Options.series = [{
                        type: 'column',
                        name: "Assistance",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.type.assistance),
                        yAxis: 0
                      },
                      {
                        type: 'column',
                        name: "Rendez-vous",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.type.appointment),
                        yAxis: 0
                      },
                    ]

                    this.chartProductionAppointmentOptions = Object.assign({}, this.chartColumnOptions)
                    this.chartProductionAppointmentOptions.xAxis = [{categories: Object.keys(this.kpis.production_data.appointment.overhaul)}]
                    this.chartProductionAppointmentOptions.yAxis = [{title: {text: 'Nombre de demandes'}}]
                    this.chartProductionAppointmentOptions.title = {text: "Répartition par type de rendez-vous"}
                    this.chartProductionAppointmentOptions.plotOptions = {
                        series: {
                            stacking: 'normal',
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    this.chartProductionAppointmentOptions.series = [{
                        type: 'column',
                        name: "Vidange/Entretien",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.appointment.overhaul),
                        yAxis: 0
                      },
                      {
                        type: 'column',
                        name: "Pneumatique",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.appointment.tires),
                        yAxis: 0
                      },
                      {
                        type: 'column',
                        name: "Plaquettes",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.appointment.brakepads),
                        yAxis: 0
                      },
                      {
                        type: 'column',
                        name: "Control Technique",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.appointment.control),
                        yAxis: 0
                      },
                      {
                        type: 'column',
                        name: "Autres",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.appointment.misc),
                        yAxis: 0
                      },
                      ]

                    this.chartProductionAssistanceOptions = Object.assign({}, this.chartColumnOptions)
                    this.chartProductionAssistanceOptions.xAxis = [{categories: Object.keys(this.kpis.production_data.assistance["3"])}]
                    this.chartProductionAssistanceOptions.yAxis = [{title: {text: 'Nombre de demandes'}}]
                    this.chartProductionAssistanceOptions.title = {text: "Répartition par type d'assistance"}
                    this.chartProductionAssistanceOptions.plotOptions = {
                        series: {
                            stacking: 'normal',
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    this.chartProductionAssistanceOptions.series = [{
                        type: 'column',
                        name: "Accident",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.assistance['1'] ?? {}),
                        yAxis: 0
                      },
                      {
                        type: 'column',
                        name: "Panne",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.assistance['2'] ?? {}),
                        yAxis: 0
                      },
                      {
                        type: 'column',
                        name: "Bris de glace",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.assistance['3'] ?? {}),
                        yAxis: 0
                      },
                      {
                        type: 'column',
                        name: "Crevaison",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.assistance['4'] ?? {}),
                        yAxis: 0
                      },
                      {
                        type: 'column',
                        name: "Vol",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.assistance['5'] ?? {}),
                        yAxis: 0
                      },
                      {
                        type: 'column',
                        name: "Autres",
                        borderWidth: 0,
                        data: Object.values(this.kpis.production_data.assistance['99'] ?? {}),
                        yAxis: 0
                      },
                      ]

                    
                    
                    //if (this.$vuetify.theme.dark) this.chartColumnOptions.chart.backgroundColor = '#222'

                    this.chartMakersDistributionOptions = Object.assign({}, this.chartColumnOptions)
                    this.chartMakersDistributionOptions.xAxis = {categories: Object.values(this.kpis.makers_distribution).map(el => el.name)}
                    this.chartMakersDistributionOptions.yAxis = [{title: {text: 'Nombre de véhicules'}}]
                    this.chartMakersDistributionOptions.title = {text: "Répartition par constructeur"}
                    this.chartMakersDistributionOptions.plotOptions = {
                        series: {
                            cursor: 'pointer',                            
                            point: {
                                events: {
                                    click: function () {
                                        let id = vm.kpis.makers_distribution.find(el => el.name == this.category).id
                                        vm.$router.push({
                                          name: 'Vehicles',
                                          query: { 
                                            makers: id, 
                                            customer: vm.currentCustomer ? vm.currentCustomer.id : 0 
                                          }   
                                        })                     
                                    }
                                }
                            }
                        }
                    }      
                    this.chartMakersDistributionOptions.series = [{
                        type: 'column',
                        name: "Constructeurs",
                        borderWidth: 0,
                        data: Object.values(this.kpis.makers_distribution).map(el => el.value)
                    }]

                    this.chartModelsDistributionOptions = Object.assign({}, this.chartColumnOptions)
                    this.chartModelsDistributionOptions.xAxis = {categories: Object.values(this.kpis.models_distribution).map(el => el.name)}
                    this.chartModelsDistributionOptions.yAxis = [{title: {text: 'Nombre de véhicules'}}]
                    this.chartModelsDistributionOptions.title = {text: "Répartition par modèle"}
                    this.chartModelsDistributionOptions.series = [{
                        type: 'column',
                        name: "Modèle",
                        borderWidth: 0,
                        data: Object.values(this.kpis.models_distribution).map(el => el.value)
                    }] 
                    this.chartModelsDistributionOptions.plotOptions = {
                        series: {
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: function () {
                                        let id = vm.kpis.models_distribution.find(el => el.name == this.category).id
                                        vm.$router.push({
                                          name: 'Vehicles',
                                          query: { 
                                            models: id, 
                                            customer: vm.currentCustomer ? vm.currentCustomer.id : 0 
                                          }   
                                        })                     
                                    }
                                }
                            }
                        }
                    }                 

                    this.chartDistancesDistributionOptions = Object.assign({}, this.chartColumnOptions)
                    this.chartDistancesDistributionOptions.xAxis = {categories: Object.keys(this.kpis.distances_distribution)}
                    this.chartDistancesDistributionOptions.yAxis = [{title: {text: 'Nombre de véhicules'}}]
                    this.chartDistancesDistributionOptions.title = {text: "Répartition par kilométrage actuel"}
                    this.chartDistancesDistributionOptions.series = [{
                        type: 'column',
                        name: "Kilométrage",
                        borderWidth: 0,
                        data: Object.values(this.kpis.distances_distribution)                        
                    }]

                    let categories = [...Object.keys(this.kpis.real_annual_distances_distribution), ...Object.keys(this.kpis.contractual_annual_distances_distribution)].map(el => parseInt(el))
                    let series = {
                      categories: [],
                      real: [],
                      contractual: []
                    } 
                                   
                    for (let i = Math.min(...categories); i <= Math.max(...categories); i+=5000) {
                      series.categories.push(i)
                      series.real.push(this.kpis.real_annual_distances_distribution.hasOwnProperty(i) ? this.kpis.real_annual_distances_distribution[i] : 0)
                      series.contractual.push(this.kpis.contractual_annual_distances_distribution.hasOwnProperty(i) ? this.kpis.contractual_annual_distances_distribution[i] : 0)
                    }
                    this.chartAnnualDistancesDistributionOptions = Object.assign({}, this.chartColumnOptions)
                    this.chartAnnualDistancesDistributionOptions.xAxis = {categories: series.categories}
                    this.chartAnnualDistancesDistributionOptions.yAxis = [{title: {text: 'Nombre de véhicules'}}]
                    this.chartAnnualDistancesDistributionOptions.title = {text: "Répartition par kilométrage annuel"}
                    this.chartAnnualDistancesDistributionOptions.plotOptions = {
                        series: {
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: function () {
                                        let query = {                                           
                                          customer: vm.currentCustomer ? vm.currentCustomer.id : 0 
                                        }
                                        
                                        if (this.series.index == 0) query.distance_real = this.category
                                        else query.distance_contract = this.category
                                                                            
                                        vm.$router.push({
                                          name: 'Vehicles',
                                          query: query
                                        })                                                     
                                    }
                                }
                            }
                        }
                    }     
                    this.chartAnnualDistancesDistributionOptions.series = [{
                        type: 'column',
                        name: "Kilométrage réel",
                        borderWidth: 0,
                        data: series.real
                      },
                      {
                        type: 'column',
                        name: "Kilométrage contractuel",
                        borderWidth: 0,
                        data: series.contractual
                      }]

                    
                    this.chartRollingLawDistributionOptions = Object.assign({}, this.chartColumnOptions)                    
                    this.chartRollingLawDistributionOptions.xAxis = {},
                    this.chartRollingLawDistributionOptions.title = {text: "Loi de roulage"}
                    this.chartRollingLawDistributionOptions.series = [{
                        type: 'pie',
                        name: "Véhicules",
                        borderWidth: 0,
                        size: '60%',
                        data: Object.entries(this.kpis.rolling_law_distribution).map(entry => {
                          let name = 'Normale'
                          let color = '#9CCC65'
                          if (entry[0] == 'under') {
                            name = 'Sous-roulage'
                            color = '#F4511E'
                          }
                          if (entry[0] == 'upper') {
                            name = 'Sur-roulage'
                            color = '#FFB74D'
                          }

                          return { name: name, y: entry[1], color: color}
                        }),
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: function () {
                                    let query = {                                           
                                      customer: vm.currentCustomer ? vm.currentCustomer.id : 0,                                           
                                    }      
                                    
                                    if (this.index == 1) query.rolling_law = '0-0.79'
                                    else if (this.index == 2) query.rolling_law = '1.21-5'
                                    else query.rolling_law = '0.8-1.2'                                                                                  
                                                                        
                                    vm.$router.push({
                                      name: 'Vehicles',
                                      query: query
                                    })                                                     
                                }
                            }
                        }
                    }]

                    this.chartAgesDistributionOptions = Object.assign({}, this.chartColumnOptions)
                    this.chartAgesDistributionOptions.xAxis = {categories: Object.keys(this.kpis.ages_distribution)}
                    this.chartAgesDistributionOptions.yAxis = [{title: {text: 'Nombre de véhicules'}}]
                    this.chartAgesDistributionOptions.title = {text: "Répartition par ancienneté"}
                    this.chartAgesDistributionOptions.plotOptions = {
                        series: {
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: function () {                                        
                                        vm.$router.push({
                                          name: 'Vehicles',
                                          query: { 
                                            years: this.category, 
                                            customer: vm.currentCustomer ? vm.currentCustomer.id : 0 
                                          }   
                                        })                     
                                    }
                                }
                            }
                        }
                    }      
                    this.chartAgesDistributionOptions.series = [{
                        type: 'column',
                        name: "Année",
                        borderWidth: 0,
                        data: Object.values(this.kpis.ages_distribution)
                      }]

                    this.chartRenewalsDistributionOptions = Object.assign({}, this.chartColumnOptions)                   
                    this.chartRenewalsDistributionOptions.xAxis = {},
                    this.chartRenewalsDistributionOptions.title = {text: "Renouvellement à 12 mois"}
                    this.chartRenewalsDistributionOptions.series = [{
                        type: 'pie',
                        name: "Renouvellement",
                        borderWidth: 0,
                        size: '60%',
                        data: [
                          {name: 'Normal', y: this.kpis.count_vehicles - this.kpis.count_leases_expired_in_9_months},
                          {name: '9 à 6 mois', y: this.kpis.count_leases_expired_in_9_months - this.kpis.count_leases_expired_in_6_months},
                          {name: '6 à 3 mois', y: this.kpis.count_leases_expired_in_6_months - this.kpis.count_leases_expired_in_3_months},
                          {name: '3 à 1 mois', y: this.kpis.count_leases_expired_in_3_months - this.kpis.count_leases_expired_in_1_months},
                          {name: 'Moins de 1 mois', y: this.kpis.count_leases_expired_in_1_months}
                        ]
                    }]

                    series = {
                      amount: [],
                      quantity: [],
                      count: []
                    };
                    Object.values(this.kpis.fuel_intakes).forEach(el => {
                      series.amount.push(el.amount)
                      series.quantity.push(el.quantity)
                      series.count.push(el.count)
                    })
                    this.chartMonthlyFuelIntakesOptions = Object.assign({}, this.chartColumnOptions)
                    this.chartMonthlyFuelIntakesOptions.xAxis = [{categories: Object.keys(this.kpis.fuel_intakes)}]
                    this.chartMonthlyFuelIntakesOptions.yAxis = [{ // Primary yAxis                    
                      title: {
                          text: 'Quantité / Montant'                         
                      }
                      }, { // Secondary yAxis
                          title: {
                              text: 'Nombre de passage à la pompe'                             
                          },
                          opposite: true
                      }]
                    this.chartMonthlyFuelIntakesOptions.title = {text: "Consommation carburant"}
                    this.chartMonthlyFuelIntakesOptions.series = [{
                        type: 'column',
                        name: "Montant",
                        borderWidth: 0,
                        data: series.amount,
                        yAxis: 0
                      },
                      {
                        type: 'column',
                        name: "Quantité",
                        borderWidth: 0,
                        data: series.quantity,
                        yAxis: 0
                      },
                      {
                        name: "Passage à la pompe",
                        type: 'line',
                        data: series.count,
                        yAxis: 1
                      }]

                    this.chartConsumptionDistributionOptions = Object.assign({}, this.chartColumnOptions)                    
                    this.chartConsumptionDistributionOptions.xAxis = {},
                    this.chartConsumptionDistributionOptions.title = {text: "Loi de consommation"}
                    this.chartConsumptionDistributionOptions.series = [{
                        type: 'pie',
                        name: "Véhicules",
                        borderWidth: 0,
                        size: '60%',
                        data: Object.entries(this.kpis.consumption_distribution).map(entry => {
                          let name = 'Normale'
                          let color = '#9CCC65'
                          if (entry[0] == 'under') {
                            name = 'Sous-consommation'
                            color = '#F4511E'
                          }
                          if (entry[0] == 'upper') {
                            name = 'Sur-consommation'
                            color = '#FFB74D'
                          }

                          return { name: name, y: entry[1], color: color}
                        }),
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: function () {
                                    let query = {                                           
                                      customer: vm.currentCustomer ? vm.currentCustomer.id : 0,                                           
                                    }      
                                    
                                    if (this.index == 1) query.consumption = '0-0.8'
                                    else if (this.index == 2) query.consumption = '1.21-10'
                                    else query.consumption = '0.8-1.2'                                                                                  
                                                                        
                                    vm.$router.push({
                                      name: 'Vehicles',
                                      query: query
                                    })                                                     
                                }
                            }
                        }
                    }]

                    this.chartEnergiesDistributionOptions = Object.assign({}, this.chartColumnOptions)                   
                    this.chartEnergiesDistributionOptions.xAxis = {},
                    this.chartEnergiesDistributionOptions.yAxis = [{title: {text: 'Nombre de véhicules'}}]
                    this.chartEnergiesDistributionOptions.title = {text: "Répartition par motorisations"}
                    this.chartEnergiesDistributionOptions.series = [{
                        type: 'pie',
                        name: "Motorisations",
                        borderWidth: 0,
                        size: '60%',
                        data: this.kpis.energies_distribution.map(el => { return {name: el.name, y: el.value} })                                              
                    }]

                    this.chartCO2DistributionOptions = Object.assign({}, this.chartColumnOptions)                   
                    this.chartCO2DistributionOptions.xAxis = {},
                    this.chartCO2DistributionOptions.title = {text: "Emission de CO2"}
                    this.chartCO2DistributionOptions.series = [{
                        type: 'pie',
                        name: "CO2",
                        borderWidth: 0,
                        size: '60%',
                        data: this.kpis.co2_distribution.map(el => { 
                          let name
                          switch (el.name) {
                            case 'A': 
                              name = el.name + ' - < 21g'
                              break
                            case 'B': 
                              name = el.name + ' - 22g à 50g'
                              break
                            case 'C': 
                              name = el.name + ' - 51g à 60g'
                              break                            
                            case 'D': 
                              name = el.name + ' - 61g à 95g'
                              break
                            case 'E': 
                              name = el.name + ' - 96g à 110g'
                              break
                            case 'F': 
                              name = el.name + ' - 111g à 130g'
                              break
                            case 'G': 
                              name = el.name + ' - > 131g'
                              break
                            case 'NR': 
                              name = el.name + ' - Non renseigné'
                              break
                            default:
                          }
                          return {
                            name: name, 
                            y: el.value} 
                          }
                        )                                              
                    }]
                }                                 
              }                                                  
          }.bind(this))
          .catch(error => {                
              console.log(error)              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });  
    }
  },
  watch: {
    currentCustomer: function() {
      this.get(true)
    }
  },
  computed: {
    ...mapGetters([
      'userEnvironment'
    ])
  },
  destroyed() {
    if (this.timerId) clearTimeout(this.timerId)
  },
  mounted() {
    if (localStorage.hasOwnProperty('askme.dashboard.customer')) this.currentCustomer = JSON.parse(localStorage.getItem('askme.dashboard.customer'))
    
    this.get()
  }
}
</script>

<style scoped>

</style>