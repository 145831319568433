<template>
  <div>
    <v-container fluid>
      <a-page-header title="Vehicules" icon="flaticon-car-1" icon-filter @filter="toggleFilter()"/> 

      <v-divider/>     
      
      <v-speed-dial
          v-model="fab"    
          top
          right        
          direction="bottom"
          open-on-hover
          transition="slide-y-reverse-transition"
        >
            <template v-slot:activator>
                <v-btn
                    v-model="fab"
                    color="blue darken-2"
                    dark
                    fab
                >
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else >mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
             <v-btn
                ref="fabEvent"
                fab
                dark
                small
                color="blue"
                @click="onClickFabAdd()"
                title="Ajouter un vehicule"
            >
            <v-icon size="20">mdi-plus</v-icon>
            </v-btn>
             
            <v-btn
                ref="fabEvent"
                fab
                dark
                small
                color="purple"
                @click="onClickFabAudit()"
                title="Demander un audit vehicule"
            >
            <v-icon size="20">flaticon-front-car</v-icon>
            </v-btn>
            <v-btn
                ref="fabMessage"
                fab
                dark
                small
                color="indigo"
                @click="onClickFabMileage()"
                title="Demander un relevé kilométrique"
            >
            <v-icon size="20">flaticon-speed</v-icon>
            </v-btn>
            <v-btn
            fab
            dark
            small
            color="red"
            >
            <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-speed-dial>   
      <v-row>
        <v-col sm="12">
          <a-vehicle-list   
            download-btn
            :models="$route.query.models"
            :makers="$route.query.makers"
            :years="$route.query.years"
            :distance-real="$route.query.distance_real"
            :distance-contract="$route.query.distance_contract"
            :customer="$route.query.customer"
            :consumption="$route.query.consumption"
            :rolling-law="$route.query.rolling_law"
            show-filter
            :cache="useCache"
            @change="onChangeSelection"         
            @clickRow="onClickRow"                        
            ref="aVehicleList"
          />    
        </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import APageHeader from "@/components/APageHeader"
import AVehicleList from "@/components/AVehicleList"



export default {
  name: 'Vehicles',
  components: { APageHeader, AVehicleList},
  data: () => ({    
    fab: false,     
  }),
  methods: {
    // Méthodes évènements UI
    // ----------------------
    onClickFabAdd: function() {
      this.$router.push({ 
        name: 'Vehicle', 
        params: { 
          id: 0
        }
      })
    },
    onClickFabAudit: function() {
      const ids = this.selectedItems.map(el => el.id)
      let url = `audit?ids=` + ids.join(',')
      this.$http
            .post(url)   
            .then()
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });

      this.$refs.aVehicleList.resetSelectedItems()
    },
    onClickFabMileage: function() {
      const ids = this.selectedItems.map(el => el.id)
      let url = `mileage?ids=` + ids.join(',')
      this.$http
            .post(url)   
            .then()
            .catch(error => {                                
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
      this.$refs.aVehicleList.resetSelectedItems()
    },
    onChangeSelection: function(event) {
      this.selectedItems = event      
    },
    onClickRow: function(row) {      
      this.$router.push({ 
        name: 'Vehicle', 
        params: { 
          id: row.id
        }
      })
    },
    toggleFilter: function() {
      this.$refs.aVehicleList.toggleFilter()
    }
  },
  watch: {
    
  },
  computed: {
    ...mapGetters([
      'userEnvironment',
      'vehicleFilters'
    ]),
    useCache: function() {      
      return Object.values(this.$route.query).length == 0
    }
  },
  mounted() {
    
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;  
}

.v-btn--floating {
  position: relative;
}
</style>