<template>   
    <v-lazy        
        :options="{
        threshold: 1
        }"        
        transition="fade-transition"
    >
        <div>
        
        <v-list-item @click="onListItemClick()">
            <v-list-item-avatar :color="request.status.color">
                <v-icon v-if="request.isAppointment" class="white--text">flaticon-event</v-icon>
                <v-icon v-if="request.isAssistance"  class="white--text">flaticon-alert</v-icon>        
            </v-list-item-avatar>
            
            <v-list-item-content>          
                <v-list-item-title class="font-weight-medium">   
                    {{title}}    
                    <template v-if="request.isAppointment">                                                              
                        <v-chip
                            v-if="item.params.reasons.overhaul"
                            class="ml-0 mr-1 my-1"
                            color="teal accent-3"
                            text-color="white"     
                            x-small         
                        >
                        <v-avatar left>
                            <v-icon size="10">flaticon-oil</v-icon>
                        </v-avatar>
                        Vidange/Revision
                        </v-chip>

                        <v-chip
                            v-if="item.params.reasons.tires"
                            class="ml-0 mr-1 my-1"
                            color="deep-purple"
                            text-color="white"
                            x-small
                        >
                        <v-avatar left>
                            <v-icon size="10">flaticon-tire</v-icon>
                        </v-avatar>
                        Pneumatiques
                        </v-chip>

                        <v-chip
                            v-if="item.params.reasons.brakepads"
                            class="ml-0 mr-1 my-1"
                            color="orange darken-3"
                            text-color="white"
                            x-small
                        >
                        <v-avatar left>
                            <v-icon size="10">flaticon-brake</v-icon>
                        </v-avatar>
                        Plaquettes de freins
                        </v-chip>

                        <v-chip
                            v-if="item.params.reasons.misc"
                            class="ml-0 mr-1 my-1"
                            color="light-blue darken-2"
                            text-color="white"
                            x-small
                        >
                        <v-avatar left>
                            <v-icon size="10">flaticon-tools-and-utensils</v-icon>
                        </v-avatar>
                        Divers/Réparation
                        </v-chip>     

                        <v-chip
                            v-if="item.params.reasons.control"
                            class="ml-0 mr-1 my-1"
                            color="yellow darken-2"
                            text-color="white"
                            x-small
                        >
                        <v-avatar left>
                            <v-icon size="10">flaticon-car</v-icon>
                        </v-avatar>
                        Controle technique
                        </v-chip>     
                    </template>        
                    
                    <v-chip 
                        :color="request.status.color" 
                        x-small 
                        class="white--text"      
                        @click.stop="isUpdatingStatus = !isUpdatingStatus" 
                        @mouseenter="openStatusChanger()"                  
                    >
                        {{ request.status.label }}
                        <v-icon size=10 class="ml-1">{{ isUpdatingStatus ? 'mdi-close' : 'mdi-pencil' }}</v-icon>
                    </v-chip>                           
                </v-list-item-title>

                <v-row class="py-0 my-0">
                    <v-col md="12" class="py-0 my-0">
                        <v-expand-transition>
                            <a-status-changer v-show="isUpdatingStatus" x-small @change="onChangeStatus($event)" />                                                
                        </v-expand-transition>    
                    </v-col>
                </v-row>

                <v-row class="mt-1">                       
                    
                    <v-col sm="3" class="ma-0 py-1">                                                                     
                        <div><v-icon size="12">flaticon-user</v-icon> {{item.requester.firstname}} {{item.requester.lastname}}</div>
                        <div class="caption light-blue--text text--darken-1 mt-1" >Demande du {{item.create_date}}</div>    
                        <div class="caption light-blue--text text--darken-1" >Status au {{item.status_date}}</div>      
                    </v-col>
                    <v-col sm="2" class="ma-0 py-1">
                        <div class="d-flex">                            
                            <v-avatar size="30" tile class="align-self-start mx-2">
                                <v-img :src="logo"></v-img>
                            </v-avatar>
                            <div class="align-self-start mx-2">                 
                                <div v-if="item.vehicle.model">{{item.vehicle.model.brand}} {{item.vehicle.model.model}}</div>
                                <div><v-icon size="12" class="mr-2">flaticon-car-1</v-icon> {{item.vehicle.registration}}</div>    
                                <div><v-icon size="12" class="mr-2">flaticon-speed</v-icon> {{item.vehicle.distance}} {{item.vehicle.distance_unit}}</div>           
                            </div>                                                     
                        </div>                                                        
                    </v-col>
                    <v-col sm="2" class="ma-0 py-1"><v-icon size="12" class="mr-2">flaticon-support</v-icon> {{item.requester.work.name}}</v-col>
                    <v-col sm="3" class="ma-0 py-1 align-self-start">
                        <div v-for="appointment in appointments" :key="appointment.id">
                            <a-appointment-label                                 
                                :appointment="appointment" 
                                show-image      
                                show-status                          
                            />                            
                        </div>
                    </v-col>
                    <v-col sm="2">   
                        <v-card v-if="item.comments" class="ma-0 py-1 yellow lighten-5">
                            <v-card-text>
                                <div class="caption comments" v-html="comments"></div>
                            </v-card-text>                                                                     
                        </v-card>                   
                    </v-col>
                </v-row>

                                           
                
                <v-progress-linear
                    v-show="onDeleting"
                    indeterminate
                    color="red"
                ></v-progress-linear>      
            </v-list-item-content>

            <v-list-item-action >
                <div v-if="item.activity">
                    <v-badge
                        v-if="item.activity.messages !== undefined"
                        color="blue darken-1"  
                        overlap  
                        class="ml-4"                      
                    >
                        <span slot="badge">{{item.activity.messages}}</span>
                        <v-icon large>flaticon-consulting-message</v-icon>
                    </v-badge>

                    <v-badge
                        v-if="item.activity.appointments_refused !== undefined"
                        color="red darken-1"  
                        overlap   
                        class="ml-4"                    
                    >
                        <span slot="badge">{{item.activity.appointments_refused}}</span>
                        <v-icon large>flaticon-event-1</v-icon>
                    </v-badge>

                    <v-badge
                        v-if="item.activity.appointments_confirmed !== undefined"
                        color="green darken-1"  
                        overlap     
                        class="ml-4"                   
                    >
                        <span slot="badge">{{item.activity.appointments_confirmed}}</span>
                        <v-icon large>flaticon-event-2</v-icon>
                    </v-badge>                
                </div>

                <v-slide-x-reverse-transition hide-on-leave>   
                    <v-btn
                        class="mb-7"
                        v-show="btnDelete && !confirmDelete"
                        elevation=0
                        fab
                        dark
                        x-small
                        color="red"
                        @click.stop="confirmDelete = true"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>   
                </v-slide-x-reverse-transition>             
            

            <v-slide-x-reverse-transition hide-on-leave>   
                <div v-show="btnDelete && confirmDelete"  >
                    <div class="mb-5">
                    <v-btn                            
                              
                        elevation=0
                        fab
                        x-small
                        dark                
                        color="green"
                        @click.stop="onClickFabConfirmDelete"                        
                    >
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                    </div>
                    <div>
                
                    <v-btn       
                                
                        elevation=0
                        fab
                        x-small
                        dark                
                        color="red"
                        @click.stop="onClickFabCancelDelete"                        
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>   
                    </div>                  
                </div>                      
                </v-slide-x-reverse-transition>
                
            
                                        
            </v-list-item-action>   
            
        </v-list-item>
        <v-divider inset></v-divider>
        </div>
    </v-lazy>
</template>

<script>
import Status, { RequestStatusCollection } from '../domain/Status';
import AAppointmentLabel from './AAppointmentLabel.vue';
import AStatusChanger from './AStatusChanger.vue';
import Request from "@/domain/Request.js";

export default {    
    name: "ARequestItem",
    components: { AAppointmentLabel, AStatusChanger },
    props: {
        item: Object,
        btnDelete: {
            type: Boolean,
            default: false    
        }
    },
    data () {
        return {
            request: Request.fromObject(this.item),                           
            confirmDelete: false,
            onDeleting: false,
            isUpdatingStatus: false
        }
    },
    computed: {
        title: function() {            
            if (this.request.isAssistance) {
                if (this.request.isCrashAssistance) return "Déclaration d'accident";
                if (this.request.isBreakdownAssistance) return "Assistance dépannage panne";
                if (this.request.isGlassBreakageAssistance) return "Déclaration de bris de glace";
                if (this.request.isTiresAssistance) return "Assistance dépannage pneumatique";
                if (this.request.isTheftAssistance) return "Déclaration de vol";
                if (this.request.isMiscAssistance) return "Assistance diverse";
            }
            return "Rendez-vous véhicule"; 
        },
        appointments: function() {
            return this.item.appointments ? this.item.appointments.filter(el => el.is_confirmed || el.is_booked) : []
        },
        logo: function() {
            try {
                return `${this.$constants.application.resourcesURL}/images/vehicles/${this.item.vehicle.model.brand.toLowerCase()}/logo.png`;
            }
            catch (error) {
                return '';
            }
        },
        comments: function() {
            return this.item.comments ? this.item.comments.replaceAll('\n', '<br/>') : '';
        }
    },
    methods: {
        onClickFabConfirmDelete: function() {                
            this.onDeleting = true        
            let url = `requests?id=${this.item.id}`      
            this.$http
                .delete(url)            
                .then(() => { 
                    this.onDeleting = false
                    this.$emit('delete', this.item.id)                                                                    
                })
                .catch(error => {                              
                    if (error.response.status === 401) this.$root.$emit("logout"); 
                    else this.$root.$emit("serverError", error);     
                });  
            
        },
        onClickFabCancelDelete: function() {
            this.confirmDelete = false
        },
        onListItemClick: function() {            
            this.$emit('select', this.item.id)
        },
        onChangeStatus: function(status) {
            this.isUpdatingStatus = false;
            this.request.status = Status.find(status, RequestStatusCollection);            

            let url = `requests/${this.request.id}/${this.request.status.code}`            
            
            this.$http
                .put(url)            
                .then(() => {                                                                                 
                })
                .catch(error => {                                     
                    if (error.response.status === 401) this.$root.$emit("logout"); 
                    else this.$root.$emit("serverError", error);     
                });   
            
        },
        openStatusChanger: function() {
            this.isUpdatingStatus = true;
            setTimeout(() => { if (this.isUpdatingStatus) this.isUpdatingStatus = false }, 10000);
        }        
    }
}
</script>

<style lang="scss" scoped>
.comments {    
    line-height: 1.1;  
    color:gray;
}
</style>