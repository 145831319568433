<template>  
  <v-container class="text-center">   
    <v-timeline>
      <v-timeline-item
        v-for="activity in stream"
        :key="activity.id"   
        :left="activity.user.role == $constants.users.USER_ROLE_COLLABORATOR"
        :right="activity.user.su || activity.user.role !== $constants.users.USER_ROLE_COLLABORATOR" 
        small
        fill-dot            
        :color="activity.status_color"      
      >      
        <span slot="opposite">{{new Date(activity.timestamp) | moment('DD/MM/YYYY HH:mm')}}</span>
        
        <div v-if="activity.type == $constants.activity.TYPE_CHANGE_STATUS"
          :class="activity.user.su || activity.user.role !== $constants.users.USER_ROLE_COLLABORATOR ? 'text-left' : 'text-right'"
        >
          <v-chip 
            :color="$functions.getStatusColor(parseInt(activity.value))"
            text-color="white"
            class="ma-0"
          >
            {{$functions.getStatusLabel(parseInt(activity.value))}}
          </v-chip>
        </div>

        <!--- MESSAGES --->        
        <v-card v-if="activity.type == $constants.activity.TYPE_NEW_MESSAGE" 
          class="elevation-2"
          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'"
        >
          <v-card-title class="subtitle-2 pb-0">
            <v-icon x-small class="mr-1">flaticon-consulting-message</v-icon>
            Message de {{activity.user.firstname}} {{activity.user.lastname}}
          </v-card-title>
          <v-card-text class="body-2 text-left" v-html="activity.chat.text" />                      
        </v-card>

        <!--- RENDEZ-VOUS --->
        <a-activity-appointment-item v-if="isAppointment(activity.type)"
          :activity="activity"
          :validateBtn="activity.type == $constants.activity.TYPE_APPOINTMENT_CONFIRMED && stream.find(el => el.reference_id == activity.reference_id && el.type == $constants.activity.TYPE_APPOINTMENT_CANCEL_CONFIRMED) === undefined"
          :cancelBtn="activity.type == $constants.activity.TYPE_NEW_APPOINTMENT"
          :renewBtn="activity.type == $constants.activity.TYPE_NEW_APPOINTMENT && stream.find(el => el.reference_id == activity.reference_id && el.type === $constants.activity.TYPE_APPOINTMENT_CONFIRMED) === undefined"
          :recallBtn="activity.type == $constants.activity.TYPE_NEW_APPOINTMENT && stream.find(el => el.reference_id == activity.reference_id && el.type === $constants.activity.TYPE_APPOINTMENT_CONFIRMED) === undefined"
          :confirmBtn="activity.type == $constants.activity.TYPE_APPOINTMENT_CANCELED && stream.find(el => el.reference_id == activity.reference_id && el.type == $constants.activity.TYPE_APPOINTMENT_CANCEL_CONFIRMED) === undefined"
        />

      </v-timeline-item>                                   
    </v-timeline>

    <v-chip
      :color="$constants.colors.STATUS_NEW"
      text-color="white"
      class="ma-2"
    >
      {{new Date(start.timestamp) | moment('DD/MM/YYYY HH:mm')}}
    </v-chip>

  </v-container>
</template>

<script>
import AActivityAppointmentItem from './AActivityAppointmentItem.vue'
 
export default {
    name: "AActivityStream",
    components: { AActivityAppointmentItem },
    props: {
        activities: {
            type: Array           
        }
    },
    data() {
      return {
        start: {},
        stream: []
      }
    },
    methods: {      
      formatTimelime: function() {
        this.stream = [...this.activities]
        this.start = this.stream.pop()

        // Détermination des couleurs de puce en fonction des changements de statuts
        let color = this.$constants.colors.STATUS_NEW
        this.stream.reverse().forEach(el => {
          el.status_color = color
          if (el.type == this.$constants.activity.TYPE_CHANGE_STATUS) {
              color = this.$functions.getStatusColor(parseInt(el.value))
          }        
        })

        this.stream.reverse();
      },
      isAppointment: function(activityType) {
        return [
          this.$constants.activity.TYPE_NEW_APPOINTMENT,
          this.$constants.activity.TYPE_APPOINTMENT_CONFIRMED,
          this.$constants.activity.TYPE_APPOINTMENT_REFUSED,
          this.$constants.activity.TYPE_APPOINTMENT_CANCELED,
          this.$constants.activity.TYPE_APPOINTMENT_CANCEL_CONFIRMED,
          this.$constants.activity.TYPE_APPOINTMENT_BOOKED
        ].indexOf(activityType) !== -1
      }
    },
    watch: {
      activities: function() {
        this.formatTimelime()
      }
    },
    mounted() {      
      this.formatTimelime()      
    }
}
</script>

<style lang="scss" scoped>
</style>