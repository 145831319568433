<template>
    <v-container fluid>
      <a-page-header :title="title" 
        icon="flaticon-support"
        :image="modificationImage"
      />    

      <v-divider/>

      <v-row>
        <v-col sm="12">
          <v-form
            ref="form"
            v-model="isValid"
            lazy-validation
          >
            <v-row>                                              
              <v-col md="4">
                <v-text-field
                  v-model="line.name"                  
                  :rules="[v => !!v || 'Nom obligatoire']"     
                  label="Nom"
                  required
                ></v-text-field>
              </v-col>   
            </v-row>            
          </v-form>        
        </v-col>

        <v-col md="12" class="text-right">
          <v-btn
            :disabled="!isValid"
            color="success"
            class="mr-4"
            @click="onClickBtnValidate"
          >
            Valider
          </v-btn>     
        </v-col> 
      </v-row>    
    </v-container>
    
  
</template>

<script>
import { mapGetters } from 'vuex'

import APageHeader from "@/components/APageHeader"

export default {
  name: 'Line',  
  components: { APageHeader },
  data: () => ({   
    isNew: true,
    isValid: true,  
    tab: '',
    line: {},   
    update: (new Date()).getTime()
  }),
  methods: {
    onClickBtnValidate: function() {
      let makerId = this.$route.params.maker === undefined ? 0 : this.$route.params.maker
      let modelId = this.$route.params.model === undefined ? 0 : this.$route.params.model
      let url = `vehiclelines/${makerId}/${modelId}/${this.modification.id}`
      if (this.modification.id == 0) {
        this.$http.post(url, this.modification)   
          .then(response => {
            if (response.status == 200) {
              this.modification = response.data
              this.isNew = false
              this.update = (new Date()).getTime()
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
      else {
        this.$http.put(url, this.modification)   
          .then(response => {
            if (response.status == 200) {
              this.modification = response.data
              this.update = (new Date()).getTime()
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
    },
    // Méthodes d'accès aux données
    // ----------------------------
    // Données vehicule
    get: function() {    
        if (this.$route.params.id !== 0) { 
          this.isLoading = true
          let url = `vehiclelines/0/0/0/${this.$route.params.id}`
          this.$http
              .get(url)            
              .then(response => {                        
                  if (response.status == 200) {                    
                    this.line = response.data                                                                                                                               
                  }                                          
                  this.isLoading = false 
              })
              .catch(error => {                
                  this.isLoading = false
                  if (error.response.status === 401) this.$root.$emit("logout"); 
                  else this.$root.$emit("serverError", error);     
              });                                  
        }        
    }
  },
  computed: {
    ...mapGetters([
      'userEnvironment'
    ]),
    title: function() {
      let prefix = (this.line.maker === undefined ? '' : this.line.maker.name + ' ') + (this.line.model === undefined ? '' : this.line.model.name + ' ') + (this.line.modification === undefined ? '' : this.line.modification.name + ' ')
      return prefix + (this.line.id == 0 
        ? "Nouvelle finition" 
        : this.line.name) + + (this.line.modification === undefined ? '' : this.line.modification.name + ' ') + (this.line.model === undefined ? '' : ' [' + this.modificatilineon.model.generation + ']')
    }  
  },
  mounted() {       
    this.isNew = this.$route.params.id == 0    
    if (!this.isNew) {
      this.get()      
    }
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;  
}

.v-btn--floating {
  position: relative;
}
</style>