<template>
  <v-card @click="$emit('click', $event)">
    <v-card-text>
      <v-row>
          <v-col :md="colSize" sm="12">
            <v-img max-height="80" contain class="image" :src="vehicle.fullpath_image_file"/>  
            <div class="body-2 font-weight-medium text-center" :class="$vuetify.theme.dark ? 'white--text' : 'black--text'">
              <v-icon size="18">flaticon-speed</v-icon> <span class="caption font-condensed-ligth grey--text">{{ new Date(vehicle.last_mileage_date).toLocaleDateString() }}</span><br>
              {{vehicle.distance}} {{vehicle.distance_unit}}
            </div>          
          </v-col>              

          <v-col :md="colSize" sm="12">                         
              <div class="caption font-condensed-ligth grey--text">Marque</div>
              <div :class="$vuetify.theme.dark ? 'white--text' : 'black--text'" class="body-2 font-weight-medium" >{{vehicle.model.brand}}</div>
              <div class="caption font-condensed-ligth grey--text">Model</div>
              <div :class="$vuetify.theme.dark ? 'white--text' : 'black--text'" class="body-2 font-weight-medium">{{vehicle.model.model}}</div>
              <div class="caption font-condensed-ligth grey--text">Immatriculation</div>
              <div :class="$vuetify.theme.dark ? 'white--text' : 'black--text'" class="body-2 font-weight-medium">{{vehicle.registration}}</div>
          </v-col>    

          <v-col :md="colSize" sm="12" v-if="showTires" class="text-center">                         
              <v-icon large color="yellow darken-3" v-if="vehicle.tires_type == 0">mdi-weather-sunny</v-icon>
              <v-icon large color="blue darken-3" v-if="vehicle.tires_type == 1">mdi-weather-snowy</v-icon>
              <v-icon large color="green darken-3" v-if="vehicle.tires_type == 2">mdi-weather-partly-snowy-rainy</v-icon>              
              <div class="caption font-condensed-ligth grey--text">AV</div>
              <div :class="$vuetify.theme.dark ? 'white--text' : 'black--text'" class="body-2 font-weight-medium">{{vehicle.front_tires_size}} - {{vehicle.front_tires_provider_name}}</div>
              <div class="caption font-condensed-ligth grey--text">AR</div>
              <div :class="$vuetify.theme.dark ? 'white--text' : 'black--text'" class="body-2 font-weight-medium">{{vehicle.rear_tires_size}} - {{vehicle.rear_tires_provider_name}}</div>              
          </v-col>      

          <v-col :md="colSize" sm="12" v-if="showLease" class="text-center">                                                     
              <div :class="$vuetify.theme.dark ? 'white--text' : 'black--text'" class="body-2 font-weight-medium">{{vehicle.lease.provider.name}}</div>
              <div class="caption font-condensed-ligth grey--text">Contrat</div>
              <div :class="$vuetify.theme.dark ? 'white--text' : 'black--text'" class="body-2 font-weight-medium">{{vehicle.lease.duration}} mois - {{vehicle.lease.distance}} km</div>                            
              <div class="caption font-condensed-ligth grey--text">Inclus au contrat</div>
              <div>              
                <v-icon v-if="vehicle.lease.has_maintenance_rent" size="18" color="teal accent-3" class="mx-1">flaticon-oil</v-icon>
                <v-icon v-if="vehicle.lease.has_maintenance_rent" size="18" color="orange darken-3" class="mx-1">flaticon-brake</v-icon>
                <v-icon v-if="vehicle.lease.has_tires_rent" size="18" color="deep-purple" class="mx-1">flaticon-tire</v-icon>
                <v-icon v-if="vehicle.lease.has_other_rent" size="18" color="light-blue darken-2" class="mx-1">flaticon-tools-and-utensils</v-icon>              
              </div>                    
          </v-col>                
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
 
export default {
    name: "AVehicleHeader",
    props: {
        vehicle: {
            type: Object,
            default: () => { return {fullpath_image_file: '', registration: '', model: {brand: '', model:''}}}
        },
        showTires: {
          type: Boolean,
          default: false
        },
        showLease: {
          type: Boolean,
          default: false
        }
    },
    computed: {
      colSize: function() {
        let colCount = 2
        if (this.showTires) colCount++
        if (this.showLease && this.vehicle.lease !== undefined) colCount++

        return 12 / colCount
      }
    }
}
</script>

<style lang="scss" scoped>
  .divider {    
    padding-left: 10px;
    border-left: solid 1px #9E9E9E;
  }
</style>