<template>
    <v-dialog
        v-model="dialog"  
        width="600"
        height="500"
      >        
        <v-card>
          <v-card-title>{{title}}</v-card-title>
          <v-card-text>
            <v-col md="12">
                <v-text-field
                    v-model="oldPassword"      
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"            
                    :rules="[v => !!v || 'Ancien mot de passe obligatoire', v => oldPasswordSHA1 == profile.password || 'Mot de passe incorrect']"     
                    label="Ancien mot de passe"
                    required
                    clearable
                    clear-icon="mdi-close-circle"                                        
                    @click:append="show1 = !show1"                
                ></v-text-field>
                </v-col>  
                <v-col md="12">
                <v-text-field
                    v-model="newPassword1"  
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"                
                    :rules="[v => !!v || 'Nouveau mot de passe obligatoire']"     
                    label="Nouveau de passe"
                    required
                    clearable
                    clear-icon="mdi-close-circle"
                    @click:append="show2 = !show2"
                ></v-text-field>
                </v-col>  
                <v-col md="12">
                <v-text-field
                    v-model="newPassword2"   
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"               
                    :rules="[v => !!v || 'Répétition du nouveau mot de passe obligatoire', v => newPassword1 !== '' && newPassword1 == newPassword2 || 'Mot de passe différent']"     
                    label="Répétition du nouveau mot de passe"
                    required
                    clearable
                    clear-icon="mdi-close-circle"
                    @click:append="show3 = !show3"
                ></v-text-field>
            </v-col>  
          </v-card-text>
          <v-card-actions class="text-right pb-2">
              
                <v-spacer></v-spacer>                
                <v-btn          
                        dark        
                        color="red"
                        class="ma-4"     
                        @click="dialog = false"             
                    >
                    Annuler
                </v-btn>    
                <v-btn
                    :disabled="!isValid"
                    color="primary"
                    class="ma-4"     
                    @click="onClickBtnValidate"             
                    >
                    Valider
                </v-btn>     
            
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import sha1 from 'sha1';

export default {    
    name: 'AModalChangePassword',
    props: {
        title: {
            type: String,
            default: "MODIFICATION DU MOT PASSE"
        }
    },
    data: () => ({
        dialog: false,         
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',   
        show1: false,
        show2: false,
        show3: false  
    }),
    methods: {
        show: function() {      
            this.oldPassword = this.newPassword1 = this.newPassword2 = ''     
            this.dialog = true            
        },
        hide: function() {
            this.dialog = false
        },
        onClickBtnValidate: function() {    
            let params = {
                id: this.profile.id,
                old_password: sha1(this.oldPassword),
                new_password: sha1(this.newPassword1)
            }
            let url = `user`
            this.$http.put(url, params)   
                .then(response => {
                    if (response.status == 200) {
                        this.collaborator = response.data
                        this.$root.$emit('notify', 'Mot de passe mis à jour', 'success', 'mdi-checkbox-marked-circle')                        
                        this.hide()
                    }  
                })
                .catch(error => {                                
                    if (error.response.status === 401) this.$root.$emit("logout"); 
                    else this.$root.$emit("serverError", error);     
                });                   
        }
    },
    computed: {
        ...mapGetters([      
            'profile'
        ]), 
        oldPasswordSHA1: function() {
            return sha1(this.oldPassword)
        },
        isValid: function() {
            return sha1(this.oldPassword) == this.profile.password && this.newPassword1 == this.newPassword2
         }
    }
}
</script>
