<template>
  <v-card elevation="0">
    <v-card-title>              
      <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Rechercher"
            single-line
            hide-details
            clearable
          ></v-text-field>
            
        <v-btn   
          class="mt-5 mx-2"  
          v-if="downloadBtn"       
          icon 
          elevation="2"
          @click="onClickBtnExport()"
          title="Exporter la sélection">
            <v-icon>mdi-download</v-icon>
        </v-btn>  
      </v-card-title>
        
      <v-card-text>    
        <v-data-table
          v-model="selectedItems"
          :headers="headersDrivers"
          :items="driversItems"
          :search="search"
          :single-select="singleSelect"
          show-select        
          item-key="id"      
          :loading="isLoading"
          loading-text="Chargement en cours..."
          locale="fr-FR"       
          disable-pagination
          hide-default-footer
          @item-selected="onItemSelected"
          @click:row="onClickRow"  
          @toggle-select-all="onToggleSelectAll"
        >      

          <template v-slot:group.header="{group, items, isOpen, toggle}">
            <th colspan="5" class="subtitle-2">
              <v-icon @click="toggle" class="mr-3"
                >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
              </v-icon>
              {{ group }}
            </th>
          </template>        

          <template v-slot:item.account="{ item }">
              <v-avatar tile size="36">
                <v-img :src="item.image"/>         
              </v-avatar>
              {{ item.account }}                  
          </template>                  

          <template v-slot:item.status="{ item }">
            <v-icon v-if="item.isSU" color='yellow' title="Super utilisateur">mdi-shield-account</v-icon> 

            <v-icon v-if="item.isLocked" color='red' title="Utilisateur verrouillé">{{item.role == 2 ? 'mdi-account' : (item.role == 1 ? 'mdi-account-tie' : 'mdi-account-tie-hat')}}</v-icon>
            <v-icon v-else color='green' title="Utilisateur autorisé">{{item.role == 2 ? 'mdi-account' : (item.role == 1 ? 'mdi-account-tie' : 'mdi-account-tie-hat')}}</v-icon> 

            <v-icon v-if="item.usingApp" color='green' title="Application AskMe autorisée">mdi-cellphone-check</v-icon> 
            <v-icon v-else color='red' title="Application AskMe non autorisée">mdi-cellphone-remove</v-icon>

            <v-chip 
              v-if="item.managedCustomersCount != 0"                   
              small
              class="ml-2 my-1"
              color="blue"
              text-color="white"        
            >                   
              {{ item.managedCustomersCount }}
            </v-chip> 

            <v-chip 
              v-if="item.status != ''"                   
              small
              class="ml-2 my-1"
              :color="item.statusColor"
              text-color="white"        
            >                   
              {{ item.status }}
            </v-chip>     
            
          </template>                       
        </v-data-table>
      </v-card-text>
  </v-card>                      
</template>

<script>
import Functions from "@/js/functions.js"

export default {
    name: "AUserList",  
    props: {
      singleSelect: {
        type: Boolean,
        default: false
      },
      defaultSearch: {
        type: String,
        default: ''
      },
      small: {
        type: Boolean,
        default: false
      },
      customer: {
        type: Number,
        default: 0
      },
      downloadBtn: {
        type: Boolean,
        default: false
      }
    }, 
    data() {
      return {
        isLoading: true,        
        // Table des conducteurs
        headersDrivers: [                  
          {text: "Nom", value: "lastname"},      
          {text: "Prénom", value: "firstname"},
          {text: "Compte", value: "account"},    
          {text: "Agence/Site", value: "agency"},    
          {text: "Car policy", value: "carPolicy"}, 
          {text: "Mobile", value: "mobile"},
          {text: "Téléphone", value: "phone"},
          {text: "Mail", value: "mail"}, 
          {text: "Role", value: "roleLabel"},     
          {text: "Connexion", value: "lastConnection"},      
          {text: "Statut", value: "status"},                
        ],
        selectedItems: [],
        driversItems: [],
        search: ''
      }    
    },
    methods: {
      get: function() {
      this.isLoading = true
      let url = `users`
      if (this.customer !== 0) url += `/customer/${this.customer}`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.driversItems = response.data.map(el => {       
                  let status = ''
                  let statusColor = ''  
                  if (el.is_out) {
                    status = 'A quitter la société'
                    statusColor = 'red'   
                  }

                  let roleLabel = "Collaborateur"
                  if (el.role == 0) roleLabel = "Administrateur"
                  else if (el.role == 1) roleLabel = "Gestionnaire"                  
                  
                  return {
                    id: el.id,
                    login: el.name,
                    firstname: el.firstname,
                    lastname: el.lastname,
                    matricule: el.user_number,
                    account: el.work.name,  
                    agency: el.work.agency === undefined ? '' : el.work.agency.name,                  
                    cost_id: el.cost_id,
                    department: el.department,
                    carPolicy: el.car_policy,
                    image: el.work.image == "" ? "" : `${this.$constants.application.resourcesURL}/images/${el.work.image}`,
                    phone: el.phone,
                    mobile: el.mobile,
                    mail: el.mail,  
                    address1: el.address1,
                    address2: el.address2,
                    address3: el.address3,
                    role: el.role,
                    roleLabel: roleLabel,
                    zip: el.zip,
                    city: el.city,  
                    latitude: el.latitude,
                    longitude: el.longitude,
                    lastConnection: el.last_connection > 0 ? new Date(el.last_connection * 1000).toLocaleString() : '',
                    status: status,
                    statusColor: statusColor,
                    usingApp: el.using_app,
                    isLocked: el.is_locked,
                    managedCustomersCount: el.managed_customers_ids == '' ? 0 : el.managed_customers_ids.split(',').length,
                    vehicles: el.vehicles == undefined ? '' : el.vehicles.map(v => v.registration).join()
                  }
                })
                this.search = this.defaultSearch
              } 
              this.isLoading = false             
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
      },
      getSelectedItems: function() {
        return this.selectedItems
      },
      resetSelectedItems: function() {
        this.selectedItems = []
      },
      deleteItems: function(ids) {
        this.driversItems = this.driversItems.filter(el => ids.indexOf(el.id) == -1)  
      },
      onItemSelected: function(event) {
        this.$emit('itemSelected', event)
        this.$nextTick(() => {this.$emit('change', this.selectedItems)})
      },      
      onToggleSelectAll: function(event) {
        this.$emit('toggleSelectAll', event)
        this.$nextTick(() => {this.$emit('change', this.selectedItems)})
      }, 
      onClickRow: function(event) {
        this.$emit('clickRow', event)
      },
      onClickBtnExport: function() {
        let exports = this.selectedItems.length == 0 ? this.driversItems : this.selectedItems;
                  
        exports = exports.map(el => {
          let ret = {};
          Object.entries(el).forEach(entry => {
            if (entry[0].indexOf('image') == -1) ret[entry[0]] = entry[1]
          })
          return ret
        })
                    
        Functions.Resources.exportData(Object.keys(exports[0]), exports.map(el => Object.values(el)), ';', 'export')        
      }
    },
    mounted() {      
      this.get()
    }
}
</script>

<style lang="scss" scoped>
 
</style>