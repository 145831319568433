var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"height":_vm.height,"headers":_vm.headersDrivers,"items":_vm.items,"search":_vm.search,"single-select":_vm.singleSelect,"show-select":_vm.showSelect,"item-key":"id","loading":_vm.isLoading,"loading-text":"Chargement en cours...","locale":"fr-FR","disable-pagination":"","hide-default-footer":""},on:{"item-selected":_vm.onItemSelected,"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{staticClass:"subtitle-2",attrs:{"colspan":"5"}},[_c('v-icon',{staticClass:"mr-3",on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" "+_vm._s(group)+" ")],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-no-wrap align-center"},[_c('v-img',{attrs:{"max-width":"36","src":item.image}}),_c('div',{staticClass:"ml-3"},[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }