<template>
  <div>
    <v-card>
      <v-card-title class="pb-0">
        {{title}}
        <v-btn v-if="iconPencil" icon color="blue" class="ml-2" @click="formMode = !formMode"><v-icon size=16>mdi-pencil</v-icon></v-btn>
      </v-card-title>
      <v-card-text v-if="formMode">
        <v-row v-if="appointment.infos">
          <v-col sm="12" class="px-5 py-1"><div>{{appointment.infos}}</div></v-col>
        </v-row>
        <v-row>
          <v-col sm="12" class="px-5 py-1">
            <div>Services</div>
            <div>
              <v-chip                
                class="ml-0 mr-1 my-1"
                :color="proposed.reasons.overhaul ? 'teal accent-3' : 'grey accent-3'"
                text-color="white"  
                :small="small"  
                close
                :close-icon="proposed.reasons.overhaul ? 'mdi-minus-circle' : 'mdi-plus-circle'" 
                @click:close="proposed.reasons.overhaul = !proposed.reasons.overhaul"       
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-oil</v-icon>
                </v-avatar>
                Vidange/Revision
              </v-chip>

              <v-chip                
                class="ml-0 mr-1 my-1"
                :color="proposed.reasons.tires ? 'deep-purple' : 'grey accent-3'"                
                text-color="white"
                :small="small"
                close
                :close-icon="proposed.reasons.tires ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                @click:close="proposed.reasons.tires = !proposed.reasons.tires"   
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-tire</v-icon>
                </v-avatar>
                Pneumatiques
              </v-chip>

              <v-chip                
                class="ml-0 mr-1 my-1"
                :color="proposed.reasons.brakepads ? 'orange darken-3' : 'grey accent-3'"                
                text-color="white"
                :small="small"
                close
                :close-icon="proposed.reasons.brakepads ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                @click:close="proposed.reasons.brakepads = !proposed.reasons.brakepads"  
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-brake</v-icon>
                </v-avatar>
                Plaquettes de freins
              </v-chip>

              <v-chip                
                class="ml-0 mr-1 my-1"
                :color="proposed.reasons.misc ? 'light-blue darken-2' : 'grey accent-3'"                
                text-color="white"
                :small="small"
                close
                :close-icon="proposed.reasons.misc ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                @click:close="proposed.reasons.misc = !proposed.reasons.misc"
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-tools-and-utensils</v-icon>
                </v-avatar>
                Divers/Réparation
              </v-chip>   
              
              <v-chip                
                class="ml-0 mr-1 my-1"
                :color="proposed.reasons.control ? 'yellow darken-2' : 'grey accent-3'"                
                text-color="white"
                :small="small"
                close
                :close-icon="proposed.reasons.control ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                @click:close="proposed.reasons.control = !proposed.reasons.control"
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-car</v-icon>
                </v-avatar>
                Contrôle Tecnhique
              </v-chip>     
            </div>        
          </v-col>

          <v-col sm="6" class="px-5 py-1">
            <div>Préférences</div>
            <div>
                  <v-chip
                    class="ml-0 mr-1 my-1"
                    :color="proposed.preferences.day1 ? 'green' : 'grey accent-3'"                
                    text-color="white"
                    :small="small"
                    close
                    :close-icon="proposed.preferences.day1 ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                    @click:close="proposed.preferences.day1 = !proposed.preferences.day1"
                  >
                    Lundi
                  </v-chip>
                  <v-chip
                    class="ml-0 mr-1 my-1"
                    :color="proposed.preferences.day2 ? 'green' : 'grey accent-3'"                
                    text-color="white"
                    :small="small"
                    close
                    :close-icon="proposed.preferences.day2 ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                    @click:close="proposed.preferences.day2 = !proposed.preferences.day2"
                  >
                    Mardi
                  </v-chip>
                  <v-chip
                    class="ml-0 mr-1 my-1"
                    :color="proposed.preferences.day3 ? 'green' : 'grey accent-3'"                
                    text-color="white"
                    :small="small"
                    close
                    :close-icon="proposed.preferences.day3 ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                    @click:close="proposed.preferences.day3 = !proposed.preferences.day3"
                  >
                    Mercredi
                  </v-chip>
                  <v-chip
                    class="ml-0 mr-1 my-1"
                    :color="proposed.preferences.day4 ? 'green' : 'grey accent-3'"                
                    text-color="white"
                    :small="small"
                    close
                    :close-icon="proposed.preferences.day4 ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                    @click:close="proposed.preferences.day4 = !proposed.preferences.day4"
                  >
                    Jeudi
                  </v-chip>
                  <v-chip
                    class="ml-0 mr-1 my-1"
                    :color="proposed.preferences.day5 ? 'green' : 'grey accent-3'"                
                    text-color="white"
                    :small="small"
                    close
                    :close-icon="proposed.preferences.day5 ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                    @click:close="proposed.preferences.day5 = !proposed.preferences.day5"
                  >
                    Vendredi
                  </v-chip>
                  <v-chip
                    class="ml-0 mr-1 my-1"
                    :color="proposed.preferences.day6 ? 'green' : 'grey accent-3'"                
                    text-color="white"
                    :small="small"
                    close
                    :close-icon="proposed.preferences.day6 ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                    @click:close="proposed.preferences.day6 = !proposed.preferences.day6"
                  >
                    Samedi
                  </v-chip>
            </div>
          </v-col>

          <v-col sm="6" class="px-5 py-1">
              <div>Localisation</div>
              <div>
                    <v-chip                      
                      class="ml-0 mr-1 my-1"
                      :color="proposed.localization.home ? 'light-blue lighten-3' : 'grey accent-3'"                
                      text-color="white"
                      :small="small"
                      close
                      :close-icon="proposed.localization.home ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                      @click:close="proposed.localization.home = !proposed.localization.home"
                    >
                      <v-avatar left>
                        <v-icon size="16">flaticon-house-1</v-icon>
                      </v-avatar>
                      Proche domicile
                    </v-chip>

                    <v-chip                      
                      class="ml-0 mr-1 my-1"
                      :color="proposed.localization.work ? 'pink lighten-3' : 'grey accent-3'"                
                      text-color="white"
                      :small="small"
                      close
                      :close-icon="proposed.localization.work ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                      @click:close="proposed.localization.work = !proposed.localization.work"
                    >
                      <v-avatar left>
                        <v-icon size="16">flaticon-briefcase-1</v-icon>
                      </v-avatar>
                      Proche lieu de travail
                    </v-chip>

                    <v-chip                      
                      class="ml-0 mr-1 my-1"
                      :color="proposed.localization.other ? 'amber lighten-3' : 'grey accent-3'"                
                      text-color="white"
                      :small="small"
                      close
                      :close-icon="proposed.localization.other ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                      @click:close="proposed.localization.other = !proposed.localization.other"
                    >
                      <v-avatar left>
                        <v-icon size="16">flaticon-user</v-icon>
                      </v-avatar>
                      Personnalisé
                    </v-chip>
                    <div v-show="proposed.localization.other">
                      <v-text-field
                        v-model="proposed.localization.custom_address"                                      
                        label="Infomations"                        
                    ></v-text-field>                      
                    </div>
                </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row v-if="appointment.infos">
          <v-col sm="12" class="px-3 py-1"><div>{{appointment.infos}}</div></v-col>
        </v-row>
        <v-row>
          <v-col sm="12" class="px-5 py-1">
            <div>Services</div>
            <div>
              <v-chip
                v-if="appointment.reasons.overhaul"
                :small="small"
                class="ml-0 mr-1 my-1"
                color="teal accent-3"
                text-color="white"    
                :close="!proposed.overhaul"
                close-icon="mdi-checkbox-marked-circle" 
                @click:close="proposed.overhaul = true"       
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-oil</v-icon>
                </v-avatar>
                Vidange/Revision
              </v-chip>

              <v-chip
                v-if="appointment.reasons.tires"
                :small="small"
                class="ml-0 mr-1 my-1"
                color="deep-purple"
                text-color="white"
                :close="!proposed.tires"
                close-icon="mdi-checkbox-marked-circle" 
                @click:close="proposed.tires = true"   
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-tire</v-icon>
                </v-avatar>
                Pneumatiques {{tiresOptions}}
              </v-chip>

              <v-chip
                v-if="appointment.reasons.brakepads"
                :small="small"
                class="ml-0 mr-1 my-1"
                color="orange darken-3"
                text-color="white"
                :close="!proposed.brakepads"
                close-icon="mdi-checkbox-marked-circle" 
                @click:close="proposed.brakepads = true"  
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-brake</v-icon>
                </v-avatar>
                Plaquettes de freins
              </v-chip>

              <v-chip
                v-if="appointment.reasons.misc"
                :small="small"
                class="ml-0 mr-1 my-1"
                color="light-blue darken-2"
                text-color="white"
                :close="!proposed.misc"
                close-icon="mdi-checkbox-marked-circle" 
                @click:close="proposed.misc = true"
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-tools-and-utensils</v-icon>
                </v-avatar>
                Divers/Réparation
              </v-chip>     

              <v-chip
                v-if="appointment.reasons.control"
                :small="small"
                class="ml-0 mr-1 my-1"
                color="yellow darken-2"
                text-color="white"
                :close="!proposed.control"
                close-icon="mdi-checkbox-marked-circle" 
                @click:close="proposed.control = true"
              >
                <v-avatar left>
                  <v-icon size="18">flaticon-car</v-icon>
                </v-avatar>
                Contrôle Tecnhique
              </v-chip>     
            </div>             
          
          </v-col>

          <v-col sm="6" class="px-5 py-1">
            <div>Préférences</div>
            <div>
                  <v-chip
                    v-if="appointment.preferences.day1"
                    :small="small"
                    class="ml-0 mr-1 my-1"
                    color="green"
                    text-color="white"
                  >
                    Lundi
                  </v-chip>
                  <v-chip
                    v-if="appointment.preferences.day2"
                    :small="small"
                    class="ml-0 mr-1 my-1"
                    color="green"
                    text-color="white"
                  >
                    Mardi
                  </v-chip>
                  <v-chip
                    v-if="appointment.preferences.day3"
                    :small="small"
                    class="ml-0 mr-1 my-1"
                    color="green"
                    text-color="white"
                  >
                    Mercredi
                  </v-chip>
                  <v-chip
                    v-if="appointment.preferences.day4"
                    :small="small"
                    class="ml-0 mr-1 my-1"
                    color="green"
                    text-color="white"
                  >
                    Jeudi
                  </v-chip>
                  <v-chip
                    v-if="appointment.preferences.day5"
                    :small="small"
                    class="ml-0 mr-1 my-1"
                    color="green"
                    text-color="white"
                  >
                    Vendredi
                  </v-chip>
                  <v-chip
                    v-if="appointment.preferences.day6"
                    :small="small"
                    class="ml-0 mr-1 my-1"
                    color="green"
                    text-color="white"
                  >
                    Samedi
                  </v-chip>
            </div>
          </v-col>

          <v-col sm="6" class="px-5 py-1">
              <div>Localisation</div>
              <div>
                    <v-chip
                      v-if="appointment.localization.home"
                      :small="small"
                      class="ml-0 mr-1 my-1"
                      color="light-blue lighten-3"
                      text-color="white"
                    >
                      <v-avatar left>
                        <v-icon size="16">flaticon-house-1</v-icon>
                      </v-avatar>
                      Proche domicile
                    </v-chip>

                    <v-chip
                      v-if="appointment.localization.work"
                      :small="small"
                      class="ml-0 mr-1 my-1"
                      color="pink lighten-3"
                      text-color="white"
                    >
                      <v-avatar left>
                        <v-icon size="16">flaticon-briefcase-1</v-icon>
                      </v-avatar>
                      Proche lieu de travail
                    </v-chip>

                    <v-chip
                      v-if="appointment.localization.other"
                      :small="small"
                      class="ml-0 mr-1 my-1"
                      color="pink lighten-3"
                      text-color="white"
                    >
                      <v-avatar left>
                        <v-icon size="16">flaticon-user</v-icon>
                      </v-avatar>
                      Personnalisé
                    </v-chip>
                    <div v-if="appointment.localization.other">
                      {{appointment.localization.custom_address}}
                    </div>
                </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="validateButton && formMode">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="onClickBtnValidate"          
        >
        Valider
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

export default {    
    name: "AVehiculeAppointment",
    props: {
        requester: Object,
        appointment: Object,
        title: {
          type: String,
          default: ""
        },
        iconPencil: {
          type: Boolean,
          default: false
        },      
        small: {
          type: Boolean,
          default: false
        },
        validateButton: {
          type: Boolean,
          default: false
        },
        form: {
          type: Boolean,
          default: false
        }            
      },
    data() {
      return {
        date: undefined,
        time: undefined,
        showDateTimeSelector: false,     
        showProviders: false,   
        proposed: {...this.appointment},
        localization: {},
        provider: undefined,
        formMode: this.form
      }
    },
    computed: {
      tiresOptions: function() {
        if (this.appointment.tires) {
          if (this.appointment.tires.front && this.appointment.tires.rear) return 'AV/AR'
          else if (this.appointment.tires.front) return 'AV'
          else if (this.appointment.tires.rear) return 'AR'
          else return ''
        }
        return '';
      }
    },
    watch: {
      showDateTimeSelector: function() {        
        if (this.showDateTimeSelector) this.showProviders = false
      },
      showProviders: function() {
        if (this.showProviders) this.showDateTimeSelector = false
      }
    },
    methods: {
      // Méthodes d'évènements
      // ---------------------
      onClickBtnValidate: function() {
        this.formMode = false
        this.$emit('validate', this.proposed)
      },
      getProposedAppointment: function() {
        return {
          services: this.proposed,
          provider: this.provider,
          date: this.date,
          time: this.time
        }
      },
      // Méthodes du composant
      // ---------------------
      formatPlaceAddress: function(place) {
        let address = place.address1.trim()
        if (place.address2 !== null) address += `<br>${place.address2.trim()}`
        if (place.address3 !== null) address += `<br>${place.address3.trim()}`
        address += `<br>${place.zip} ${place.city}`
        return address
      }
    },
    updated() {
      this.localization = this.appointment.localization
      this.localization.markers = {
          home: {
            id: this.requester.id,
            firstname: this.requester.firstname,
            lastname: this.requester.lastname,
            address1: this.requester.address1,
            address2: this.requester.address2,
            address3: this.requester.address3,
            zip: this.requester.zip,
            city: this.requester.city,
            latitude: this.requester.latitude,
            longitude: this.requester.longitude
          },
          work:this.requester.work
      }
    }
}
</script>

<style lang="scss" scoped>
  
</style>