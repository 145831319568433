<template>
  <v-card elevation="0">
    <v-card-title>              
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher"
        single-line
        hide-details
        clearable
      ></v-text-field>    

      <v-btn   
        class="mt-5 mx-2"
        v-if="addBtn"                    
        icon 
        elevation="2"
        @click="onClickBtnAdd"
        title="Ajouter un modele">
          <v-icon>mdi-plus</v-icon>
      </v-btn>    
    </v-card-title>
        
    <v-card-text>
      <v-data-table
        v-model="selectedItems"
        :headers="headersList"
        :items="listItems"
        :search="search"
        :single-select="singleSelect"
        :show-select="showSelect"
        :items-per-page="itmesPerPage"        
        item-key="id"      
        :loading="isLoading"
        loading-text="Chargement en cours..."
        locale="fr-FR"   
        :disable-pagination="itemsPerPage <= 0"
        :hide-default-footer="itemsPerPage <= 0"
        fixed-header
        @item-selected="onItemSelected"
        @click:row="onClickRow"          
        ref="dataTable"
      >                                                    
      </v-data-table>
    </v-card-text>
  </v-card>             
</template>

<script>

export default {
    name: "APlaceList",  
    props: {
      customer: {
        type: Number,
        default: 0
      },
      showSelect: {
        type: Boolean,
        default: false
      },
      singleSelect: {
        type: Boolean,
        default: false
      },
      itemsPerPage: {
        type:Number,
        default: -1
      },
      defaultSearch: {
        type: String,
        default: ''
      },
      small: {
        type: Boolean,
        default: false
      },
      selectedIds: {
        type: String,
        default: ""
      },
      addBtn: {
        type: Boolean,
        default: false
      }
    }, 
    data() {
      return {
        isLoading: true,        
        headersList: [                                    
          {text: "Nom", value: "name"},     
          {text: "Adresse", value: "address1"},    
          {text: "Code Postal", value: "zip"},    
          {text: "Ville", value: "city"}                                          
        ],
        selectedItems: [],
        listItems: [],
        search: ''
      }    
    },
    methods: {
      get: function() {
        this.isLoading = true

        
        let url = `places?cstm=${this.customer}`                 
    
        this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.listItems = response.data                
                this.search = this.defaultSearch          
                
                let selected = this.selectedIds.split(',').map(element => parseInt(element));
                this.listItems.forEach(element => {                  
                  if (selected.indexOf(element.id) != -1) this.selectedItems.push(element)                  
                });
              } 
              this.isLoading = false             
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
      },
      getSelectedItems: function() {
        return this.selectedItems
      },      
      resetSelectedItems: function() {
        this.selectedItems = []
      },
      onItemSelected: function(event) { 
        this.$emit('itemSelected', event)
        this.$nextTick(() => {this.$emit('change', this.selectedItems)})
      },      
      onClickRow: function(event) {
        console.log(event)
        this.$emit('clickRow', event)
      },
      onClickBtnAdd: function(event) {
        this.$emit('clickBtnAdd', event)
      },
      updateRow: function(row) {
        this.listItems = this.listItems.map(el => {
          if (el.id === row.id) return row 
          else return el
        })

      }
    },
    mounted() {         
      this.get()      
    }
}
</script>

<style lang="scss" scoped>
 
</style>