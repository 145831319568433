import { Colors } from "./Colors";

export default class Status {
    constructor(code, value, color, label) {
        this.code = code;
        this.value = value;
        this.color = color;
        this.label = label;
    } 

    equals(status = this) {
        return this.code === status.code && this.value === status.value;
    }
    
    static find(value, statusCollection = []) {
        const status = statusCollection.find(el => el.value == value);
        if (status) return status;
        else throw new Error(`Undefined status with value ${value}`);        
    }

    static toArray(statucCollection) {
        return statucCollection.map(el => Object.values(el));
    }
}

export const CommonStatus = Object.freeze({
    new: 0,
    received: 10,
    recalled: 15,
    processing: 20,
    waiting: 30,
    waiting_user: 40,
    waiting_provider: 50,
    complete: 60,
    canceled: 70,
    refused: 80,
    archived: 90    
});

export const RequestStatusCollection = [
    new Status("new", CommonStatus.new, Colors.status.new, "Nouveau"),
    new Status("received", CommonStatus.received, Colors.status.received, "Reçue"),        
    new Status("processing", CommonStatus.processing, Colors.status.processing, "En cours"),
    new Status("waiting", CommonStatus.waiting, Colors.status.waiting, "En attente"),
    new Status("waiting_user", CommonStatus.waiting_user, Colors.status.waiting_infos, "En attente d'information"),
    new Status("waiting_provider", CommonStatus.waiting_provider, Colors.status.waiting_provider_info, "En attente fournisseur"),
    new Status("complete", CommonStatus.complete, Colors.status.complete, "Réalisée"),
    new Status("canceled", CommonStatus.canceled, Colors.status.canceled, "Annulée"),
    new Status("refused", CommonStatus.refused, Colors.status.refused, "Refusée"),
    new Status("archived", CommonStatus.archived, Colors.status.archived, "Archivée")
];

export const DossierStatusCollection = [    
    new Status("new", CommonStatus.new, Colors.status.new, "Nouveau"),
    new Status("received", CommonStatus.received, Colors.status.received, "Reçu"),
    new Status("recalled", CommonStatus.recalled, Colors.status.recalled, "Relancé"),
    new Status("processing", CommonStatus.processing, Colors.status.processing, "En cours"),
    new Status("waiting", CommonStatus.waiting, Colors.status.waiting, "En attente"),
    new Status("waiting_user", CommonStatus.waiting_user, Colors.status.waiting_infos, "En attente d'information"),
    new Status("waiting_provider", CommonStatus.waiting_provider, Colors.status.waiting_provider_info, "En attente fournisseur"),
    new Status("complete", CommonStatus.complete, Colors.status.complete, "Réalisé"),
    new Status("canceled", CommonStatus.canceled, Colors.status.canceled, "Annulé"),
    new Status("refused", CommonStatus.refused, Colors.status.refused, "Refusé"),
    new Status("archived", CommonStatus.archived, Colors.status.archived, "Archivé")
];    

export const ServiceStatus = Object.freeze({
    todo: 0,
    scheduled: 10,
    processing: 20,
    completed: 30,
    canceled: 99,
});

export const ServiceStatusCollection = [
    new Status("todo", ServiceStatus.todo, Colors.status.new, "A prévoir"),
    new Status("scheduled", ServiceStatus.scheduled, Colors.status.received, "Planifié"),    
    new Status("processing", ServiceStatus.processing, Colors.status.processing, "En cours"),
    new Status("completed", ServiceStatus.completed, Colors.status.complete, "Réalisé"),
    new Status("canceled", ServiceStatus.canceled, Colors.status.canceled, "Annulé")
];