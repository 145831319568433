<template>
  <v-card elevation="0">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      
      <v-data-table
        v-model="selectedItems"
        :headers="headersDrivers"
        :items="driversItems"
        :search="search"
        :single-select="singleSelect"
        :show-select="showSelect"        
        item-key="id"      
        :loading="isLoading"
        loading-text="Chargement en cours..."
        locale="fr-FR"       
        disable-pagination
        hide-default-footer
        @item-selected="onItemSelected"        
        @toggle-select-all="onToggleSelectAll"
        @click:row="onClickRow"          
        ref="dataTable"
      >      
        <template v-slot:group.header="{group, items, isOpen, toggle}">
          <th colspan="5" class="subtitle-2">
            <v-icon @click="toggle" class="mr-3"
              >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
            </v-icon>
            {{ group }}
          </th>
        </template>        

        <template v-slot:item.name="{ item }">
            <div class="d-flex flex-no-wrap align-center">
                <v-img max-width="36" :src="item.image"/>                       
                <div class="ml-3">{{ item.name }}</div>
            </div>            
        </template>                  
                         
      </v-data-table>
    </v-card>                      
</template>

<script>
 
export default {
    name: "ACustomerList",  
    props: {
      showSelect: {
        type: Boolean,
        default: false
      },
      singleSelect: {
        type: Boolean,
        default: false
      },
      defaultSearch: {
        type: String,
        default: ''
      },
      small: {
        type: Boolean,
        default: false
      },
      selectedIds: {
        type: String,
        default: ""
      }
    }, 
    data() {
      return {
        isLoading: true,        
        // Table des conducteurs
        headersDrivers: [                  
          {text: "Nom/Raison sociale", value: "name"},      
          {text: "Adresse", value: "address1", groupable: false},
          {text: "Code postal", value: "zip"},     
          {text: "Ville", value: "city"}                                  
        ],
        selectedItems: [],
        driversItems: [],
        search: ''
      }    
    },
    methods: {
      get: function() {
      this.isLoading = true
      let url = `customers`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.driversItems = response.data.map(el => {                  
                  return {
                    id: el.id,
                    name: el.name,
                    address1: el.address1,
                    zip : el.zip,
                    city: el.city,
                    image: el.image != "" ? `${this.$constants.application.resourcesURL}/images/${el.image}` : "",                         
                  }
                })
                this.search = this.defaultSearch          
                
                let selected = this.selectedIds.split(',').map(element => parseInt(element));
                this.driversItems.forEach(element => {                  
                  if (selected.indexOf(element.id) != -1) this.selectedItems.push(element)                  
                });
                this.$emit('change', this.selectedItems)              
              } 
              this.isLoading = false             
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
      },
      getSelectedItems: function() {
        return this.selectedItems
      },      
      resetSelectedItems: function() {
        this.selectedItems = []
      },
      onItemSelected: function(event) { 
        this.$emit('itemSelected', event)
        this.$nextTick(() => {this.$emit('change', this.selectedItems)})
      },      
      onToggleSelectAll: function(event) { 
        this.selectedItems = event.value ? event.items : []
        this.$emit('change', this.selectedItems)
      },  
      onClickRow: function(event) {
        this.$emit('clickRow', event)
      }
    },
    mounted() {      
      this.get()      
    }
}
</script>

<style lang="scss" scoped>
 
</style>