<template>
    <v-dialog v-model="dialog" width="70%">     
        <v-card>
          <v-card-title>ENTRETIEN</v-card-title>
          <v-card-text>
            <a-maintenance-form 
              :service="service" 
              :vehicle="vehicle"
              :driver="driver"                        
              @cancel="onCancelMaintenance" 
              @validate="onValidateMaintenance($event)"
              :key="key"
            />
          </v-card-text>
          </v-card>
    </v-dialog>
</template>

<script>
import Service from "@/domain/Service.js"
import AMaintenanceForm from "@/components/AMaintenanceForm"

export default {
  name: 'AModalMaintenance',
  components: { AMaintenanceForm },
  props: {    
    vehicle: Object,    
    driver: Object,    
  },
  data: () => ({
        key: 0,
        dialog: false,
        service: {}
  }),
  methods: {    
    show: function(service) {                                 
        this.service = service ? {...service} : new Service();
        
        this.key = Math.random();
        this.dialog = true
    },
    hide: function() {
        this.dialog = false
    },
    onValidateMaintenance: function(service) {      
        this.$emit('validate', service);
        this.hide()
    },
    onCancelMaintenance: function() {  
        this.$emit('cancel');   
        this.hide();
    },
  }
}
</script>
