<template>  
    <v-container fluid>
      <v-row>
        <v-col md="12" class="text-center">
          <v-avatar tile size="64">
            <v-img 
              :src="imgFuelCompany" 
              max-width="100"
            />
          </v-avatar>
        </v-col>
      </v-row>
      <v-form>
            <v-row>
              <v-col md="6">
                <v-select
                  v-model="data.provider_id"
                  :items="fuelCompanies"
                  item-text="name"
                  item-value="id"
                  :rules="[rc => !!rc || 'Pétrolier obligatoire']"     
                  label="Pétrolier"
                  required 
                  @change="setFuelCompanyImage()"                 
                ></v-select>
              </v-col>

              <v-col md="6">
                <v-text-field
                  v-model="data.date"                                    
                  label="Date"
                  :rules="[v => !!v || 'Date obligatoire']"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>                          
              <v-col md="6">
                  <v-select
                  v-model="data.fuel"
                  :items="fuelTypes"
                  item-text="label"
                  item-value="id"
                  :rules="[v => !!v || 'Carburant obligatoire']"     
                  label="Carburant"
                  required                              
                ></v-select>
                </v-col>

                <v-col md="6">
                  <v-text-field
                    v-model="data.distance"                                    
                    label="Kilométrage"                    
                  ></v-text-field>
             </v-col>
            </v-row>           

            <v-row>                         
              <v-col md="6">
                  <v-text-field
                    v-model="data.quantity"                                    
                    label="Quantité"
                    :rules="[v => !!v || 'Quantité obligatoire']"
                    required
                  ></v-text-field>
                </v-col>

                <v-col md="6">
                  <v-text-field
                    v-model="data.unit_price"                                    
                    label="Prix unitaire"
                    :rules="[v => !!v || 'Prix unitaire obligatoire']"
                    required
                  ></v-text-field>
                </v-col>
            </v-row>   

            <v-row>
              <v-col md="12">
                <a-user-list 
                  single-select
                  :default-search="vehicle.driver == undefined ? '' : `${vehicle.driver.lastname}`"
                  @itemSelected="onItemSelected"
                />
              </v-col>
            </v-row>         

            <v-row>
              <v-col md="12" class="text-right">
                <v-btn                  
                  color="error"
                  class="mr-4"
                  @click="onClickBtnCancel"
                >
                  Annuler
                </v-btn>                   
                <v-btn                  
                  color="success"
                  class="mr-4"
                  @click="onClickBtnValidate"
                >
                  Valider
                </v-btn>     
              </v-col> 
            </v-row>     
      </v-form>
    </v-container>  
</template>

<script>
import AUserList from '../components/AUserList'

export default {
  name: 'AFuelIntakeForm',
  components: { AUserList },
  props: {    
    vehicle: Object,
    fuelIntake: Object,
    initialize: Boolean
  },
  data: () => ({
    fuelCompanies: [],
    data: {},
    fuelCompanyId: 0,
    imgFuelCompany: '',
    fuelTypes: [],
  }),
  methods: {
    onClickBtnValidate: function () {
      let id = this.data.id === undefined ? 0 : this.data.id
      let url = `fuelintakes`
      let params = {
        id: id,
        provider_id: this.data.provider_id,
        vehicle_id: this.vehicle.id,
        user_id: this.data.driver_id,
        distance: parseInt(this.data.distance),
        quantity: parseInt(this.data.quantity),
        fuel: this.data.fuel,
        date: this.data.date,
        unit_price: parseFloat(this.data.unit_price)
      }
      console.log(params)
      
      if (id == 0) {
        this.$http
          .post(url, params)            
          .then(response => {                        
              if (response.status == 200) {
                this.$emit('validate', this.data)              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });  
      }
      else {
        this.$http
          .put(url, params)            
          .then(response => {                        
              if (response.status == 200) {
                this.$emit('validate', this.data)              
              }                                          
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });  
      }          
      
    },
    onClickBtnCancel: function () {
      this.$emit('cancel')
    },
    onClickBtnClose: function () {
      this.data.dateEnd = this.$moment(Date.now()).format("YYYY-MM-DD")
      this.onClickBtnValidate()      
    },
    onClickBtnOpen: function() {
      this.data.dateEnd = ""
      this.onClickBtnValidate(false)      
    },
    onItemSelected: function(event) {
      this.data.driver_id = event.item.id
    },
    getFuelsList: function() {
      let url = `listitems/fuels`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.fuelTypes = response.data.map(el => {return {...el, "label": `${el.name} - ${el.type}`}})
              }              
          })
          .catch(error => {                              
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })
    },
    getfuelCompanies: function() {
      let url = `providers/types/1`
      this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.fuelCompanies = response.data
              }              
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout")
              else this.$root.$emit("serverError", error)
          })
                 
    },
    setFuelCompanyImage: function() {  
      if (this.data !== undefined) {  
        let fuelCompany = this.fuelCompanies.find(el => el.id == this.data.provider_id)
        this.imgFuelCompany =  fuelCompany !== undefined ? `${this.$constants.application.resourcesURL}/images/${fuelCompany.image}` : ''  
      }
    }
  },
  watch: {
    initialize: function() {
      if (this.initialize) {
        this.data = Object.assign({}, this.fuelIntake)
        this.setFuelCompanyImage()
      }
    }
  },
  mounted() {
    this.getFuelsList()
    this.getfuelCompanies()
    this.data = Object.assign({}, this.fuelIntake)   
    this.setFuelCompanyImage()   
  }
}
</script>

<style scoped>
img {
  max-width: 150px;
}

.login {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}
</style>
