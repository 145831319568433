<template>
    <v-container fluid>
      <a-page-header :title="title" 
        icon="flaticon-support"
        :image="modelImage"
      />    

      <v-divider/>

      
        
    <v-row class="pt-5">
      <v-col md="12">
          <v-row>
            <v-col md="12" class="subtitle-1 font-weight-medium">INFORMATIONS</v-col>
            <v-col sm="12">
              <v-form
                ref="form"
                v-model="isValid"
                lazy-validation                    
              >
                <v-row>    
                  <v-col md="3">
                    <v-text-field
                      v-model="model.name"                  
                      :rules="[v => !!v || 'Nom obligatoire']"     
                      label="Nom"
                      required
                    ></v-text-field>
                  </v-col>   

                  <v-col md="3">
                    <v-text-field
                      v-model="model.version"                  
                      :rules="[v => !!v || 'Génération obligatoire']"     
                      label="Génération"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col md="3">
                    <v-select
                      v-model="model.type"                  
                      label="Catégorie"
                      :items="$constants.vehicleModels.CATEGORIES"
                      item-text="label"
                      item-value="id"
                      required
                    ></v-select>
                  </v-col>     

                  <v-col md="1">
                    <v-select
                      v-model="model.family"                  
                      label="Famille"
                      :items="$constants.vehicleModels.FAMILIES"
                      item-text="label"
                      item-value="id"
                      required
                    ></v-select>
                  </v-col>
                    
                  <v-col md="2">
                    <v-select
                      v-if="!isNew"
                      v-model="model.merge"                  
                      label="Fusionner avec"
                      :items="modelsList"
                      item-text="name"
                      item-value="id"
                      required
                      @change="showConfirmButton = false"
                    ></v-select>
                  </v-col>                                             
                </v-row>  
              </v-form>        
            </v-col>    
          </v-row>
          <v-row>
            <v-col md="12" class="text-right">
              <v-btn v-if="deletable"               
                class="mr-4"    
                color="error" 
                @click="onClickBtnDeleteModel()"
              >
                SUPPRIMER
              </v-btn>

              <v-btn
                v-show="!model.merge"
                :disabled="!isValid"                    
                color="success"
                class="mr-4"                
                @click="onClickBtnValidate()"
              >
                VALIDER
              </v-btn>      

              <v-btn 
                v-show="model.merge && !showConfirmButton" 
                :disabled="!isValid"                    
                color="success"
                class="mr-4"                
                @click="showConfirmButton = true"
              >
                VALIDER
              </v-btn>

              <v-btn 
                v-show="model.merge && showConfirmButton"                     
                :disabled="!isValid"                    
                color="default"
                class="mr-4"                
                @click="onClickCancel"
              >
                ANNULER
              </v-btn>

              <v-btn 
                v-show="model.merge && showConfirmButton"                     
                :disabled="!isValid"                    
                color="warning"
                class="mr-4"                
                @click="onClickBtnValidate()"
              >
                CONFIRMER
              </v-btn>
            </v-col>     
          </v-row>    

          <v-row v-if="!this.isNew">
            <v-col sm="12">
                  <v-tabs v-model="tab">                                          
                    <v-tab v-if="!this.isNew" href="#tab-1">MOTORISATIONS</v-tab>                    
                    <v-tab v-if="!this.isNew" href="#tab-2">VEHICULES</v-tab>  

                    <v-tab-item value="tab-1">
                      <v-card flat>
                        <v-card-text>            
                          <a-vehicle-modification-list   
                            :maker-id="model.provider_id"  
                            :model-id="model.id"              
                            single-select    
                            add-btn         
                            @clickRow="onClickRowVehicleModification" 
                            @clickBtnAdd="onclickBtnAddVehicleModificication"
                            :key="update"        
                          />            
                        </v-card-text>
                      </v-card>
                    </v-tab-item>                  

                    <v-tab-item value="tab-2">
                      <v-card flat>
                        <v-card-text>
                          <a-vehicle-list   
                            download-btn     
                            small
                            :models="model.id"                                                                                                                 
                            @clickRow="onClickRowVehicle"
                            ref="aVehicleList"
                          />    
                        </v-card-text>
                      </v-card>
                    </v-tab-item>  
                  </v-tabs>
            </v-col>
          </v-row>      
      </v-col>
    </v-row>


      <v-dialog        
        v-model="dialogModification"
        max-width="60%"          
      >        
        <v-card min-height="400px">
          <v-card-title>MOTORISATION DU MODEL</v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6" v-show="vehicleModification.id == 0">               
               <a-modification-list     
                  :maker-id="model.provider_id"  
                  :items-per-page=10
                  single-select                                        
                  @clickRow="onClickRowModification"                    
                  ref="aModificationList"                           
                />
              </v-col>                

              <v-col :md="vehicleModification.id == 0 ? 6 : 12">    
                  <v-col md="12" class="text-center">
                    <v-img max-height="100" contain class="image" :src="modelImage"/>
                    <div>{{model.provider.name}}&nbsp;{{vehicleModification.modelName}}</div>
                    <div>{{vehicleModification.modelGeneration}}</div>
                    <div>{{vehicleModification.modificationName}}</div>
                    <div>{{$functions.Vehicles.getEnergyLabel(vehicleModification.modelEnergy)}}</div>
                  </v-col>                                                               
                  <v-col md="12">
                    <v-text-field
                      v-model="vehicleModification.cityConsumption"                                       
                      label="Consommation urbaine"
                    
                    ></v-text-field>
                  </v-col>    

                  <v-col md="12">
                    <v-text-field
                      v-model="vehicleModification.mixteConsumption"                                     
                      label="Consommation mixte"
                    
                    ></v-text-field>
                  </v-col>    

                  <v-col md="12">
                    <v-text-field
                      v-model="vehicleModification.highwayConsumption"                                      
                      label="Consomation autoroute"                  
                    ></v-text-field>
                  </v-col>    

                  <v-col md="12">
                    <v-text-field
                      v-model="vehicleModification.co2"                                      
                      label="CO2"                  
                    ></v-text-field>
                  </v-col>                     
              </v-col>     
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="danger" @click="dialogModification = false">QUITTER</v-btn>
            <v-btn v-show="vehicleModification.count === 0" text color="error" @click="onClickBtnDeleteVehicleModification()">SUPPRIMER</v-btn>
            <v-btn text color="primary" @click="onClickBtnValidateVehicleModification">VALIDER</v-btn>            
          </v-card-actions>
          </v-card>
      </v-dialog>   
    </v-container>
    
  
</template>

<script>
import { mapGetters } from 'vuex'

import APageHeader from "@/components/APageHeader"
import AModificationList from "@/components/AModificationList"
import AVehicleModificationList from "@/components/AVehicleModificationList"
import AVehicleList from '@/components/AVehicleList.vue'

export default {
  name: 'Model',  
  components: { APageHeader, AVehicleModificationList, AModificationList, AVehicleList },
  data: () => ({   
    isNew: false,
    isValid: true,  
    tab: '',
    model: {},
    modelImage: "",
    customers: [],    
    modifications: [],
    modelsList: [],
    mergeModelWith: 0,
    vehicleModification: {},
    lines: [],
    update: (new Date()).getTime(),
    dialogModification: false,
    showConfirmButton: false,
    deletable: false
  }),
  methods: {
    onClickBtnValidate: function() {
      //let makerId = this.$route.params.maker === undefined ? 0 : this.$route.params.maker
      //let url = `vehiclemodels/${makerId}/${this.model.id}`;
      let url = `catalogs/${this.model.id}`;
      if (this.isNew) {
        this.$http.post(url, this.model)   
          .then(response => {
            if (response.status == 200) { 
              this.model = {...this.model, ...response.data, merge: 0};             
              this.isNew = false;                      
              this.update = (new Date()).getTime()
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
      else {
        this.$http.put(url, this.model)   
          .then(response => {
            if (response.status == 200) {
              if (this.model.merge !== 0) this.modelsList = this.modelsList.filter(el => el.id !== this.model.merge);
              this.model = {...this.model, ...response.data, merge: 0};
              this.showConfirmButton = false;              
              this.update = (new Date()).getTime();
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
      }
    },
    onClickBtnDeleteModel: function() {
      let url = `catalogs?id=${this.model.id}`
      this.$http.delete(url)   
          .then(response => {
            if (response.status == 200) {    
              this.$router.go(-1);
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
    },
    onClickCancel: function() {
      this.model.merge = 0;
      this.showConfirmButton = false;   
    },
    onClickBtnDeleteVehicleModification: function() {      
      let url = `vehiclemodifications/${this.vehicleModification.id}`        
      this.$http.delete(url)   
        .then(response => {
          if (response.status == 200) {              
            this.update = (new Date()).getTime();
            this.dialogModification = false;
          }  
        })
        .catch(error => {                                
            if (error.response.status === 401) this.$root.$emit("logout"); 
            else this.$root.$emit("serverError", error);     
        });
    },
    onClickBtnValidateVehicleModification: function() {
      let data= {
        id: this.vehicleModification.id,
        maker_id: this.vehicleModification.makerId,
        model_id: this.vehicleModification.modelId,
        modification_id: this.vehicleModification.modificationId,
        city_consumption: this.vehicleModification.cityConsumption,
        mixte_consumption: this.vehicleModification.mixteConsumption,
        highway_consumption: this.vehicleModification.highwayConsumption,
        co2: this.vehicleModification.co2
      }
      let url = `vehiclemodifications`
      if (this.vehicleModification.id == 0) {
        this.$http.post(url, data)   
          .then(response => {
            if (response.status == 200) {              
              this.update = (new Date()).getTime()
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
      }
      else {
        this.$http.put(url, data)   
          .then(response => {
            if (response.status == 200) {              
              this.update = (new Date()).getTime()
              this.dialogModification = false
            }  
          })
          .catch(error => {                                
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
      }
    },
    onClickRowVehicleModification: function(row) {
      this.vehicleModification = {...row}
      this.dialogModification = true     
    },
    onclickBtnAddVehicleModificication: function() {      
      this.vehicleModification = {
        id: 0,
        modelId: this.model.id,
        makerId: this.model.provider_id,
        modelName: this.model.name,
        modelGeneration: this.model.version,
        modificationId: 0,
        modificationName: '',
        modelEnergy: -1
      }
      this.dialogModification = true     
    },
     onClickRowModification: function(row) {
      this.vehicleModification.modificationId = row.id
      this.vehicleModification.modificationName = row.name
      this.vehicleModification.modelEnergy = row.family        
    },
    onClickRowVehicle: function(row) {      
      this.$router.push({ 
        name: 'Vehicle', 
        params: { 
          id: row.id
        }
      })
    },
    // Méthodes d'accès aux données
    // ----------------------------
    // Données vehicule
    get: function() {    
        if (this.$route.params.id !== 0) { 
          this.isLoading = true          
          let url = `catalogs/${this.$route.params.id}?provider`
          this.$http
              .get(url)            
              .then(response => {                        
                  if (response.status == 200) {                                        
                    this.model = response.data    
                    if (this.model.image === undefined || this.model.image === '') {
                      this.model.image = this.model.provider.image;
                    }                                                                                     
                    this.modelImage = `${this.$constants.application.resourcesURL}/images/${this.model.image}`;                                        
                    this.isNew = false
                  }                                          
                  this.isLoading = false 
              })
              .catch(error => {                
                  this.isLoading = false
                  if (error.response.status === 401) this.$root.$emit("logout"); 
                  else this.$root.$emit("serverError", error);     
              });                                  
        }        
    },
    getModels: function() {
        let makerId = this.$route.params.maker === undefined ? 0 : this.$route.params.maker
        this.isLoading = true
                
        let url = `catalogs/provider/${makerId}/vehicle_model`;                  
    
        this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.modelsList = [{id: 0, name: ''}, ...response.data.filter(el => el.id !== this.$route.params.id).map(el => { return {'id': el.id, 'name': `${el.name} ${el.version}` }; } )];
              } 
              this.isLoading = false             
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
      },
  },
  computed: {
    ...mapGetters([
      'userEnvironment'
    ]),
    title: function() {      
        let prefix = ''
        if (this.model.id !== undefined) prefix = (this.model.provider === undefined ? '' : this.model.provider.name + ' ')
        return prefix + (this.model.id == undefined ? "Nouveau model" : this.model.name + ' [' + this.model.version + ']')      
    }  
  },
  mounted() {                 
    if (this.$route.params.id !== 0) {
      this.get();      
      this.getModels();    
    }
    else {
      this.isNew = true;
      this.modelImage = `${this.$constants.application.resourcesURL}/images/${this.$route.params.provider.image}`;
      this.model = {
        id: 0,
        class: 'vehicle_model',
        name: '',
        version: '',
        type: 0,
        provider_id: this.$route.params.provider.id,
        provider: {...this.$route.params.provider}
      }
    }
    this.deletable = this.$route.params.deletable;
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;  
}

.v-btn--floating {
  position: relative;
}
</style>