<template>
    <v-card>
      <!-- PHOTOS GALLERY --->
      <v-card-actions>
          <a-photo-gallery-action-bar :show-close-btn="photoGalleryBarStyle == 'photo'" bar-title="Photos" :count="Object.values(filteredImageList).length" @close="photoGalleryBarStyle = 'gallery'"/>
      </v-card-actions>
      <v-fade-transition>
      <v-card-text v-show="photoGalleryBarStyle == 'gallery'">
          <v-row>
              <v-col
              v-for="(image, index) in filteredImageList"
              :key="index"
              class="d-flex child-flex"
              cols="3"
              >
              <v-img                  
                  :src="image"   
                  :lazy-src="image"          
                  aspect-ratio="1"   
                  contain               
                  class="grey lighten-5"
                  @click="onClickImage(index)"
              >
                  <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"                      
                      justify="center"
                      align="center"
                  >
                      <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                      ></v-progress-circular>
                  </v-row>
                  </template>
              </v-img>
              </v-col>
          </v-row>
        </v-card-text>
      </v-fade-transition>

        <v-fade-transition>
        <v-card-text v-show="photoGalleryBarStyle == 'photo'">
          <v-row>
              <v-col cols="12" class="text-center">  
                  <img                    
                    :src="selectedImage"                     
                    style="max-width: 100%;"
                    
                  />            
                                         
              </v-col>
          </v-row>        
      </v-card-text>
    </v-fade-transition>
  </v-card>       
</template>

<script>
import APhotoGalleryActionBar from "@/components/APhotoGalleryActionBar"

export default {
    name: "APhotoGallery",
    components: { APhotoGalleryActionBar },
    props: {
      barTitle: {
        type: String,
        default: "Photo"
      },   
      imageList: {
        type: Array
      },
      base64: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {  
        photoGalleryBarStyle: 'gallery', 
        selectedImage: ''            
      }    
    },
    methods: {
      onClickImage: function(index) {
        this.selectedImage = this.imageList[index]
        this.photoGalleryBarStyle = "photo"
    },    
  },
  computed: {
      filteredImageList: function() {
        return this.imageList.filter(el => el !== undefined && el !== null)       
      }
    }
}
</script>

<style lang="scss" scoped>
 
</style>