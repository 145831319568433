<template>
  <v-container fluid>
    <a-page-header title="Demandes" icon="flaticon-question"/>       

    <v-divider/>

     <v-row>
        <v-col sm="12">
          <v-tabs v-model="tab" >
              <v-tab href="#tab-1" @click="onClickTab('stream')">Flux</v-tab>
              <v-tab href="#tab-2" @click="onClickTab('activated')">Actives</v-tab>
              <v-tab href="#tab-3" @click="onClickTab('completed')">Terminées</v-tab>
              <v-tab href="#tab-4" @click="onClickTab('failed')">Archivées</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" >
            <v-tab-item value="tab-1">
              <v-card flat>
                <v-card-text>
                    <div>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                  </div>

                  <v-list>
                    <a-request-item v-for="item in filteredRequests.stream" :item="item" :key="item.id" @select="onClickRequestItem"/>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
              <v-card flat>
                <v-card-text>
                  <div>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                  </div>

                  <v-row>
                    <v-col sm="2">
                      <v-switch
                        v-model="showAppointment"
                        flat
                        dense
                        label="Rendez-vous"
                      ></v-switch>
                    </v-col>

                    <v-col sm="2">
                      <v-switch
                        v-model="showAssistance"
                        flat
                        dense
                        label="Assistances"
                      ></v-switch>
                    </v-col>
                  </v-row>


                  <div class="text-center mt-10" v-if="isLoading">                                      
                    <v-progress-circular
                      :size="50"
                      :width="3"                      
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <v-list v-else>
                    <a-request-item v-for="item in filteredRequests.activated" 
                      :item="item" 
                      :key="item.id" 
                      :btn-delete="showActionButton"
                      @delete="deleteItem"
                      @select="onClickRequestItem"/>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-3">
              <v-card flat>
                <v-card-text>
                  <div>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                  </div>

                  <v-row>
                    <v-col sm="2">
                      <v-switch
                        v-model="showAppointment"
                        flat
                        dense
                        label="Rendez-vous"
                      ></v-switch>
                    </v-col>

                    <v-col sm="2">
                      <v-switch
                        v-model="showAssistance"
                        flat
                        dense
                        label="Assistances"
                      ></v-switch>
                    </v-col>
                  </v-row>

                  <div class="text-center mt-10" v-if="isLoading">                                      
                    <v-progress-circular
                      :size="50"
                      :width="3"                      
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <v-list v-else>
                    <a-request-item v-for="item in filteredRequests.completed" 
                      :item="item" 
                      :key="item.id"
                      :btn-delete="showActionButton"
                      @delete="deleteItem"
                      @select="onClickRequestItem"/>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-4">
              <v-card flat>
                <v-card-text>
                  <div>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                  </div>    
                  
                  <v-row>
                    <v-col sm="2">
                      <v-switch
                        v-model="showAppointment"
                        flat
                        dense
                        label="Rendez-vous"
                      ></v-switch>
                    </v-col>

                    <v-col sm="2">
                      <v-switch
                        v-model="showAssistance"
                        flat
                        dense
                        label="Assistances"
                      ></v-switch>
                    </v-col>
                  </v-row>

                  <div class="text-center mt-10" v-if="isLoading">                                      
                    <v-progress-circular
                      :size="50"
                      :width="3"                      
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <v-list v-else>
                    <a-request-item v-for="item in filteredRequests.failed" 
                      :item="item" 
                      :key="item.id" 
                      :btn-delete="showActionButton"
                      @delete="deleteItem"
                      @select="onClickRequestItem"/>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
     </v-row>
            
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import APageHeader from "@/components/APageHeader"
import ARequestItem from "@/components/ARequestItem"

export default {
  name: 'Requests',
  components: { APageHeader, ARequestItem },
  data: () => ({
    intervalId: undefined,
    interval: 10000,
    isLoading: false,
    showAppointment: true,
    showAssistance: true,
    requests: {
      stream: [],
      activated: [],
      completed: [],
      failed: []
    },
    filteredRequests: {
      stream: [],
      activated: [],
      completed: [],
      failed: []
    },
    tab: null,
    status: 'stream',
    search: ''
  }),
  methods: {
    // Méthodes d'évènements
    // ---------------------
    onClickRequestItem: function(id) {
      this.$router.push({ 
        name: 'Request', 
        params: { 
          id: id
        }
      })    
    },

    onClickTab: function(status) {      
      if (status !== undefined) this.status = status     
      this.search = ''
      this.showAssistance = true
      this.showAppointment = true
      this.get()
    },

    // Méthodes d'accès aux données
    // ----------------------------
    // Liste des demandes de l'utilisateur
    get: function() {         
        this.isLoading = true
        let url = `requests/${this.status}`      
        this.$http
            .get(url)            
            .then(response => {                        
                if (response.status == 200 || response.status == 204) {
                  this.requests[this.status] = response.data       
                  this.filterRequests()                                                                                                                        
                }                                           
                this.isLoading = false 
            })
            .catch(error => {  
              console.log(error)              
                this.isLoading = false
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
    },

    // Méthodes fonctionnelles
    // -----------------------
    deleteItem: function(id) {
      this.requests[this.status] = this.requests[this.status].filter(el => el.id !== id) 
      this.filteredRequests[this.status] = this.filteredRequests[this.status].filter(el => el.id !== id) 
    },
    // Filtre les demandes du status en cour en fonction du contenu de la zone de recherche
    filterRequests: function() {         
      if (!this.requests[this.status]) return [];

      this.filteredRequests[this.status] = this.requests[this.status].filter(el =>  {  
            let filterResult = true
            if (this.search && this.search != '') {
              filterResult = `${el.requester.lastname} ${el.requester.firstname} ${el.requester.work.name} ${el.requester.work.agency ? el.requester.work.agency.name : ''} ${el.vehicle ? el.vehicle.registration : ''}}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1  
            }
            if (filterResult) {              
              if (this.showAppointment && el.type == this.$constants.dossiers.TYPE_APPOINTMENT) filterResult = true
              else if (this.showAssistance && el.type == this.$constants.dossiers.TYPE_ASSISTANCE) filterResult = true              
              else filterResult = false
            }
           
            return filterResult
        })     
    }

  },
  watch: {
    search: function() {      
       this.filterRequests()
    },
    showAppointment: function() { 
      this.filterRequests()
    },
    showAssistance: function() { 
      this.filterRequests()
    }
  },
  computed: {
    ...mapGetters([
      'userEnvironment',
      'profile'
    ]),
    showActionButton: function() {  return this.profile.is_su || this.profile.role == this.$constants.users.USER_ROLE_MANAGER || this.profile.role == this.$constants.users.USER_ROLE_ADMIN }
  },
  mounted() {
    this.get();
    this.intervalId = setInterval(() => { 
        if (this.status == 'stream') this.get()
      }, 
      this.interval)
  },
  destroyed() {
    clearInterval(this.intervalId)
  }
}
</script>

<style scoped>

</style>