export default class Label {
    constructor(code, value, text) {
        this.code = code;
        this.value = value;        
        this.text = text;
    } 
}

export const ServiceLabelCollection = [
    new Label("overhaul", 1, "Revision/Vidange"),
    new Label("tires", 2, "Pneumatiques"),        
    new Label("brakepads", 3, "Plaquettes de frein"),
    new Label("misc", 4, "Divers/Réparation"),
    new Label("technical_control", 5, "Contrôle technique"),    
];