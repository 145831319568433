<template>
  <div>
    <div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          clearable
        ></v-text-field>
    </div>

    <div class="text-right py-3" v-if="addBtn">
      <v-btn                    
        icon 
        elevation="2"
        @click="onClickBtnAdd"
        title="Ajouter un modele">
          <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
      
    <v-data-table
      v-model="selectedItems"
      :headers="headersList"
      :items="listItems"
      :search="search"
      :single-select="singleSelect"
      :show-select="showSelect"        
      item-key="id"      
      :loading="isLoading"
      loading-text="Chargement en cours..."
      locale="fr-FR"   
      disable-pagination
      hide-default-footer    
      @item-selected="onItemSelected"
      @click:row="onClickRow"          
      ref="dataTable"
    >      
      
    <template v-slot:item.count="{ item }">
      <v-chip
        color="default"
        dark
      >
        {{ item.count }}
      </v-chip>
    </template>             
                        
    </v-data-table>
  </div>             
</template>

<script>
 
export default {
    name: "ALineList",  
    props: {
      makerId: {
        type: Number,
        default: 0
      },
      showSelect: {
        type: Boolean,
        default: false
      },
      singleSelect: {
        type: Boolean,
        default: false
      },
      defaultSearch: {
        type: String,
        default: ''
      },
      small: {
        type: Boolean,
        default: false
      },
      selectedIds: {
        type: String,
        default: ""
      },
      showImage: {
        type: Boolean,
        default: false
      },
      addBtn: {
        type: Boolean,
        default: false
      }
    }, 
    data() {
      return {
        isLoading: true,        
        // Table des conducteurs
        headersList: [                                    
          { text: "Nom", value: "name" },
          { text: "Utilisation", value: "count", align: 'end'}
        ],
        selectedItems: [],
        listItems: [],
        search: ''
      }    
    },
    methods: {
      get: function() {
        this.isLoading = true

        
        let url = `catalogs/provider/${this.makerId}/vehicle_line?count`                  
    
        this.$http
          .get(url)            
          .then(response => {                        
              if (response.status == 200) {
                this.listItems = response.data
                this.search = this.defaultSearch          
                
                let selected = this.selectedIds.split(',').map(element => parseInt(element));
                this.listItems.forEach(element => {                  
                  if (selected.indexOf(element.id) != -1) this.selectedItems.push(element)                  
                });

                this.$emit('loaded', this.listItems);
              } 
              this.isLoading = false             
          })
          .catch(error => {                
              this.isLoading = false
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          })
      },
      getSelectedItems: function() {
        return this.selectedItems
      },      
      resetSelectedItems: function() {
        this.selectedItems = []
      },
      onItemSelected: function(event) { 
        this.$emit('itemSelected', event)
        this.$nextTick(() => {this.$emit('change', this.selectedItems)})
      },      
      onClickRow: function(event) {
        this.$emit('clickRow', event)
      },
      onClickBtnAdd: function(event) {
        this.$emit('clickBtnAdd', event)
      },
      updateRow: function(row) {
        this.listItems = this.listItems.map(el => {
          if (el.id === row.id) return row 
          else return el
        })
      }
    },
    mounted() {         
      this.get()      
    }
}
</script>

<style lang="scss" scoped>
 
</style>