<template>
<div>
  <v-container fluid>
    <a-page-header :title="title" icon="flaticon-portfolio" :chip-text="status" :chip-color="color" icon-pencil @update="isUpdatingStatus = !isUpdatingStatus"/>       
    <v-divider/>

    <v-expand-transition>
        <a-status-changer v-show="isUpdatingStatus" 
            @change="onChangeStatus($event)" />    
    </v-expand-transition>

    <template v-if="item != undefined">
    <v-row>
        <!-- DEMANDEUR -->
        <v-col md="6">
            <a-user-header :user="item.recipient" @click="onClickUserHeader()"></a-user-header>
        </v-col>

        <!-- VEHICULE -->    
        <v-col md="6">
            <a-vehicle-header :vehicle="vehicle" @click="onClickVehicleHeader()"></a-vehicle-header>
        </v-col>
    </v-row>    
    
    <v-row>        
        <!-- DEMANDE TYPE : RENDEZ-VOUS ENTRETIEN VEHICULE -->
        <v-col md="12" id="request">           
            <v-speed-dial
                v-model="fab"    
                top
                right        
                direction="bottom"
                open-on-hover
                transition="slide-y-reverse-transition"
            >
                <template v-slot:activator>
                    <v-btn
                        v-model="fab"
                        color="blue darken-2"
                        dark
                        fab
                    >
                        <v-icon v-if="fab">mdi-close</v-icon>
                        <v-icon v-else >mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-btn
                    ref="fabRecall"
                    fab
                    dark
                    small
                    color="indigo"
                    @click="onClickFabRecall()"
                >
                <v-icon size="20">flaticon-update-arrow</v-icon>
                </v-btn>
                
                <v-btn
                fab
                dark
                small
                color="red"
                @click="onClickFabDelete()"
                >
                <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-speed-dial>
        </v-col>        
    </v-row>    

    <!-- AFFICHAGE SELON TYPE DU DOSSIER-->
     <!-- RELEVE KILOMETRIQUE --->
    <v-row v-if="item.type == $constants.dossiers.TYPE_VEHICLE_MILEAGE">
        <v-col md="12">
            <v-card>
                <v-card-title>Relevé compteur</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col sm="12" class="title">    
                            <v-icon size=32 class="mr-5">flaticon-speed</v-icon>                                                    
                            {{item.vehicle_mileage.distance}} km
                        </v-col>                 
                    </v-row>
                    </v-card-text>
            </v-card>                   
        </v-col>
    </v-row>

    <!-- AUDIT VEHICULE --->
    <v-row v-if="item.type == $constants.dossiers.TYPE_VEHICLE_AUDIT">
        <v-col md="12">
            <v-card>
                <v-card-title>Relevé compteur</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col sm="12" class="title">    
                            <v-icon size=32 class="mr-5">flaticon-speed</v-icon>                                                    
                            {{item.vehicle_audit.distance}} km
                        </v-col>                 
                    </v-row>
                    </v-card-text>
            </v-card>                   
        </v-col>
    </v-row>

    <v-row v-if="item.type == $constants.dossiers.TYPE_VEHICLE_AUDIT">
        <v-col md="12">                      
            <a-photo-gallery v-if="item.type == $constants.dossiers.TYPE_VEHICLE_AUDIT" bar-title="Photos" :image-list="imageList" />                   
        </v-col>
    </v-row>

    <v-row v-if="activities !== undefined">
        <!-- FLUX D'ACTIVITE SUR LA DEMANDE -->
        <v-col md="12">
            <v-card>
                <v-card-actions>
                    <a-activity-stream-action-bar bar-style="request" bar-title="Flux d'activitées" />
                </v-card-actions>

                <v-card-text>
                    <a-activity-stream 
                        v-if="activity" :activities="activities"
                        @change="get(false)"
                        @update-activity="onUpdateActivity($event)"
                    />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    </template>

  </v-container>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import APageHeader from "@/components/APageHeader"
import AVehicleHeader from "@/components/AVehicleHeader"
import AUserHeader from "@/components/AUserHeader"
import AStatusChanger from '@/components/AStatusChanger.vue'

import AActivityStream from "@/components/AActivityStream"
import AActivityStreamActionBar from "@/components/AActivityStreamActionBar"
import APhotoGallery from "@/components/APhotoGallery"


import mapboxgl from 'mapbox-gl';


export default {
  name: 'Dossier',
  components: { APageHeader, AVehicleHeader, AUserHeader, AActivityStream, AActivityStreamActionBar, AStatusChanger, APhotoGallery },
  props: {
        request: Object
    },
  data: () => ({
    activities: undefined,
    activity: undefined,
    title: '',
    status: '',
    color: '',
    timerId: 0,
    timer: 5000,
    item: undefined,
    localization: {markers: {}},
    fab: false,
    dialogBarColor: "",
    dialogBarTitle: "",
    map: undefined,
    isUpdatingStatus: false,
    vehicle: {}   
  }),
  methods: {
    // Méthodes d'accès aux données
    // ----------------------------
    get: function(initTimer = true) {      
      let url = `dossiers/${this.$route.params.id}`      
      this.$http
          .get(url)            
          .then(function(response) {                        
              if (response.status == 200) {                
                this.item = response.data 
                this.activities = this.item.activities
                this.activity = this.item.activity
                this.defineContent()
                this.defineStatusCSS()   
                this.defineRequestDisplay()     
                if (initTimer) {
                    this.timerId = setTimeout(() => {
                            this.get()
                        }, 
                        this.timer
                    )
                }                 
                if (this.item.params !== null) {
                    if (this.item.params.location !== undefined && this.map === undefined) this.initMap()
                }
              }                                          
          }.bind(this))
          .catch(error => {                
              console.log(error)              
              if (error.response.status === 401) this.$root.$emit("logout"); 
              else this.$root.$emit("serverError", error);     
          });
    },
    // Méthodes d'évènements
    // ---------------------
    onClickFabDelete: function() {
        let url = `dossiers?ids=${this.item.id}`      
        this.$http
            .delete(url)            
            .then(() => { 
                this.$router.go(-1)                                                                             
            })
            .catch(error => {                              
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });   
    },
    onClickFabRecall: function() {
        this.onChangeStatus(this.$constants.status.STATUS_RECALLED)
    },
    onChangeStatus: function(status) {
        this.item.status = status
        this.defineStatusCSS()
        this.isUpdatingStatus = false

        let url = `dossiers/${this.item.id}/${this.statusApiParams}`
        this.$http
            .put(url)            
            .then(response => {                        
                if (response.status == 200) {
                     console.log('ok')                             
                }                                          
            })
            .catch(error => {                                     
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });    
    },
    onClickUserHeader: function() {      
      this.$router.push({ 
        name: 'Profile', 
        params: { 
          id: this.item.requester.id
        }
      })
    },
    onClickVehicleHeader: function() {      
      this.$router.push({ 
        name: 'Vehicle', 
        params: { 
          id: this.item.vehicle.id
        }
      })
    },
    onUpdateActivity: function(activity) {        
        this.$refs.modalProviderSelector.show(activity.appointment)
    },
    // Méthodes du composant
    // ---------------------    
    // Determination des composants a afficher en fonction du type de la demande
    defineRequestDisplay: function() {            
        switch (this.item.type) {
            case this.$constants.dossiers.TYPE_VEHICLE_AUDIT:
                                                         
                this.title = "AUDIT VEHICULE"
                this.vehicle = this.item.vehicle_audit.vehicle                                    
                        
                break

            case this.$constants.dossiers.TYPE_VEHICLE_MILEAGE:
                                                         
                this.title = "RELEVE KILOMETRIQUE"
                this.vehicle = this.item.vehicle_mileage.vehicle                                    
                        
                break            
            
            default:
        }

        if (this.vehicle.fullpath_image_file !== null) this.vehicle.fullpath_image_file = `${this.$constants.application.resourcesURL}/${this.vehicle.fullpath_image_file}`

    },
    defineContent: function() {
            switch (this.item.type) {
                case this.$constants.dossiers.TYPE_APPOINTMENT:                    
                    this.title = "Rendez-vous véhicule"
                    this.icon = '&#xf121;'
                    this.content = `${this.item.vehicle.model.brand} ${this.item.vehicle.model.model} : ${this.item.vehicle.registration}`
                    break

                case this.$constants.dossiers.TYPE_ASSISTANCE:
                    
                    switch (parseInt(this.item.params.type)) {
                        case this.$constants.assistances.TYPE_ACCIDENT:
                            this.title = "Déclaration d'accident"
                            break

                        case this.$constants.assistances.TYPE_BREAKDOWN:
                            this.title = "Assistance dépannage panne"
                            break

                        case this.$constants.assistances.TYPE_BROKEN_GLASS:
                            this.title = "Déclaration de bris de glace"
                            break

                        case this.$constants.assistances.TYPE_TIRES:
                            this.title = "Assistance dépannage pneumatique"
                            break

                        case this.$constants.assistances.TYPE_THEFT:
                            this.title = "Déclaration de vol"
                            break

                        default:
                            this.title = "Assistance diverse"
                            break
                    }
                    this.icon = '&#xf105;'
                    this.content = `${this.item.vehicle.model.brand} ${this.item.vehicle.model.model} : ${this.item.vehicle.registration}`
                    break

                default:
                    break
            }
        },        
        defineStatusCSS: function() {
            switch (this.item.status) {
                case this.$constants.status.STATUS_CREATE:
                    this.status = "Nouveau"  
                    this.statusApiParams = "new"                  
                    this.color = this.$constants.colors.STATUS_NEW                    
                    break
                case this.$constants.status.STATUS_RECEIVED:
                    this.status = "Reçue"
                    this.statusApiParams = "received"
                    this.color = this.$constants.colors.STATUS_RECEIVED                    
                    break
                case this.$constants.status.STATUS_RECALLED:
                    this.status = "Relancé"
                    this.statusApiParams = "recalled"
                    this.color = this.$constants.colors.STATUS_RECALLED                    
                    break
                case this.$constants.status.STATUS_PROCESSING:
                    this.status = "En cours"
                    this.statusApiParams = "processing"
                    this.color = this.$constants.colors.STATUS_PROCESSING                    
                    break
                case this.$constants.status.STATUS_STANDBY:
                    this.status = "En attente"
                    this.statusApiParams = "waiting"
                    this.color = this.$constants.colors.STATUS_WAITING                   
                    break
                case this.$constants.status.STATUS_WAITING_USER_INFOS:
                    this.status = "En attente d'information"
                    this.statusApiParams = "waiting_user"
                    this.color = this.$constants.colors.STATUS_WAITING_INFOS                    
                    break
                case this.$constants.status.STATUS_WAITING_PROVIDER_INFOS:
                    this.status = "En attente fournisseur"
                    this.statusApiParams = "waiting_provider"
                    this.color = this.$constants.colors.STATUS_WAITING_PROVIDER_INFOS                    
                    break
                case this.$constants.status.STATUS_COMPLETED:
                    this.status = "Réalisée"
                    this.statusApiParams = "complete"
                    this.color = this.$constants.colors.STATUS_COMPLETED                    
                    break
                case this.$constants.status.STATUS_CANCELED:
                    this.status = "Annulée"
                    this.statusApiParams = "cancel"
                    this.color = this.$constants.colors.STATUS_CANCELED                    
                    break
                case this.$constants.status.STATUS_REFUSED:
                    this.status = "Refusée"
                    this.statusApiParams = "new"
                    this.color = this.$constants.colors.STATUS_REFUSED                    
                    break
                case this.$constants.status.STATUS_ARCHIVED:
                    this.status = "Archivée"
                    this.statusApiParams = "archived"
                    this.color = this.$constants.colors.STATUS_ARCHIVED                    
                    break
                default:
                    break

            }
        },
        initMap: function() {
            this.$nextTick(() => {
            let center = []
            let lat = this.item.params.location.geocoding.latitude
            let lng = this.item.params.location.geocoding.longitude            
            
            center = [lng, lat]

            mapboxgl.accessToken = 'pk.eyJ1IjoiZnNnYXJkIiwiYSI6ImNrODVtanhmcTA3Y3YzZ3BqbXBpaXcwY2EifQ.Q9J9kR1nowA7GThkE7SCWw'
            this.map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
                center: center, // starting position [lng, lat]
                zoom: 17, // starting zoom
                attributionControl: false
            });                  

            this.map.on('load',
                function() {
                    let popupHTMLContent = this.item.params.location.geocoding.place_name.replace(/,/g, "</br>")
                    // create the popup
                    var popup = new mapboxgl.Popup({ offset: 25, closeButton: false }).setHTML(popupHTMLContent)    

                    let marker = new mapboxgl.Marker()
                        .setLngLat([lng, lat])
                        .setPopup(popup)  
                         .addTo(this.map)
                    
                    marker.togglePopup()   
                 }.bind(this))                
            })
      }      
  },
  computed: {
    ...mapGetters([
      'userEnvironment'
    ]),
    imageList: function() {
        return Object.values(this.item.vehicle_audit.image_files).filter(el => el !== null).map(el => `${this.$constants.application.resourcesURL}/${el}`)
    }    
  },
  mounted() {             
    this.get()
  },
  destroyed() {
      clearTimeout(this.timerId)
  }
}
</script>

<style scoped>
#map { position: absolute; top: 0.5%; bottom: 0.5%; left: 0.5%; height: 99%; width: 99%; overflow: hidden;}

#request .v-speed-dial {
  position: absolute;  
}

#request .v-btn--floating {
  position: relative;
}

</style>