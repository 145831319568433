import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access_token: '',
    renewal_token: '',
    expire_in: 0,
    push_token: '',
    ip:'',
    offset: (new Date()).getTimezoneOffset(),
    functionalities: [],
    showHeader: true,
    showFooter: true,
    environment: 'db000000',
    appBarCaption: 'AskMe',
    isHello: true,
    profile: {},
    preLoadVehicles: [],
    vehicleFilters: {},
    listItems: {}
  },
  getters: {
    isViewableHeader: (state) => state.showHeader,
    isViewableFooter: (state) => state.showFooter,    
    isHello: (state) => state.isHello,
    userEnvironment: (state) => state.environment,
    profile: (state) => state.profile,
    appBarCaption: (state) => state.appBarCaption,
    preLoadVehicles: (state) => state.preLoadVehicles,
    vehicleFilters: (state) => state.vehicleFilters,
    listItems: (state) => state.listItems
  },
  mutations: {
    setViewableHeader: (state, value) => {state.showHeader = value},
    setViewableFooter: (state, value) => {state.showFooter = value},
    setAppBarCaption: (state, value) => {state.appBarCaption = value},
    setHello: (state, value) => {state.isHello = value},
    setProfile: (state, profile) => {state.profile = profile},
    setFunctionalities: (state, functionalities) => {state.functionalities = functionalities},    
    setAccessToken: (state, token) => {state.access_token = token},
    setPushToken: (state, token) => {state.access_token = token},
    setRenewalToken: (state, token) => {state.renewal_token = token},
    setExpirationTime: (state, time) => {state.expire_in = time},
    setIp: (state, ip) => {state.ip = ip},
    setPreLoadVehicles: (state, vehicles) => {state.preLoadVehicles = vehicles},
    setVehicleFilters: (state, filters) => {
      state.vehicleFilters = filters
      localStorage.setItem('askme.filters.vehicles', JSON.stringify(filters))
    }
  },
  actions: {
    logout(context) {
        context.commit('setAccessToken', '');
        context.commit('setRenewalToken', '');
        context.commit('setExpirationTime', 0);
        context.commit('setProfile', {});        
    },
    login(context, loginData) {
      const axiosConfig = {        
        headers: {'Content-Type': 'application/json'}
      };    
            
      return new Promise((resolve, reject) => {
        if (loginData.user === '') reject()
        //const url = 'http://192.168.1.47:8888/api/app/login';      
        //const url = 'https://ask.ipcvt.org/api/app/login';   
        const url = "login";       
        Axios
          .post(url, loginData, axiosConfig)            
          .then(response => {        
            if (response.status === 200) {    
                localStorage.setItem('user-token', response.data.access_token) 
                Axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`
                context.commit('setAccessToken', response.data.access_token);
                context.commit('setRenewalToken', response.data.renewal_token);
                context.commit('setExpirationTime', response.data.expire_in);
                let info = {
                  name: loginData.user,
                };
                context.commit('setProfile', info);   
                 
                resolve();                
            } else {
              reject();
            }
          })
          .catch(error => {reject(error)}); 
      });           
    },
    renewalToken(context) {
      const axiosConfig = {headers: {'Content-Type': 'application/json'}};           
      return new Promise((resolve, reject) => {
        const url = "renewal";             
        const renewalToken = {token: context.state.renewal_token};        
        Vue.prototype.$http
          .post(url, renewalToken, axiosConfig)            
          .then(response => {              
            if (response.status === 200) {                
                context.commit('setAccessToken', response.data.access_token);
                context.commit('setRenewalToken', response.data.renewal_token);
                context.commit('setExpirationTime', response.data.expire_in);                            
                resolve(response);                
            } else {
              reject();
            }
          })
          .catch(error => {reject(error)}); 
      });           
    },
    initFiltersVehicle(context) {
      let filters = localStorage.getItem('askme.filters.vehicles')
      if (filters !== null) context.state.vehicleFilters = JSON.parse(localStorage.getItem('askme.filters.vehicles'))
    },
    getListItems(context, name) {
      return new Promise((resolve, reject) => {
        if (context.state.listItems.hasOwnProperty(name)) resolve(context.state.listItems[name])
        else {
          let url = `listitems/${name}`
          Vue.prototype.$http
              .get(url)            
              .then(response => {                        
                  if (response.status == 200) {                  
                    context.state.listItems[name] = response.data        
                    resolve(context.state.listItems[name])          
                  }      
                  else reject()        
              })                              
              .catch(error => {reject(error)});             
          }
      })
    }
  },
  modules: {
  }
})
