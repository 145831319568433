<template>
  <v-card @click="$emit('click', $event)">
    <v-card-text>
      <v-row>
        <v-col md="6" sm="12" class="text-center">
          <v-avatar size="120" color="grey">            
              <span class="white--text display-2">{{text}}</span>        
          </v-avatar>
        </v-col>     
        <v-col md="6" sm="12">                                     
            <div :class="$vuetify.theme.dark ? 'white--text' : 'black--text'" class="title">{{user.firstname}} {{user.lastname}}</div>                         
            <div class="body-2">{{user.phone}}</div>
            <div class="body-2">{{user.mobile}}</div>
            <div class="body-2 blue--text">{{user.mail}}</div>
        </v-col>                                         
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
 
export default {
    name: "AUserHeader",
    props: {
      user: Object
    },
    data() {
      return {
        text: ''
      }    
    },
    updated() {      
        this.text = this.user.firstname.substr(0, 1) + this.user.lastname.substr(0, 1)
        this.text = this.text.toUpperCase()
    },
    mounted() {      
        this.text = this.user.firstname.substr(0, 1) + this.user.lastname.substr(0, 1)
        this.text = this.text.toUpperCase()
    }
}
</script>

<style lang="scss" scoped>
 
</style>